import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Icons, MinLevel } from "..";
import logo from "../../assets/images/logo-white.png";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { AppStateContext } from "../functional/Provider";
import calls from "../../graphql";
import { userRoleLevels } from "../../utils";
const { LOGOUT } = calls;

/**
 * App navigation header
 * @param {node} children - Header content after breadcrumbs
 * @param {string} page - Page to be highlighted
 * @param {string} initials - Initials of the current user
 * @param {node} breadcrumbs - Current page breadcrumbs
 * @returns {node} React header component
 */
function Header({ children, page, initials, breadcrumbs }) {
  // Hooks
  const { t } = useTranslation();
  const { appSetLogout } = useContext(AppStateContext);
  const history = useHistory();
  // State
  const [modalOpen, setModalOpen] = useState(false);
  // Queries
  // Mutations
  const [logout] = useMutation(LOGOUT);

  const onClickLogout = async () => {
    try {
      const logoutResult = await logout();
      if (logoutResult.data && logoutResult.data.logout) {
        appSetLogout();
        history.replace("/login");
      }
    } catch (error) {
      appSetLogout();
      history.replace("/login");
    }
  };

  const navLinks = {
    Projects: t("components.header.links.projects", "Projects"),
    Clients: t("components.header.links.clients", "Clients"),
    Templates: t("components.header.links.templates", "Templates"),
  };

  return (
    <>
      <header className="navbar bg-neutral px-5 md:px-12 text-neutral-content">
        <div className="navbar-start">
          <div className="flex flex-col items-center justify-center drawer-content">
            <label
              htmlFor="my-drawer"
              className="btn btn-circle text-3xl drawer-button md:hidden "
            >
              <Icons.Menu />
            </label>
          </div>
          <div className="hidden md:block">
            <Link to="/">
              <img className="h-10" src={logo} alt="Impuls Logo" />
            </Link>
          </div>
        </div>

        <div className="navbar-center">
          <div className="md:hidden">
            <Link to="/">
              <img className="h-10 md:hidden" src={logo} alt="Impuls Logo" />
            </Link>
          </div>
        </div>

        <div className="navbar-end space-x-2">
          <div className="hidden md:flex items-center space-x-2">
            {Object.entries(navLinks).map(([key, translation]) => (
              <Button
                key={key}
                isLink={true}
                to={`/${key.toLocaleLowerCase()}`}
                color={page === key ? "primary" : "neutral"}
                size="sm"
              >
                {translation}
              </Button>
            ))}
          </div>
          {initials ? (
            <>
              <button
                className="avatar placeholder"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <div className="btn bg-neutral text-neutral-content rounded-full w-12 h-12">
                  <span className="text-xl">{initials}</span>
                </div>
              </button>
              {modalOpen ? (
                <ul className="menu px-5 py-3 shadow-lg bg-base-100 text-base-content rounded-box absolute top-16 z-50">
                  <li>
                    <Button
                      color="ghost"
                      isLink={true}
                      to="/settings"
                      start={<Icons.Settings className="text-xl" />}
                      className="text-lg min-w-36"
                    >
                      {t("components.header.settings", "Settings")}
                    </Button>
                  </li>
                  <MinLevel minLevel={userRoleLevels.Admin}>
                    <li>
                      <Button
                        color="ghost"
                        isLink={true}
                        to="/users"
                        start={<Icons.Group className="text-xl" />}
                        className="text-lg min-w-36"
                      >
                        {t("components.header.users", "Users")}
                      </Button>
                    </li>
                  </MinLevel>
                  <li>
                    <Button
                      color="ghost"
                      onClick={onClickLogout}
                      start={<Icons.Logout className="text-xl" />}
                      className="text-lg min-w-36"
                    >
                      {t("components.header.logout", "Log out")}
                    </Button>
                  </li>
                </ul>
              ) : undefined}
            </>
          ) : undefined}
        </div>
      </header>
      <nav className="navbar py-5 px-5 md:px-12 shadow-sm">
        <div className="flex-grow">{breadcrumbs}</div>
        <div>{children}</div>
      </nav>
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-side">
        <label
          htmlFor="my-drawer"
          className="drawer-overlay absolute top-16 bottom-0 left-0 right-0"
        ></label>
        <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content absolute top-16 bottom-0">
          {Object.entries(navLinks).map(([key, translation]) => {
            return (
              <li key={key}>
                <Link key={key} to={`/${key.toLocaleLowerCase()}`}>
                  {translation}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
  initials: PropTypes.string,
  breadcrumbs: PropTypes.node,
};

export default Header;
