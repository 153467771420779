import { gql } from "@apollo/client";

const CREATEPROJECT = gql`
  mutation CreateProject(
    $label: String!
    $description: String
    $clientId: ID!
    $createdById: ID!
    $assignedToId: ID
  ) {
    createProject(
      project: {
        label: $label
        description: $description
        clientId: $clientId
        createdById: $createdById
        assignedToId: $assignedToId
      }
    ) {
      id
    }
  }
`;

const UPDATEPROJECT = gql`
  mutation UpdateProject(
    $id: ID!
    $label: String
    $description: String
    $clientId: ID
    $createdById: ID
    $assignedToId: ID
  ) {
    updateProject(
      id: $id
      project: {
        label: $label
        description: $description
        clientId: $clientId
        createdById: $createdById
        assignedToId: $assignedToId
      }
    ) {
      id
    }
  }
`;

const DELETEPROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`;

const GETPROJECTS = gql`
  query GetProjects {
    projects {
      id
      label
      description
      createdAt
      updatedAt
      client {
        id
        name
        email
        industry
      }
      createdBy {
        id
        userName
        email
      }
      assignedTo {
        id
        userName
        email
      }
    }
  }
`;

const GETPROJECT = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      id
      label
      description
      createdAt
      updatedAt
      client {
        id
        name
        email
        industry
      }
      createdBy {
        id
        userName
        email
      }
      assignedTo {
        id
        userName
        email
      }
    }
  }
`;

const GETPROJECTSURVEYS = gql`
  query GetProjectSurveys($projectId: ID!) {
    project(id: $projectId) {
      id
      label
      surveys {
        id
        label
        description
        isArchived
        createdAt
        updatedAt
      }
    }
  }
`;

const calls = {
  CREATEPROJECT,
  UPDATEPROJECT,
  DELETEPROJECT,
  GETPROJECTS,
  GETPROJECT,
  GETPROJECTSURVEYS,
};

export default calls;
