import { gql } from "@apollo/client";

const SYNCTYPEFORMSURVEY = gql`
  mutation SyncTypeformSurvey($id: ID!) {
    syncTypeformSurvey(id: $id)
  }
`;

const DELETETYPEFORMSURVEY = gql`
  mutation DeleteTypeformSurvey($id: ID!) {
    deleteTypeformSurvey(id: $id)
  }
`;

const GETTYPEFORMSURVEYS = gql`
  query GetTypeformSurveys {
    typeformSurveys {
      id
      url
      reference
      language {
        id
        label
      }
    }
  }
`;

const GETTYPEFORMSURVEY = gql`
  query GetTypeformSurvey($id: ID!) {
    typeformSurvey(id: $id) {
      id
      url
      reference
      language {
        id
        label
      }
    }
  }
`;

const calls = {
  SYNCTYPEFORMSURVEY,
  DELETETYPEFORMSURVEY,
  GETTYPEFORMSURVEYS,
  GETTYPEFORMSURVEY,
};

export default calls;
