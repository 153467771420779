import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, QuestionsPage } from "../../components";

export default function Questions() {
  // Hooks
  const { surveyId } = useParams();
  const { t } = useTranslation();

  return (
    <QuestionsPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t(
                "templates.survey.questions.breadcrumbs.templates",
                "Templates"
              ),
              link: `/templates`,
            },
            {
              label: t(
                "templates.survey.questions.breadcrumbs.dashboard",
                "Dashboard"
              ),
              link: `/templates/${surveyId}/dashboard`,
            },
            {
              label: t(
                "templates.survey.questions.breadcrumbs.questions",
                "Questions"
              ),
              link: `/templates/${surveyId}/questions`,
            },
          ]}
          back={`/templates/${surveyId}/dashboard`}
        />
      }
      questionURL={`/templates/${surveyId}/questions`}
      surveyId={surveyId}
    />
  );
}
