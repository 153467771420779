import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Icons, Modal } from "..";

/**
 * Action modal window
 * A modal window containing some action or form
 * @param {node} children - Modal content
 * @param {string} title - Modal title
 * @param {node} actions - Action buttons at the bottom of the window
 * @param {boolean} modalOpen - Is modal open
 * @param {function} closeModal - Close modal function
 * @param {string} className - ClassName overwrite
 * @returns {node} React modal window component
 */
function ActionModal({
  children,
  title,
  actions,
  modalOpen,
  closeModal,
  className,
}) {
  const { t } = useTranslation();

  return (
    <Modal closeModal={closeModal} modalOpen={modalOpen} className={className}>
      <h1 className="text-xl font-bold text-center mb-2">{title}</h1>
      {children}
      <div className="-mx-6 -mb-6 px-6 py-4 mt-4 bg-base-200 sm:rounded-b-box flex flex-col space-y-2">
        {actions}
        <Button
          color="neutral"
          onClick={closeModal}
          size="sm"
          start={<Icons.Cancel className="text-xl" />}
        >
          {t("components.modal.cancel", "Cancel")}
        </Button>
      </div>
    </Modal>
  );
}

ActionModal.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  title: PropTypes.string,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  className: PropTypes.string,
};

export default ActionModal;
