import React from "react";
import PropTypes from "prop-types";

/**
 * Styled wrapper for the html table component
 * @param {node} head - Table headers
 * @param {node} children - Table body
 * @returns {node} Table
 */
function Table({ head, children }) {
  return (
    <div className="w-full overflow-auto">
      <table
        className="w-full border border-base-300 rounded-md border-separate shadow-md text-left"
        style={{ borderSpacing: "0px" }}
      >
        <thead className="uppercase text-sm text-gray-600 font-normal border-separate">
          {head}
        </thead>
        <tbody className="bg-base-100 text-gray-600">{children}</tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  children: PropTypes.node,
  head: PropTypes.node,
};

export default Table;
