import React, { useContext } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/images/logo-primary.png";
import { AppStateContext } from "./Provider";
import { Redirect } from "react-router-dom";

/**
 * Layout for the pages which can be viewed when not logged in.
 * When logged in, the user can be automatically redirected to the app.
 * @param {node} children - Page content
 * @param {img} hero - Page hero image
 * @param {boolean} redirect - True if the user should be redirected when logged in.
 * @returns {node} React login layout wrapper
 */
function LoginLayout({ children, hero, redirect }) {
  const { appState } = useContext(AppStateContext);
  const { loggedIn } = appState;

  return loggedIn && redirect ? (
    <Redirect to="/projects" />
  ) : (
    <div className="flex flex-col md:flex-row min-h-screen items-center">
      <div className="flex flex-col w-full min-h-screen md:w-7/12 px-10 md:px-15">
        <div className="w-56 pb-20 absolute top-5">
          <img src={logo} alt="Logo" className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col w-full justify-center flex-grow mt-20 mb-10">
          {children}
        </div>
      </div>

      <div className="w-full w-9/12 md:h-screen">
        <img src={hero} alt="Analysis" className="w-full h-full object-cover" />
        <svg
          className="absolute top-0 bottom-0 h-screen hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 100"
          preserveAspectRatio="none"
        >
          <polygon fill="white" points="10,0 0,100 0,0" />
        </svg>
      </div>
    </div>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node,
  hero: PropTypes.string.isRequired,
  redirect: PropTypes.bool,
};

LoginLayout.defaultProps = {
  children: undefined,
  hero: undefined,
  redirect: true,
};

export default LoginLayout;
