import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SelectQuestion, SelectCategories, SelectTags } from ".";
import { Select } from "../..";

function SelectQuestionOrCategoriesOrTags({
  level,
  onChangeLevel,
  allowedLevels,
  sections,
  questionCategories,
  selectedCategoryIds,
  setSelectedCategoryIds,
  questionTags,
  selectedTagIds,
  setSelectedTagIds,
  selectedQuestionId,
  setSelectedQuestionId,
  allowedQuestionTypes,
}) {
  // Hooks
  const { t } = useTranslation();
  // Constants
  const levels = [
    {
      label: t(
        "components.select-questions-or-categories-or-tags.levels.by-question",
        "By question"
      ),
      value: "question",
    },
    {
      label: t(
        "components.select-questions-or-categories-or-tags.levels.by-category",
        "By categories"
      ),
      value: "category",
    },
    {
      label: t(
        "components.select-questions-or-categories-or-tags.levels.by-tag",
        "By tags"
      ),
      value: "tag",
    },
  ];

  return (
    <>
      <Select
        id="select-level"
        label={t(
          "components.select-questions-or-categories-or-tags.select-level-label",
          "Level"
        )}
        value={level}
        placeholderValue={""}
        placeholderLabel={t(
          "components.select-questions-or-categories-or-tags.select-level-placeholder",
          "Select level"
        )}
        options={levels.filter(
          (l) =>
            !allowedLevels ||
            !Array.isArray(allowedLevels) ||
            allowedLevels.includes(l.value)
        )}
        onChange={onChangeLevel}
      />
      {level === "question" && (
        <SelectQuestion
          sections={sections}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
          allowedQuestionTypes={allowedQuestionTypes}
        />
      )}
      {level === "category" && (
        <SelectCategories
          questionCategories={questionCategories}
          selectedCategoryIds={selectedCategoryIds}
          setSelectedCategoryIds={setSelectedCategoryIds}
        />
      )}
      {level === "tag" && (
        <SelectTags
          questionCategories={questionCategories}
          questionTags={questionTags}
          selectedTagIds={selectedTagIds}
          setSelectedTagIds={setSelectedTagIds}
        />
      )}
    </>
  );
}

SelectQuestionOrCategoriesOrTags.propTypes = {
  level: PropTypes.string.isRequired,
  onChangeLevel: PropTypes.func.isRequired,
  allowedLevels: PropTypes.arrayOf(PropTypes.string),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionCategories: PropTypes.arrayOf(PropTypes.object),
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.string),
  setSelectedCategoryIds: PropTypes.func,
  questionTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedTagIds: PropTypes.arrayOf(PropTypes.string),
  setSelectedTagIds: PropTypes.func,
  selectedQuestionId: PropTypes.string,
  setSelectedQuestionId: PropTypes.func,
  allowedQuestionTypes: PropTypes.arrayOf(PropTypes.string),
};

export default SelectQuestionOrCategoriesOrTags;
