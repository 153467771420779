import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../utils";
import { Select } from "../..";

function SelectQuestion({
  sections,
  selectedQuestionId,
  setSelectedQuestionId,
  allowedQuestionTypes,
}) {
  const { t, i18n } = useTranslation();

  return (
    <Select
      id="select-question"
      label={t(
        "components.select-question.labels.select-question-label",
        "Question"
      )}
      value={selectedQuestionId || "-1"}
      placeholderLabel={t(
        "components.select-question.labels.select-question-placeholder",
        "Select question"
      )}
      placeholderValue={"-1"}
      onChange={setSelectedQuestionId}
      options={sections.map((section) => ({
        label: section.label,
        options: section.questions
          .filter(
            (question) =>
              !allowedQuestionTypes ||
              !Array.isArray(allowedQuestionTypes) ||
              allowedQuestionTypes.includes(question.questionType.label)
          )
          .map((question) => ({
            label: getTranslation(question.translations, i18n.language).label,
            value: question.id,
          })),
      }))}
    />
  );
}

SelectQuestion.propTypes = {
  sections: PropTypes.array,
  selectedQuestionId: PropTypes.string,
  setSelectedQuestionId: PropTypes.func,
  allowedQuestionTypes: PropTypes.array,
};

export default SelectQuestion;
