import React from "react";
import PropTypes from "prop-types";

/**
 * Styled react wrapper for table data (td) cell
 * @param {node} children - Contents of the table cell
 * @returns {node} td cell
 */
function TableData({ children }) {
  return (
    <td className="px-6 py-4 border-b">
      {typeof children === "string" ? (
        <div className="font-normal">{children}</div>
      ) : (
        children
      )}
    </td>
  );
}

TableData.propTypes = {
  children: PropTypes.node,
};

export default TableData;
