import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import {
  Breadcrumbs,
  LoggedInLayout,
  Button,
  Icons,
  PublishTaskForm,
  ResponseTaskForm,
  TypeformForm,
  InfoModal,
} from "../../../components";
import { userRoleLevels } from "../../../utils";
import calls from "../../../graphql";

const {
  CREATEPUBLISHEDSURVEY,
  CREATEEMAIL,
  CREATEPUBLISHTASK,
  PERFORMPUBLISHTASK,
  DELETEPUBLISHTASK,
  CREATERESPONSETASK,
  GETSURVEYCLIENT,
  SURVEYTRANSLATIONSCOMPLETE,
} = calls;

export default function Publish() {
  // Hooks
  const { projectId, surveyId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  // State
  const [step, setStep] = useState(0);
  const [client, setClient] = useState(undefined);
  const [surveyLanguages, setSurveyLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [publishedSurveyTitle, setPublishedSurveyTitle] = useState("");
  const [sendResponseEmail, setSendResponseEmail] = useState(false);
  const [responseEmailFrom, setResponseEmailFrom] = useState("impuls");
  const [responseReportStructureId, setResponseReportStructureId] =
    useState("0");
  const [responseEmailFieldId, setResponseEmailFieldId] = useState("0");
  const [responseEmailSubjects, setResponseEmailSubjects] = useState({});
  const [responseEmailMessages, setResponseEmailMessages] = useState({});
  const [sendPublishEmail, setSendPublishEmail] = useState(false);
  const [publishEmailFrom, setPublishEmailFrom] = useState("impuls");
  const [sendEmailNow, setSendEmailNow] = useState(true);
  const [publishMailingListIds, setPublishMailingListIds] = useState([]);
  const [publishEmailSubjects, setPublishEmailSubjects] = useState({});
  const [publishEmailMessages, setPublishEmailMessages] = useState({});
  const [publishIsRecurring, setPublishIsRecurring] = useState(false);
  const [publishStartAt, setPublishStartAt] = useState(new Date());
  const [publishStartHour, setPublishStartHour] = useState(
    `${new Date().getHours() + 1}`
  );
  const [publishRepeatPeriod, setPublishRepeatPeriod] = useState("monthly");
  const [publishEndAt, setPublishEndAt] = useState(new Date());
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalType, setInfoModalType] = useState("");
  // Queries
  const getSurveyState = useQuery(GETSURVEYCLIENT, {
    variables: {
      id: surveyId,
    },
  });
  const [getSurveyTranslationsComplete, getSurveyTranslationsCompleteState] =
    useLazyQuery(SURVEYTRANSLATIONSCOMPLETE, {
      fetchPolicy: "network-only",
    });
  // Mutations
  const [createPublishedSurvey, createPublishedSurveyState] = useMutation(
    CREATEPUBLISHEDSURVEY
  );
  const [createEmail, createEmailState] = useMutation(CREATEEMAIL);
  const [createResponseTask, createResponseTaskState] =
    useMutation(CREATERESPONSETASK);
  const [createPublishTask, createPublishTaskState] =
    useMutation(CREATEPUBLISHTASK);
  const [performPublishTask, performPublishTaskState] =
    useMutation(PERFORMPUBLISHTASK);
  const [deletePublishTask, deletePublishTaskState] =
    useMutation(DELETEPUBLISHTASK);
  // Handle errors
  if (getSurveyState.error) toast.error(getSurveyState.error.message);
  if (getSurveyTranslationsCompleteState.error) {
    toast.error(getSurveyTranslationsCompleteState.error.message);
    setStep(0);
  }
  // Effects
  useEffect(() => {
    const survey = getSurveyState.data ? getSurveyState.data.survey : undefined;
    if (survey) {
      setPublishedSurveyTitle(
        `${survey.label} - ${new Date().toLocaleDateString("nl-be")}`
      );
      const { languages } = survey;
      setSurveyLanguages(languages);
      setSelectedLanguages(languages);
      const publishMessages = {};
      languages.forEach(
        (lang) => (publishMessages[lang.label] = "{{name}}\n{{url}}")
      );
      setPublishEmailMessages(publishMessages);
      if (survey.project && survey.project.client) {
        setClient(survey.project.client);
      }
    }
  }, [getSurveyState.data]);
  useEffect(() => {
    if (getSurveyTranslationsCompleteState.data) {
      const result =
        getSurveyTranslationsCompleteState.data.surveyTranslationsComplete;
      if (!result) {
        toast.custom(
          t(
            "project.survey.publish.messages.warning.incomplete-translations",
            "Not all translations for the selected language are available."
          )
        );
        setStep(0);
      }
    }
  }, [getSurveyTranslationsCompleteState.data]);

  const checkStepComplete = (index) => {
    switch (index) {
      case 0:
        if (!publishedSurveyTitle) {
          toast.custom(
            t(
              "project.survey.publish.messages.warning.complete-title",
              "Please enter a publication title."
            )
          );
          return false;
        }
        if (!(selectedLanguages && selectedLanguages.length)) {
          toast.custom(
            t(
              "project.survey.publish.messages.warning.select-one-language",
              "Please select at least one language."
            )
          );
          return false;
        }
        getSurveyTranslationsComplete({
          variables: {
            id: surveyId,
            languageIds: selectedLanguages.map((lang) => lang.id),
          },
        });
        return true;
      case 1:
        if (!sendPublishEmail) return true;
        if (!(publishMailingListIds && publishMailingListIds.length)) {
          toast.custom(
            t(
              "project.survey.publish.messages.warning.select-mailinglist",
              "Please select at least one mailing list."
            )
          );
          return false;
        } else if (
          publishEmailFrom !== "alert" &&
          publishEmailFrom !== "impuls"
        ) {
          toast.custom(
            t(
              "project.survey.publish.messages.warning.select-publish-mail-source",
              "Please choose where to send the e-mail from."
            )
          );
          return false;
        } else if (
          !selectedLanguages.reduce(
            (result, lang) =>
              result &&
              publishEmailSubjects[lang.label] &&
              publishEmailMessages[lang.label],
            true
          )
        ) {
          toast.custom(
            t(
              "project.survey.publish.messages.warning.complete-publish-email-message",
              "Please complete the email subject and message for all languages."
            )
          );
          return false;
        } else {
          if (sendEmailNow) return true;
          const today = new Date();
          today.setHours(today.getHours() + 1);
          publishStartAt.setHours(publishStartHour, 0, 0, 0);
          if (publishStartAt < today) {
            toast.custom(
              t(
                "project.survey.publish.messages.warning.start-at-too-soon",
                "Scheduled sending date is too soon. If you desire to send the mails now, please deselect the 'Schedule delivery' option."
              )
            );
            return false;
          } else {
            if (!publishIsRecurring) return true;
            if (publishEndAt < publishStartAt) {
              toast.custom(
                t(
                  "project.survey.publish.messages.warning.end-at-before-start-at",
                  "Scheduled ending date should fall after the scheduled sending date."
                )
              );
              return false;
            }
            return true;
          }
        }
      case 2:
        if (!sendResponseEmail) return true;
        if (responseEmailFieldId === "0") {
          toast.custom(
            t(
              "project.survey.publish.messages.warning.complete-response-email-field",
              "Please select an email address field to notify your respondents."
            )
          );
          return false;
        } else if (
          !selectedLanguages.reduce(
            (result, lang) =>
              result &&
              responseEmailSubjects[lang.label] &&
              responseEmailMessages[lang.label],
            true
          )
        ) {
          toast.custom(
            t(
              "project.survey.publish.messages.warning.complete-response-email-message",
              "Please complete the email subject and message in all languages."
            )
          );
          return false;
        } else {
          return true;
        }
      default: {
        return false;
      }
    }
  };

  const onPublishSurvey = async () => {
    try {
      const createPublishedSurveyResult = await createPublishedSurvey({
        variables: {
          label: publishedSurveyTitle,
          surveyId: surveyId,
          languageIds: selectedLanguages.map((lang) => lang.id),
        },
      });
      if (
        createPublishedSurveyResult.data &&
        createPublishedSurveyResult.data.createPublishedSurvey
      ) {
        const publishedSurveyId =
          createPublishedSurveyResult.data.createPublishedSurvey.id;
        // Create tasks
        await Promise.all([
          savePublishTask(publishedSurveyId),
          saveResponseTask(publishedSurveyId),
        ]);
      } else {
        toast.error("NO");
      }

      toast.success(
        t(
          "project.survey.publish.messages.success.published-survey",
          "Survey Published!"
        )
      );
      history.replace(
        `/projects/${projectId}/surveys/${surveyId}/publications`
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const savePublishTask = async (publishedSurveyId) => {
    if (sendPublishEmail) {
      // Send now = Plan tomorrow, perform now & delete (TODO: improve flow)
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      // Create publish task
      try {
        publishStartAt.setHours(publishStartHour, 0, 0, 0);
        const createPublishTaskResult = await createPublishTask({
          variables: {
            publishedSurveyId: publishedSurveyId,
            scheduleAt: sendEmailNow ? tomorrow : publishStartAt,
            isRecurring: publishIsRecurring,
            endAt: publishIsRecurring ? publishEndAt : undefined,
            schedulePeriod: publishIsRecurring
              ? publishRepeatPeriod
              : undefined,
            respondentListIds: publishMailingListIds,
          },
        });
        if (
          createPublishTaskResult &&
          createPublishTaskResult.data &&
          createPublishTaskResult.data.createPublishTask
        ) {
          // Save emails
          const publishTaskId =
            createPublishTaskResult.data.createPublishTask.id;
          await Promise.all(
            selectedLanguages.map(async (language) => {
              try {
                await createEmail({
                  variables: {
                    from: publishEmailFrom,
                    subject: publishEmailSubjects[language.label],
                    message: publishEmailMessages[language.label],
                    publishTaskId: publishTaskId,
                    languageId: language.id,
                  },
                });
              } catch (error) {
                toast.error(error.message);
              }
            })
          );
          if (sendEmailNow) {
            // Perform publish task now
            await performPublishTask({
              variables: {
                id: publishTaskId,
              },
            });
            await deletePublishTask({
              variables: {
                id: publishTaskId,
              },
            });
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const saveResponseTask = async (publishedSurveyId) => {
    if (sendResponseEmail) {
      try {
        const createResponseTaskResult = await createResponseTask({
          variables: {
            publishedSurveyId: publishedSurveyId,
            emailFieldId: responseEmailFieldId,
            reportStructureId:
              responseReportStructureId !== "0"
                ? responseReportStructureId
                : undefined,
          },
        });
        if (
          createResponseTaskResult &&
          createResponseTaskResult.data &&
          createResponseTaskResult.data.createResponseTask
        ) {
          // Save emails
          const responseTaskId =
            createResponseTaskResult.data.createResponseTask.id;
          await Promise.all(
            selectedLanguages.map(async (language) => {
              try {
                await createEmail({
                  variables: {
                    from: responseEmailFrom,
                    subject: responseEmailSubjects[language.label],
                    message: responseEmailMessages[language.label],
                    responseTaskId: responseTaskId,
                    languageId: language.id,
                  },
                });
              } catch (error) {
                toast.error(error.message);
              }
            })
          );
        }
      } catch (error) {
        toast.error();
      }
    }
  };

  const steps = [
    {
      label: t(
        "project.survey.publish.steps.publish-typeform.label",
        "Create typeform"
      ),
      description: t(
        "project.survey.publish.steps.publish-typeform.description",
        "Create a typeform with the questions in this survey."
      ),
    },
    {
      label: t(
        "project.survey.publish.steps.publish-task.label",
        "Send emails"
      ),
      description: t(
        "project.survey.publish.steps.publish-task.description",
        "Let your respondents know that a new survey is available by sending out a message to your client's respondents."
      ),
    },
    {
      label: t(
        "project.survey.publish.steps.response-task.label",
        "Reply respondents"
      ),
      description: t(
        "project.survey.publish.steps.response-task.description",
        "Send your respondent an email when they completed their survey."
      ),
    },
  ];

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                label: t(
                  "project.survey.publish.breadcrumbs.projects",
                  "Projects"
                ),
                link: `/projects`,
              },
              {
                label: t(
                  "project.survey.publish.breadcrumbs.surveys",
                  "Surveys"
                ),
                link: `/projects/${projectId}/surveys`,
              },
              {
                label: t(
                  "project.survey.publish.breadcrumbs.dashboard",
                  "Dashboard"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
              },
              {
                label: t(
                  "project.survey.publish.breadcrumbs.publish",
                  "Publish"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/publish`,
              },
            ]}
            back={`/projects/${projectId}/surveys/${surveyId}/dashboard`}
          />
        }
        isLoading={getSurveyState.loading}
        minLevel={userRoleLevels.Manager}
      >
        <ul className="w-full steps pb-5">
          {steps.map(({ label }, index) => (
            <li
              key={index}
              className={`step ${step >= index ? "step-primary" : ""}`}
            >
              {label}
            </li>
          ))}
        </ul>
        <div className="w-3/4 self-center flex-1 flex flex-col space-y-2">
          <h3 className="text-lg md:text-xl font-bold pb-2">
            {steps[step].label}
          </h3>
          <p className="pb-2">{steps[step].description}</p>
          {step === 0 ? (
            <TypeformForm
              publishedSurveyTitle={publishedSurveyTitle}
              setPublishedSurveyTitle={setPublishedSurveyTitle}
              surveyLanguages={surveyLanguages}
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
            />
          ) : undefined}
          {step === 1 ? (
            <PublishTaskForm
              selectedLanguages={selectedLanguages}
              client={client}
              sendPublishEmail={sendPublishEmail}
              setSendPublishEmail={setSendPublishEmail}
              publishEmailFrom={publishEmailFrom}
              setPublishEmailFrom={setPublishEmailFrom}
              sendEmailNow={sendEmailNow}
              setSendEmailNow={setSendEmailNow}
              publishMailingListIds={publishMailingListIds}
              setPublishMailingListIds={setPublishMailingListIds}
              publishStartAt={publishStartAt}
              setPublishStartAt={setPublishStartAt}
              publishStartHour={publishStartHour}
              setPublishStartHour={setPublishStartHour}
              publishEndAt={publishEndAt}
              setPublishEndAt={setPublishEndAt}
              publishIsRecurring={publishIsRecurring}
              setPublishIsRecurring={setPublishIsRecurring}
              publishRepeatPeriod={publishRepeatPeriod}
              setPublishRepeatPeriod={setPublishRepeatPeriod}
              publishEmailSubjects={publishEmailSubjects}
              setPublishEmailSubjects={setPublishEmailSubjects}
              publishEmailMessages={publishEmailMessages}
              setPublishEmailMessages={setPublishEmailMessages}
              setInfoModalOpen={setInfoModalOpen}
              setInfoModalType={setInfoModalType}
            />
          ) : undefined}
          {step === 2 ? (
            <ResponseTaskForm
              selectedLanguages={selectedLanguages}
              surveyId={surveyId}
              sendResponseEmail={sendResponseEmail}
              setSendResponseEmail={setSendResponseEmail}
              responseEmailFrom={responseEmailFrom}
              setResponseEmailFrom={setResponseEmailFrom}
              responseEmailFieldId={responseEmailFieldId}
              setResponseEmailFieldId={setResponseEmailFieldId}
              responseEmailSubjects={responseEmailSubjects}
              setResponseEmailSubjects={setResponseEmailSubjects}
              responseEmailMessages={responseEmailMessages}
              setResponseEmailMessages={setResponseEmailMessages}
              responseReportStructureId={responseReportStructureId}
              setResponseReportStructureId={setResponseReportStructureId}
            />
          ) : undefined}
        </div>
        <div className="w-3/4 flex flex-row self-center items-center justify-between">
          {step > 0 ? (
            <Button
              onClick={() => setStep(step - 1)}
              start={<Icons.Previous className="text-xl" />}
            >
              {t("project.survey.publish.labels.back", "Back")}
            </Button>
          ) : (
            <div></div>
          )}

          {step < steps.length - 1 ? (
            <Button
              onClick={() =>
                checkStepComplete(step) ? setStep(step + 1) : undefined
              }
              end={<Icons.Next className="text-xl" />}
            >
              {t("project.survey.publish.labels.next", "Next")}
            </Button>
          ) : (
            <Button
              start={<Icons.Send className="text-xl" />}
              isLoading={
                createPublishedSurveyState.loading ||
                createEmailState.loading ||
                createResponseTaskState.loading ||
                createPublishTaskState.loading ||
                performPublishTaskState.loading ||
                deletePublishTaskState.loading
              }
              onClick={() =>
                checkStepComplete(step) ? onPublishSurvey() : undefined
              }
            >
              {t("project.survey.publish.labels.publish", "Publish")}
            </Button>
          )}
        </div>
      </LoggedInLayout>
      <InfoModal
        modalOpen={infoModalOpen}
        closeModal={() => setInfoModalOpen(false)}
        infoModalType={infoModalType}
      />
    </>
  );
}
