import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Input, TextArea, Icons, LanguageTabs } from "..";

/**
 * International email message input
 * @param {array} selectedLanguages - Languages supported by email message [{id: "", label: ""}]
 * @param {string} id - Input base id
 * @param {object} emailSubjects - Object with language label - subject pairs
 * @param {function} setEmailSubjects - Change function for emailSubjects
 * @param {object} emailMessages - Object with language label - message pairs
 * @param {function} setEmailMessages - Change function for emailMessages
 * @param {string} infoMessage - Message for info tooltip
 * @param {function} setInfoModalOpen - Info modal open function
 * @param {function} setInfoModalType - Info modal type (TODO: needs refactor)
 * @returns {node} React email input component
 */
function EmailInput({
  selectedLanguages,
  id,
  emailSubjects,
  setEmailSubjects,
  emailMessages,
  setEmailMessages,
  infoMessage,
  setInfoModalOpen,
  setInfoModalType,
}) {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(undefined);

  useEffect(() => {
    if (selectedLanguages && selectedLanguages.length) {
      setSelectedLanguage(selectedLanguages[0]);
    }
  }, [selectedLanguages]);

  const onChangeEmailSubject = (subject, languageLabel) => {
    const subjects = { ...emailSubjects };
    subjects[languageLabel] = subject;
    setEmailSubjects(subjects);
  };

  const onChangeEmailMessage = (message, languageLabel) => {
    const messages = { ...emailMessages };
    messages[languageLabel] = message;
    setEmailMessages(messages);
  };

  const onSelectLanguage = (id) => {
    const language = selectedLanguages.find((lang) => lang.id === id);
    setSelectedLanguage(language);
  };

  if (selectedLanguage) {
    return (
      <>
        <div className="flex flex-row items-end justify-between">
          <p className="font-bold">
            {t("components.email-input.title", "Email")}
          </p>
          <LanguageTabs
            languages={selectedLanguages}
            selectedLanguageLabel={selectedLanguage.label}
            onSelectLanguage={onSelectLanguage}
            onAddLanguage={undefined}
            onRemoveLanguage={undefined}
          />
        </div>
        <Input
          id={`${id}-email-subject`}
          label={t("components.email-input.subject-label", "Subject")}
          placeholder={t(
            "components.email-input.subject-placeholder",
            "subject"
          )}
          value={emailSubjects[selectedLanguage.label]}
          onChange={(subject) =>
            onChangeEmailSubject(subject, selectedLanguage.label)
          }
        />
        <div className="flex flex-row items-center">
          <label htmlFor={`${id}-email-message`} className="flex-grow">
            {t("components.email-input.message-label", "Message")}
          </label>
          {infoMessage ? (
            <button
              type="button"
              data-tip={infoMessage}
              onClick={() => {
                setInfoModalType(`${id}-email-message`);
                setInfoModalOpen(true);
              }}
              className="tooltip tooltip-left"
            >
              <Icons.Info className="text-xl text-gray-600 hover:text-black" />
            </button>
          ) : undefined}
        </div>
        <TextArea
          id={`${id}-email-message`}
          placeholder={t(
            "components.email-input.message-placeholder",
            "message"
          )}
          value={emailMessages[selectedLanguage.label]}
          onChange={(message) =>
            onChangeEmailMessage(message, selectedLanguage.label)
          }
          className="h-36"
        />
      </>
    );
  } else {
    return null;
  }
}

EmailInput.propTypes = {
  selectedLanguages: PropTypes.array,
  id: PropTypes.string,
  emailSubjects: PropTypes.object,
  setEmailSubjects: PropTypes.func,
  emailMessages: PropTypes.object,
  setEmailMessages: PropTypes.func,
  infoMessage: PropTypes.string,
  setInfoModalOpen: PropTypes.func,
  setInfoModalType: PropTypes.func,
};

export default EmailInput;
