/**
 * Verifies password strength:
 * - At least one number
 * - At least one upper case
 * - At least one lower case
 * - At lease one special character
 * @param {string} password - Password string to be verified
 * @returns {boolean} Whether the password is sufficiently strong
 */
export default function validatePasswordStrength(password) {
  const hasNumber = /\d/;
  const hasUppercase = /[A-Z]/;
  const hasLowercase = /[a-z]/;
  const hasNoSpecial = /^[A-Za-z0-9]+$/;
  return (
    hasNumber.test(password) &&
    hasUppercase.test(password) &&
    hasLowercase.test(password) &&
    !hasNoSpecial.test(password)
  );
}
