/* eslint-disable no-undef */
import dotenv from "dotenv";
dotenv.config();

const NODE_ENV = process.env.NODE_ENV || "development";

const env = {
  development: NODE_ENV === "development",
  test: NODE_ENV === "test",
  staging: NODE_ENV === "staging",
  production: NODE_ENV === "production",
};

const api =
  process.env.REACT_APP_API_URI || "https://survey.alert-impuls.eu/api";

export { env, api };
