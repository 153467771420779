import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import {
  Breadcrumbs,
  LoggedInLayout,
  Table,
  TableHead,
  TableRow,
  TableData,
  Icons,
  Button,
  Modal,
  ConfirmModal,
  MinLevel,
} from "../../../components";
import { formatDate, getTranslation, userRoleLevels } from "../../../utils";
import calls from "../../../graphql";
import toast from "react-hot-toast";
const {
  SYNCTYPEFORMSURVEY,
  DELETEPUBLISHEDSURVEY,
  GETSURVEYPUBLICATIONS,
  DELETERESPONSETASK,
} = calls;

export default function Publications() {
  // Hooks
  const { projectId, surveyId } = useParams();
  const { t, i18n } = useTranslation();
  // State
  const [publications, setPublications] = useState([]);
  const [selectedPublication, setSelectedPublication] = useState(undefined);
  const [deletePublicationModalOpen, setDeletePublicationModalOpen] =
    useState(false);
  const [selectedTypeform, setSelectedTypeform] = useState(undefined);
  const [typeformModalOpen, setTypeformModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [editPublicationModalOpen, setEditPublicationModalOpen] =
    useState(false);
  const [selectedResponseTaskId, setSelectedResponseTaskId] =
    useState(undefined);
  const [deleteResponseTaskModalOpen, setDeleteResponseTaskModalOpen] =
    useState(false);
  // Queries
  const getSurveyPublicationsState = useQuery(GETSURVEYPUBLICATIONS, {
    variables: {
      id: surveyId,
    },
    fetchPolicy: "cache-and-network",
  });
  // Mutations
  const [deletePublishedSurvey, deletePublishedSurveyState] = useMutation(
    DELETEPUBLISHEDSURVEY,
    {
      refetchQueries: [GETSURVEYPUBLICATIONS],
    }
  );
  const [syncTypeformSurvey, syncTypeformSurveyState] =
    useMutation(SYNCTYPEFORMSURVEY);
  const [deleteResponseTask, deleteResponseTaskState] = useMutation(
    DELETERESPONSETASK,
    {
      refetchQueries: [GETSURVEYPUBLICATIONS],
    }
  );
  // Handle errors
  if (getSurveyPublicationsState.error)
    toast.error(getSurveyPublicationsState.error.message);
  // Effects
  useEffect(() => {
    if (
      getSurveyPublicationsState.data &&
      getSurveyPublicationsState.data.survey
    ) {
      setPublications(getSurveyPublicationsState.data.survey.publishedSurveys);
    }
  }, [getSurveyPublicationsState.data]);
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const resetState = () => {
    setSelectedTypeform(undefined);
    setSelectedPublication(undefined);
    setDeletePublicationModalOpen(false);
    setTypeformModalOpen(false);
  };

  const onSyncTypeformSurvey = async () => {
    if (selectedTypeform && selectedTypeform.id) {
      try {
        await syncTypeformSurvey({
          variables: {
            id: selectedTypeform.id,
          },
        });
        toast.success(
          t(
            "project.survey.publications.messages.success.typeform-synced",
            "Typeform synced!"
          )
        );
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error(
        t(
          "project.survey.publications.messages.error.no-typeform-selected",
          "No typeform seems to be selected, please try again."
        )
      );
    }
  };

  const onDeletePublication = async () => {
    if (selectedPublication && selectedPublication.id) {
      try {
        await deletePublishedSurvey({
          variables: {
            id: selectedPublication.id,
          },
        });
        resetState();
        toast.success(
          t(
            "project.survey.publications.messages.success.publication-deleted",
            "Deleted publication!"
          )
        );
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error(
        t(
          "project.survey.publications.messages.error.no-publication-selected",
          "No publication seems to be selected, please try again."
        )
      );
    }
  };

  const onDeleteResponseTask = async () => {
    try {
      await deleteResponseTask({
        variables: {
          id: selectedResponseTaskId,
        },
      });
      toast.success(
        t(
          "project.survey.publications.messages.success.response-task-deleted",
          "Deleted automatic response!"
        )
      );
      setDeleteResponseTaskModalOpen(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                label: t(
                  "project.survey.publications.breadcrumbs.projects",
                  "Projects"
                ),
                link: `/projects`,
              },
              {
                label: t(
                  "project.survey.publications.breadcrumbs.surveys",
                  "Surveys"
                ),
                link: `/projects/${projectId}/surveys`,
              },
              {
                label: t(
                  "project.survey.publications.breadcrumbs.dashboard",
                  "Dashboard"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
              },
              {
                label: t(
                  "project.survey.publications.breadcrumbs.publications",
                  "Publications"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/publications`,
              },
            ]}
            back={`/projects/${projectId}/surveys/${surveyId}/dashboard`}
          />
        }
        isLoading={getSurveyPublicationsState.loading}
      >
        <h1 className="text-2xl md:text-3xl font-bold">
          {t("project.survey.publications.labels.publications", "Publications")}
        </h1>
        {publications && publications.length ? (
          <Table
            head={
              <tr className="h-10">
                <TableHead>
                  {t(
                    "project.survey.publications.table.headers.title",
                    "Title"
                  )}
                </TableHead>
                <TableHead>
                  {t(
                    "project.survey.publications.table.headers.typeforms",
                    "Typeforms"
                  )}
                </TableHead>
                <TableHead>
                  {t(
                    "project.survey.publications.table.headers.published",
                    "Published"
                  )}
                </TableHead>
                <TableHead />
              </tr>
            }
          >
            {publications.map((publication) => (
              <TableRow key={publication.id}>
                <TableData>
                  <div className="flex items-center space-x-4">
                    {publication.label}
                  </div>
                </TableData>
                <TableData>
                  <div className="flex flex-row items-center space-x-1">
                    {publication.typeformSurveys
                      ? publication.typeformSurveys.map((tf) => (
                          <Button
                            key={tf.id}
                            color="accent"
                            onClick={() => {
                              setSelectedPublication(publication);
                              setSelectedTypeform(tf);
                              setTypeformModalOpen(true);
                            }}
                            size="sm"
                          >
                            {tf.language.label}
                          </Button>
                        ))
                      : undefined}
                  </div>
                </TableData>
                <TableData>{formatDate(publication.updatedAt)}</TableData>
                <TableData>
                  <MinLevel minLevel={userRoleLevels.Manager}>
                    <div className="flex flex-row items-center justify-end space-x-2">
                      {/* <Button
                        onClick={() => {
                          setSelectedPublication(publication);
                          setEditPublicationModalOpen(true);
                        }}
                        isLoading={deletePublishedSurveyState.loading}
                        color="primary"
                        size="sm"
                        start={<Icons.Edit className="text-xl" />}
                      >
                        {t(
                          "project.survey.publications.table.labels.edit-publication",
                          "Edit"
                        )}
                      </Button> */}
                      <Button
                        onClick={() => {
                          setSelectedPublication(publication);
                          setDeletePublicationModalOpen(true);
                        }}
                        isLoading={deletePublishedSurveyState.loading}
                        color="error"
                        size="sm"
                        start={<Icons.Delete className="text-xl" />}
                      >
                        {t(
                          "project.survey.publications.table.labels.delete-publication",
                          "Delete"
                        )}
                      </Button>
                    </div>
                  </MinLevel>
                </TableData>
              </TableRow>
            ))}
          </Table>
        ) : (
          <div className="text-3xl">
            {t(
              "project.survey.publications.messages.warning.no-publications",
              "No publications found"
            )}
          </div>
        )}
      </LoggedInLayout>
      <Modal
        modalOpen={typeformModalOpen}
        closeModal={() => setTypeformModalOpen(false)}
      >
        {selectedTypeform ? (
          <>
            <div className="flex flex-row items-center justify-between w-full mb-2 space-x-1">
              <h1 className="text-xl font-bold">
                {selectedPublication.label} - {selectedTypeform.language.label}
              </h1>
              <button onClick={() => setTypeformModalOpen(false)}>
                <Icons.Close className="text-xl" />
              </button>
            </div>
            <div className="space-y-2">
              <div>
                <p className="font-bold">
                  {t("project.survey.publications.modals.typeform.url", "URL")}
                </p>
                <div className="w-full flex flex-row items-center justify-between">
                  <a
                    href={selectedTypeform.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedTypeform.url}
                  </a>
                  <Button
                    color="accent"
                    size="sm"
                    onClick={() => {
                      navigator.clipboard.writeText(selectedTypeform.url);
                      setCopied(true);
                    }}
                    className={copied ? "btn-disabled" : ""}
                  >
                    {copied ? (
                      <Icons.Save className="text-xl" />
                    ) : (
                      <Icons.Copy className="text-xl" />
                    )}
                  </Button>
                </div>
              </div>
              <MinLevel minLevel={userRoleLevels.Manager}>
                <div>
                  <div className="w-full flex flex-row items-center justify-between">
                    <p className="font-bold">
                      {t(
                        "project.survey.publications.modals.typeform.sync",
                        "Sync responses"
                      )}
                    </p>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => onSyncTypeformSurvey()}
                      isLoading={syncTypeformSurveyState.loading}
                    >
                      <Icons.Sync className="text-xl" />
                    </Button>
                  </div>
                </div>
              </MinLevel>
            </div>
          </>
        ) : undefined}
      </Modal>
      <Modal
        modalOpen={editPublicationModalOpen}
        closeModal={() => {
          setEditPublicationModalOpen(false);
        }}
      >
        <>
          <div className="flex flex-row items-center justify-between w-full mb-2 space-x-1">
            <h1 className="text-xl font-bold">
              {selectedPublication && selectedPublication.label}
            </h1>
            <button onClick={() => setEditPublicationModalOpen(false)}>
              <Icons.Close className="text-xl" />
            </button>
          </div>
          <div className="space-y-2">
            <div>
              <p className="font-bold">
                {t(
                  "project.survey.publications.modals.edit-publication.response-tasks",
                  "Automatic responses"
                )}
              </p>
              <div className="w-full flex flex-row items-center justify-between">
                {selectedPublication && selectedPublication.responseTasks ? (
                  <div className="w-full">
                    {selectedPublication.responseTasks.map((task) => {
                      const subject = getTranslation(
                        task.emails.map((mail) => ({
                          label: mail.subject,
                          language: mail.subject,
                        })),
                        i18n.language
                      ).label;
                      return (
                        <div
                          key={task.id}
                          className="flex flex-row items-center w-full justify-between"
                        >
                          <p>{subject}</p>
                          <Button
                            size="sm"
                            color="error"
                            onClick={() => {
                              setSelectedResponseTaskId(task.id);
                              setDeleteResponseTaskModalOpen(true);
                            }}
                          >
                            <Icons.Delete className="text-xl" />
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                ) : undefined}
              </div>
            </div>
          </div>
        </>
      </Modal>
      <ConfirmModal
        toConfirmType={t(
          "project.survey.publications.modals.delete-response-task.type",
          "automatic response"
        )}
        toConfirmLabel={""}
        modalOpen={deleteResponseTaskModalOpen}
        closeModal={() => setDeleteResponseTaskModalOpen(false)}
        onConfirm={onDeleteResponseTask}
        action="delete"
        isLoading={deleteResponseTaskState.loading}
      />
      <ConfirmModal
        toConfirmType={t(
          "project.survey.publications.modals.delete-publication.type",
          "publication"
        )}
        toConfirmLabel={selectedPublication ? selectedPublication.name : ""}
        modalOpen={deletePublicationModalOpen}
        closeModal={() => setDeletePublicationModalOpen(false)}
        onConfirm={onDeletePublication}
        action="delete"
        isLoading={deletePublishedSurveyState.loading}
        warning={t(
          "project.survey.publications.modals.delete-publication.warning",
          "This operation will also delete all typeforms and responses for this publication!"
        )}
      />
    </>
  );
}
