import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/**
 * Status component
 * Styled component indicating status as active or archived
 * @param {boolean} active - Status
 * @returns {node} React component
 */
function Status({ active }) {
  const { t } = useTranslation();

  return active ? (
    <div className="bg-success text-neutral-content text-center rounded-lg py-1">
      {t("components.status.active", "Active")}
    </div>
  ) : (
    <div className="bg-warning text-neutral-content text-center rounded-lg py-1">
      {t("components.status.archived", "Archived")}
    </div>
  );
}

Status.propTypes = {
  active: PropTypes.bool,
};

export default Status;
