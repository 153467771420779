import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ActionModal, Button, Icons } from "..";

/**
 * Confirmation modal window
 * A modal window asking to confirm, archive or delete something
 * @param {node} children - Modal content
 * @param {string} warning - Modal warning: text written in orange with warning symbol
 * @param {string} action - One of "confirm", "delete" or "archive"
 * @param {boolean} isLoading - Loading state of confirmation button
 * @param {string} toConfirmType - Type of the object on which the action is to be confirmed (e.g. delete book --> type = book)
 * @param {string} toConfirmLabel - Confirmation message
 * @param {boolean} modalOpen - Is modal open
 * @param {function} closeModal - Close modal function
 * @param {function} onConfirm - Confirmation callback function
 * @returns {node} React modal window component
 */
function ConfirmModal({
  modalOpen,
  closeModal,
  onConfirm,
  action,
  isLoading,
  toConfirmType,
  toConfirmLabel,
  warning,
  children,
}) {
  const { t } = useTranslation();

  let color = "";
  let label = "";
  let Icon;
  switch (action) {
    case "confirm":
      color = "primary";
      label = t("components.confirm-modal.confirm", "Confirm");
      Icon = Icons.Save;
      break;
    case "delete":
      color = "error";
      label = t("components.confirm-modal.delete", "Delete");
      Icon = Icons.Delete;
      break;
    case "archive":
      color = "warning";
      label = t("components.confirm-modal.archive", "Archive");
      Icon = Icons.Archive;
      break;
    default:
      color = "primary";
      label = t("components.confirm-modal.no-action", "None");
      Icon = Icons.Cancel;
      break;
  }

  return (
    <ActionModal
      title={label + " " + toConfirmType}
      modalOpen={modalOpen}
      closeModal={closeModal}
      actions={
        <Button
          onClick={onConfirm}
          color={color}
          size="sm"
          isLoading={isLoading}
          start={<Icon className="text-xl" />}
        >
          {label + " " + toConfirmType}
        </Button>
      }
    >
      {toConfirmLabel ? (
        <p className="text-center">&quot;{`${toConfirmLabel}`}&quot;</p>
      ) : (
        ""
      )}
      {warning ? (
        <div className="flex flex-col items-center text-center text-warning">
          <Icons.Warning className="text-xl" />
          {warning}
        </div>
      ) : (
        ""
      )}
      {children}
    </ActionModal>
  );
}

ConfirmModal.propTypes = {
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onConfirm: PropTypes.func,
  action: PropTypes.oneOf(["confirm", "delete", "archive"]),
  isLoading: PropTypes.bool,
  toConfirmType: PropTypes.string,
  toConfirmLabel: PropTypes.string,
  warning: PropTypes.string,
  children: PropTypes.node,
};

export default ConfirmModal;
