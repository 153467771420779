/**
 * Date formatting function for readability
 * @param {string} isoDate - Date in ISO format
 * @returns {string} formated date
 */
export default function formatDate(isoDate, includeDate = false) {
  if (includeDate) {
    return new Date(isoDate).toLocaleString("nl-BE", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return new Date(isoDate).toLocaleDateString("nl-BE");
  }
}
