import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const colorClassNames = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  accent: "btn-accent",
  ghost: "btn-ghost",
  link: "btn-link",
  info: "btn-info",
  success: "btn-success",
  warning: "btn-warning",
  error: "btn-error",
  neutral: "",
};

const sizeClassNames = {
  xs: "btn-xs",
  sm: "btn-sm",
  md: "btn-md",
  lg: "btn-lg",
  xl: "btn-xl",
};

/**
 * Button input component
 * @param {node} children - Center node of the button
 * @param {node} start - Beginning node of the button
 * @param {node} end - Ending node of the button
 * @param {function} onClick - Function executed upon click
 * @param {boolean} isLink - Whether the button is actually a react link
 * @param {string} to - Used if isLink: where should the link redirect
 * @param {string} size - Button size
 * @param {string} color - Button color
 * @param {string} className - Component class overwrite
 * @param {boolean} isLoading - Button loading animation
 * @param {boolean} isSubmit - Activate form submit
 * @returns {node} Button - react component
 */
function Button({
  children,
  start,
  end,
  color,
  isLoading,
  isLink,
  to,
  onClick,
  className,
  size,
  isSubmit,
}) {
  const colorClassName = colorClassNames[color];
  const sizeClassName = sizeClassNames[size];

  if (isLoading) {
    return (
      <button
        className={`btn normal-case space-x-2 ${sizeClassName} ${colorClassName} loading ${className}`}
        type={isSubmit ? "submit" : "button"}
      >
        <div className="flex flex-row space-x-2 items-center">{children}</div>
        {end ? <div>{end}</div> : undefined}
      </button>
    );
  }

  if (isLink && to) {
    return (
      <Link
        to={to}
        className={`btn normal-case space-x-2 ${sizeClassName} ${colorClassName} ${className}`}
      >
        {start ? <div>{start}</div> : undefined}
        <div className="flex flex-row space-x-2 items-center">{children}</div>
        {end ? <div>{end}</div> : undefined}
      </Link>
    );
  }

  return (
    <button
      to={to}
      className={`btn normal-case space-x-2 ${sizeClassName} ${colorClassName} ${className}`}
      onClick={onClick}
      type={isSubmit ? "submit" : "button"}
    >
      {start ? <div>{start}</div> : undefined}
      <div className="flex flex-row space-x-2 items-center">{children}</div>
      {end ? <div>{end}</div> : undefined}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  start: PropTypes.node,
  end: PropTypes.node,
  isLoading: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(colorClassNames)),
  isLink: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)),
  isSubmit: PropTypes.bool,
};

Button.defaultProps = {
  children: undefined,
  start: undefined,
  end: undefined,
  isLoading: false,
  color: "primary",
  isLink: false,
  to: undefined,
  onClick: () => {},
  className: "",
  size: "md",
  isSubmit: false,
};

export default Button;
