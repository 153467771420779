import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { print } from "graphql";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import { api } from "../../utils/environment";
import calls from "../../graphql";
const graphqlAPI = api;

const { REFRESH } = calls;

// Keep the auth token in memory (not state!!!) --> security
let authToken = "";

const initial = {
  appState: { loggedIn: false, userId: undefined, userLevel: 0 },
  appSetLogin: () => {},
  appSetLogout: () => {},
  appSetAuthToken: () => {},
  appClearAuthToken: () => {},
};

export const AppStateContext = createContext(initial);

/**
 * The AppStateProvide component provides a global application state and sets up the apollo client with authentication headers & cookies
 * @param {node} children - Application content
 * @returns {node} React provider wrapper
 */
export default function AppStateProvider({ children }) {
  // app state
  const [appState, setAppState] = useState(initial.appState);

  const appSetLogin = (token, userId, userLevel) => {
    authToken = token;
    setAppState({ ...appState, loggedIn: true, userId, userLevel });
  };

  const appSetLogout = () => {
    authToken = "";
    setAppState({ ...appState, loggedIn: false, user: undefined });
  };

  const appSetAuthToken = (token) => {
    authToken = token;
  };
  const appClearAuthToken = () => {
    authToken = "";
  };
  const appGetAuthToken = () => {
    return authToken;
  };

  // apollo client
  const cache = new InMemoryCache({});
  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle;
        Promise.resolve(operation)
          .then((operation) => {
            operation.setContext({
              headers: { authorization: `Bearer ${appGetAuthToken()}` },
            });
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));
        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      new TokenRefreshLink({
        accessTokenField: "token",
        isTokenValidOrUndefined: () => {
          const token = appGetAuthToken();
          if (!token || !token.length) return true;
          try {
            const { exp } = jwtDecode(token);
            return Date.now() < exp * 1000;
          } catch {
            return false;
          }
        },
        fetchAccessToken,
        handleFetch: (accessToken) => {
          appSetAuthToken(accessToken);
        },
        handleResponse: () => {},
        handleError: () => {
          appSetLogout();
        },
      }),
      requestLink,
      createUploadLink({
        uri: graphqlAPI,
        credentials: "include",
      }),
    ]),
    cache,
  });

  return (
    <AppStateContext.Provider
      value={{
        appState,
        appSetLogin,
        appSetLogout,
        appSetAuthToken,
        appClearAuthToken,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AppStateContext.Provider>
  );
}

/**
 * Function fetchAccessToken makes a refresh request to the API in order to obtain a new access JWT and refresh cookie
 * @returns {string} New access token
 */
export async function fetchAccessToken() {
  const payload = {
    operationName: "Refresh",
    variables: {},
    query: print(REFRESH),
  };

  const res = await fetch(graphqlAPI, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload),
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  });
  const response = await res.json();
  return response.data.refresh;
}

AppStateProvider.propTypes = { children: PropTypes.node };
