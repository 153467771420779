import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getTranslation, useRouteOperators } from "../../../utils";
import { Icons, Input, Select, Button } from "../..";

/**
 * Question routes (conditional jumps) form component
 * @param {array} questions - All questions of the current survey
 * @param {object} questionLanguage - Language of the question currently editing
 * @param {array} routeOperators - Route operators for the current question type
 * @param {array} questionRouteValueOptions - Possible values to apply the route operators to (only truthy for some question types)
 * @param {array} responseOptions - All response options of the current question
 * @param {array} questionRoutes - Question routes (conditional jumps)
 * @param {function} setChanged - Setter function indicating changes
 * @param {function} setQuestionRoutes - Setter function
 * @param {string} questionId - Id of the current question
 * @returns {node} React component
 */
function QuestionRoutesForm({
  questions,
  questionLanguage,
  routeOperators,
  questionRouteValueOptions,
  responseOptions,
  questionRoutes,
  setChanged,
  setQuestionRoutes,
  questionId,
}) {
  const { t } = useTranslation();
  const routeOperatorTranslations = useRouteOperators();

  const onAddQuestionRoute = () => {
    setChanged(true);
    const routes = [
      ...questionRoutes,
      { questionToId: "0", routeOperatorId: "0", value: "" },
    ];
    setQuestionRoutes(routes);
  };

  const onDeleteQuestionRoute = (index) => {
    setChanged(true);
    const routes = [...questionRoutes];
    routes.splice(index, 1);
    setQuestionRoutes(routes);
  };

  const onChangeQuestionRoute = (route, index) => {
    setChanged(true);
    const routes = [...questionRoutes];
    routes[index] = route;
    setQuestionRoutes(routes);
  };

  return (
    <div className="flex flex-col w-full space-y-2">
      <div className="flex flex-row items-center">
        <h2 className="text-3xl font-bold flex-grow">
          {t(
            "project.survey.question.form.question-routes-label",
            "Question routes"
          )}
        </h2>
        <div
          data-tip={t(
            "project.survey.question.form.question-routes-explanation",
            "Add conditional jumps to other questions depending on the answer to this question."
          )}
          className="tooltip tooltip-left"
        >
          <Icons.Info className="text-xl text-gray-600 hover:text-black" />
        </div>
      </div>
      {questionRoutes && questionRoutes.length ? (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2">
            <label htmlFor="question-route-question-to" className="w-1/3">
              {t(
                "project.survey.question.form.question-route-jump-to-label",
                "Jump to"
              )}
            </label>
            <label htmlFor="label" className="w-1/4">
              {t(
                "project.survey.question.form.question-route-route-operator-label",
                "When"
              )}
            </label>
            <label htmlFor="question-route-value" className="flex-grow">
              {t(
                "project.survey.question.form.question-route-value-label",
                "Value"
              )}
            </label>
          </div>
          {questionRoutes.map((route, index) => (
            <div
              key={`questionroute${index}`}
              className="flex flex-row items-center space-x-2"
            >
              <Select
                id={`question-to${index}`}
                value={route.questionToId}
                onChange={(value) => {
                  onChangeQuestionRoute(
                    {
                      ...route,
                      questionToId: value,
                    },
                    index
                  );
                }}
                options={questions
                  .filter((question) => question.id !== questionId)
                  .map((question) => ({
                    value: question.id,
                    label: getTranslation(
                      question.translations,
                      questionLanguage
                    ).label,
                  }))}
                placeholderValue={"0"}
                placeholderLabel={t(
                  "project.survey.question.form.question-to-placeholder",
                  "Select Question"
                )}
                className="w-1/3"
              />
              <Select
                id={`route-operator${index}`}
                value={route.routeOperatorId}
                onChange={(value) => {
                  onChangeQuestionRoute(
                    {
                      ...route,
                      routeOperatorId: value,
                    },
                    index
                  );
                }}
                options={routeOperators.map((operator) => ({
                  value: operator.id,
                  label: routeOperatorTranslations[operator.label].label,
                }))}
                placeholderValue={"0"}
                placeholderLabel={t(
                  "project.survey.question.form.route-operator-placeholder",
                  "Select Operator"
                )}
                className="w-1/4"
              />
              {(questionRouteValueOptions &&
                questionRouteValueOptions.length) ||
              (responseOptions && responseOptions.length) ? (
                <Select
                  id={`route-response-option${index}`}
                  value={route.value}
                  onChange={(value) => {
                    onChangeQuestionRoute(
                      {
                        ...route,
                        value: value,
                      },
                      index
                    );
                  }}
                  options={
                    questionRouteValueOptions &&
                    questionRouteValueOptions.length
                      ? questionRouteValueOptions
                      : responseOptions.map((option) => ({
                          value: option.value,
                          label: option.value,
                        }))
                  }
                  placeholderValue={""}
                  placeholderLabel={t(
                    "project.survey.question.form.route-value-placeholder",
                    "Select value"
                  )}
                  className="flex-grow"
                />
              ) : (
                <Input
                  placeholder={t(
                    "project.survey.question.form.response-option-value-placeholder",
                    "Value"
                  )}
                  value={route.value}
                  onChange={(value) => {
                    onChangeQuestionRoute(
                      {
                        ...route,
                        value: value,
                      },
                      index
                    );
                  }}
                  placeholderValue={undefined}
                  placeholderLabel={t(
                    "project.survey.question.form.route-operator-placeholder",
                    "Select Operator"
                  )}
                  className="w-full"
                />
              )}

              <Button
                color="error"
                onClick={() => {
                  onDeleteQuestionRoute(index);
                }}
              >
                <Icons.Delete className="text-xl" />
              </Button>
            </div>
          ))}
        </div>
      ) : undefined}

      <Button
        color="primary"
        start={<Icons.Add className="text-xl" />}
        onClick={() => {
          onAddQuestionRoute();
        }}
      >
        {t(
          "project.survey.question.form.add-question-route",
          "Add question route"
        )}
      </Button>
    </div>
  );
}

QuestionRoutesForm.propTypes = {
  questions: PropTypes.array,
  questionLanguage: PropTypes.string,
  routeOperators: PropTypes.array,
  questionRouteValueOptions: PropTypes.array,
  responseOptions: PropTypes.array,
  questionRoutes: PropTypes.array,
  setQuestionRoutes: PropTypes.func,
  setChanged: PropTypes.func,
  questionId: PropTypes.string,
};

export default QuestionRoutesForm;
