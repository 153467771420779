import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Checkbox,
  Select,
  DatePicker,
  EmailInput,
  MultipleSelect,
  Radio,
} from "../..";

/**
 * Publish task form
 * Step 2 in the publishing window flow
 * @param {array} selectedLanguages - The languages to be published [{id: "", label: ""}]
 * @param {object} client - Client object {respondentLists: [{id: "", label: ""}], id: ""}
 * @param {boolean} sendPublishEmail - True if publish task needs to be created
 * @param {function} setSendPublishEmail - Setter function
 * @param {string} publishEmailFrom - Send emails from (impuls or alert)
 * @param {function} setPublishEmailFrom - Setter function
 * @param {boolean} sendEmailNow - False if email should be scheduled
 * @param {function} setSendEmailNow - Setter function
 * @param {array} publishMailingListIds - Ids of selected respondent lists
 * @param {function} setPublishMailingListIds - Setter function
 * @param {date} publishStartAt - Scheduled email (start) date
 * @param {function} setPublishStartAt - Setter function
 * @param {string} publishStartHour - Scheduled email hour ("0" - "23")
 * @param {function} setPublishStartHour - Setter function
 * @param {boolean} publishIsRecurring - True if schedule is recurring
 * @param {function} setPublishIsRecurring - Setter function
 * @param {date} publishEndAt - Recurring schedule end date
 * @param {function} setPublishEndAt - Setter function
 * @param {string} publishRepeatPeriod - Recurring schedule period ["weekly", "biweekly", "monthly", "quarterly", "semesterly", "yearly"]
 * @param {function} setPublishRepeatPeriod - Setter function
 * @param {object} publishEmailSubjects - Email subjects object {["langageLabel"]: "subject"}
 * @param {function} setPublishEmailSubjects - Setter function
 * @param {object} publishEmailMessages - Email messages object {["langageLabel"]: "message"}
 * @param {function} setPublishEmailMessages - Setter function
 * @param {function} setInfoModalOpen - Open info modal function
 * @param {function} setInfoModalType - Info modal type setter (TODO: needs refactor)
 * @returns  {node} React component
 */
function PublishTaskForm({
  selectedLanguages,
  client,
  sendPublishEmail,
  setSendPublishEmail,
  publishEmailFrom,
  setPublishEmailFrom,
  sendEmailNow,
  setSendEmailNow,
  publishMailingListIds,
  setPublishMailingListIds,
  publishStartAt,
  setPublishStartAt,
  publishStartHour,
  setPublishStartHour,
  publishEndAt,
  setPublishEndAt,
  publishIsRecurring,
  setPublishIsRecurring,
  publishRepeatPeriod,
  setPublishRepeatPeriod,
  publishEmailSubjects,
  setPublishEmailSubjects,
  publishEmailMessages,
  setPublishEmailMessages,
  setInfoModalOpen,
  setInfoModalType,
}) {
  const { t } = useTranslation();

  if (client.respondentLists && client.respondentLists.length) {
    return (
      <div className="space-y-2 flex flex-col w-full">
        <Checkbox
          id="send-publish-email"
          label={t(
            "components.publish-publish-task.send-email-label",
            "Send emails"
          )}
          checked={sendPublishEmail}
          toggle={() => setSendPublishEmail(!sendPublishEmail)}
        />
        {sendPublishEmail ? (
          <>
            <MultipleSelect
              id="respondent-list"
              selectLabel={t(
                "components.publish-publish-task.mailinglist-label",
                "Mailing list"
              )}
              selectedValues={publishMailingListIds}
              setSelectedValues={setPublishMailingListIds}
              options={client.respondentLists.map((list) => ({
                value: list.id,
                label: list.label,
              }))}
              selectPlaceholder={t(
                "components.publish-publish-task.mailinglist-placeholder",
                "Add mailing list"
              )}
            />
            <Radio
              id="send-mail-from"
              label={t(
                "components.publish-publish-task.send-from-label",
                "Send from"
              )}
              options={[
                { label: "Impuls", value: "impuls" },
                { label: "Alert", value: "alert" },
              ]}
              value={publishEmailFrom}
              onChange={setPublishEmailFrom}
            />
            <EmailInput
              selectedLanguages={selectedLanguages}
              id="publish"
              emailSubjects={publishEmailSubjects}
              setEmailSubjects={setPublishEmailSubjects}
              emailMessages={publishEmailMessages}
              setEmailMessages={setPublishEmailMessages}
              infoMessage={t(
                "components.publish-publish-task.template-info-message",
                "You can use templating syntax to add the name and url to the message. Click for more information."
              )}
              setInfoModalOpen={setInfoModalOpen}
              setInfoModalType={setInfoModalType}
            />
            <Checkbox
              id="schedule-delivery"
              label={t(
                "components.publish-publish-task.schedule-delivery",
                "Schedule delivery"
              )}
              checked={!sendEmailNow}
              toggle={() => setSendEmailNow(!sendEmailNow)}
            />
            {!sendEmailNow ? (
              <>
                <DatePicker
                  onChange={setPublishStartAt}
                  value={publishStartAt}
                  id="start-publish-at-date"
                  label={t(
                    "components.publish-publish-task.start-at-label",
                    "Send at"
                  )}
                  min={new Date()}
                />
                <Select
                  id="start-publish-at-hour"
                  value={publishStartHour}
                  onChange={setPublishStartHour}
                  options={[...new Array(24)]
                    .map((_, index) => ({
                      label: `${index}:00`,
                      value: index,
                    }))
                    .filter(
                      ({ value }) =>
                        publishStartAt.toDateString() !==
                          new Date().toDateString() ||
                        value >= new Date().getHours() + 1
                    )}
                  placeholderValue={"0"}
                  placeholderLabel={t(
                    "components.publish-publish-task.mailinglist-placeholder",
                    "Add mailing list"
                  )}
                />
                <Checkbox
                  id="is-recurring"
                  label={t(
                    "components.publish-publish-task.is-recurring-label",
                    "Recurring emails?"
                  )}
                  checked={publishIsRecurring}
                  toggle={() => setPublishIsRecurring(!publishIsRecurring)}
                />
                {publishIsRecurring ? (
                  <>
                    <Select
                      label={t(
                        "components.publish-publish-task.repeat-period-label",
                        "Repetition period"
                      )}
                      id="publish-repeat-period"
                      value={publishRepeatPeriod}
                      onChange={setPublishRepeatPeriod}
                      options={[
                        {
                          label: t(
                            "components.publish-publish-task.repeat-periods.weekly",
                            "weekly"
                          ),
                          value: "weekly",
                        },
                        {
                          label: t(
                            "components.publish-publish-task.repeat-periods.biweekly",
                            "every two weeks"
                          ),
                          value: "biweekly",
                        },
                        {
                          label: t(
                            "components.publish-publish-task.repeat-periods.monthly",
                            "monthly"
                          ),
                          value: "monthly",
                        },
                        {
                          label: t(
                            "components.publish-publish-task.repeat-periods.quarterly",
                            "every three months"
                          ),
                          value: "quarterly",
                        },
                        {
                          label: t(
                            "components.publish-publish-task.repeat-periods.semesterly",
                            "every six months"
                          ),
                          value: "semesterly",
                        },
                        {
                          label: t(
                            "components.publish-publish-task.repeat-periods.yearly",
                            "yearly"
                          ),
                          value: "yearly",
                        },
                      ]}
                    />
                    <DatePicker
                      onChange={setPublishEndAt}
                      value={publishEndAt}
                      id="end-publish-at-date"
                      label={t(
                        "components.publish-publish-task.end-at-label",
                        "End at"
                      )}
                      min={publishStartAt}
                    />
                  </>
                ) : undefined}{" "}
              </>
            ) : undefined}
          </>
        ) : undefined}
      </div>
    );
  } else {
    return (
      <div className="space-y-2">
        <h3 className="text-3xl">
          {t(
            "components.publish-publish-task.no-mailinglists-found",
            "No mailing lists found"
          )}
        </h3>
        <Link
          to={`/clients/${client.id}/mailinglists`}
          className="text-xl text-primary"
        >
          {t(
            "components.publish-publish-task.create-mailinglist",
            "Create one"
          )}
        </Link>
      </div>
    );
  }
}

PublishTaskForm.propTypes = {
  selectedLanguages: PropTypes.array,
  client: PropTypes.object,
  sendPublishEmail: PropTypes.bool,
  setSendPublishEmail: PropTypes.func,
  publishEmailFrom: PropTypes.oneOf(["impuls", "alert"]),
  setPublishEmailFrom: PropTypes.func,
  sendEmailNow: PropTypes.bool,
  setSendEmailNow: PropTypes.func,
  publishMailingListIds: PropTypes.array,
  setPublishMailingListIds: PropTypes.func,
  publishStartAt: PropTypes.object,
  setPublishStartAt: PropTypes.func,
  publishStartHour: PropTypes.string,
  setPublishStartHour: PropTypes.func,
  publishEndAt: PropTypes.object,
  setPublishEndAt: PropTypes.func,
  publishIsRecurring: PropTypes.bool,
  setPublishIsRecurring: PropTypes.func,
  publishRepeatPeriod: PropTypes.oneOf([
    "weekly",
    "biweekly",
    "monthly",
    "quarterly",
    "semesterly",
    "yearly",
  ]),
  setPublishRepeatPeriod: PropTypes.func,
  publishEmailSubjects: PropTypes.object,
  setPublishEmailSubjects: PropTypes.func,
  publishEmailMessages: PropTypes.object,
  setPublishEmailMessages: PropTypes.func,
  setInfoModalOpen: PropTypes.func,
  setInfoModalType: PropTypes.func,
};

export default PublishTaskForm;
