import { gql } from "@apollo/client";

const CREATEEMAIL = gql`
  mutation CreateEmail(
    $from: EmailFroms!
    $subject: String!
    $message: String!
    $languageId: ID!
    $publishTaskId: ID
    $responseTaskId: ID
  ) {
    createEmail(
      email: {
        from: $from
        subject: $subject
        message: $message
        languageId: $languageId
        publishTaskId: $publishTaskId
        responseTaskId: $responseTaskId
      }
    ) {
      id
    }
  }
`;

const UPDATEEMAIL = gql`
  mutation UpdateEmail(
    $from: EmailFroms
    $subject: String
    $message: String
    $languageId: ID
    $publishTaskId: ID
    $responseTaskId: ID
  ) {
    updateEmail(
      id: $id
      email: {
        from: $from
        subject: $subject
        message: $message
        languageId: $languageId
        publishTaskId: $publishTaskId
        responseTaskId: $responseTaskId
      }
    ) {
      id
    }
  }
`;

const DELETEEMAIL = gql`
  mutation DeleteEmail($id: ID!) {
    deleteEmail(id: $id)
  }
`;

const GETEMAILS = gql`
  query GetEmails {
    emails {
      id
      from
      subject
      message
      createdAt
      updatedAt
      language {
        id
        label
      }
    }
  }
`;

const GETEMAIL = gql`
  query GetEmail($id: ID!) {
    email(id: $id) {
      id
      from
      subject
      message
      createdAt
      updatedAt
      language {
        id
        label
      }
    }
  }
`;

const calls = {
  CREATEEMAIL,
  UPDATEEMAIL,
  DELETEEMAIL,
  GETEMAILS,
  GETEMAIL,
};

export default calls;
