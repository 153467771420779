import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import fuzzysort from "fuzzysort";
import calls from "../graphql";
import toast from "react-hot-toast";
import {
  LoggedInLayout,
  Breadcrumbs,
  Button,
  Checkbox,
  Icons,
  ActionModal,
  Table,
  TableData,
  TableHead,
  TableRow,
  Input,
  MinLevel,
} from "../components";
import { formatDate, useLanguageLabels, userRoleLevels } from "../utils";
const { GETSURVEYTEMPLATES, CREATESURVEY, GETLANGUAGES } = calls;

export default function Templates() {
  // Hooks
  const { t } = useTranslation();
  const { projectId } = useParams();
  const languageLabels = useLanguageLabels();
  const history = useHistory();
  // State
  const [languages, setLanguages] = useState([]);
  const [surveyModalOpen, setSurveyModalOpen] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [surveyLanguageIds, setSurveyLanguageIds] = useState([]);
  const [search, setSearch] = useState("");
  const [surveys, setSurveys] = useState([]);
  // Queries
  const getSurveyTemplatesState = useQuery(GETSURVEYTEMPLATES, {
    fetchPolicy: "cache-and-network",
  });
  const getLanguagesState = useQuery(GETLANGUAGES);
  // Mutations
  const [createSurvey, createSurveyState] = useMutation(CREATESURVEY, {
    refetchQueries: [GETSURVEYTEMPLATES],
  });
  // Handle errors
  if (getSurveyTemplatesState.error)
    toast.error(getSurveyTemplatesState.error.message);
  if (getLanguagesState.error) toast.error(getLanguagesState.error.message);
  // Effects
  useEffect(() => {
    const surveys =
      getSurveyTemplatesState.data &&
      getSurveyTemplatesState.data.surveyTemplates
        ? getSurveyTemplatesState.data.surveyTemplates
        : [];
    if (search) {
      const delayedSearch = setTimeout(() => {
        const results = fuzzysort.go(search, surveys, {
          keys: ["label", "description", "client.name", "createdBy.userName"],
        });
        setSurveys(results.map((result) => result.obj));
      }, 200);
      return () => clearTimeout(delayedSearch);
    } else {
      setSurveys(surveys);
    }
  }, [search, getSurveyTemplatesState.data]);
  useEffect(() => {
    if (getLanguagesState.data && getLanguagesState.data.languages) {
      const languages = getLanguagesState.data.languages.filter(
        (language) => !!languageLabels[language.label]
      );
      setLanguages(languages);
    }
  }, [getLanguagesState]);

  const resetState = () => {
    setSurveyModalOpen(false);
    setSurveyTitle("");
    setSurveyDescription("");
  };

  const toggleLanguage = (id) => {
    let ids = [...surveyLanguageIds];
    if (ids.includes(id)) {
      ids = ids.filter((lId) => lId !== id);
    } else {
      ids = [...ids, id];
    }
    setSurveyLanguageIds(ids);
  };

  const onSaveSurvey = async () => {
    if (surveyTitle) {
      if (surveyLanguageIds.length) {
        try {
          const { data } = await createSurvey({
            variables: {
              label: surveyTitle,
              description: surveyDescription,
              isTemplate: true,
              projectId: projectId,
              languageIds: surveyLanguageIds,
            },
          });
          if (data && data.createSurvey) {
            const id = data.createSurvey.id;
            resetState();
            toast.success(
              t(
                "templates.surveys.messages.success.create-survey-template",
                "Survey template created!"
              )
            );
            history.replace(`/templates/${id}/dashboard`);
          }
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        toast.custom(
          t(
            "project.surveys.messages.warning.select-one-language",
            "You should select at least one language"
          )
        );
      }
    } else {
      toast.custom(
        t(
          "templates.surveys.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: "/templates",
                label: t("templates.surveys.labels.templates", "Templates"),
              },
            ]}
          />
        }
        isLoading={getSurveyTemplatesState.loading}
        page="Templates"
      >
        {search || (surveys && surveys.length) ? (
          <>
            <div className="flex items-center">
              <div className="flex-grow">
                <Input
                  id="search"
                  placeholder={t("templates.surveys.labels.search", "Search")}
                  value={search}
                  onChange={setSearch}
                  className="w-4/5"
                />
              </div>
              <MinLevel minLevel={userRoleLevels.Manager}>
                <Button
                  start={<Icons.Add className="text-xl" />}
                  isLoading={createSurveyState.loading}
                  onClick={() => setSurveyModalOpen(true)}
                >
                  {t("templates.surveys.labels.new-survey", "New Template")}
                </Button>
              </MinLevel>
            </div>
            <Table
              head={
                <tr className="h-10">
                  <TableHead>
                    {t("templates.surveys.table.headers.survey", "Template")}
                  </TableHead>
                  <TableHead>
                    {t("templates.surveys.table.headers.updated", "Updated")}
                  </TableHead>
                  <TableHead />
                </tr>
              }
            >
              {surveys.map((survey) => (
                <TableRow key={survey.id}>
                  <TableData>
                    <div>
                      <div className="font-bold">{survey.label}</div>
                      <div className="font-normal">{survey.description}</div>
                    </div>
                  </TableData>
                  <TableData>{formatDate(survey.updatedAt)}</TableData>
                  <TableData>
                    <div className="flex flex-grow w-full items-center justify-end space-x-4">
                      <Link
                        to={`/templates/${survey.id}/dashboard`}
                        className="flex justify-end"
                      >
                        <Icons.Next className="text-4xl" />
                      </Link>
                    </div>
                  </TableData>
                </TableRow>
              ))}
            </Table>
          </>
        ) : (
          <div className="flex items-center">
            <div className="text-3xl flex-grow">
              {t(
                "templates.surveys.messages.warning.no-surveys",
                "No templates found, create one!"
              )}
            </div>
            <MinLevel minLevel={userRoleLevels.Manager}>
              <Button
                start={<Icons.Add className="text-xl" />}
                isLoading={createSurveyState.loading}
                onClick={() => setSurveyModalOpen(true)}
              >
                {t("templates.surveys.labels.new-survey", "New Template")}
              </Button>
            </MinLevel>
          </div>
        )}
      </LoggedInLayout>
      <ActionModal
        title={t(
          "templates.surveys.modals.create-survey.title",
          "New Survey Template"
        )}
        modalOpen={surveyModalOpen}
        closeModal={() => setSurveyModalOpen(false)}
        actions={
          <Button
            onClick={onSaveSurvey}
            isLoading={createSurveyState.loading}
            size="sm"
            color="primary"
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "templates.surveys.modals.create-survey.save-survey",
              "Save Template"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveSurvey}>
          <Input
            id="survey-title"
            label={t(
              "templates.surveys.modals.create-survey.title-label",
              "Title"
            )}
            placeholder={t(
              "templates.surveys.modals.create-survey.title-placeholder",
              "title"
            )}
            value={surveyTitle}
            onChange={setSurveyTitle}
          />
          <Input
            id="survey-description"
            label={t(
              "templates.surveys.modals.create-survey.description-label",
              "Description"
            )}
            placeholder={t(
              "templates.surveys.modals.create-survey.description-placeholder",
              "description"
            )}
            value={surveyDescription}
            onChange={setSurveyDescription}
          />
          <label>
            {t(
              "project.surveys.modals.create-survey.languages-label",
              "Languages"
            )}
          </label>
          {languages.map((language) => (
            <Checkbox
              key={`survey-language-${language.id}`}
              id={`survey-language-${language.id}`}
              label={`${languageLabels[language.label].flags} ${
                languageLabels[language.label].label
              }`}
              checked={surveyLanguageIds.includes(language.id)}
              toggle={() => {
                toggleLanguage(language.id);
              }}
            />
          ))}
        </form>
      </ActionModal>
    </>
  );
}
