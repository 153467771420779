import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useReportComponentTranslations } from "../../../utils";
import {
  Select,
  ReportComponentOptionsForm,
  ComponentsPane,
  Button,
  Icons,
} from "../..";

/**
 * Report builder component form
 * @param {array} surveySections - Sections of the survey
 * @param {array} componentTypes - All supported report component types
 * @param {array} questionCategories - All supported question categories
 * @param {array} questionTags - All supported question tags
 * @param {string} componentTypeId - Id of the selected component type
 * @param {function} setComponentTypeId - Setter function
 * @param {string} componentContent - Component main content
 * @param {function} setComponentContent - Setter function
 * @param {object} componentOptions - Component options to be parsed by the report builder
 * @param {function} setComponentOptions - Setter function
 * @param {object} componentQuestionId - Id of the question related to the selected component
 * @param {function} setComponentQuestionId - Setter function
 * @param {array} componentQuestionCategoryIds - Selected question category Ids for the component
 * @param {function} setComponentQuestionCategoryIds - Setter function
 * @param {array} componentQuestionTagIds - Selected question tag Ids for the component
 * @param {function} setComponentQuestionTagIds - Setter function
 * @param {array} childComponents - Child components of the selected component
 * @param {function} setChildComponents - Setter function
 * @param {function} onAddChildComponent - On add child component callback
 * @param {function} onEditChildComponent - On edit child component callback
 * @param {function} onDeleteChildComponent - On delete child component callback
 * @param {function} allowStructural - Whether to allow this component to be a structural one (i.e. one that can contain child components)
 * @returns {node} Report template component form react component
 */
export default function ReportComponentForm({
  surveySections,
  componentTypes,
  questionCategories,
  questionTags,
  componentTypeId,
  setComponentTypeId,
  componentContent,
  setComponentContent,
  componentOptions,
  setComponentOptions,
  componentQuestionId,
  setComponentQuestionId,
  componentQuestionCategoryIds,
  setComponentQuestionCategoryIds,
  componentQuestionTagIds,
  setComponentQuestionTagIds,
  childComponents,
  setChildComponents,
  onAddChildComponent,
  onEditChildComponent,
  onDeleteChildComponent,
  allowStructural,
}) {
  // Hooks
  const { t } = useTranslation();
  const componentTranslations = useReportComponentTranslations();
  // State
  const [componentType, setComponentType] = useState(undefined);
  // Effects
  useEffect(() => {
    if (Array.isArray(componentTypes) && componentTypeId) {
      const type = componentTypes.find((cType) => cType.id === componentTypeId);
      setComponentType(type);
    }
  }, [componentTypeId, componentTypes]);

  return (
    <form className="form-control space-y-2">
      <label htmlFor="component-type">
        {t(
          "components.reportcomponentform.labels.component-type-label",
          "Component type"
        )}
      </label>
      <Select
        id="component-type"
        value={componentTypeId}
        onChange={setComponentTypeId}
        options={[
          {
            label: t(
              "components.reportcomponentform.labels.visual-type-label",
              "Visual"
            ),
            options: componentTypes
              .filter((type) => !type.allowSubcomponents)
              .map((type) => ({
                value: type.id,
                label: componentTranslations[type.label],
              })),
          },
          {
            label: t(
              "components.reportcomponentform.labels.structural-type-label",
              "Structural"
            ),
            options: componentTypes
              .filter((type) => type.allowSubcomponents)
              .map((type) => ({
                value: type.id,
                label: componentTranslations[type.label],
              })),
          },
        ].filter((option) => allowStructural || option.label !== "Structural")}
        placeholderValue={"-1"}
        placeholderLabel={t(
          "components.reportcomponentform.labels.component-type-placeholder",
          "Select component type"
        )}
      />
      {componentType ? (
        <>
          <ReportComponentOptionsForm
            surveySections={surveySections}
            componentType={componentType}
            componentContent={componentContent}
            setComponentContent={setComponentContent}
            componentOptions={componentOptions}
            setComponentOptions={setComponentOptions}
            componentQuestionId={componentQuestionId}
            setComponentQuestionId={setComponentQuestionId}
            questionCategories={questionCategories}
            questionTags={questionTags}
            componentQuestionCategoryIds={componentQuestionCategoryIds}
            setComponentQuestionCategoryIds={setComponentQuestionCategoryIds}
            componentQuestionTagIds={componentQuestionTagIds}
            setComponentQuestionTagIds={setComponentQuestionTagIds}
          />
          {componentType.allowSubcomponents ? (
            onAddChildComponent ? (
              <ComponentsPane
                id="child-compontents"
                header={
                  <>
                    <div className="flex flex-row justify-between items-center">
                      <div className="text-2xl font-bold">
                        {t(
                          "components.reportcomponentform.labels.child-components",
                          "Subcomponents"
                        )}
                      </div>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={onAddChildComponent}
                        className={onAddChildComponent ? "" : "btn-disabled"}
                      >
                        <Icons.Add className="text-xl" />
                        <div className="hidden lg:block">
                          {t(
                            "components.reportcomponentform.labels.new-component",
                            "New component"
                          )}
                        </div>
                      </Button>
                    </div>
                  </>
                }
                components={childComponents}
                onChangeComponents={setChildComponents}
                onEditComponent={onEditChildComponent}
                onDeleteComponent={onDeleteChildComponent}
              />
            ) : (
              <div className="flex flex-grow flex-col bg-base-300 rounded-box px-5 pt-3 pb-3 space-y-2">
                <div className="flex flex-row justify-between items-center">
                  <div className="text-2xl font-bold">
                    {t(
                      "components.reportcomponentform.labels.child-components",
                      "Subcomponents"
                    )}
                  </div>
                </div>
                {t(
                  "components.reportcomponentform.messages.warning.save-component-to-add-children",
                  "Save component to add subcomponents"
                )}
              </div>
            )
          ) : null}
        </>
      ) : null}
    </form>
  );
}

ReportComponentForm.propTypes = {
  surveySections: PropTypes.array,
  componentTypes: PropTypes.array,
  questionCategories: PropTypes.array,
  questionTags: PropTypes.array,
  componentTypeId: PropTypes.string,
  setComponentTypeId: PropTypes.func,
  componentContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setComponentContent: PropTypes.func,
  componentOptions: PropTypes.object,
  setComponentOptions: PropTypes.func,
  componentQuestionId: PropTypes.string,
  setComponentQuestionId: PropTypes.func,
  componentQuestionCategoryIds: PropTypes.array,
  setComponentQuestionCategoryIds: PropTypes.func,
  componentQuestionTagIds: PropTypes.array,
  setComponentQuestionTagIds: PropTypes.func,
  childComponents: PropTypes.array,
  setChildComponents: PropTypes.func,
  onAddChildComponent: PropTypes.func,
  onEditChildComponent: PropTypes.func,
  onDeleteChildComponent: PropTypes.func,
  allowStructural: PropTypes.bool,
};
