import { gql } from "@apollo/client";

const GETREPORTS = gql`
  query getReports {
    reports {
      id
      label
      location
      createdAt
      updatedAt
      reportStructure {
        id
        label
      }
      respondent {
        id
        name
        email
      }
    }
  }
`;

const GETREPORT = gql`
  query getReport($id: ID!) {
    report(id: $id) {
      id
      label
      location
      createdAt
      updatedAt
      reportStructure {
        id
        label
      }
      respondent {
        id
        name
        email
      }
    }
  }
`;

const CREATEREPORT = gql`
  mutation CreateReport(
    $label: String!
    $surveyId: ID!
    $reportStructureId: ID!
    $surveyResponseIds: [ID!]!
  ) {
    createReport(
      report: {
        label: $label
        surveyId: $surveyId
        reportStructureId: $reportStructureId
        surveyResponseIds: $surveyResponseIds
      }
    ) {
      id
    }
  }
`;

const DELETEREPORT = gql`
  mutation DeleteReport($id: ID!) {
    deleteReport(id: $id)
  }
`;

const calls = {
  GETREPORTS,
  GETREPORT,
  CREATEREPORT,
  DELETEREPORT,
};

export default calls;
