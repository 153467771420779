import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import {
  LoggedInLayout,
  Button,
  Icons,
  Checkbox,
  Select,
  ActionModal,
  ConfirmModal,
  ReportComponentForm,
  Loading,
  ComponentsPane,
  MinLevel,
} from "../../../components";
import { userRoleLevels } from "../../../utils";
import calls from "../../../graphql";
const {
  GETCOMPONENTTYPES,
  GETREPORTSTRUCTURE,
  GETREPORTSTRUCTUREPREVIEW,
  CREATEREPORTPAGE,
  UPDATEREPORTPAGE,
  COPYREPORTPAGE,
  DELETEREPORTPAGE,
  CREATEREPORTCOMPONENT,
  UPDATEREPORTCOMPONENT,
  DELETEREPORTCOMPONENT,
  UPDATEPAGESORDER,
  GETQUESTIONCATEGORIES,
  GETSURVEYSURVEYSECTIONSQUESTIONS,
  UPLOADFILE,
} = calls;

/**
 * Report builder page
 * @param {node} breadCrumbs - React component that goes in the breadcrumbs section of the header
 * @param {node} actions - React component that goes in the actions section of the header
 * @returns {node} Report builder page react component
 */
export default function ReportBuilderPage({ breadCrumbs, actions }) {
  // Hooks
  const { surveyId, reportStructureId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  // State
  // DATA
  const [questionCategories, setQuestionCategories] = useState([]);
  const [questionTags, setQuestionTags] = useState([]);
  const [surveySections, setSurveySections] = useState([]);
  const [componentTypes, setComponentTypes] = useState([]);
  const [reportStructureLabel, setReportStructureLabel] = useState(undefined);
  const [pages, setPages] = useState([]);
  const [reportPreview, setReportPreview] = useState("");
  // STATE
  const [previewFullScreen, setPreviewFullScreen] = useState(false);
  const [newPageModalOpen, setNewPageModalOpen] = useState(false);
  const [editPageModalOpen, setEditPageModalOpen] = useState(false);
  const [deletePageModalOpen, setDeletePageModalOpen] = useState(false);
  const [deleteComponentModalOpen, setDeleteComponentModalOpen] =
    useState(false);
  const [componentModalOpen, setComponentModalOpen] = useState(false);
  const [childComponentModalOpen, setChildComponentModalOpen] = useState(false);
  // PAGE
  const [selectedPage, setSelectedPage] = useState(undefined);
  const [pageComponents, setPageComponents] = useState([]);
  const [pageBefore, setPageBefore] = useState(false);
  const [pageOrder, setPageOrder] = useState(-1);
  // COMPONENT
  const [selectedComponent, setSelectedComponent] = useState(undefined);
  const [componentTypeId, setComponentTypeId] = useState("-1");
  const [componentContent, setComponentContent] = useState("");
  const [componentOptions, setComponentOptions] = useState({});
  const [componentQuestionId, setComponentQuestionId] = useState("-1");
  const [componentQuestionCategoryIds, setComponentQuestionCategoryIds] =
    useState([]);
  const [componentQuestionTagIds, setComponentQuestionTagIds] = useState([]);
  const [childComponents, setChildComponents] = useState([]);
  // CHILD COMPONENT
  const [selectedChildComponent, setSelectedChildComponent] =
    useState(undefined);
  const [childComponentTypeId, setChildComponentTypeId] = useState("-1");
  const [childComponentContent, setChildComponentContent] = useState("");
  const [childComponentOptions, setChildComponentOptions] = useState({});
  const [childComponentQuestionId, setChildComponentQuestionId] =
    useState("-1");
  const [
    childComponentQuestionCategoryIds,
    setChildComponentQuestionCategoryIds,
  ] = useState([]);
  const [childComponentQuestionTagIds, setChildComponentQuestionTagIds] =
    useState([]);
  // Queries
  const getSurveyQuestionsState = useQuery(GETSURVEYSURVEYSECTIONSQUESTIONS, {
    variables: {
      surveyId: surveyId,
    },
  });
  const getComponentTypeState = useQuery(GETCOMPONENTTYPES);
  const getReportStructureState = useQuery(GETREPORTSTRUCTURE, {
    variables: {
      id: reportStructureId,
    },
    fetchPolicy: "cache-and-network",
  });
  const getQuestionCategoriesState = useQuery(GETQUESTIONCATEGORIES);
  const [getPreview, getPreviewState] = useLazyQuery(
    GETREPORTSTRUCTUREPREVIEW,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  // Mutations
  const [createReportPage, createReportPageState] = useMutation(
    CREATEREPORTPAGE,
    {
      refetchQueries: [GETREPORTSTRUCTURE],
    }
  );
  const [updateReportPage, updateReportPageState] = useMutation(
    UPDATEREPORTPAGE,
    {
      refetchQueries: [GETREPORTSTRUCTURE],
    }
  );
  const [copyReportPage, copyReportPageState] = useMutation(COPYREPORTPAGE, {
    refetchQueries: [GETREPORTSTRUCTURE],
  });
  const [deleteReportPage, deleteReportPageState] = useMutation(
    DELETEREPORTPAGE,
    {
      refetchQueries: [GETREPORTSTRUCTURE],
    }
  );
  const [createReportComponent, createReportComponentState] = useMutation(
    CREATEREPORTCOMPONENT,
    {
      refetchQueries: [GETREPORTSTRUCTURE],
    }
  );
  const [updateReportComponent, updateReportComponentState] = useMutation(
    UPDATEREPORTCOMPONENT,
    {
      refetchQueries: [GETREPORTSTRUCTURE],
    }
  );
  const [deleteReportComponent, deleteReportComponentState] = useMutation(
    DELETEREPORTCOMPONENT,
    {
      refetchQueries: [GETREPORTSTRUCTURE],
    }
  );
  const [updatePagesOrder, updatePagesOrderState] = useMutation(
    UPDATEPAGESORDER,
    {
      refetchQueries: [GETREPORTSTRUCTURE],
    }
  );
  const [upload, uploadState] = useMutation(UPLOADFILE);
  // Handle errors
  if (getComponentTypeState.error)
    toast.error(getComponentTypeState.error.message);
  if (getReportStructureState.error)
    toast.error(getReportStructureState.error.message);
  if (getQuestionCategoriesState.error)
    toast.error(getQuestionCategoriesState.error.message);
  if (getPreviewState.error) toast.error(getPreviewState.error.message);
  // Effects
  useEffect(() => {
    if (getComponentTypeState.data) {
      setComponentTypes(getComponentTypeState.data.componentTypes);
    }
  }, [getComponentTypeState]);
  useEffect(() => {
    if (getReportStructureState.data) {
      const structure = getReportStructureState.data.reportStructure;
      setReportStructureLabel(structure.label);
      const pages = structure.reportPages.map((page) => ({
        ...page,
      }));
      setPages(pages);
      if (selectedPage) {
        const page = pages.find((page) => page.id === selectedPage.id);
        setSelectedPage(page);
        setPageComponents(page ? page.reportComponents : []);
        if (page && selectedComponent) {
          const component = page.reportComponents.find(
            (comp) => comp.id === selectedComponent.id
          );
          setSelectedComponent(component);
          if (component && componentModalOpen) {
            setComponentTypeId(component.componentType.id);
            setComponentContent(component.content);
            setComponentOptions(JSON.parse(component.options));
            setComponentQuestionCategoryIds(
              component.questionCategories.map((ct) => ct.id)
            );
            setComponentQuestionTagIds(
              component.questionTags.map((tg) => tg.id)
            );
          }
          setChildComponents(component ? component.childComponents : []);
        }
      } else if (pages.length) {
        const query = new URLSearchParams(location.search);
        const pageNumber = parseInt(query.get("page"));
        if (pageNumber && pageNumber < pages.length) {
          setSelectedPage(pages[pageNumber]);
        } else if (pageNumber >= pages.length) {
          setSelectedPage(pages[pages.length - 1]);
        } else {
          setSelectedPage(pages[0]);
        }
      }
    }
  }, [getReportStructureState]);
  useEffect(() => {
    if (selectedPage) {
      loadPreview();
      setPageComponents(selectedPage.reportComponents);
    }
  }, [selectedPage]);
  useEffect(() => {
    if (selectedComponent?.componentType?.allowSubcomponents) {
      setChildComponents(selectedComponent.childComponents);
    } else {
      setChildComponents([]);
    }
  }, [selectedComponent]);
  useEffect(() => {
    if (getPreviewState.data) {
      setReportPreview(getPreviewState.data.reportStructurePreview);
    }
  }, [getPreviewState]);
  useEffect(() => {
    if (getQuestionCategoriesState.data) {
      const categories = getQuestionCategoriesState.data.questionCategories;
      const tags = categories.reduce(
        (qTags, category) => [
          ...qTags,
          ...category.tags.map(({ id, label }) => ({
            id,
            label,
            color: category.color,
          })),
        ],
        []
      );
      setQuestionCategories(categories);
      setQuestionTags(tags);
    }
  }, [getQuestionCategoriesState]);
  useEffect(() => {
    if (getSurveyQuestionsState.data) {
      setSurveySections(getSurveyQuestionsState.data?.survey?.sections);
    }
  }, [getSurveyQuestionsState.data]);

  const resetComponentState = () => {
    setComponentContent("");
    setComponentTypeId("-1");
    setComponentOptions({});
    setComponentQuestionCategoryIds([]);
    setComponentQuestionTagIds([]);
    setComponentQuestionId("-1");
  };

  const resetChildComponentState = () => {
    setChildComponentContent("");
    setChildComponentTypeId("-1");
    setChildComponentOptions({});
    setChildComponentQuestionCategoryIds([]);
    setChildComponentQuestionTagIds([]);
    setChildComponentQuestionId("-1");
  };

  const loadPreview = async () => {
    try {
      if (selectedPage && selectedPage.id && selectedPage.order >= 0) {
        await getPreview({
          variables: {
            id: reportStructureId,
            pageNumber: selectedPage.order,
          },
        });
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onAddPage = async () => {
    try {
      const { data } = await createReportPage({
        variables: {
          reportStructureId: reportStructureId,
          order: pageOrder - pageBefore,
        },
      });
      if (data && data.createReportPage && data.createReportPage.id) {
        await updatePagesOrder({
          variables: {
            id: reportStructureId,
          },
        });
        toast.success(
          t(
            "project.survey.reportbuilder.messages.success.create-page",
            "Report page created!"
          )
        );
        setNewPageModalOpen(false);
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onEditPage = async () => {
    try {
      let order = parseInt(pageOrder) + (pageBefore ? -1 : +1);
      await updateReportPage({
        variables: {
          id: selectedPage.id,
          order: order,
        },
      });
      await updatePagesOrder({
        variables: {
          id: reportStructureId,
        },
      });
      toast.success(
        t(
          "project.survey.reportbuilder.messages.success.update-page",
          "Report page saved!"
        )
      );
      setEditPageModalOpen(false);
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onCopyPage = async () => {
    try {
      await copyReportPage({
        variables: {
          id: selectedPage.id,
        },
      });
      toast.success(
        t(
          "project.survey.reportbuilder.messages.success.copy-page",
          "Report page copied!"
        )
      );
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onDeletePage = async () => {
    try {
      await deleteReportPage({
        variables: {
          id: selectedPage.id,
        },
      });
      await updatePagesOrder({
        variables: {
          id: reportStructureId,
        },
      });
      toast.success(
        t(
          "project.survey.reportbuilder.messages.success.delete-page",
          "Report page deleted!"
        )
      );
      setDeletePageModalOpen(false);
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onSaveComponent = async (isChild = false) => {
    try {
      // Child check
      if (isChild && !selectedComponent) {
        toast.error(
          t(
            "project.survey.reportbuilder.messages.error.child-without-parent",
            "Cannot save child component without knowing its parent. Please reload page and try again."
          )
        );
        return;
      }
      let selection = isChild ? selectedChildComponent : selectedComponent;
      let content = isChild ? childComponentContent : componentContent;
      let options = isChild ? childComponentOptions : componentOptions;
      let order = isChild
        ? selectedComponent.childComponents.length
        : selectedPage.reportComponents.length;
      let pageId = isChild ? undefined : selectedPage.id;
      let componentId = isChild ? selectedComponent.id : undefined;
      let typeId = isChild ? childComponentTypeId : componentTypeId;
      let categoryIds = isChild
        ? childComponentQuestionCategoryIds
        : componentQuestionCategoryIds;
      let tagIds = isChild
        ? childComponentQuestionTagIds
        : componentQuestionTagIds;
      let questionId = isChild ? childComponentQuestionId : componentQuestionId;
      // Completeness check
      const componentType = componentTypes.find((type) => type.id === typeId);
      let complete = true;
      switch (componentType.label) {
        case "Heading":
          if (!(content && options.style && options.style.headingSize)) {
            complete = false;
          }
          break;
        case "Paragraph":
          if (!content) complete = false;
          break;
        case "Image":
          if (!(content && options.image && options.image.width)) {
            complete = false;
          }
          break;
        case "Table":
          if (
            !(
              options.table &&
              (categoryIds.length ||
                tagIds.length ||
                (questionId && questionId !== "-1"))
            )
          ) {
            complete = false;
          }
          break;
        case "Chart":
          if (
            !(
              options.chart &&
              (categoryIds.length ||
                tagIds.length ||
                (questionId && questionId !== "-1"))
            )
          ) {
            complete = false;
          }
          break;
        case "ScoreCard":
          if (
            !(
              content &&
              options.scorecard &&
              options.scorecard.maxScore &&
              (categoryIds.length || tagIds.length)
            )
          ) {
            complete = false;
          }
          break;
        case "NetPromotorScore": {
          if (!questionId || questionId === "-1") {
            complete = false;
          }
          break;
        }
        case "Row":
          break;
        case "Conditional":
          if (
            !(
              options.conditional &&
              options.conditional.operator &&
              (!isNaN(options.conditional.lowerValue) ||
                !isNaN(options.conditional.upperValue))
            )
          ) {
            complete = false;
          }
          break;
        default: {
          // No (known type selected)
          toast.custom(
            t(
              "project.survey.reportbuilder.messages.error.no-type",
              "Please select a component type"
            )
          );
          return;
        }
      }
      if (!complete) {
        toast.custom(
          t(
            "project.survey.reportbuilder.messages.error.no-content",
            "Please complete all fields"
          )
        );
        return;
      }
      // Upload image
      if (componentType.label === "Image" && typeof content !== "string") {
        const { data } = await upload({
          variables: {
            file: content,
          },
        });
        content = data.uploadFile;
      }
      // Save component
      let id = "";
      if (selection) {
        // Existing component - edit
        await updateReportComponent({
          variables: {
            id: selection.id,
            content: content,
            options: JSON.stringify(options),
            componentTypeId: typeId,
            questionCategoryIds: categoryIds,
            questionTagIds: tagIds,
            questionId: questionId === "-1" ? undefined : questionId,
          },
        });
        id = selection.id;
        toast.success(
          t(
            "project.survey.reportbuilder.messages.success.update-component",
            "Component saved!"
          )
        );
      } else {
        // New component - create
        const { data } = await createReportComponent({
          variables: {
            content: content,
            options: JSON.stringify(options),
            order: order,
            reportPageId: pageId,
            reportComponentId: componentId,
            componentTypeId: typeId,
            questionCategoryIds: categoryIds,
            questionTagIds: tagIds,
            questionId: questionId === "-1" ? undefined : questionId,
          },
        });
        if (data && data.createReportComponent) {
          id = data.createReportComponent.id;
          if (!isChild) setSelectedComponent({ id });
        }
        toast.success(
          t(
            "project.survey.reportbuilder.messages.success.create-component",
            "Component created!"
          )
        );
      }
      // Update child order
      if (componentType.allowSubcomponents && childComponents.length) {
        // Update child component order
        await updateComponentsOrder(childComponents);
      }
      // Close modal
      if (isChild) {
        setChildComponentModalOpen(false);
        resetChildComponentState();
      } else {
        if (selectedComponent || !componentType.allowSubcomponents) {
          setComponentModalOpen(false);
        }
        resetComponentState();
      }
      return id;
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onDeleteComponent = async () => {
    try {
      const deleteId = selectedChildComponent
        ? selectedChildComponent.id
        : selectedComponent.id;
      await deleteReportComponent({
        variables: { id: deleteId },
      });
      toast.success(
        t(
          "project.survey.reportbuilder.messages.success.delete-component",
          "Component deleted!"
        )
      );
      setDeleteComponentModalOpen(false);
      if (selectedChildComponent) {
        setSelectedChildComponent(undefined);
      } else {
        setSelectedComponent(undefined);
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onRefresh = async () => {
    if (selectedPage && selectedPage.changed) {
      await updateComponentsOrder(pageComponents);
      selectedPage.changed = false;
    }
    await loadPreview();
  };

  const updateComponentsOrder = async (components) => {
    await Promise.all(
      components.map(async (component, index) => {
        try {
          await updateReportComponent({
            variables: {
              id: component.id,
              order: index,
            },
          });
        } catch ({ message }) {
          toast.error(message);
        }
      })
    );
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={breadCrumbs}
        actions={actions}
        isLoading={false}
      >
        <div className="w-full flex-grow flex flex-row space-x-12">
          {!previewFullScreen ? (
            <div className="flex flex-col sm:w-1/2 w-full space-y-5">
              <div className="flex flex-row items-center justify-between">
                <div className="text-2xl md:text-3xl font-bold">
                  {reportStructureLabel
                    ? reportStructureLabel
                    : t(
                        "project.survey.reportbuilder.labels.report-title",
                        "Template title"
                      )}
                </div>
              </div>
              <div className="flex flex-col w-full bg-base-300 rounded-box px-5 py-3">
                <div className="flex flex-row pb-4 justify-between items-center">
                  <div className="text-2xl font-bold">
                    {t("project.survey.reportbuilder.labels.pages", "Pages")}
                  </div>
                  <MinLevel minLevel={userRoleLevels.Manager}>
                    <Button
                      color="primary"
                      size="sm"
                      start={<Icons.Add className="text-xl" />}
                      isLoading={createReportPageState.loading}
                      onClick={() => {
                        setPageOrder(-1);
                        setPageBefore(false);
                        if (pages && pages.length) {
                          setNewPageModalOpen(true);
                        } else {
                          onAddPage();
                        }
                      }}
                    >
                      {t(
                        "project.survey.reportbuilder.labels.new-page",
                        "New page"
                      )}
                    </Button>
                  </MinLevel>
                </div>
                <div className="w-full flex flex-row items-center space-x-4 overflow-x-auto pb-2">
                  {pages && pages.length ? (
                    pages.map((page, i) => (
                      <button
                        key={page.id}
                        onClick={async () => {
                          if (page.changed) {
                            updateComponentsOrder(pageComponents);
                          }
                          history.replace({
                            pathname: location.pathname,
                            search: `?page=${page.order}`,
                          });
                          setSelectedPage(page);
                        }}
                        className={`w-32 min-w-min h-40 ${
                          selectedPage && page.id === selectedPage.id
                            ? "bg-base-200"
                            : "bg-base-100"
                        } hover:bg-base-200 rounded-lg shadow-md flex items-center justify-center`}
                      >
                        <div className="w-32 flex items-center justify-center text-3xl font-bold">
                          {i + 1}
                        </div>
                      </button>
                    ))
                  ) : (
                    <div className="text-xl font-bold">
                      {t(
                        "project.survey.reportbuilder.messages.warning.no-pages",
                        "No pages found, create one!"
                      )}
                    </div>
                  )}
                </div>
              </div>
              {selectedPage ? (
                <ComponentsPane
                  id="page-components"
                  header={
                    <>
                      <div className="flex flex-row justify-between items-center">
                        <div className="text-2xl font-bold">
                          {t(
                            "project.survey.reportbuilder.labels.content",
                            "Page"
                          )}{" "}
                          {selectedPage.order + 1}
                        </div>
                        <MinLevel minLevel={userRoleLevels.Manager}>
                          <Button
                            color="accent"
                            size="sm"
                            start={<Icons.Sync className="text-xl" />}
                            onClick={onRefresh}
                            isLoading={
                              updateReportComponentState.loading ||
                              getPreviewState.loading
                            }
                          >
                            {t(
                              "project.survey.reportbuilder.labels.refresh-preview",
                              "Sync"
                            )}
                          </Button>
                        </MinLevel>
                      </div>
                      <MinLevel minLevel={userRoleLevels.Manager}>
                        <div className="flex flex-col w-full items-center space-y-2 bg-base-300 rounded-box">
                          <div className="w-full flex flex-row justify-between">
                            <div className="space-x-1">
                              <Button
                                color="accent"
                                size="sm"
                                onClick={() => onCopyPage()}
                                isLoading={copyReportPageState.loading}
                              >
                                <Icons.Copy className="text-xl" />
                                <div className="hidden lg:block">
                                  {t(
                                    "project.survey.reportbuilder.labels.copy-page",
                                    "Copy"
                                  )}
                                </div>
                              </Button>
                              {pages && pages.length > 1 ? (
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    if (selectedPage.order === 0) {
                                      setPageBefore(true);
                                      setPageOrder(1);
                                    } else {
                                      setPageBefore(false);
                                      setPageOrder(selectedPage.order - 1);
                                    }
                                    setEditPageModalOpen(true);
                                  }}
                                >
                                  <Icons.Edit className="text-xl" />
                                  <div className="hidden lg:block">
                                    {t(
                                      "project.survey.reportbuilder.labels.edit-page",
                                      "Edit"
                                    )}
                                  </div>
                                </Button>
                              ) : null}
                              <Button
                                color="error"
                                size="sm"
                                onClick={() => setDeletePageModalOpen(true)}
                                isLoading={deleteReportPageState.loading}
                              >
                                <Icons.Delete className="text-xl" />
                                <div className="hidden lg:block">
                                  {t(
                                    "project.survey.reportbuilder.labels.delete-page",
                                    "Delete"
                                  )}
                                </div>
                              </Button>
                            </div>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setSelectedComponent(undefined);
                                setComponentModalOpen(true);
                              }}
                            >
                              <Icons.Add className="text-xl" />
                              <div className="hidden lg:block">
                                {t(
                                  "project.survey.reportbuilder.labels.new-component",
                                  "New component"
                                )}
                              </div>
                            </Button>
                          </div>
                        </div>
                      </MinLevel>
                    </>
                  }
                  components={pageComponents}
                  onChangeComponents={(components) => {
                    selectedPage.changed = true;
                    setPageComponents(components);
                  }}
                  onEditComponent={(component) => {
                    const questionId = component?.question?.id;
                    setSelectedComponent(component);
                    setComponentTypeId(component.componentType.id);
                    setComponentContent(component.content);
                    setComponentOptions(JSON.parse(component.options));
                    setComponentQuestionId(questionId ? questionId : "-1");
                    setComponentQuestionCategoryIds(
                      component.questionCategories.map((ct) => ct.id)
                    );
                    setComponentQuestionTagIds(
                      component.questionTags.map((tg) => tg.id)
                    );
                    setComponentModalOpen(true);
                  }}
                  onDeleteComponent={(component) => {
                    setSelectedComponent(component);
                    setDeleteComponentModalOpen(true);
                  }}
                />
              ) : pages && pages.length ? (
                <div className="text-2xl font-bold">
                  {t(
                    "project.survey.reportbuilder.messages.warning.select-page",
                    "Select a page to view its content"
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          <div
            className={`hidden sm:flex flex-col ${
              previewFullScreen ? "w-full" : "w-1/2"
            }`}
          >
            <div className="flex flex-row pb-2 justify-between items-center">
              <h3 className="text-2xl font-bold">
                {t("project.survey.reportbuilder.labels.preview", "Preview")}
              </h3>
              <div className="flex flex-row space-x-2">
                <Button
                  onClick={() => setPreviewFullScreen(!previewFullScreen)}
                  color="ghost"
                >
                  {previewFullScreen ? (
                    <Icons.ExitFullScreen className="text-2xl" />
                  ) : (
                    <Icons.FullScreen className="text-2xl" />
                  )}
                </Button>
              </div>
            </div>
            {getPreviewState.loading ? (
              <div className="w-full flex-grow h-full flex items-center justify-center">
                <Loading />
              </div>
            ) : reportPreview ? (
              <embed
                src={reportPreview}
                type="application/pdf"
                className="w-full flex-grow h-full"
              />
            ) : null}
          </div>
        </div>
      </LoggedInLayout>
      <ActionModal
        modalOpen={newPageModalOpen}
        closeModal={() => setNewPageModalOpen(false)}
        title={t(
          "project.survey.reportbuilder.modals.new-page-modal.title",
          "New page"
        )}
        actions={
          <Button
            size="sm"
            onClick={onAddPage}
            color="primary"
            start={<Icons.Save className="text-xl" />}
            isLoading={
              createReportPageState.loading || updatePagesOrderState.loading
            }
          >
            {t(
              "project.survey.reportbuilder.modals.new-page-modal.save-page",
              "Save page"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2">
          {pages && pages.length ? (
            <>
              <label htmlFor="page-order">
                {t(
                  "project.survey.reportbuilder.modals.new-page-modal.page-order-label",
                  "Position"
                )}
              </label>
              <Checkbox
                id="beforeOrAfter"
                label={
                  pageBefore
                    ? t(
                        "project.survey.reportbuilder.modals.new-page-modal.position-before",
                        "Before"
                      )
                    : t(
                        "project.survey.reportbuilder.modals.new-page-modal.position-after",
                        "After"
                      )
                }
                checked={pageBefore}
                toggle={() => setPageBefore(!pageBefore)}
              />
              <Select
                id="page-order"
                value={pageOrder}
                onChange={setPageOrder}
                options={pages.map((page) => ({
                  value: page.order,
                  label: `${page.order + 1}`,
                }))}
                placeholderValue={-1}
                placeholderLabel={t(
                  "project.survey.reportbuilder.modals.new-page-modal.page-order-placeholder",
                  "Select a page"
                )}
              />
            </>
          ) : null}
        </form>
      </ActionModal>
      <ActionModal
        modalOpen={editPageModalOpen}
        closeModal={() => setEditPageModalOpen(false)}
        title={t(
          "project.survey.reportbuilder.modals.edit-page-modal.title",
          "Edit page"
        )}
        actions={
          <Button
            size="sm"
            onClick={onEditPage}
            color="primary"
            start={<Icons.Save className="text-xl" />}
            isLoading={
              updateReportPageState.loading || updatePagesOrderState.loading
            }
          >
            {t(
              "project.survey.reportbuilder.modals.edit-page-modal.save-page",
              "Save page"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2">
          {pages && pages.length ? (
            <>
              <label htmlFor="page-order">
                {t(
                  "project.survey.reportbuilder.modals.edit-page-modal.page-order-label",
                  "Position"
                )}
              </label>
              <Checkbox
                id="beforeOrAfter"
                label={
                  pageBefore
                    ? t(
                        "project.survey.reportbuilder.modals.edit-page-modal.position-before",
                        "Before"
                      )
                    : t(
                        "project.survey.reportbuilder.modals.edit-page-modal.position-after",
                        "After"
                      )
                }
                checked={pageBefore}
                toggle={() => setPageBefore(!pageBefore)}
              />
              <Select
                id="page-order"
                value={pageOrder}
                onChange={setPageOrder}
                options={pages
                  .filter(
                    (page) => !selectedPage || selectedPage.id !== page.id
                  )
                  .map((page) => ({
                    value: page.order,
                    label: `${page.order + 1}`,
                  }))}
                placeholderValue={-1}
                placeholderLabel={t(
                  "project.survey.reportbuilder.modals.edit-page-modal.page-order-placeholder",
                  "Select a page"
                )}
              />
            </>
          ) : null}
        </form>
      </ActionModal>
      <ActionModal
        modalOpen={componentModalOpen}
        closeModal={() => {
          resetComponentState();
          setSelectedComponent(undefined);
          setComponentModalOpen(false);
        }}
        title={
          selectedComponent
            ? t(
                "project.survey.reportbuilder.modals.component-modal.edit-component",
                "Edit component"
              )
            : t(
                "project.survey.reportbuilder.modals.component-modal.new-component",
                "New component"
              )
        }
        className="sm:w-11/12 md:w-3/4 2xl:w-7/12 sm:max-w-full overflow-x-hidden"
        actions={
          <Button
            size="sm"
            onClick={() => onSaveComponent(false)}
            color="primary"
            start={<Icons.Save className="text-xl" />}
            isLoading={
              createReportComponentState.loading ||
              updateReportComponentState.loading ||
              uploadState.loading
            }
          >
            {t(
              "project.survey.reportbuilder.modals.component-modal.save-page",
              "Save component"
            )}
          </Button>
        }
      >
        <ReportComponentForm
          surveySections={surveySections}
          componentTypes={componentTypes}
          questionCategories={questionCategories}
          questionTags={questionTags}
          componentTypeId={componentTypeId}
          setComponentTypeId={(id) => {
            resetComponentState();
            setComponentTypeId(id);
          }}
          componentContent={componentContent}
          setComponentContent={setComponentContent}
          componentOptions={componentOptions}
          setComponentOptions={setComponentOptions}
          componentQuestionId={componentQuestionId}
          setComponentQuestionId={setComponentQuestionId}
          componentQuestionCategoryIds={componentQuestionCategoryIds}
          setComponentQuestionCategoryIds={setComponentQuestionCategoryIds}
          componentQuestionTagIds={componentQuestionTagIds}
          setComponentQuestionTagIds={setComponentQuestionTagIds}
          childComponents={childComponents}
          setChildComponents={setChildComponents}
          onAddChildComponent={
            selectedComponent
              ? () => {
                  setSelectedChildComponent(undefined);
                  setChildComponentModalOpen(true);
                }
              : undefined
          }
          onEditChildComponent={(component) => {
            const questionId = component?.question?.id;
            setSelectedChildComponent(component);
            setChildComponentTypeId(component.componentType.id);
            setChildComponentContent(component.content);
            setChildComponentOptions(JSON.parse(component.options));
            setChildComponentQuestionId(questionId ? questionId : "-1");
            setChildComponentQuestionCategoryIds(
              component.questionCategories.map((ct) => ct.id)
            );
            setChildComponentQuestionTagIds(
              component.questionTags.map((tg) => tg.id)
            );
            setChildComponentModalOpen(true);
          }}
          onDeleteChildComponent={(component) => {
            setSelectedChildComponent(component);
            setDeleteComponentModalOpen(true);
          }}
          allowStructural={true}
        />
      </ActionModal>
      <ActionModal
        modalOpen={childComponentModalOpen}
        closeModal={() => {
          resetChildComponentState();
          setSelectedChildComponent(undefined);
          setChildComponentModalOpen(false);
        }}
        title={
          selectedChildComponent
            ? t(
                "project.survey.reportbuilder.modals.child-component-modal.edit-component",
                "Edit subcomponent"
              )
            : t(
                "project.survey.reportbuilder.modals.child-component-modal.new-component",
                "New subcomponent"
              )
        }
        className="sm:w-11/12 md:w-3/4 2xl:w-7/12 sm:max-w-full overflow-x-hidden"
        actions={
          <Button
            size="sm"
            onClick={() => onSaveComponent(true)}
            color="primary"
            start={<Icons.Save className="text-xl" />}
            isLoading={
              createReportComponentState.loading ||
              updateReportComponentState.loading ||
              uploadState.loading
            }
          >
            {t(
              "project.survey.reportbuilder.modals.child-component-modal.save-page",
              "Save subcomponent"
            )}
          </Button>
        }
      >
        <ReportComponentForm
          surveySections={surveySections}
          componentTypes={componentTypes}
          questionCategories={questionCategories}
          questionTags={questionTags}
          componentTypeId={childComponentTypeId}
          setComponentTypeId={(id) => {
            resetChildComponentState();
            setChildComponentTypeId(id);
          }}
          componentContent={childComponentContent}
          setComponentContent={setChildComponentContent}
          componentOptions={childComponentOptions}
          setComponentOptions={setChildComponentOptions}
          componentQuestionId={childComponentQuestionId}
          setComponentQuestionId={setChildComponentQuestionId}
          componentQuestionCategoryIds={childComponentQuestionCategoryIds}
          setComponentQuestionCategoryIds={setChildComponentQuestionCategoryIds}
          componentQuestionTagIds={childComponentQuestionTagIds}
          setComponentQuestionTagIds={setChildComponentQuestionTagIds}
          childComponents={[]}
          setChildComponents={() => {}}
          onAddChildComponent={() => {}}
          onEditChildComponent={() => {}}
          onDeleteChildComponent={() => {}}
          allowStructural={false}
        />
      </ActionModal>
      <ConfirmModal
        toConfirmType={t(
          "project.survey.reportbuilder.modals.delete-page.type",
          "report page"
        )}
        modalOpen={deletePageModalOpen}
        closeModal={() => {
          setDeletePageModalOpen(false);
        }}
        onConfirm={onDeletePage}
        action="delete"
        isLoading={deleteReportPageState.loading}
      />
      <ConfirmModal
        toConfirmType={t(
          "project.survey.reportbuilder.modals.delete-component.type",
          "component"
        )}
        modalOpen={deleteComponentModalOpen}
        closeModal={() => {
          setDeleteComponentModalOpen(false);
          if (selectedChildComponent) {
            setSelectedChildComponent(undefined);
          } else {
            setSelectedComponent(undefined);
          }
        }}
        onConfirm={onDeleteComponent}
        action="delete"
        isLoading={deleteReportComponentState.loading}
      />
    </>
  );
}

ReportBuilderPage.propTypes = {
  breadCrumbs: PropTypes.node,
  actions: PropTypes.node,
};
