import React from "react";
import toast, { useToaster } from "react-hot-toast";
import { Alert, Icons } from "..";

const typeMap = {
  error: "error",
  success: "success",
  blank: "info",
  custom: "warning",
};

/**
 * Notifications
 * React component displaying toaster notifications at a fixed location on the screen
 * @returns {node} React component
 */
function Notifications() {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <div
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      className="fixed top-5 space-y-2 w-full z-50 px-5"
    >
      {toasts
        .filter((alert) => alert.visible)
        .map((alert) => {
          return (
            <Alert
              key={alert.id}
              type={typeMap[alert.type]}
              {...alert.ariaProps}
              end={
                <button onClick={() => toast.remove(alert.id)}>
                  <Icons.Close className="text-3xl" />
                </button>
              }
            >
              {alert.message}
            </Alert>
          );
        })}
    </div>
  );
}

export default Notifications;
