import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { Breadcrumbs, LoggedInLayout } from "../../../../components";
import calls from "../../../../graphql";
import toast from "react-hot-toast";
const { GETREPORT } = calls;

export default function Reports() {
  // Hooks
  const { projectId, surveyId, reportId } = useParams();
  const { t } = useTranslation();
  // State
  const [surveyReport, setSurveyReport] = useState([]);
  const [fileURL, setFileURL] = useState("");
  // Queries
  const getSurveyReportState = useQuery(GETREPORT, {
    variables: {
      id: reportId,
    },
  });
  // Mutations
  // Handle errors
  if (getSurveyReportState.error)
    toast.error(getSurveyReportState.error.message);
  // Effects
  useEffect(() => {
    if (getSurveyReportState.data) {
      const report = getSurveyReportState.data.report;
      setSurveyReport(report);
      try {
        const url = new URL(report.location);
        setFileURL(url);
      } catch ({ message }) {
        toast.error(message);
      }
    }
  }, [getSurveyReportState.data]);

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                label: t(
                  "project.survey.reports.breadcrumbs.projects",
                  "Projects"
                ),
                link: `/projects`,
              },
              {
                label: t(
                  "project.survey.reports.breadcrumbs.surveys",
                  "Surveys"
                ),
                link: `/projects/${projectId}/surveys`,
              },
              {
                label: t(
                  "project.survey.reports.breadcrumbs.dashboard",
                  "Dashboard"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
              },
              {
                label: t(
                  "project.survey.reports.breadcrumbs.reports",
                  "Reports"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/reports`,
              },
              {
                label: t("project.survey.reports.breadcrumbs.report", "Report"),
                link: `/projects/${projectId}/surveys/${surveyId}/reports/${reportId}/report`,
              },
            ]}
            back={`/projects/${projectId}/surveys/${surveyId}/reports`}
          />
        }
        isLoading={getSurveyReportState.loading}
      >
        <div className="text-2xl md:text-3xl font-bold">
          {surveyReport.label}
          {surveyReport.respondent ? ` - ${surveyReport.respondent.name}` : ""}
        </div>
        <div className="w-full flex-grow flex flex-col">
          {fileURL ? (
            <embed
              src={fileURL}
              type="application/pdf"
              className="w-full flex-grow"
            />
          ) : (
            t(
              "project.survey.report.messages.error.no-pdf-found",
              "No pdf document found."
            )
          )}
        </div>
      </LoggedInLayout>
    </>
  );
}
