import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Pill, Select } from "../..";

function SelectTags({
  questionCategories,
  questionTags,
  selectedTagIds,
  setSelectedTagIds,
}) {
  // Hooks
  const { t } = useTranslation();
  // State
  const [selectedCategoryId, setSelectedCategoryId] = useState("-1");
  const [categoryTags, setCategoryTags] = useState([]);
  // Effects
  useEffect(() => {
    if (selectedCategoryId !== "-1") {
      const category = questionCategories.find(
        (category) => category.id === selectedCategoryId
      );
      if (category) {
        setCategoryTags(category.tags);
      }
    } else {
      setCategoryTags([]);
    }
  }, [selectedCategoryId]);

  const onAddTag = (value) => {
    if (value === "all") {
      const toAdd = [...categoryTags]
        .map((tag) => tag.id)
        .filter((id) => !selectedTagIds.includes(id));
      const values = [...selectedTagIds, ...toAdd];
      setSelectedTagIds(values);
    } else {
      const values = [...selectedTagIds, value];
      setSelectedTagIds(values);
    }
  };

  const onDeleteTag = (value) => {
    const values = [...selectedTagIds].filter((id) => id !== value);
    setSelectedTagIds(values);
  };

  return (
    <>
      <Select
        id="select-category"
        label={t("components.chart-form.select-category-label", "Add tags")}
        options={[...questionCategories]
          .sort((a, b) => a.label >= b.label)
          .map((category) => ({
            value: category.id,
            label: category.label,
          }))}
        value={selectedCategoryId}
        onChange={setSelectedCategoryId}
        placeholderLabel={t(
          "components.chart-form.select-category-placeholder",
          "Select category"
        )}
        placeholderValue="-1"
      />
      {selectedCategoryId !== "-1" ? (
        <>
          <Select
            id="add-tags"
            value="-1"
            onChange={onAddTag}
            options={[
              {
                id: "all",
                label: t("components.chart-form.add-all-tags", "Add all tags"),
              },
              ...categoryTags,
            ]
              .map((tag) => ({
                value: tag.id,
                label: tag.label,
              }))
              .filter(({ value }) => !selectedTagIds.includes(value))}
            placeholderValue="-1"
            placeholderLabel={t("components.chart-form.add-tags", "Add tags")}
          />
        </>
      ) : null}
      <div className="flex flex-row flex-wrap">
        {selectedTagIds && selectedTagIds.length
          ? questionTags
              .filter(({ id }) => selectedTagIds.includes(id))
              .map(({ id, label, color }) => (
                <Pill
                  key={`$add-tags-pill${id}`}
                  onDelete={() => onDeleteTag(id)}
                  className="mb-1 mr-1"
                  style={{ backgroundColor: color }}
                >
                  {label}
                </Pill>
              ))
          : null}
      </div>
    </>
  );
}

SelectTags.propTypes = {
  questionCategories: PropTypes.array,
  questionTags: PropTypes.array,
  selectedTagIds: PropTypes.array,
  setSelectedTagIds: PropTypes.func,
};

export default SelectTags;
