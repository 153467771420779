import React from "react";
import PropTypes from "prop-types";

/**
 * Styled wrapper of the table head (th) html component
 * @param {node} children - Table head content
 * @returns {node} th component
 */
function TableHead({ children }) {
  return (
    <th className="px-6">
      {typeof children === "string" ? (
        <div className="font-normal">{children}</div>
      ) : (
        children
      )}
    </th>
  );
}

TableHead.propTypes = {
  children: PropTypes.node,
};

export default TableHead;
