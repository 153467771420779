import { gql } from "@apollo/client";

const GETREPORTSTRUCTURES = gql`
  query getReportStructures {
    reportStructures {
      id
      label
      isTemplate
      isIndividual
      createdAt
      updatedAt
    }
  }
`;

const GETREPORTSTRUCTURE = gql`
  query getReportStructure($id: ID!) {
    reportStructure(id: $id) {
      id
      label
      isTemplate
      isIndividual
      createdAt
      updatedAt
      reportPages {
        id
        order
        reportComponents {
          id
          order
          content
          options
          childComponents {
            id
            order
            content
            options
            componentType {
              id
              label
            }
            questionCategories {
              id
            }
            questionTags {
              id
            }
            question {
              id
            }
          }
          componentType {
            id
            label
            allowSubcomponents
          }
          questionCategories {
            id
          }
          questionTags {
            id
          }
          question {
            id
          }
        }
      }
    }
  }
`;

const GETREPORTSTRUCTUREPREVIEW = gql`
  query GetReportStructurePreview(
    $id: ID!
    $pageNumber: Int!
    $nResponses: Int
  ) {
    reportStructurePreview(
      id: $id
      pageNumber: $pageNumber
      nResponses: $nResponses
    )
  }
`;

const DELETEREPORTSTRUCTURE = gql`
  mutation DeleteReportStructure($id: ID!) {
    deleteReportStructure(id: $id)
  }
`;

const CREATEREPORTSTRUCTURE = gql`
  mutation CreateReportStructure(
    $label: String!
    $isTemplate: Boolean!
    $isIndividual: Boolean!
    $surveyId: ID!
  ) {
    createReportStructure(
      reportStructure: {
        label: $label
        isTemplate: $isTemplate
        isIndividual: $isIndividual
        surveyId: $surveyId
      }
    ) {
      id
    }
  }
`;

const COPYREPORTSTRUCTURE = gql`
  mutation CopyReportStructure($id: ID!, $surveyId: ID!) {
    copyReportStructure(id: $id, surveyId: $surveyId) {
      id
    }
  }
`;

const UPDATEREPORTSTRUCTURE = gql`
  mutation UpdateReportStructure(
    $id: ID!
    $label: String
    $isTemplate: Boolean
    $isIndividual: Boolean
    $surveyId: ID
  ) {
    updateReportStructure(
      id: $id
      reportStructure: {
        label: $label
        isTemplate: $isTemplate
        isIndividual: $isIndividual
        surveyId: $surveyId
      }
    ) {
      id
    }
  }
`;

const UPDATEPAGESORDER = gql`
  mutation UpdatePagesOrder($id: ID!) {
    updatePagesOrder(id: $id)
  }
`;

const calls = {
  GETREPORTSTRUCTURES,
  GETREPORTSTRUCTURE,
  GETREPORTSTRUCTUREPREVIEW,
  DELETEREPORTSTRUCTURE,
  CREATEREPORTSTRUCTURE,
  COPYREPORTSTRUCTURE,
  UPDATEREPORTSTRUCTURE,
  UPDATEPAGESORDER,
};

export default calls;
