import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../..";
import { SelectCategories, SelectTags } from ".";

function SelectCategoriesOrTags({
  level,
  onChangeLevel,
  questionCategories,
  questionTags,
  selectedCategoryIds,
  setSelectedCategoryIds,
  selectedTagIds,
  setSelectedTagIds,
}) {
  // Hooks
  const { t } = useTranslation();
  // State
  const [isCategoryLevel, setIsCategoryLevel] = useState(false);
  // Effects
  useEffect(() => {
    if (level === "category") {
      setIsCategoryLevel(true);
    } else {
      setIsCategoryLevel(false);
    }
  }, [level]);

  return (
    <>
      <Checkbox
        id="selection-level"
        label={
          isCategoryLevel
            ? t(
                "components.select-categories-or-tags.by-category",
                "By category"
              )
            : t("components.select-categories-or-tags.by-tag", "By tag")
        }
        checked={isCategoryLevel}
        toggle={() => onChangeLevel(!isCategoryLevel)}
      />
      {isCategoryLevel ? (
        <SelectCategories
          questionCategories={questionCategories}
          selectedCategoryIds={selectedCategoryIds}
          setSelectedCategoryIds={setSelectedCategoryIds}
        />
      ) : (
        <SelectTags
          questionCategories={questionCategories}
          questionTags={questionTags}
          selectedTagIds={selectedTagIds}
          setSelectedTagIds={setSelectedTagIds}
        />
      )}
    </>
  );
}

SelectCategoriesOrTags.propTypes = {
  level: PropTypes.string,
  onChangeLevel: PropTypes.func,
  questionCategories: PropTypes.array,
  questionTags: PropTypes.array,
  selectedCategoryIds: PropTypes.array,
  setSelectedCategoryIds: PropTypes.func,
  selectedTagIds: PropTypes.array,
  setSelectedTagIds: PropTypes.func,
};

export default SelectCategoriesOrTags;
