import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      userId
      userLevel
    }
  }
`;

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

const REGISTER = gql`
  mutation Register(
    $userName: String
    $email: String!
    $password: String!
    $confirmation: String!
  ) {
    register(
      userName: $userName
      email: $email
      password: $password
      confirmation: $confirmation
    )
  }
`;

const REFRESH = gql`
  mutation Refresh {
    refresh {
      token
      userId
      userLevel
    }
  }
`;

const PROFILE = gql`
  query Profile {
    me {
      id
      email
      userName
    }
  }
`;

const UPDATEPROFILE = gql`
  mutation UpdateUser(
    $oldEmail: String!
    $newUserName: String
    $newEmail: String
    $newPassword: String
    $newPasswordConfirmation: String
  ) {
    updateUser(
      oldEmail: $oldEmail
      newUserName: $newUserName
      newEmail: $newEmail
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    )
  }
`;

const UPDATEUSER = gql`
  mutation UpdateUser(
    $id: ID!
    $newUserName: String
    $newEmail: String
    $newUserRoleId: ID
    $newPassword: String
    $newPasswordConfirmation: String
  ) {
    updateUser(
      id: $id
      newUserName: $newUserName
      newEmail: $newEmail
      newUserRoleId: $newUserRoleId
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    )
  }
`;

const GETUSERROLES = gql`
  query getUserRoles {
    userRoles {
      id
      label
      level
    }
  }
`;

const GETUSERS = gql`
  query getUsers {
    users {
      id
      userName
      email
      createdAt
      updatedAt
      role {
        id
        label
        level
      }
    }
  }
`;

const calls = {
  LOGIN,
  LOGOUT,
  REGISTER,
  REFRESH,
  PROFILE,
  UPDATEPROFILE,
  UPDATEUSER,
  GETUSERROLES,
  GETUSERS,
};

export default calls;
