import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory, Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import {
  Icons,
  Modal,
  LoggedInLayout,
  QuestionCategorySettings,
  InfoModal,
  QuestionForm,
  Button,
} from "../..";
import calls from "../../../graphql";
import toast from "react-hot-toast";
import { userRoleLevels } from "../../../utils";
const {
  UPDATEQUESTIONSORDER,
  GETQUESTION,
  CREATEQUESTION,
  UPDATEQUESTION,
  CREATEQUESTIONTRANSLATION,
  UPDATEQUESTIONTRANSLATION,
  CREATERESPONSEOPTION,
  UPDATERESPONSEOPTION,
  DELETERESPONSEOPTION,
  CREATERESPONSEOPTIONTRANSLATION,
  UPDATERESPONSEOPTIONTRANSLATION,
  GETQUESTIONCATEGORIES,
  GETQUESTIONTYPES,
  GETLANGUAGES,
  GETSURVEYSURVEYSECTIONSQUESTIONS,
  CREATEQUESTIONROUTE,
  UPDATEQUESTIONROUTE,
  DELETEQUESTIONROUTE,
} = calls;

/**
 * Question page component
 * Used to edit questions from surveys and survey templates
 * @param {string} backURL - Base link of the questions page
 * @param {string} surveyId - Id of the current survey
 * @param {string} questionId - Id of the current question
 * @param {node} breadCrumbs - Nav breadcrumbs of the question page
 * @returns {node} React component
 */
export default function QuestionPage({
  backURL,
  surveyId,
  questionId,
  breadCrumbs,
}) {
  // Hooks
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  // State
  // - Fields, hasFields, editFields
  const [changed, setChanged] = useState(false);
  const [questionOrder, setQuestionOrder] = useState(-1);
  const [questionSectionId, setQuestionSectionId] = useState("0");
  const [questionTypeId, setQuestionTypeId] = useState("0");
  const [hasQuestionCategory, setHasQuestionCategory] = useState(false);
  const [questionCategoryIds, setQuestionCategoryIds] = useState([]);
  const [questionTagIds, setQuestionTagIds] = useState([]);
  const [hasQuestionDirection, setHasQuestionDirection] = useState(false);
  const [questionDirection, setQuestionDirection] = useState("NOTAPPLICABLE");
  const [hasAllowMultipleSelection, setHasAllowMultipleSelection] =
    useState(false);
  const [allowMultipleSelection, setAllowMultipleSelection] = useState(false);
  const [editResponseOptionValues, setEditResponseOptionValues] =
    useState(true);
  const [editResponseOptionsAmount, setEditResponseOptionsAmount] =
    useState(false);
  const [hasQuestionRoutes, setHasQuestionRoutes] = useState(false);
  const [questionRoutes, setQuestionRoutes] = useState([]);
  // - Translated fields
  const [questionTitles, setQuestionTitles] = useState({});
  const [questionDescriptions, setQuestionDescriptions] = useState({});
  const [responseOptions, setResponseOptions] = useState([]);
  // - Data
  const [routeOperators, setRouteOperators] = useState([]);
  const [questionRouteValueOptions, setQuestionRouteValueOptions] = useState(
    []
  );
  const [questions, setQuestions] = useState([]);
  const [surveySections, setSurveySections] = useState([]);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [surveyLanguages, setSurveyLanguages] = useState([]);
  // - State
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalType, setInfoModalType] = useState("");
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  // Queries
  const getQuestionState = useQuery(GETQUESTION, {
    variables: {
      id: questionId,
    },
    fetchPolicy: "network-only",
  });
  const getQuestionsState = useQuery(GETSURVEYSURVEYSECTIONSQUESTIONS, {
    variables: {
      surveyId,
    },
    fetchPolicy: "cache-first",
  });
  const getQuestionCategoriesState = useQuery(GETQUESTIONCATEGORIES);
  const getQuestionTypesState = useQuery(GETQUESTIONTYPES);
  const getLanguagesState = useQuery(GETLANGUAGES);
  // Mutations
  const [createQuestion, createQuestionState] = useMutation(CREATEQUESTION);
  const [updateQuestion, updateQuestionState] = useMutation(UPDATEQUESTION);
  const [createQuestionTranslation, createQuestionTranslationState] =
    useMutation(CREATEQUESTIONTRANSLATION);
  const [updateQuestionTranslation, updateQuestionTranslationState] =
    useMutation(UPDATEQUESTIONTRANSLATION);
  const [createResponseOption, createResponseOptionState] =
    useMutation(CREATERESPONSEOPTION);
  const [updateResponseOption, updateResponseOptionState] =
    useMutation(UPDATERESPONSEOPTION);
  const [deleteResponseOption, deleteResponseOptionState] =
    useMutation(DELETERESPONSEOPTION);
  const [createQuestionRoute, createQuestionRouteState] =
    useMutation(CREATEQUESTIONROUTE);
  const [updateQuestionRoute, updateQuestionRouteState] =
    useMutation(UPDATEQUESTIONROUTE);
  const [deleteQuestionRoute, deleteQuestionRouteState] =
    useMutation(DELETEQUESTIONROUTE);
  const [
    createResponseOptionTranslation,
    createResponseOptionTranslationState,
  ] = useMutation(CREATERESPONSEOPTIONTRANSLATION);
  const [
    updateResponseOptionTranslation,
    updateResponseOptionTranslationState,
  ] = useMutation(UPDATERESPONSEOPTIONTRANSLATION);
  const [updateQuestionsOrder, updateQuestionsOrderState] =
    useMutation(UPDATEQUESTIONSORDER);
  const createLoading =
    createQuestionState.loading ||
    createQuestionTranslationState.loading ||
    createResponseOptionState.loading ||
    createResponseOptionTranslationState.loading ||
    createQuestionRouteState.loading;
  const updateLoading =
    updateQuestionState.loading ||
    updateQuestionTranslationState.loading ||
    updateResponseOptionState.loading ||
    updateResponseOptionTranslationState.loading ||
    updateQuestionsOrderState.loading ||
    deleteResponseOptionState.loading ||
    updateQuestionRouteState.loading ||
    deleteQuestionRouteState.loading;

  // Show any loading errors
  if (getQuestionState.error) toast.error(getQuestionState.error.message);
  if (getQuestionCategoriesState.error)
    toast.error(getQuestionCategoriesState.error.message);
  if (getQuestionTypesState.error)
    toast.error(getQuestionTypesState.error.message);
  if (getLanguagesState.error) toast.error(getLanguagesState.error.message);
  if (getQuestionsState.error) toast.error(getQuestionsState.error.message);
  // Effects
  const question = getQuestionState.data
    ? getQuestionState.data.question
    : undefined;
  useEffect(() => {
    if (getQuestionsState.data && getQuestionsState.data.survey) {
      const { sections, languages } = getQuestionsState.data.survey;
      // Set languages
      setSurveyLanguages(languages);
      if (languages && languages.length) setSelectedLanguages([languages[0]]);
      // setSelectedLanguages([languages[0]]);
      const titles = {};
      const descriptions = {};
      languages.forEach((language) => {
        titles[language.label] = "";
        descriptions[language.label] = "";
      });
      setQuestionTitles(titles);
      setQuestionDescriptions(descriptions);
      const questions = sections.reduce((qArray, section) => {
        qArray.push(...section.questions);
        return qArray;
      }, []);
      setQuestions(questions);
      setSurveySections(
        sections.map((section) => ({
          id: section.id,
          label: section.label,
        }))
      );
    }
  }, [getQuestionsState.data]);
  useEffect(() => {
    if (getQuestionCategoriesState.data) {
      setQuestionCategories(getQuestionCategoriesState.data.questionCategories);
    }
  }, [getQuestionCategoriesState.data]);
  useEffect(() => {
    if (getQuestionTypesState.data) {
      setQuestionTypes(getQuestionTypesState.data.questionTypes);
    }
  }, [getQuestionTypesState.data]);
  useEffect(() => {
    if (questionId === "new") {
      const query = new URLSearchParams(location.search);
      const sectionIdParam = query.get("sectionId");
      if (sectionIdParam) setQuestionSectionId(sectionIdParam);
    } else if (question) {
      const titles = {};
      const descriptions = {};
      surveyLanguages.forEach((language) => {
        const translation = question.translations.find(
          (translation) => translation.language.id === language.id
        );
        if (translation) {
          titles[language.label] = translation.label;
          descriptions[language.label] = translation.description;
        } else {
          titles[language.label] = "";
          descriptions[language.label] = "";
        }
      });
      setQuestionTitles(titles);
      setQuestionDescriptions(descriptions);
      setQuestionSectionId(question.section.id);
      if (question.questionCategories && question.questionCategories.length)
        setQuestionCategoryIds(
          question.questionCategories.map((cat) => cat.id)
        );
      if (question.questionTags && question.questionTags.length)
        setQuestionTagIds(question.questionTags.map((tag) => tag.id));
      if (question.questionType)
        onChangeQuestionTypeId(question.questionType.id);
      if (question.order >= 0) setQuestionOrder(question.order);
      setAllowMultipleSelection(!!question.allowMultipleSelection);
      setQuestionDirection(question.direction);
      setResponseOptions(
        question.responseOptions.map((responseOption) => {
          if (responseOption) {
            const { value, translations, order } = responseOption;
            const labels = {};
            surveyLanguages.forEach((language) => {
              const translation = translations.find(
                (translation) => translation.language.id === language.id
              );
              if (translation) {
                labels[language.label] = translation.label;
              } else {
                labels[language.label] = "";
              }
            });
            return { id: responseOption.id, value, order, labels };
          } else {
            toast.error(
              t(
                "components.question-page.messages.error.faulty-response-option",
                "Could not parse response option."
              )
            );
            return { id: responseOption.id, value: "", labels: {} };
          }
        })
      );
      setQuestionRoutes(
        question.routesFrom.map((route) => ({
          id: route.id,
          questionToId: route.questionTo.id,
          routeOperatorId: route.routeOperator.id,
          value: route.value,
        }))
      );
      setChanged(false);
    }
  }, [questionId, question, surveyLanguages]);

  const onChangeQuestionSectionId = (id) => {
    setQuestionOrder(-1);
    setChanged(true);
    setQuestionSectionId(id);
  };

  const onChangeQuestionTypeId = (id) => {
    setChanged(true);
    const type = questionTypes.find((type) => type.id === id);
    const prevType = questionTypes.find((type) => type.id === questionTypeId);
    if (type) {
      setEditResponseOptionsAmount(false);
      setHasQuestionDirection(false);
      setEditResponseOptionValues(false);
      setHasQuestionCategory(false);
      setHasAllowMultipleSelection(false);
      setHasQuestionRoutes(true);
      setQuestionRoutes([]);
      setQuestionRouteValueOptions([]);
      setRouteOperators(type.routeOperators);
      let responseOptions = [];
      switch (type.label) {
        case "EmailAddress":
          setHasQuestionRoutes(false);
          break;
        case "MultipleChoice": {
          setEditResponseOptionsAmount(true);
          setEditResponseOptionValues(true);
          setHasQuestionCategory(true);
          setHasAllowMultipleSelection(true);
          responseOptions = [
            {
              value: "",
              label: "",
            },
            {
              value: "",
              label: "",
            },
            {
              value: "",
              label: "",
            },
          ];
          break;
        }
        case "ScoredMultipleChoice": {
          setEditResponseOptionsAmount(true);
          setEditResponseOptionValues(true);
          setHasQuestionCategory(true);
          setHasQuestionDirection(true);
          setHasAllowMultipleSelection(true);
          responseOptions = [
            {
              value: "0",
              key: "",
            },
            {
              value: "1",
              key: "",
            },
            {
              value: "2",
              key: "",
            },
          ];
          break;
        }
        case "Integer": {
          setHasQuestionCategory(true);
          break;
        }
        case "OpenText": {
          setHasQuestionCategory(true);
          break;
        }
        case "Likert": {
          setHasQuestionDirection(true);
          setHasQuestionCategory(true);
          responseOptions = [
            {
              value: "0",
              key: "components.question-page.responseOptions.likert.strongly-disagree",
              label: t(
                "components.question-page.responseOptions.likert.strongly-disagree",
                "Strongly disagree"
              ),
            },
            {
              value: "1",
              key: "components.question-page.responseOptions.likert.disagree",
              label: t(
                "components.question-page.responseOptions.likert.disagree",
                "Disagree"
              ),
            },
            {
              value: "2",
              key: "components.question-page.responseOptions.likert.neutral",
              label: t(
                "components.question-page.responseOptions.likert.neutral",
                "Neither agree nor disagree"
              ),
            },
            {
              value: "3",
              key: "components.question-page.responseOptions.likert.agree",
              label: t(
                "components.question-page.responseOptions.likert.agree",
                "Agree"
              ),
            },
            {
              value: "4",
              key: "components.question-page.responseOptions.likert.strongly-agree",
              label: t(
                "components.question-page.responseOptions.likert.strongly-agree",
                "Strongly agree"
              ),
            },
          ];
          break;
        }
        case "NetPromotorScore": {
          setHasQuestionCategory(true);
          setQuestionRouteValueOptions(
            [...Array(11)].map((_, index) => ({
              value: index,
              label: index,
            }))
          );
          break;
        }
        case "Score10": {
          setHasQuestionDirection(true);
          setHasQuestionCategory(true);
          responseOptions = [
            {
              value: "0",
              label: "",
            },
            {
              value: "5",
              label: "",
            },
            {
              value: "10",
              label: "",
            },
          ];
          setQuestionRouteValueOptions(
            [...Array(11)].map((_, index) => ({
              value: index,
              label: index,
            }))
          );
          break;
        }
        case "YesNo": {
          setHasQuestionDirection(true);
          setHasQuestionCategory(true);
          setQuestionRouteValueOptions([
            {
              value: true,
              label: t(
                "components.question-page.question-route-values.yes",
                "Yes"
              ),
            },
            {
              value: false,
              label: t(
                "components.question-page.question-route-values.no",
                "No"
              ),
            },
          ]);
          break;
        }
        case "Statement": {
          setHasQuestionRoutes(false);
          break;
        }
        default: {
          break;
        }
      }
      responseOptions = responseOptions.map(({ value, key }) => {
        const option = {
          value,
          labels: {},
        };
        surveyLanguages.forEach((language) => {
          let label = "";
          if (key) {
            label = i18n.t(key, { lng: language.label });
          }
          option.labels[language.label] = label;
        });
        return option;
      });
      // Keep response options if changing between multiple choice types
      if (
        !(
          prevType &&
          (type.label === "MultipleChoice" ||
            type.label === "ScoredMultipleChoice") &&
          (prevType.label === "MultipleChoice" ||
            prevType.label === "ScoredMultipleChoice" ||
            prevType.label === "Likert")
        )
      ) {
        setResponseOptions(responseOptions);
      }
    } else {
      return null;
    }
    setQuestionDirection("NOTAPPLICABLE");
    setQuestionTypeId(id);
  };

  const onChangeQuestionLanguage = (index, languageId) => {
    const languages = [...selectedLanguages];
    const language = surveyLanguages.find(
      (language) => language.id === languageId
    );
    if (language) {
      languages[index] = language;
      setSelectedLanguages(languages);
    }
  };
  const onAddQuestionLanguage = () => {
    const unselectedLanguages = [...surveyLanguages].filter(
      (language) => !selectedLanguages.includes(language)
    );
    if (unselectedLanguages.length) {
      const languages = [...selectedLanguages, unselectedLanguages[0]];
      setSelectedLanguages(languages);
    }
  };
  const onRemoveQuestionLanguage = () => {
    const languages = [...selectedLanguages];
    languages.pop();
    setSelectedLanguages(languages);
  };

  const onChangeQuestionTitle = (title, languageLabel) => {
    const titles = { ...questionTitles };
    titles[languageLabel] = title;
    setQuestionTitles(titles);
  };
  const onChangeQuestionDescription = (description, languageLabel) => {
    const descriptions = { ...questionDescriptions };
    descriptions[languageLabel] = description;
    setQuestionDescriptions(descriptions);
  };

  const isCompleted = () => {
    if (
      !questionSectionId ||
      !questionTypeId ||
      (hasQuestionDirection && questionDirection === "NOTAPPLICABLE")
    ) {
      toast.custom(
        t(
          "components.question-page.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
      return false;
    }
    if (
      !surveyLanguages.reduce(
        (result, language) => result && questionTitles[language.label],
        true
      ) ||
      !responseOptions.reduce((result, option) => {
        return (
          result &&
          option.value &&
          surveyLanguages.reduce(
            (result, language) => result && option.labels[language.label],
            true
          )
        );
      }, true)
    ) {
      toast.custom(
        t(
          "components.question-page.messages.warning.complete-translations",
          "Please complete all translations and response options."
        )
      );
      return false;
    }
    if (
      questionRoutes &&
      questionRoutes.length &&
      !questionRoutes.reduce(
        (result, option) =>
          option.questionToId !== "0" &&
          option.routeOperatorId !== "0" &&
          option.value &&
          result,
        true
      )
    ) {
      toast.custom(
        t(
          "components.question-page.messages.warning.invalid-question-routes",
          "Please complete all question routes."
        )
      );
      return false;
    }
    return true;
  };

  const onSaveQuestion = async () => {
    if (changed) {
      if (isCompleted()) {
        try {
          // Save question
          const qId = await saveOrUpdateQuestion();
          if (!qId)
            throw new Error(
              t(
                "components.question-page.messages.error.save-question-failed",
                "Error while saving question."
              )
            );
          // Save, update & delete related properties
          await Promise.all([
            saveOrUpdateQuestionTranslations(qId),
            saveOrUpdateResponseOptions(qId),
            deleteRemovedResponseOptions(),
            saveOrUpdateQuestionRoutes(qId),
            deleteRemovedQuestionRoutes(),
          ]);
          toast.success(
            t(
              "components.question-page.messages.success.save-question",
              "Question saved!"
            )
          );
          setChanged(false);
          history.replace(backURL);
        } catch (error) {
          toast.error(error.message);
        }
      }
    } else {
      // No changes made
      toast.custom(
        t(
          "components.question-page.messages.warning.no-changes",
          "No changes were made."
        )
      );
    }
  };

  const saveOrUpdateQuestion = async () => {
    let qId;
    if (questionId && questionId === "new") {
      // New question
      try {
        const createQuestionResult = await createQuestion({
          variables: {
            order: questionOrder,
            isTemplate: false,
            direction: questionDirection,
            questionTypeId: questionTypeId,
            questionCategoryIds: questionCategoryIds,
            questionTagIds: questionTagIds,
            surveySectionId: questionSectionId,
            allowMultipleSelection: allowMultipleSelection,
          },
        });
        if (
          createQuestionResult.data &&
          createQuestionResult.data.createQuestion &&
          createQuestionResult.data.createQuestion.id
        ) {
          qId = createQuestionResult.data.createQuestion.id;
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else if (questionId) {
      // Update question
      try {
        const updateQuestionResult = await updateQuestion({
          variables: {
            id: questionId,
            direction: questionDirection,
            questionTypeId: questionTypeId,
            questionCategoryIds: questionCategoryIds,
            questionTagIds: questionTagIds,
            surveySectionId: questionSectionId,
            allowMultipleSelection: allowMultipleSelection,
          },
        });
        if (question.section.id !== questionSectionId) {
          await updateQuestionsOrder({
            variables: {
              id: questionSectionId,
            },
          });
        }
        if (
          updateQuestionResult.data &&
          updateQuestionResult.data.updateQuestion &&
          updateQuestionResult.data.updateQuestion.id
        ) {
          qId = questionId;
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      // Invalid questionId
      toast.error(
        t(
          "components.question-page.messsages.error.invalid-question-id",
          "This doesn't seem to be a valid question, please try creating a new one."
        )
      );
    }
    return qId;
  };

  const saveOrUpdateQuestionTranslations = async (qId) => {
    await Promise.all(
      surveyLanguages.map(async (language) => {
        try {
          let translation;
          if (question && question.translations) {
            translation = question.translations.find(
              (translation) => translation.language.id === language.id
            );
          }
          if (translation && translation.id) {
            // Update translation
            await updateQuestionTranslation({
              variables: {
                id: translation.id,
                label: questionTitles[language.label],
                description: questionDescriptions[language.label],
              },
            });
          } else {
            // Create new translation
            await createQuestionTranslation({
              variables: {
                label: questionTitles[language.label],
                description: questionDescriptions[language.label],
                questionId: qId,
                languageId: language.id,
              },
            });
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
    );
  };

  const saveOrUpdateResponseOptions = async (qId) => {
    // Save response options & translations
    await Promise.all(
      responseOptions.map(async (responseOption, index) => {
        // Save response option
        let rId;
        try {
          if (responseOption.id) {
            // Update response option
            await updateResponseOption({
              variables: {
                id: responseOption.id,
                value: responseOption.value,
                order: index,
              },
            });
            rId = responseOption.id;
          } else {
            // Create response option
            const createResponseOptionResult = await createResponseOption({
              variables: {
                value: responseOption.value,
                questionId: qId,
                order: index,
              },
            });
            if (
              createResponseOptionResult.data &&
              createResponseOptionResult.data.createResponseOption
            ) {
              rId = createResponseOptionResult.data.createResponseOption.id;
            }
          }
        } catch (error) {
          toast.error(error.message);
        }
        // Save response option translations
        if (rId) {
          await saveOrUpdateResponseOptionTranslations(responseOption, rId);
        } else {
          // Saving response option failed
          toast.error(
            t(
              "components.question-page.messages.error.no-response-option-id",
              "Error occured while saving a response option."
            )
          );
        }
      })
    );
  };

  const saveOrUpdateResponseOptionTranslations = async (
    responseOption,
    rId
  ) => {
    await Promise.all(
      surveyLanguages.map(async (language) => {
        try {
          let translation;
          if (question && question.responseOptions) {
            const rOption = question.responseOptions.find(
              (option) => option.id === rId
            );
            if (rOption) {
              translation = rOption.translations.find(
                (translation) => translation.language.id === language.id
              );
            }
          }
          if (translation && translation.id) {
            // Update translation
            await updateResponseOptionTranslation({
              variables: {
                id: translation.id,
                label: responseOption.labels[language.label],
              },
            });
          } else {
            // Create new translation
            await createResponseOptionTranslation({
              variables: {
                label: responseOption.labels[language.label],
                responseOptionId: rId,
                languageId: language.id,
              },
            });
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
    );
  };

  const deleteRemovedResponseOptions = async () => {
    // Delete removed response options
    if (question && question.responseOptions) {
      return Promise.all(
        question.responseOptions.map(async (responseOption) => {
          // Check if response option is still present
          const option = responseOptions.find(
            (option) => option.id && option.id === responseOption.id
          );
          if (!option) {
            // Response option was deleted
            try {
              await deleteResponseOption({
                variables: {
                  id: responseOption.id,
                },
              });
            } catch (error) {
              toast.error(error.message);
            }
          }
        })
      );
    }
  };

  const saveOrUpdateQuestionRoutes = async (qId) => {
    await Promise.all(
      questionRoutes.map(async (route) => {
        try {
          if (route.id) {
            // Update question route
            await updateQuestionRoute({
              variables: {
                id: route.id,
                questionFromId: qId,
                questionToId: route.questionToId,
                routeOperatorId: route.routeOperatorId,
                value: route.value,
              },
            });
          } else {
            // New question route
            await createQuestionRoute({
              variables: {
                questionFromId: qId,
                questionToId: route.questionToId,
                routeOperatorId: route.routeOperatorId,
                value: route.value,
              },
            });
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
    );
  };

  const deleteRemovedQuestionRoutes = async () => {
    // Delete removed question routes
    if (question && question.routesFrom) {
      return Promise.all(
        question.routesFrom.map(async (questionRoute) => {
          // Check if route is still present
          const route = questionRoutes.find(
            (route) => route.id === questionRoute.id
          );
          if (!route) {
            // Route has been deleted
            await deleteQuestionRoute({
              variables: {
                id: questionRoute.id,
              },
            });
          }
        })
      );
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={breadCrumbs}
        isLoading={
          getQuestionState.loading ||
          getQuestionsState.loading ||
          getQuestionCategoriesState.loading ||
          getQuestionTypesState.loading
        }
        minLevel={userRoleLevels.Manager}
      >
        <div className="flex flex-row w-full space-x-10 items-center justify-center">
          {selectedLanguages.map((language, index) => (
            <QuestionForm
              key={`question-form-language-${language.label}-${index}`}
              questionLanguage={language.label}
              setQuestionLanguage={(languageId) =>
                onChangeQuestionLanguage(index, languageId)
              }
              addQuestionLanguage={
                index === 0 && selectedLanguages.length < surveyLanguages.length
                  ? () => onAddQuestionLanguage()
                  : undefined
              }
              removeQuestionLanguage={
                index > 0 ? () => onRemoveQuestionLanguage() : undefined
              }
              surveyLanguages={surveyLanguages}
              questionId={questionId}
              setChanged={setChanged}
              setInfoModalOpen={setInfoModalOpen}
              setInfoModalType={setInfoModalType}
              setCategoryModalOpen={setCategoryModalOpen}
              onSaveQuestion={onSaveQuestion}
              surveySections={surveySections}
              questions={questions}
              questionTypes={questionTypes}
              questionCategories={questionCategories}
              routeOperators={routeOperators}
              questionRouteValueOptions={questionRouteValueOptions}
              questionTitle={questionTitles[language.label]}
              setQuestionTitle={(title) =>
                onChangeQuestionTitle(title, language.label)
              }
              questionDescription={questionDescriptions[language.label]}
              setQuestionDescription={(description) =>
                onChangeQuestionDescription(description, language.label)
              }
              questionSectionId={questionSectionId}
              onChangeQuestionSectionId={onChangeQuestionSectionId}
              questionTypeId={questionTypeId}
              onChangeQuestionTypeId={onChangeQuestionTypeId}
              hasQuestionCategory={hasQuestionCategory}
              questionCategoryIds={questionCategoryIds}
              setQuestionCategoryIds={setQuestionCategoryIds}
              questionTagIds={questionTagIds}
              setQuestionTagIds={setQuestionTagIds}
              hasQuestionDirection={hasQuestionDirection}
              questionDirection={questionDirection}
              setQuestionDirection={setQuestionDirection}
              editResponseOptionValues={editResponseOptionValues}
              editResponseOptionsAmount={editResponseOptionsAmount}
              responseOptions={responseOptions}
              setResponseOptions={setResponseOptions}
              hasAllowMultipleSelection={hasAllowMultipleSelection}
              allowMultipleSelection={allowMultipleSelection}
              setAllowMultipleSelection={setAllowMultipleSelection}
              hasQuestionRoutes={hasQuestionRoutes}
              questionRoutes={questionRoutes}
              setQuestionRoutes={setQuestionRoutes}
            />
          ))}
        </div>
        <div
          className={`${
            selectedLanguages.length > 1 ? "w-full" : "w-3/4"
          } self-center flex flex-col`}
        >
          <Button
            color="primary"
            start={<Icons.Save className="text-xl" />}
            isLoading={createLoading || updateLoading}
            onClick={(e) => {
              e.preventDefault();
              onSaveQuestion();
            }}
          >
            {t("project.survey.question.form.save-question", "Save question")}
          </Button>
        </div>
      </LoggedInLayout>
      <Prompt
        when={changed}
        message={t(
          "project.survey.messages.warning.unsaved-changes",
          "There are unsaved changes, are you sure you want to leave?"
        )}
      />
      <InfoModal
        modalOpen={infoModalOpen}
        closeModal={() => setInfoModalOpen(false)}
        infoModalType={infoModalType}
      />
      <Modal
        modalOpen={categoryModalOpen}
        closeModal={() => setCategoryModalOpen(false)}
        className="sm:w-11/12 md:w-3/4 2xl:w-7/12 sm:max-w-full"
      >
        <button
          onClick={() => setCategoryModalOpen(false)}
          className="absolute right-6 top-6"
        >
          <Icons.Close className="text-xl" />
        </button>
        <div className="flex flex-row items-center w-full justify-center mb-2 space-x-1">
          <Icons.Settings className="text-xl" />
          <h1 className="text-xl font-bold text-center">
            {t("components.question-page.modals.categories.title", "Settings")}
          </h1>
        </div>
        <QuestionCategorySettings />
      </Modal>
    </>
  );
}

QuestionPage.propTypes = {
  backURL: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  surveyId: PropTypes.string,
  questionId: PropTypes.string,
  breadCrumbs: PropTypes.node,
};
