import { gql } from "@apollo/client";

const CREATESURVEYSECTION = gql`
  mutation CreateSurveySection($label: String!, $order: Int!, $surveyId: ID!) {
    createSurveySection(
      surveySection: { label: $label, order: $order, surveyId: $surveyId }
    ) {
      id
    }
  }
`;

const UPDATESURVEYSECTION = gql`
  mutation UpdateSurveySection(
    $id: ID!
    $label: String
    $order: Int
    $surveyId: ID
  ) {
    updateSurveySection(
      id: $id
      surveySection: { label: $label, order: $order, surveyId: $surveyId }
    ) {
      id
    }
  }
`;

const DELETESURVEYSECTION = gql`
  mutation DeleteSurveySection($id: ID!) {
    deleteSurveySection(id: $id)
  }
`;

const UPDATEQUESTIONSORDER = gql`
  mutation UpdateQuestionsOrder($id: ID!) {
    updateQuestionsOrder(id: $id)
  }
`;

const GETSURVEYSECTIONS = gql`
  query GetSurveySections {
    surveySections {
      id
      label
      order
    }
  }
`;

const GETSURVEYSECTION = gql`
  query GetSurveySection($id: ID!) {
    surveySection(id: $id) {
      id
      label
      order
    }
  }
`;

const calls = {
  CREATESURVEYSECTION,
  UPDATESURVEYSECTION,
  DELETESURVEYSECTION,
  UPDATEQUESTIONSORDER,
  GETSURVEYSECTIONS,
  GETSURVEYSECTION,
};

export default calls;
