import { useTranslation } from "react-i18next";

/**
 * React hook to obtain translations and descriptions of the different user role levels in the user language
 * @returns
 */
export default function useUserRoles() {
  const { t } = useTranslation();

  const userRoles = {
    Guest: {
      label: t("utils.use-user-roles.guest.label", "Guest"),
      explanation: t(
        "utils.use-user-roles.guest.explanation",
        "This is the default role a user gets after signing up. These users can see no information nor make any edits."
      ),
    },
    User: {
      label: t("utils.use-user-roles.user.label", "User"),
      explanation: t(
        "utils.use-user-roles.user.explanation",
        "The user role allows a user to see everything (clients, surveys, reports), but they can't make any edits nor publish surveys."
      ),
    },
    Manager: {
      label: t("utils.use-user-roles.manager.label", "Manager"),
      explanation: t(
        "utils.use-user-roles.manager.explanation",
        "A manager can see everything and make edits, but can't manage the access and information of other users."
      ),
    },
    Admin: {
      label: t("utils.use-user-roles.admin.label", "Admin"),
      explanation: t(
        "utils.use-user-roles.admin.explanation",
        "Admins have read and write rights to everything. They can also manage access for other users."
      ),
    },
  };

  return userRoles;
}
