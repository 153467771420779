import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import {
  Breadcrumbs,
  ActionModal,
  ConfirmModal,
  Button,
  Icons,
  LoggedInLayout,
  MinLevel,
  Input,
  Radio,
  Checkbox,
  DatePicker,
  Select,
} from "../../../components";
import { useMutation, useQuery } from "@apollo/client";
import calls from "../../../graphql";
import toast from "react-hot-toast";
import { userRoleLevels, formatDate } from "../../../utils";
const {
  SAVESURVEYASTEMPLATE,
  ARCHIVESURVEY,
  UNARCHIVESURVEY,
  DELETESURVEY,
  GETSURVEY,
  EXPORTSURVEY,
  GETSURVEYRESPONSES,
} = calls;

export default function Dashboard() {
  // Hooks
  const { projectId, surveyId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  // State
  const [survey, setSurvey] = useState(undefined);
  const [surveyResponses, setSurveyResponses] = useState([]);
  const [deleteSurveyModalOpen, setDeleteSurveyModalOpen] = useState(false);
  const [confirmSurveyModalOpen, setConfirmSurveyModalOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [saveSurveyTemplateModalOpen, setSaveSurveyTemplateModalOpen] =
    useState(false);
  const [templateTitle, setTemplateTitle] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [isArchivingSurvey, setIsArchivingSurvey] = useState(false);
  const [exportFormat, setExportFormat] = useState("csv");
  const [singleResponse, setSingleResponse] = useState(true);
  const [responsesStart, setResponsesStart] = useState(new Date());
  const [responsesEnd, setResponsesEnd] = useState(new Date());
  const [exportResponseIds, setExportResponseIds] = useState([]);
  // Queries
  const getSurveyStatus = useQuery(GETSURVEY, {
    variables: {
      id: surveyId,
    },
  });
  const getSurveyResponsesState = useQuery(GETSURVEYRESPONSES, {
    variables: {
      id: surveyId,
    },
  });
  // Mutations
  const [saveSurveyAsTemplate, saveSurveyAsTemplateState] =
    useMutation(SAVESURVEYASTEMPLATE);
  const [archiveSurvey, archiveSurveyState] = useMutation(ARCHIVESURVEY, {
    refetchQueries: [GETSURVEY],
  });
  const [unarchiveSurvey, unarchiveSurveyState] = useMutation(UNARCHIVESURVEY, {
    refetchQueries: [GETSURVEY],
  });
  const [deleteSurvey, deleteSurveyState] = useMutation(DELETESURVEY);
  const [exportSurvey, exportSurveyState] = useMutation(EXPORTSURVEY);
  // Handle errors
  if (getSurveyStatus.error) toast.error(getSurveyStatus.error.message);
  // Effects
  useEffect(() => {
    if (getSurveyStatus.data && getSurveyStatus.data.survey) {
      const survey = getSurveyStatus.data.survey;
      setSurvey(survey);
      setTemplateTitle(survey.label);
      setTemplateDescription(survey.description);
    }
  }, [getSurveyStatus.data]);
  useEffect(() => {
    if (getSurveyResponsesState.data) {
      const { survey } = getSurveyResponsesState.data;
      const surveyResponses = survey.publishedSurveys
        .reduce(
          (group, publishedSurvey) => [
            ...group,
            ...publishedSurvey.surveyResponses,
          ],
          []
        )
        .sort((a, b) => a.submittedAt >= b.submittedAt);
      setSurveyResponses(surveyResponses);
    }
  }, [getSurveyResponsesState.data]);
  useEffect(() => {
    if (!singleResponse) {
      const start = responsesStart;
      start.setHours(0, 0, 0, 0);
      const end = responsesEnd;
      end.setHours(23, 59, 59, 999);
      const responseSelection = surveyResponses.filter((response) => {
        return (
          response.submittedAt >= start.toISOString() &&
          response.submittedAt <= end.toISOString()
        );
      });
      setExportResponseIds(responseSelection.map((res) => res.id));
    }
  }, [singleResponse, responsesStart, responsesEnd, surveyResponses]);

  const onDeleteSurvey = async () => {
    if (!confirmSurveyModalOpen) {
      setDeleteSurveyModalOpen(false);
      setIsArchivingSurvey(false);
      setConfirmSurveyModalOpen(true);
    } else {
      try {
        const { data } = await deleteSurvey({
          variables: {
            id: surveyId,
          },
        });
        if (data.deleteSurvey) {
          setConfirmSurveyModalOpen(false);
          history.replace(`/projects/${projectId}/surveys`);
          toast.success(
            t(
              "project.survey.dashboard.messages.success.delete-survey",
              "Survey deleted!"
            )
          );
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const onArchiveSurvey = async () => {
    if (!confirmSurveyModalOpen) {
      setDeleteSurveyModalOpen(false);
      setIsArchivingSurvey(true);
      setConfirmSurveyModalOpen(true);
    } else {
      try {
        const { data } = await archiveSurvey({
          variables: {
            id: surveyId,
          },
        });
        if (data.updateSurvey) {
          setConfirmSurveyModalOpen(false);
          toast.success(
            t(
              "project.survey.dashboard.messages.success.archive-survey",
              "Survey archived!"
            )
          );
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const onUnarchiveSurvey = async () => {
    try {
      const { data } = await unarchiveSurvey({
        variables: {
          id: surveyId,
        },
      });
      if (data.updateSurvey) {
        setConfirmSurveyModalOpen(false);
        toast.success(
          t(
            "project.survey.dashboard.messages.success.unarchive-survey",
            "Survey unarchived!"
          )
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSaveTemplate = async () => {
    if (!templateTitle) {
      toast.custom(
        t(
          "project.survey.dashboard.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
      return;
    }
    try {
      const { data } = await saveSurveyAsTemplate({
        variables: {
          id: surveyId,
          label: templateTitle,
          description: templateDescription,
        },
      });
      if (data && data.saveSurveyAsTemplate) {
        const { id } = data.saveSurveyAsTemplate;
        toast.success(
          t(
            "project.survey.dashboard.messages.success.created-template",
            "Survey template saved!"
          )
        );
        history.replace(`/templates/${id}/dashboard`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onDownload = async () => {
    try {
      if (!exportFormat) {
        toast.custom(
          t(
            "project.survey.dashboard.messages.warning.no-export-format",
            "Please select an export format."
          )
        );
        return;
      }
      const { data } = await exportSurvey({
        variables: {
          id: surveyId,
          format: exportFormat,
          surveyResponseIds:
            exportResponseIds &&
            exportResponseIds.length &&
            exportResponseIds[0] !== "-1"
              ? exportResponseIds
              : [],
        },
      });
      if (data) {
        const { exportSurvey } = data;
        if (exportSurvey) {
          const fileName = `export.${exportFormat}`;
          saveAs(exportSurvey, fileName);
        }
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const navLinks = {
    Questions: t("project.survey.dashboard.links.questions", "Questions"),
    Publications: t(
      "project.survey.dashboard.links.publications",
      "Publications"
    ),
    Reports: t("project.survey.dashboard.links.reports", "Reports"),
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                label: t(
                  "project.survey.dashboard.breadcrumbs.projects",
                  "Projects"
                ),
                link: `/projects`,
              },
              {
                label: t(
                  "project.survey.dashboard.breadcrumbs.surveys",
                  "Surveys"
                ),
                link: `/projects/${projectId}/surveys`,
              },
              {
                label: t(
                  "project.survey.dashboard.breadcrumbs.dashboard",
                  "Dashboard"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
              },
            ]}
            back={`/projects/${projectId}/surveys`}
          />
        }
        isLoading={getSurveyStatus.loading}
      >
        <h1 className="text-3xl md:text-4xl font-bold">
          {survey
            ? survey.label
            : t("project.survey.dashboard.labels.survey", "Survey")}
        </h1>
        <div className="flex-grow bg-base-100 border border-base-300 rounded-box flex flex-col mb-10">
          {Object.entries(navLinks).map(([key, label], index) => {
            const NavIcon = Icons[`${key.slice(0, -1)}`];
            return (
              <Link
                key={key}
                to={`/projects/${projectId}/surveys/${surveyId}/${key.toLowerCase()}`}
                className={`flex flex-row flex-grow justify-between items-center px-12 ${
                  index < Object.keys(navLinks).length - 1
                    ? "border-b border-base-300"
                    : ""
                }`}
              >
                <div className="min-w-min">
                  <NavIcon className="text-4xl md:text-5xl" />
                </div>
                <div className="text-2xl md:text-4xl font-bold">{label}</div>
                <div className="min-w-min">
                  <Icons.Next className="text-4xl md:text-5xl" />
                </div>
              </Link>
            );
          })}
        </div>
        <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row">
          <div className="flex-grow md:space-x-2 flex flex-col md:flex-row">
            <MinLevel minLevel={userRoleLevels.Manager}>
              <Button
                isLink={true}
                to={`/projects/${projectId}/surveys/${surveyId}/publish`}
                color="primary"
                start={<Icons.Send className="text-xl" />}
                size="lg"
              >
                {t("project.survey.dashboard.labels.publish-survey", "Publish")}
              </Button>
              <Button
                onClick={() => setDownloadModalOpen(true)}
                color="primary"
                start={<Icons.Download className="text-xl" />}
                size="lg"
              >
                {t(
                  "project.survey.dashboard.labels.download-survey",
                  "Download"
                )}
              </Button>
              <Button
                onClick={() => setSaveSurveyTemplateModalOpen(true)}
                color="primary"
                start={<Icons.Save className="text-xl" />}
                size="lg"
              >
                {t(
                  "project.survey.dashboard.labels.save-template",
                  "Save Template"
                )}
              </Button>
            </MinLevel>
          </div>
          <MinLevel minLevel={userRoleLevels.Manager}>
            {survey && survey.isArchived ? (
              <Button
                onClick={onUnarchiveSurvey}
                color="warning"
                start={<Icons.Archive className="text-xl" />}
                size="lg"
                isLoading={unarchiveSurveyState.loading}
              >
                {t(
                  "project.survey.dashboard.labels.unarchive-survey",
                  "Unarchive"
                )}
              </Button>
            ) : (
              <Button
                onClick={() => setDeleteSurveyModalOpen(true)}
                color="error"
                start={<Icons.Delete className="text-xl" />}
                size="lg"
              >
                {t("project.survey.dashboard.labels.delete", "Delete")}
              </Button>
            )}
          </MinLevel>
        </div>
      </LoggedInLayout>
      <ActionModal
        title={t(
          "project.survey.dashboard.modals.delete-modal.title",
          "Delete survey"
        )}
        modalOpen={deleteSurveyModalOpen}
        closeModal={() => setDeleteSurveyModalOpen(false)}
        actions={
          <>
            <Button
              color="warning"
              onClick={onArchiveSurvey}
              isLoading={getSurveyStatus.loading}
              size="sm"
              start={<Icons.Archive className="text-xl" />}
            >
              {t(
                "project.survey.dashboard.modals.delete-modal.archive-survey",
                "Archive survey"
              )}
            </Button>
            <Button
              color="error"
              onClick={onDeleteSurvey}
              isLoading={getSurveyStatus.loading}
              size="sm"
              start={<Icons.Delete className="text-xl" />}
            >
              {t(
                "project.survey.dashboard.modals.delete-modal.delete-survey",
                "Delete survey"
              )}
            </Button>
          </>
        }
      >
        <div className="text-center">
          {t(
            "project.survey.dashboard.modals.delete-modal.message",
            "Do you want to delete or archive this survey?"
          )}
        </div>
      </ActionModal>
      <ActionModal
        title={t(
          "project.survey.dashboard.modals.save-template.title",
          "Save as template"
        )}
        modalOpen={saveSurveyTemplateModalOpen}
        closeModal={() => setSaveSurveyTemplateModalOpen(false)}
        actions={
          <Button
            onClick={onSaveTemplate}
            color="primary"
            size="sm"
            isLoading={saveSurveyAsTemplateState.loading}
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "project.survey.dashboard.modals.save-template.save",
              "Save template"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveTemplate}>
          <Input
            id="template-title"
            label={t(
              "project.survey.dashboard.modals.save-template.title-label",
              "Title"
            )}
            placeholder={t(
              "project.survey.dashboard.modals.save-template.title-placeholder",
              "title"
            )}
            value={templateTitle}
            onChange={setTemplateTitle}
          />
          <Input
            id="template-description"
            label={t(
              "project.survey.dashboard.modals.save-template.description-label",
              "Description"
            )}
            placeholder={t(
              "project.survey.dashboard.modals.save-template.description-placeholder",
              "description"
            )}
            value={templateDescription}
            onChange={setTemplateDescription}
          />
        </form>
      </ActionModal>
      <ActionModal
        title={t(
          "project.survey.dashboard.export-data.title",
          "Download survey"
        )}
        modalOpen={downloadModalOpen}
        closeModal={() => setDownloadModalOpen(false)}
        actions={
          <Button
            onClick={onDownload}
            color="primary"
            size="sm"
            isLoading={exportSurveyState.loading}
            start={<Icons.Download className="text-xl" />}
          >
            {t("project.survey.dashboard.export-data.download", "Download")}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onDownload}>
          <Radio
            id="export-format"
            label={t(
              "project.survey.dashboard.modals.export-data.format-label",
              "Format"
            )}
            value={exportFormat}
            onChange={setExportFormat}
            options={[
              {
                label: "CSV (Excel / Power BI)",
                value: "csv",
              },
              // {
              //   label: "Parquet",
              //   value: "parquet",
              // }, // Uncomment to enable support for parquet export
            ]}
          />
          <label>
            {t(
              "project.survey.dashboard.modals.export-data.labels.add-responses",
              "Add responses"
            )}
          </label>
          <Checkbox
            id="add-responses"
            label={
              singleResponse
                ? t(
                    "project.survey.dashboard.modals.export-data.single-response-label",
                    "Single response"
                  )
                : t(
                    "project.survey.dashboard.modals.export-data.multiple-response-label",
                    "Multiple responses"
                  )
            }
            checked={!singleResponse}
            toggle={() => {
              setExportResponseIds([]);
              setSingleResponse(!singleResponse);
            }}
          />
          {singleResponse ? (
            <Select
              id="survey-response"
              label={t(
                "project.survey.dashboard.modals.export-data.select-response-label",
                "Survey response"
              )}
              placeholderLabel={t(
                "project.survey.dashboard.modals.export-data.select-response-placeholder",
                "No response"
              )}
              placeholderValue="-1"
              options={surveyResponses.map((response) => ({
                value: response.id,
                label: `${formatDate(response.submittedAt, true)} - ${
                  response.respondent
                    ? response.respondent.name
                    : t(
                        "project.survey.dashboard.modals.export-data.respondent-anonymous",
                        "anonymous"
                      )
                }`,
              }))}
              value={
                exportResponseIds && exportResponseIds.length
                  ? exportResponseIds[0]
                  : "-1"
              }
              onChange={(id) => {
                setExportResponseIds([id]);
              }}
            />
          ) : (
            <>
              <p>
                {t(
                  "project.survey.dashboard.modals.export-data.responses-between-dates",
                  "Select date range"
                )}
              </p>
              <DatePicker
                id="responses-start"
                value={responsesStart}
                onChange={setResponsesStart}
                max={responsesEnd}
              />
              <DatePicker
                id="responses-end"
                value={responsesEnd}
                onChange={setResponsesEnd}
                min={responsesStart}
              />
              {exportResponseIds ? exportResponseIds.length : 0}{" "}
              {t(
                "project.survey.dashboard.modals.export-data.responses-selected",
                "responses selected"
              )}
            </>
          )}
        </form>
      </ActionModal>
      <ConfirmModal
        toConfirmType={t(
          "project.survey.dashboard.modals.delete-survey.type",
          "survey"
        )}
        toConfirmLabel={survey ? survey.label : ""}
        action={isArchivingSurvey ? "archive" : "delete"}
        modalOpen={confirmSurveyModalOpen}
        closeModal={() => setConfirmSurveyModalOpen(false)}
        onConfirm={isArchivingSurvey ? onArchiveSurvey : onDeleteSurvey}
        isLoading={deleteSurveyState.loading || archiveSurveyState.loading}
        warning={
          isArchivingSurvey
            ? ""
            : t(
                "project.survey.dashboard.modals.delete-survey.warning",
                "This operation will also delete all questions, responses, publications and reports of this survey and cannot be undone!"
              )
        }
      />
    </>
  );
}
