import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Input, Checkbox } from "../..";
import { useLanguageLabels } from "../../../utils";

/**
 * Typeform publish form
 * Step 1 in the publishing window flow
 * @param {string} publishedSurveyTitle - Title of the typeform to be published
 * @param {function} setPublishedSurveyTitle - Setter function
 * @param {array} surveyLanguages - Languages supported by the survey [{id: "", label: ""}]
 * @param {array} selectedLanguages - Languages selected to be published [{id: "", label: ""}]
 * @param {function} setSelectedLanguages - Setter function
 * @returns {node} React component
 */
function TypeformForm({
  publishedSurveyTitle,
  setPublishedSurveyTitle,
  surveyLanguages,
  selectedLanguages,
  setSelectedLanguages,
}) {
  const { t } = useTranslation();
  const languageLabels = useLanguageLabels();

  const toggleLanguage = (language) => {
    let langs = [...selectedLanguages];
    if (langs.includes(language)) {
      langs = langs.filter((lang) => lang.id !== language.id);
    } else {
      langs = [...langs, language];
    }
    setSelectedLanguages(langs);
  };

  return (
    <>
      <div className="w-full pb-2">
        <Input
          id="publication-title"
          label={t("components.publish-typeform.title-label", "Title")}
          placeholder={t(
            "components.publish-typeform.title-placeholder",
            "title"
          )}
          value={publishedSurveyTitle}
          onChange={setPublishedSurveyTitle}
        />
      </div>
      <label>{t("components.publish-typeform.languages", "Languages")}</label>
      {surveyLanguages.map((language) => (
        <Checkbox
          key={`survey-language-${language.id}`}
          id={`survey-language-${language.id}`}
          label={`${languageLabels[language.label].flags} ${
            languageLabels[language.label].label
          }`}
          checked={selectedLanguages.includes(language)}
          toggle={() => {
            toggleLanguage(language);
          }}
        />
      ))}
    </>
  );
}

TypeformForm.propTypes = {
  publishedSurveyTitle: PropTypes.string,
  setPublishedSurveyTitle: PropTypes.func,
  surveyLanguages: PropTypes.array,
  selectedLanguages: PropTypes.array,
  setSelectedLanguages: PropTypes.func,
};

export default TypeformForm;
