import React from "react";
import PropTypes from "prop-types";

const sizeClassNames = {
  xs: "input-xs",
  sm: "input-sm",
  md: "input-md",
  lg: "input-lg",
};

/**
 * String input
 * @param {string} id - Input id
 * @param {string} label - Input label
 * @param {string} value - Input value
 * @param {string} placeholder - Input placeholder
 * @param {function} onChange - Input change handler
 * @param {boolean} hasError - Error state indicator
 * @param {boolean} hasWarning - Warning state indicator
 * @param {boolean} isDisabled - True if input should be disabled
 * @param {boolean} isPassword - True if input should be hidden
 * @param {string} size - Input box size
 * @param {string} className - Class name overwrite
 * @returns {node} React input component
 */
function Input({
  id,
  label,
  value,
  placeholder,
  onChange,
  hasError,
  hasWarning,
  isDisabled,
  isPassword,
  size,
  className,
}) {
  const sizeClassName = sizeClassNames[size];

  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      {label ? <label htmlFor={id}>{label}</label> : undefined}
      <input
        type={isPassword ? "password" : "text"}
        name={id}
        id={id}
        placeholder={placeholder}
        className={`input input-bordered ${hasError ? "input-error" : ""} ${
          hasWarning ? "input-warning" : ""
        } ${sizeClassName || ""} ${className}`}
        value={value}
        onChange={isDisabled ? () => {} : (e) => onChange(e.target.value)}
        readOnly={isDisabled}
      />
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPassword: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)),
  className: PropTypes.string,
};

Input.defaultProps = {
  id: "",
  label: "",
  value: "",
  placeholder: "",
  onChange: () => {},
  hasError: false,
  hasWarning: false,
  isDisabled: false,
  isPassword: false,
  size: "md",
  className: "",
};

export default Input;
