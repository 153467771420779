import React from "react";
import hero from "../assets/images/404-hero.jpg";
import { LoginLayout, Button } from "../components";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <LoginLayout hero={hero} redirect={false}>
      <h1 className="text-5xl xl:text-6xl font-bold">404</h1>
      <h1 className="text-primary text-5xl xl:text-6xl font-bold mb-5">
        {t("404.labels.message", "Page not found")}
      </h1>
      <Button isLink={true} to="/">
        {t("404.labels.home", "Go home")}
      </Button>
    </LoginLayout>
  );
}
