/**
 * Trims a string to a given length
 * @param {string} input - String to be trimmed
 * @param {int} length - Maximum length of the output
 * @returns {string} Trimmed string
 */
export default function trimString(input, length = 50) {
  if (!input) return "";
  if (input.length > length) {
    return `${input.substring(0, length)}...`;
  } else {
    return input;
  }
}
