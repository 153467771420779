import { gql } from "@apollo/client";

const CREATERESPONDENT = gql`
  mutation CreateRespondent(
    $name: String!
    $email: String!
    $languageId: ID!
    $respondentListId: ID!
    $department: String
    $function: String
    $age: Int
  ) {
    createRespondent(
      respondent: {
        name: $name
        email: $email
        languageId: $languageId
        respondentListId: $respondentListId
        department: $department
        function: $function
        age: $age
      }
    ) {
      id
    }
  }
`;

const UPDATERESPONDENT = gql`
  mutation UpdateRespondent(
    $id: ID!
    $name: String
    $email: String
    $languageId: ID
    $respondentListId: ID
    $department: String
    $function: String
    $age: Int
  ) {
    updateRespondent(
      id: $id
      respondent: {
        name: $name
        email: $email
        languageId: $languageId
        respondentListId: $respondentListId
        department: $department
        function: $function
        age: $age
      }
    ) {
      id
    }
  }
`;

const DELETERESPONDENT = gql`
  mutation DeleteRespondent($id: ID!) {
    deleteRespondent(id: $id)
  }
`;

const GETRESPONDENTS = gql`
  query GetRespondents {
    respondents {
      id
      name
      email
      language {
        id
        label
      }
    }
  }
`;

const GETRESPONDENT = gql`
  query GetRespondent($id: ID!) {
    respondent(id: $id) {
      id
      name
      email
      language {
        id
        label
      }
    }
  }
`;

const calls = {
  CREATERESPONDENT,
  UPDATERESPONDENT,
  DELETERESPONDENT,
  GETRESPONDENTS,
  GETRESPONDENT,
};

export default calls;
