import attributeCalls from "./attribute";
import authenticationCalls from "./authentication.js";
import clientCalls from "./client";
import emailCalls from "./email";
import languageCalls from "./language";
import projectCalls from "./project";
import publishedSurveyCalls from "./publishedsurvey";
import publishTaskCalls from "./publishtask";
import questionCalls from "./question";
import questionCategoryCalls from "./questioncategory";
import questionRouteCalls from "./questionroute";
import questionTagCalls from "./questiontag";
import questionTranslationCalls from "./questiontranslation";
import reportCalls from "./report";
import reportComponentCalls from "./reportcomponent";
import reportPageCalls from "./reportpage";
import reportStructureCalls from "./reportstructure";
import respondentCalls from "./respondent";
import respondentListCalls from "./respondentlist";
import responseOptionCalls from "./responseoption";
import responseOptionTranslationCalls from "./responseoptiontranslation";
import responseTaskCalls from "./responsetask";
import surveyCalls from "./survey";
import surveySectionCalls from "./surveysection";
import typeformSurveyCalls from "./typeformsurvey";
import uploadCalls from "./upload";

const calls = {
  ...attributeCalls,
  ...authenticationCalls,
  ...clientCalls,
  ...emailCalls,
  ...languageCalls,
  ...projectCalls,
  ...publishedSurveyCalls,
  ...publishTaskCalls,
  ...questionCalls,
  ...questionCategoryCalls,
  ...questionRouteCalls,
  ...questionTagCalls,
  ...questionTranslationCalls,
  ...reportCalls,
  ...reportComponentCalls,
  ...reportPageCalls,
  ...reportStructureCalls,
  ...respondentCalls,
  ...respondentListCalls,
  ...responseOptionCalls,
  ...responseOptionTranslationCalls,
  ...responseTaskCalls,
  ...surveyCalls,
  ...surveySectionCalls,
  ...typeformSurveyCalls,
  ...uploadCalls,
};

export default calls;
