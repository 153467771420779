import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import {
  Breadcrumbs,
  ConfirmModal,
  Button,
  Icons,
  LoggedInLayout,
  MinLevel,
} from "../../components";
import { userRoleLevels } from "../../utils";
import calls from "../../graphql";
const { GETSURVEY, DELETESURVEY } = calls;

export default function Dashboard() {
  // Hooks
  const { surveyId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  // State
  const [survey, setSurvey] = useState(undefined);
  const [deleteSurveyModalOpen, setDeleteSurveyModalOpen] = useState(false);
  // Queries
  const getSurveyStatus = useQuery(GETSURVEY, {
    variables: {
      id: surveyId,
    },
  });
  // Mutations
  const [deleteSurvey, deleteSurveyState] = useMutation(DELETESURVEY);
  // Handle errors
  if (getSurveyStatus.error) toast.error(getSurveyStatus.error.message);
  // Effects
  useEffect(() => {
    if (getSurveyStatus.data && getSurveyStatus.data.survey) {
      const survey = getSurveyStatus.data.survey;
      setSurvey(survey);
    }
  }, [getSurveyStatus.data]);

  const onDeleteSurvey = async () => {
    try {
      const { data } = await deleteSurvey({
        variables: {
          id: surveyId,
        },
      });
      if (data.deleteSurvey) {
        setDeleteSurveyModalOpen(false);
        toast.success(
          t(
            "template.survey.dashboard.messages.success.delete-survey",
            "Survey deleted!"
          )
        );
        history.replace(`/templates`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                label: t(
                  "template.survey.questions.breadcrumbs.templates",
                  "Templates"
                ),
                link: `/templates`,
              },
              {
                label: t(
                  "template.survey.questions.breadcrumbs.dashboard",
                  "Dashboard"
                ),
                link: `/templates/${surveyId}/dashboard`,
              },
            ]}
            back={`/templates`}
          />
        }
        isLoading={getSurveyStatus.loading}
      >
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-3xl md:text-4xl font-bold">
            {survey
              ? survey.label
              : t("template.survey.dashboard.labels.survey", "Survey")}
          </h1>
          <MinLevel minLevel={userRoleLevels.Manager}>
            <Button
              start={<Icons.Delete className="text-xl" />}
              color="error"
              onClick={() => {
                setDeleteSurveyModalOpen(true);
              }}
              isLoading={deleteSurveyState.loading}
            >
              {t(
                "template.survey.dashboard.labels.delete-survey",
                "Delete Survey"
              )}
            </Button>
          </MinLevel>
        </div>
        <div className="flex-grow bg-base-100 border border-base-300 rounded-box flex flex-col mb-10">
          <Link
            to={`/templates/${surveyId}/questions`}
            className={`flex flex-row flex-grow justify-between items-center px-12 border-b border-base-300`}
          >
            <div className="min-w-min">
              <Icons.Question className="text-4xl md:text-5xl" />
            </div>
            <div className="text-2xl md:text-4xl font-bold">
              {t("template.survey.dashboard.links.questions", "Questions")}
            </div>
            <div className="min-w-min">
              <Icons.Next className="text-4xl md:text-5xl" />
            </div>
          </Link>
          <Link
            to={`/templates/${surveyId}/reportstructures`}
            className={`flex flex-row flex-grow justify-between items-center px-12`}
          >
            <div className="min-w-min">
              <Icons.Report className="text-4xl md:text-5xl" />
            </div>
            <div className="text-2xl md:text-4xl font-bold">
              {t("template.survey.dashboard.links.reports", "Reports")}
            </div>
            <div className="min-w-min">
              <Icons.Next className="text-4xl md:text-5xl" />
            </div>
          </Link>
        </div>
      </LoggedInLayout>
      <ConfirmModal
        toConfirmType={t(
          "template.survey.dashboard.modals.delete-survey.type",
          "survey"
        )}
        toConfirmLabel={survey ? survey.label : ""}
        action={"delete"}
        modalOpen={deleteSurveyModalOpen}
        closeModal={() => setDeleteSurveyModalOpen(false)}
        onConfirm={onDeleteSurvey}
        isLoading={deleteSurveyState.loading}
        warning={t(
          "template.survey.dashboard.modals.delete-survey.warning",
          "This operation will also delete all questions, responses and reports of this survey and cannot be undone!"
        )}
      />
    </>
  );
}
