import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Icons, Checkbox, Input, Button } from "../..";

/**
 * Response options form component
 * @param {array} surveyLanguages - Array of languages supported by the survey
 * @param {array} questionLanguage - Language currently being edited
 * @param {array} responseOptions - Response options of the question
 * @param {array} questionTypes - Supported question types
 * @param {array} questionTypeId - Current selected question type
 * @param {array} hasAllowMultipleSelection - Whether it is possible to allow multiple selections
 * @param {array} setAllowMultipleSelection - Setter function
 * @param {array} allowMultipleSelection - Allow multiple selections for the questions
 * @param {array} setChanged - Setter function to indicate changes
 * @param {array} setResponseOptions - Setter function for the response options
 * @param {array} editResponseOptionValues - Allow editing the values of the response options
 * @param {array} editResponseOptionsAmount - Allow editing the amount of the response options
 * @returns {node} React component
 */
function ResponseOptionsForm({
  surveyLanguages,
  questionLanguage,
  responseOptions,
  questionTypes,
  questionTypeId,
  hasAllowMultipleSelection,
  setAllowMultipleSelection,
  allowMultipleSelection,
  setChanged,
  setResponseOptions,
  editResponseOptionValues,
  editResponseOptionsAmount,
}) {
  // Hooks
  const { t } = useTranslation();
  // State
  const [selectedType, setSelectedType] = useState(undefined);
  // Effects
  useEffect(() => {
    if (questionTypeId && questionTypes) {
      const type = questionTypes.find((type) => type.id === questionTypeId);
      setSelectedType(type);
    }
  }, [questionTypeId, questionTypes]);

  const onChangeResponseOptionLabel = (label, index) => {
    setChanged(true);
    const options = [...responseOptions];
    options[index].labels[questionLanguage] = label;
    setResponseOptions(options);
  };

  const onAddResponseOption = () => {
    setChanged(true);
    const option = {
      value: "",
      labels: {},
    };
    surveyLanguages.forEach((language) => (option.labels[language.label] = ""));
    const options = [...responseOptions, option];
    setResponseOptions(options);
  };

  const onDeleteResponseOption = (index) => {
    setChanged(true);
    const options = [...responseOptions];
    options.splice(index, 1);
    setResponseOptions(options);
  };

  const onChangeResponseOptionValue = (value, index) => {
    const type = selectedType;
    if (
      type &&
      type.label === "ScoredMultipleChoice" &&
      isNaN(value) &&
      value !== "-"
    ) {
      toast.custom(
        t(
          "project.survey.question.messsages.warning.non-numeric-not-applicable",
          "Non-numerical values will be interpreted as not applicable!"
        )
      );
    }
    setChanged(true);
    if (editResponseOptionValues) {
      const options = [...responseOptions];
      options[index].value = value;
      setResponseOptions(options);
    }
  };

  return (
    <div className="flex flex-col w-full space-y-2">
      <div className="flex flex-row items-center">
        <h2 className="text-3xl font-bold flex-grow">
          {t(
            "project.survey.question.form.response-options-section-label",
            "Response options"
          )}
        </h2>
        <div
          data-tip={t(
            "project.survey.question.form.response-option-explanation",
            "The values (for analysis) and labels (shown to surveyee) of the response option."
          )}
          className="tooltip tooltip-left"
        >
          <Icons.Info className="text-xl text-gray-600 hover:text-black" />
        </div>
      </div>
      {hasAllowMultipleSelection ? (
        <Checkbox
          id="allowMultipleSelection"
          label={t(
            "project.survey.question.form.allow-multiple-selections-label",
            "Allow multiple selections"
          )}
          checked={allowMultipleSelection}
          toggle={() => {
            setChanged(true);
            setAllowMultipleSelection(!allowMultipleSelection);
          }}
        />
      ) : undefined}
      {responseOptions && responseOptions.length ? (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2">
            <label htmlFor="value" className="w-20">
              {t(
                "project.survey.question.form.response-option-value-label",
                "Value"
              )}
            </label>
            <label htmlFor="label" className="flex-grow">
              {t(
                "project.survey.question.form.response-option-label-label",
                "Label"
              )}
            </label>
          </div>
          {responseOptions.map((option, index) => (
            <div
              key={`responseOption${index}`}
              className="flex flex-row items-center space-x-2"
            >
              <Input
                placeholder={t(
                  "project.survey.question.form.response-option-value-placeholder",
                  "Value"
                )}
                value={option.value}
                onChange={(value) => {
                  onChangeResponseOptionValue(value, index);
                }}
                isDisabled={!editResponseOptionValues}
                className="w-20"
                hasWarning={
                  selectedType &&
                  selectedType.label === "ScoredMultipleChoice" &&
                  isNaN(option.value)
                }
              />
              <Input
                placeholder={t(
                  "project.survey.question.form.response-option-label-placeholder",
                  "Label"
                )}
                value={option.labels[questionLanguage]}
                onChange={(value) => {
                  onChangeResponseOptionLabel(value, index);
                }}
                className="flex-grow"
              />

              {editResponseOptionsAmount ? (
                <Button
                  color="error"
                  onClick={() => {
                    onDeleteResponseOption(index);
                  }}
                >
                  <Icons.Delete className="text-xl" />
                </Button>
              ) : undefined}
            </div>
          ))}
        </div>
      ) : undefined}
      {questionTypeId && editResponseOptionsAmount ? (
        <Button
          color="primary"
          start={<Icons.Add className="text-xl" />}
          onClick={() => {
            onAddResponseOption();
          }}
        >
          {t(
            "project.survey.question.form.add-response-option",
            "Add response option"
          )}
        </Button>
      ) : undefined}
    </div>
  );
}

ResponseOptionsForm.propTypes = {
  surveyLanguages: PropTypes.array,
  questionLanguage: PropTypes.string,
  responseOptions: PropTypes.array,
  questionTypes: PropTypes.array,
  questionTypeId: PropTypes.string,
  hasAllowMultipleSelection: PropTypes.bool,
  setAllowMultipleSelection: PropTypes.func,
  allowMultipleSelection: PropTypes.bool,
  setChanged: PropTypes.func,
  setResponseOptions: PropTypes.func,
  editResponseOptionValues: PropTypes.bool,
  editResponseOptionsAmount: PropTypes.bool,
};

export default ResponseOptionsForm;
