import React, { useContext } from "react";
import PropTypes from "prop-types";
import { userRoleLevels } from "../../utils";
import { AppStateContext } from "./Provider";

/**
 * Component wrapper which hides content for unauthorized user
 * @param {integer} minLevel - Minimum level a user should be to view the content
 * @param {node} children - Content to be shown or hidden based on level
 * @returns {node} Either the children or nothing, depending on the user level
 */
function MinLevel({ minLevel, children }) {
  const { appState } = useContext(AppStateContext);
  const { userLevel } = appState;

  if (userLevel >= minLevel) {
    return <>{children}</>;
  } else {
    return null;
  }
}

MinLevel.propTypes = {
  children: PropTypes.node,
  minLevel: PropTypes.number,
};

MinLevel.defaultProps = {
  children: undefined,
  minLevel: userRoleLevels.User,
};

export default MinLevel;
