import React from "react";
import { initials as makeInitials } from "../utils";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Header } from "../components";
import toast from "react-hot-toast";
import calls from "../graphql";
const { PROFILE } = calls;

export default function Projects() {
  // Hooks
  const { t } = useTranslation();
  const { data, error } = useQuery(PROFILE);
  if (error) toast.error(error.message);
  let initials = "?";
  if (data && data.me) {
    if (data.me.userName) {
      initials = makeInitials(data.me.userName);
    } else {
      initials = makeInitials(data.me.email);
    }
  }

  return (
    <div className="min-h-screen flex flex-col shadow-sm">
      <Header
        initials={initials}
        breadcrumbs={
          <Breadcrumbs
            links={[
              {
                link: "/projects",
                label: t("projects.labels.projects", "Projects"),
              },
            ]}
          />
        }
      />
      <div className="px-5 md:px-12 py-5 md:py-12 bg-base-200 flex flex-col flex-grow space-y-5">
        <div>
          <h3 className="text-3xl">
            {t(
              "components.loggedinlayout.messages.warning.no-access",
              "You have no access yet to this page."
            )}
          </h3>
          <p>
            {t(
              "components.loggedinlayout.messages.warning.contact-admin",
              "Contact your system administrator if you require more permissions."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
