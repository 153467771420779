import { gql } from "@apollo/client";

const CREATERESPONSEOPTIONTRANSLATION = gql`
  mutation CreateResponseOptionTranslation(
    $label: String!
    $description: String
    $responseOptionId: ID!
    $languageId: ID!
  ) {
    createResponseOptionTranslation(
      responseOptionTranslation: {
        label: $label
        description: $description
        responseOptionId: $responseOptionId
        languageId: $languageId
      }
    ) {
      id
    }
  }
`;

const UPDATERESPONSEOPTIONTRANSLATION = gql`
  mutation UpdateResponseOptionTranslation(
    $id: ID!
    $label: String
    $description: String
    $responseOptionId: ID
    $languageId: ID
  ) {
    updateResponseOptionTranslation(
      id: $id
      responseOptionTranslation: {
        label: $label
        description: $description
        responseOptionId: $responseOptionId
        languageId: $languageId
      }
    ) {
      id
    }
  }
`;

const DELETERESPONSEOPTIONTRANSLATION = gql`
  mutation DeleteResponseOptionTranslation($id: ID!) {
    deleteResponseOptionTranslation(id: $id)
  }
`;

const GETRESPONSEOPTIONTRANSLATIONS = gql`
  query GetResponseOptionTranslations {
    responseOptionTranslations {
      id
      label
      description
      createdAt
      updatedAt
      language {
        id
        label
      }
    }
  }
`;

const GETRESPONSEOPTIONTRANSLATION = gql`
  query GetResponseOptionTranslation($id: ID!) {
    responseOptionTranslation(id: $id) {
      id
      label
      description
      createdAt
      updatedAt
      language {
        id
        label
      }
    }
  }
`;

const calls = {
  CREATERESPONSEOPTIONTRANSLATION,
  UPDATERESPONSEOPTIONTRANSLATION,
  DELETERESPONSEOPTIONTRANSLATION,
  GETRESPONSEOPTIONTRANSLATIONS,
  GETRESPONSEOPTIONTRANSLATION,
};

export default calls;
