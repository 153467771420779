/**
 * Makes initials from strings
 * e.g. "john doe" --> "JD"
 * @param {string} longText - Text to make initials from
 * @returns {string} initials
 */
export default function initials(longText) {
  return longText
    .split(" ")
    .map((part) => part[0].toUpperCase())
    .join("");
}
