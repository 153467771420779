import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Icons,
  Input,
  InfoSelect,
  TextArea,
  CategoryForm,
  QuestionRoutesForm,
  ResponseOptionsForm,
  LanguageTabs,
} from "../..";
import { useQuestionTypes, useQuestionDirections } from "../../../utils";
/**
 * Complete question creation/edit form
 * @param {string} questionId - Current question id
 * @param {array} surveyLanguages - Languages supported by the survey [{id: "", label: ""}]
 * @param {object} questionLanguage - Language of the question currently being edited
 * @param {function} setQuestionLanguage - Setter function
 * @param {function} addQuestionLanguage - Plus language tab handler function
 * @param {function} removeQuestionLanguage - Minus language tab handler function
 * @param {function} setChanged - Call on any edit
 * @param {function} setInfoModalOpen - Setter function to open information modal
 * @param {function} setInfoModalType - Setter function (TODO: needs refactor)
 * @param {function} setCategoryModalOpen - Setter function to open category settings modal
 * @param {function} onSaveQuestion - Question save handler function
 * @param {array} surveySections - Sections of the current survey
 * @param {array} questions - Question of the current survey
 * @param {array} questionTypes - All available question types
 * @param {array} questionCategories - All available question categories
 * @param {array} questionTags - All available question tags
 * @param {array} routeOperators - All available route operators
 * @param {array} questionRouteValueOptions - Value options to which the route operators can be applied (only truthy for some question types)
 * @param {string} questionTitle - Question label
 * @param {function} setQuestionTitle - Setter function
 * @param {string} questionDescription - Question description
 * @param {function} setQuestionDescription - Setter function
 * @param {string} questionSectionId - Id of the section containing the question
 * @param {function} onChangeQuestionSectionId - Setter function
 * @param {string} questionTypeId - Id of the question type
 * @param {function} onChangeQuestionTypeId - Setter function
 * @param {boolean} hasQuestionCategory - True if a category can be selected for this type of question
 * @param {array} questionCategoryIds - Ids of the categories selected for this question
 * @param {function} setQuestionCategoryIds - Setter function
 * @param {array} questionTagIds - Ids of the tags selected for this question
 * @param {function} setQuestionTagIds - Setter function
 * @param {boolean} hasQuestionDirection - True if a question direction can be selected for this type of question
 * @param {string} questionDirection - Question direction value
 * @param {function} setQuestionDirection - Setter function
 * @param {boolean} editResponseOptionValues - True if the values of the response options should be editable
 * @param {boolean} editResponseOptionsAmount - True if the amount of response options should be editable
 * @param {array} responseOptions - Response options of the question
 * @param {function} setResponseOptions - Setter function
 * @param {boolean} hasAllowMultipleSelection - True if one could allow multiple selections to be made
 * @param {boolean} allowMultipleSelection - Whether to allow multiple selections
 * @param {function} setAllowMultipleSelection - Setter function
 * @param {boolean} hasQuestionRoutes - True if the question supports conditional jumps
 * @param {array} questionRoutes - Conditional jumps for the question
 * @param {function} setQuestionRoutes - Setter function
 * @returns {node} React component
 */
function QuestionForm({
  questionId,
  surveyLanguages,
  questionLanguage,
  setQuestionLanguage,
  addQuestionLanguage,
  removeQuestionLanguage,
  setChanged,
  setInfoModalOpen,
  setInfoModalType,
  setCategoryModalOpen,
  onSaveQuestion,
  surveySections,
  questions,
  questionTypes,
  questionCategories,
  routeOperators,
  questionRouteValueOptions,
  questionTitle,
  setQuestionTitle,
  questionDescription,
  setQuestionDescription,
  questionSectionId,
  onChangeQuestionSectionId,
  questionTypeId,
  onChangeQuestionTypeId,
  hasQuestionCategory,
  questionCategoryIds,
  setQuestionCategoryIds,
  questionTagIds,
  setQuestionTagIds,
  hasQuestionDirection,
  questionDirection,
  setQuestionDirection,
  editResponseOptionValues,
  editResponseOptionsAmount,
  responseOptions,
  setResponseOptions,
  hasAllowMultipleSelection,
  allowMultipleSelection,
  setAllowMultipleSelection,
  hasQuestionRoutes,
  questionRoutes,
  setQuestionRoutes,
}) {
  const { t } = useTranslation();
  const questionTypeTranslations = useQuestionTypes();
  const questionDirections = useQuestionDirections();
  return (
    <form
      className="form-control w-3/4 self-center space-y-10"
      onSubmit={onSaveQuestion}
    >
      <div className="flex flex-col w-full space-y-2">
        <div className="w-full flex flex-row justify-between items-end">
          <h2 className="text-3xl font-bold">
            {t(
              "project.survey.question.form.general-section-label",
              "Question"
            )}
          </h2>
          {surveyLanguages && surveyLanguages.length ? (
            <LanguageTabs
              languages={surveyLanguages}
              selectedLanguageLabel={questionLanguage}
              onSelectLanguage={setQuestionLanguage}
              onAddLanguage={addQuestionLanguage}
              onRemoveLanguage={removeQuestionLanguage}
            />
          ) : undefined}
        </div>
        <div className="flex flex-row items-center">
          <label htmlFor="title" className="flex-grow">
            {t("project.survey.question.form.title-label", "Title")}
          </label>
          <div
            data-tip={t(
              "project.survey.question.form.title-explanation",
              "The title of the question to be shown to the surveyee."
            )}
            className="tooltip tooltip-left"
          >
            <Icons.Info className="text-xl text-gray-600 hover:text-black" />
          </div>
        </div>
        <Input
          id="title"
          placeholder={t(
            "project.survey.question.form.title-placeholder",
            "title"
          )}
          value={questionTitle}
          onChange={(value) => {
            setChanged(true);
            setQuestionTitle(value);
          }}
        />
        <div className="flex flex-row items-center">
          <label htmlFor="description" className="flex-grow">
            {t("project.survey.question.form.description-label", "Description")}
          </label>
          <div
            data-tip={t(
              "project.survey.question.form.description-explanation",
              "The description of the question to be shown to the surveyee."
            )}
            className="tooltip tooltip-left"
          >
            <Icons.Info className="text-xl text-gray-600 hover:text-black" />
          </div>
        </div>
        <TextArea
          id="description"
          placeholder={t(
            "project.survey.question.form.description-placeholder",
            "description"
          )}
          value={questionDescription}
          onChange={(value) => {
            setChanged(true);
            setQuestionDescription(value);
          }}
        />
      </div>
      <div className="flex flex-col w-full space-y-2">
        <h2 className="text-3xl font-bold">
          {t(
            "project.survey.question.form.properties-section-label",
            "Properties"
          )}
        </h2>
        <InfoSelect
          label={t("project.survey.question.form.section-label", "Section")}
          setInfoModalOpen={() => {}}
          setInfoModalType={() => {}}
          infoMessage={t(
            "project.survey.question.form.section-explanation",
            "A survey is structured in sections, which contain questions."
          )}
          id="section"
          value={questionSectionId}
          onChange={onChangeQuestionSectionId}
          options={surveySections.map((section) => ({
            value: section.id,
            label: section.label,
          }))}
          placeholderValue="0"
          placeholderLabel={t(
            "project.survey.question.form.section-placeholder",
            "Select Survey Section"
          )}
        />
        <InfoSelect
          label={t("project.survey.question.form.type-label", "Type")}
          setInfoModalOpen={setInfoModalOpen}
          setInfoModalType={setInfoModalType}
          infoMessage={t(
            "project.survey.question.form.type-explanation",
            "The type of question. Click for more information."
          )}
          id="type"
          value={questionTypeId}
          onChange={onChangeQuestionTypeId}
          options={questionTypes
            .filter((type) => !!questionTypeTranslations[type.label])
            .sort(
              (typeA, typeB) =>
                questionTypeTranslations[typeA.label].label >
                questionTypeTranslations[typeB.label].label
            )
            .map((type) => ({
              value: type.id,
              label: questionTypeTranslations[type.label].label,
            }))}
          placeholderValue={"0"}
          placeholderLabel={t(
            "project.survey.question.form.type-placeholder",
            "Select Type"
          )}
        />
        {hasQuestionCategory ? (
          <CategoryForm
            questionCategories={questionCategories}
            questionCategoryIds={questionCategoryIds}
            setQuestionCategoryIds={setQuestionCategoryIds}
            questionTagIds={questionTagIds}
            setQuestionTagIds={setQuestionTagIds}
            setCategoryModalOpen={setCategoryModalOpen}
            setChanged={setChanged}
          />
        ) : undefined}
        {questionTypeId && hasQuestionDirection ? (
          <InfoSelect
            label={t(
              "project.survey.question.form.direction-label",
              "Direction"
            )}
            id="direction"
            setInfoModalOpen={setInfoModalOpen}
            setInfoModalType={setInfoModalType}
            infoMessage={t(
              "project.survey.question.form.direction-explanation",
              "Direction of the question scoring. Click for more information."
            )}
            value={questionDirection}
            options={Object.entries(questionDirections)
              .filter(
                ([, direction]) =>
                  direction.value !== questionDirections.not_applicable.value
              )
              .map(([, value]) => ({
                value: value.value,
                label: value.label,
              }))}
            onChange={(value) => {
              setChanged(true);
              setQuestionDirection(value);
            }}
            placeholderValue={questionDirections.not_applicable.value}
            placeholderLabel={t(
              "project.survey.question.form.direction-placeholder",
              "Select Question Direction"
            )}
          />
        ) : undefined}
      </div>
      {questionTypeId &&
      ((responseOptions && responseOptions.length) ||
        editResponseOptionsAmount) ? (
        <ResponseOptionsForm
          surveyLanguages={surveyLanguages}
          questionLanguage={questionLanguage}
          responseOptions={responseOptions}
          questionTypes={questionTypes}
          questionTypeId={questionTypeId}
          hasAllowMultipleSelection={hasAllowMultipleSelection}
          setAllowMultipleSelection={setAllowMultipleSelection}
          allowMultipleSelection={allowMultipleSelection}
          setChanged={setChanged}
          setResponseOptions={setResponseOptions}
          editResponseOptionValues={editResponseOptionValues}
          editResponseOptionsAmount={editResponseOptionsAmount}
        />
      ) : undefined}
      {hasQuestionRoutes && routeOperators && routeOperators.length ? (
        <QuestionRoutesForm
          questions={questions}
          questionId={questionId}
          questionLanguage={questionLanguage}
          routeOperators={routeOperators}
          questionRouteValueOptions={questionRouteValueOptions}
          responseOptions={responseOptions}
          questionRoutes={questionRoutes}
          setQuestionRoutes={setQuestionRoutes}
          setChanged={setChanged}
        />
      ) : undefined}
    </form>
  );
}
QuestionForm.propTypes = {
  questionId: PropTypes.string,
  surveyLanguages: PropTypes.array,
  questionLanguage: PropTypes.string,
  setQuestionLanguage: PropTypes.func,
  addQuestionLanguage: PropTypes.func,
  removeQuestionLanguage: PropTypes.func,
  setChanged: PropTypes.func,
  setInfoModalOpen: PropTypes.func,
  setInfoModalType: PropTypes.func,
  setCategoryModalOpen: PropTypes.func,
  onSaveQuestion: PropTypes.func,
  surveySections: PropTypes.array,
  questions: PropTypes.array,
  questionTypes: PropTypes.array,
  questionCategories: PropTypes.array,
  routeOperators: PropTypes.array,
  questionRouteValueOptions: PropTypes.array,
  questionTitle: PropTypes.string,
  setQuestionTitle: PropTypes.func,
  questionDescription: PropTypes.string,
  setQuestionDescription: PropTypes.func,
  questionSectionId: PropTypes.string,
  onChangeQuestionSectionId: PropTypes.func,
  questionTypeId: PropTypes.string,
  onChangeQuestionTypeId: PropTypes.func,
  hasQuestionCategory: PropTypes.bool,
  questionCategoryIds: PropTypes.array,
  setQuestionCategoryIds: PropTypes.func,
  questionTagIds: PropTypes.array,
  setQuestionTagIds: PropTypes.func,
  hasQuestionDirection: PropTypes.bool,
  questionDirection: PropTypes.string,
  setQuestionDirection: PropTypes.func,
  editResponseOptionValues: PropTypes.bool,
  editResponseOptionsAmount: PropTypes.bool,
  responseOptions: PropTypes.array,
  setResponseOptions: PropTypes.func,
  hasAllowMultipleSelection: PropTypes.bool,
  allowMultipleSelection: PropTypes.bool,
  setAllowMultipleSelection: PropTypes.func,
  hasQuestionRoutes: PropTypes.bool,
  questionRoutes: PropTypes.array,
  setQuestionRoutes: PropTypes.func,
};
export default QuestionForm;
