import React from "react";
import PropTypes from "prop-types";

/**
 * Styled wrapper of the table row (tr) html component
 * @param {node} children - Table row content
 * @returns {node} tr component
 */
function TableRow({ children }) {
  return <tr>{children}</tr>;
}

TableRow.propTypes = {
  children: PropTypes.node,
};

export default TableRow;
