import React from "react";
import PropTypes from "prop-types";
import { Icons } from "..";

const alertClassNames = {
  info: "alert-info",
  success: "alert-success",
  warning: "alert-warning",
  error: "alert-error",
};

/**
 * Notification banner
 * @param {node} icon - Alert icon - default depending on type
 * @param {string} type - Type of alert, one of ["info", "success", "warning", "error"] - default: undefined
 * @param {node} children - Alert main content - default: undefined
 * @returns {node}
 */
export default function Alert({ icon, type, children, end }) {
  const alertClassName = alertClassNames[type];
  const defaultIcon = type
    ? Icons[type[0].toUpperCase() + type.slice(1)]
    : undefined;
  const AlertIcon = icon || defaultIcon;

  return (
    <div className="bg-white rounded-box">
      <div className={`alert ${alertClassName}`} data-testid="alert-component">
        <div className="flex-1 items-center">
          {AlertIcon ? (
            <div className="text-3xl mr-3" data-testid="alert-component-icon">
              <AlertIcon />
            </div>
          ) : undefined}
          <label className="text-left">{children}</label>
        </div>
        {end ? <div>{end}</div> : undefined}
      </div>
    </div>
  );
}

Alert.propTypes = {
  icon: PropTypes.node,
  type: PropTypes.oneOf(["info", "success", "warning", "error"]),
  children: PropTypes.node,
  end: PropTypes.node,
};

Alert.defaultProps = {
  icon: undefined,
  type: undefined,
  children: undefined,
  end: undefined,
};
