import { gql } from "@apollo/client";

const GETLANGUAGES = gql`
  query GetLanguages {
    languages {
      id
      label
    }
  }
`;

const calls = {
  GETLANGUAGES,
};

export default calls;
