import React, { useState, useEffect } from "react";
import {
  LoggedInLayout,
  Breadcrumbs,
  ConfirmModal,
  Icons,
  Button,
  ActionModal,
  Table,
  TableHead,
  TableData,
  TableRow,
  Select,
  Input,
  MinLevel,
  Checkbox,
} from "../../../components";
import {
  validateEmail,
  userRoleLevels,
  useLanguageLabels,
} from "../../../utils";
import calls from "../../../graphql";
import { parseString } from "@fast-csv/parse";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import fuzzysort from "fuzzysort";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
const {
  GETRESPONDENTLIST,
  GETLANGUAGES,
  UPDATERESPONDENTLIST,
  DELETERESPONDENTLIST,
  CREATERESPONDENT,
  UPDATERESPONDENT,
  DELETERESPONDENT,
} = calls;

let duplicateWarningShown = false;

export default function MailingList() {
  // Hooks
  const { t } = useTranslation();
  const { clientId, mailingListId } = useParams();
  const history = useHistory();
  const languageLabels = useLanguageLabels();
  // State
  const [mailingList, setMailingList] = useState(undefined);
  const [respondents, setRespondents] = useState([]);
  const [mailingListTitle, setMailingListTitle] = useState("");
  const [selectedRespondent, setSelectedRespondent] = useState(undefined);
  const [respondentName, setRespondentName] = useState("");
  const [respondentEmail, setRespondentEmail] = useState("");
  const [languages, setLanguages] = useState([]);
  const [respondentLanguageId, setRespondentLanguageId] = useState("0");
  const [deleteMailingListModalOpen, setDeleteMailingListModalOpen] =
    useState(false);
  const [deleteRespondentModalOpen, setDeleteRespondentModalOpen] =
    useState(false);
  const [createRespondentModalOpen, setCreateRespondentModalOpen] =
    useState(false);
  const [search, setSearch] = useState("");
  const [csvRespondents, setCsvRespondents] = useState([]);
  const [csvRespondentModalOpen, setCsvRespondentModalOpen] = useState(false);
  const [overwriteRespondents, setOverwriteRespondents] = useState(true);
  // Queries
  const getRespondentListState = useQuery(GETRESPONDENTLIST, {
    variables: {
      id: mailingListId,
    },
  });
  const getLanguagesState = useQuery(GETLANGUAGES);
  // Mutations
  const [updateRespondentList, updateRespondentListState] = useMutation(
    UPDATERESPONDENTLIST,
    {
      refetchQueries: [GETRESPONDENTLIST],
    }
  );
  const [deleteRespondentList, deleteRespondentListState] =
    useMutation(DELETERESPONDENTLIST);
  const [createRespondent, createRespondentState] = useMutation(
    CREATERESPONDENT,
    {
      refetchQueries: [GETRESPONDENTLIST],
    }
  );
  const [updateRespondent, updateRespondentState] = useMutation(
    UPDATERESPONDENT,
    {
      refetchQueries: [GETRESPONDENTLIST],
    }
  );
  const [deleteRespondent, deleteRespondentState] = useMutation(
    DELETERESPONDENT,
    {
      refetchQueries: [GETRESPONDENTLIST],
    }
  );
  // Handle errors
  if (getRespondentListState.error)
    toast.error(getRespondentListState.error.message);
  // Effects
  useEffect(() => {
    if (
      getRespondentListState.data &&
      getRespondentListState.data.respondentList
    ) {
      const respondentList = getRespondentListState.data.respondentList;
      setMailingList(respondentList);
      setMailingListTitle(respondentList.label);
    }
  }, [getRespondentListState.data]);
  useEffect(() => {
    if (
      getRespondentListState.data &&
      getRespondentListState.data.respondentList
    ) {
      const respondentList = getRespondentListState.data.respondentList;
      const respondents = respondentList.respondents;
      if (search) {
        const delayedSearch = setTimeout(() => {
          const results = fuzzysort.go(search, respondents, {
            keys: ["name", "email"],
          });
          setRespondents(results.map((result) => result.obj));
        }, 200);
        return () => clearTimeout(delayedSearch);
      } else {
        setRespondents(respondents);
      }
    }
  }, [getRespondentListState.data, search]);
  useEffect(() => {
    if (getLanguagesState.data) {
      const languages = getLanguagesState.data.languages.filter(
        (language) => !!languageLabels[language.label]
      );
      setLanguages(languages);
    }
  }, [getLanguagesState.data]);

  const onSaveMailingList = async () => {
    if (mailingListTitle) {
      try {
        await updateRespondentList({
          variables: {
            id: mailingListId,
            label: mailingListTitle,
          },
        });
        toast.success(
          t(
            "clients.client.mailinglist.messages.success.mailing-list-created",
            "Mailing list saved!"
          )
        );
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.custom(
        t(
          "clients.client.mailinglist.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  const onDeleteMailingList = async () => {
    if (mailingListId) {
      try {
        await deleteRespondentList({
          variables: {
            id: mailingListId,
          },
        });
        setDeleteMailingListModalOpen(false);
        toast.success(
          t(
            "clients.client.mailinglist.messages.success.delete-mailing-list",
            "Mailing list deleted!"
          )
        );
        history.replace(`/clients/${clientId}/mailinglists`);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const selectRespondent = (respondent) => {
    setSelectedRespondent(respondent);
    setRespondentName(respondent.name);
    setRespondentEmail(respondent.email);
    setRespondentLanguageId(respondent.language ? respondent.language.id : "0");
  };

  const resetRespondentFields = () => {
    setSelectedRespondent(undefined);
    setRespondentName("");
    setRespondentEmail("");
    setRespondentLanguageId("0");
  };

  const onSaveRespondent = async () => {
    if (
      !(
        respondentName &&
        respondentEmail &&
        respondentLanguageId &&
        respondentLanguageId !== "0"
      )
    ) {
      toast.custom(
        t(
          "clients.client.mailinglist.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
      return;
    }
    if (!validateEmail(respondentEmail)) {
      toast.custom(
        t(
          "clients.client.mailinglist.messages.warning.invalid-email",
          "Please enter a valid email address."
        )
      );
      return;
    }
    try {
      if (selectedRespondent) {
        // Update respondent
        await updateRespondent({
          variables: {
            id: selectedRespondent.id,
            name: respondentName,
            email: respondentEmail,
            languageId: respondentLanguageId,
          },
        });
      } else {
        // Create new respondent
        await createRespondent({
          variables: {
            name: respondentName,
            email: respondentEmail,
            languageId: respondentLanguageId,
            respondentListId: mailingListId,
          },
        });
      }
      setCreateRespondentModalOpen(false);
      resetRespondentFields();
      toast.success(
        t(
          "clients.client.mailinglist.messages.success.saved-respondent",
          "Respondent saved!"
        )
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onDeleteRespondent = async () => {
    if (selectedRespondent) {
      try {
        await deleteRespondent({
          variables: {
            id: selectedRespondent.id,
          },
        });
        setDeleteRespondentModalOpen(false);
        toast.success(
          t(
            "clients.client.mailinglist.messages.success.delete-respondent",
            "Respondent deleted!"
          )
        );
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const onReceiveFile = async (files) => {
    if (files.length) {
      const file = files[0];
      if (file.type !== "text/csv") {
        toast.error(
          t(
            "clients.client.mailinglist.messages.error.only-csv",
            "Only CSV files are supported!"
          )
        );
        return;
      }
      try {
        const contents = await file.text();
        const parsed = [];
        parseString(contents, { headers: true, ignoreEmpty: true })
          .on("error", (error) => toast.error(error))
          .on("data", ({ name, email, language }) => {
            const lang = languages.find((lang) => lang.label === language);
            const sameEmail = parsed.find((obj) => obj.email === email);
            if (!sameEmail) {
              parsed.push({
                name: name ? name : "",
                email: email ? email : "",
                languageId: lang ? lang.id : "0",
              });
            } else if (!duplicateWarningShown) {
              duplicateWarningShown = true;
              toast.custom(
                t(
                  "clients.client.mailinglist.messages.warning.duplicate-emails",
                  "Duplicate emails detected, removing duplicates."
                )
              );
            }
          })
          .on("end", () => {
            duplicateWarningShown = false;
            if (parsed && parsed.length) {
              setCsvRespondents(parsed);
              setCsvRespondentModalOpen(true);
            } else {
              toast.error(
                t(
                  "clients.client.mailinglist.messages.error.no-csv-respondents",
                  "No respondents found!"
                )
              );
            }
          });
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const onChangeCsvRespondent = (respondent, index) => {
    const respondents = [...csvRespondents];
    respondents[index] = respondent;
    setCsvRespondents(respondents);
  };

  const isCsvRespondentsComplete = () => {
    return csvRespondents.reduce(
      (previous, respondent) =>
        previous &&
        respondent.name &&
        validateEmail(respondent.email) &&
        respondent.languageId &&
        respondent.languageId !== "0",
      true
    );
  };

  const onAddCsvRespondents = async () => {
    if (!isCsvRespondentsComplete()) {
      toast.custom(
        t(
          "clients.client.mailinglist.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
      return;
    }
    try {
      await Promise.all(
        csvRespondents.map(async ({ name, email, languageId }) => {
          const sameEmail = respondents.find(
            (respondent) => respondent.email === email
          );
          try {
            if (!sameEmail) {
              // Create respondent
              await createRespondent({
                variables: {
                  name,
                  email,
                  languageId,
                  respondentListId: mailingListId,
                },
              });
            } else if (overwriteRespondents) {
              // Update respondent
              await updateRespondent({
                variables: {
                  id: sameEmail.id,
                  name,
                  email,
                  languageId,
                },
              });
            }
          } catch (error) {
            toast.error(error.message);
          }
        })
      );
      setCsvRespondentModalOpen(false);
      setCsvRespondents([]);
      toast.success(
        t(
          "clients.client.mailinglist.messages.success.saved-respondents",
          "Respondents saved!"
        )
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: "/clients",
                label: t(
                  "clients.client.mailinglist.labels.clients",
                  "Clients"
                ),
              },
              {
                link: `/clients/${clientId}/mailinglists`,
                label: t(
                  "clients.client.mailinglist.labels.mailing-lists",
                  "Mailing lists"
                ),
              },
              {
                link: `/clients/${clientId}/mailinglists/${mailingListId}/mailinglist`,
                label: t(
                  "clients.client.mailinglist.labels.mailing-list",
                  "Mailing list"
                ),
              },
            ]}
            back={`/clients/${clientId}/mailinglists`}
          />
        }
        isLoading={getRespondentListState.loading}
      >
        <div className="w-3/4 self-center flex flex-col items-center space-y-10">
          <div className="w-full space-y-2">
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-3xl font-bold">
                {mailingList
                  ? mailingList.label
                  : t(
                      "clients.client.mailinglist.mailing-list.title",
                      "Mailing list"
                    )}
              </h3>
              <MinLevel minLevel={userRoleLevels.Manager}>
                <div className="flex flex-row space-x-2 items-center">
                  <label
                    htmlFor="csv-upload"
                    className="btn normal-case space-x-2 btn-primary"
                  >
                    <Icons.Upload className="text-xl" />
                    {t("clients.client.mailinglist.upload-csv", "Upload CSV")}
                  </label>
                  <input
                    id="csv-upload"
                    type="file"
                    accept=".csv"
                    onChange={(e) => onReceiveFile(e.target.files)}
                    className="hidden"
                  />
                  <Button
                    color="primary"
                    onClick={() => {
                      resetRespondentFields();
                      setCreateRespondentModalOpen(true);
                    }}
                    start={<Icons.Add className="text-xl" />}
                  >
                    {t(
                      "clients.client.mailinglist.respondents.new",
                      "New respondent"
                    )}
                  </Button>
                </div>
              </MinLevel>
            </div>
            {search || (respondents && respondents.length) ? (
              <>
                <Input
                  id="search"
                  placeholder={t("project.surveys.labels.search", "Search")}
                  value={search}
                  onChange={setSearch}
                  className="w-full"
                />
                <Table
                  head={
                    <tr className="h-10">
                      <TableHead>
                        {t(
                          "clients.client.mailinglist.table.headers.name",
                          "Name"
                        )}
                      </TableHead>
                      <TableHead>
                        {t(
                          "clients.client.mailinglist.table.headers.email",
                          "Email"
                        )}
                      </TableHead>
                      <TableHead>
                        {t(
                          "clients.client.mailinglist.table.headers.language",
                          "Language"
                        )}
                      </TableHead>
                      <TableHead />
                    </tr>
                  }
                >
                  {respondents.map((respondent) => (
                    <TableRow key={`respondent${respondent.id}`}>
                      <TableData>{respondent.name}</TableData>
                      <TableData>{respondent.email}</TableData>
                      <TableData>
                        {languageLabels[respondent.language.label].flags}{" "}
                        {languageLabels[respondent.language.label].label}
                      </TableData>
                      <TableData>
                        <div className="flex flex-grow w-full justify-end space-x-2">
                          <MinLevel minLevel={userRoleLevels.Manager}>
                            <Button
                              size="sm"
                              color="primary"
                              start={<Icons.Edit className="text-xl" />}
                              onClick={() => {
                                selectRespondent(respondent);
                                setCreateRespondentModalOpen(true);
                              }}
                            >
                              {t(
                                "clients.client.mailinglist.table.labels.edit",
                                "Edit"
                              )}
                            </Button>
                            <Button
                              size="sm"
                              color="error"
                              start={<Icons.Delete className="text-xl" />}
                              onClick={() => {
                                selectRespondent(respondent);
                                setDeleteRespondentModalOpen(true);
                              }}
                            >
                              {t(
                                "clients.client.mailinglist.table.labels.delete",
                                "Delete"
                              )}
                            </Button>
                          </MinLevel>
                        </div>
                      </TableData>
                    </TableRow>
                  ))}
                </Table>
              </>
            ) : (
              <p>
                {t(
                  "clients.client.mailinglist.respondents.no-respondents-found",
                  "No respondents found, create one!"
                )}
              </p>
            )}
          </div>
          <MinLevel minLevel={userRoleLevels.Manager}>
            <form
              className="form-control space-y-2 w-full"
              onSubmit={onSaveMailingList}
            >
              <h3 className="text-3xl font-bold">
                {t(
                  "clients.client.mailinglist.edit-mailinglist-form.title",
                  "Edit mailing list"
                )}
              </h3>
              <Input
                id="mailinglist-title"
                label={t(
                  "clients.client.mailinglist.edit-mailinglist-form.title-label",
                  "Title"
                )}
                placeholder={t(
                  "clients.client.mailinglist.edit-mailinglist-form.title-placeholder",
                  "title"
                )}
                value={mailingListTitle}
                onChange={setMailingListTitle}
              />

              <Button
                onClick={onSaveMailingList}
                color="primary"
                isLoading={updateRespondentListState.loading}
                start={<Icons.Save className="text-xl" />}
              >
                {t(
                  "clients.client.mailinglist.edit-mailinglist-form.save",
                  "Save mailing list"
                )}
              </Button>
              <Button
                onClick={() => setDeleteMailingListModalOpen(true)}
                color="error"
                isLoading={deleteRespondentListState.loading}
                start={<Icons.Delete className="text-xl" />}
              >
                {t(
                  "clients.client.mailinglist.edit-mailinglist-form.delete",
                  "Delete mailing list"
                )}
              </Button>
            </form>
          </MinLevel>
        </div>
      </LoggedInLayout>
      <ActionModal
        title={
          selectedRespondent
            ? t(
                "clients.client.mailinglist.modals.edit-respondent.title",
                "Edit respondent"
              )
            : t(
                "clients.client.mailinglist.modals.create-respondent.title",
                "New respondent"
              )
        }
        modalOpen={createRespondentModalOpen}
        closeModal={() => setCreateRespondentModalOpen(false)}
        actions={
          <Button
            onClick={onSaveRespondent}
            color="primary"
            size="sm"
            isLoading={
              createRespondentState.loading || updateRespondentState.loading
            }
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "clients.client.mailinglist.modals.create-respondent.save",
              "Save respondent"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveRespondent}>
          <Input
            id="respondent-name"
            label={t(
              "clients.client.mailinglist.modals.create-respondent.name",
              "Name"
            )}
            placeholder={t(
              "clients.client.mailinglist.modals.create-respondent.name-placeholder",
              "name"
            )}
            value={respondentName}
            onChange={setRespondentName}
          />
          <Input
            id="respondent-email"
            label={t(
              "clients.client.mailinglist.modals.create-respondent.email",
              "Email"
            )}
            placeholder={t(
              "clients.client.mailinglist.modals.create-respondent.email-placeholder",
              "email"
            )}
            value={respondentEmail}
            onChange={setRespondentEmail}
            hasWarning={!!respondentEmail && !validateEmail(respondentEmail)}
          />
          <Select
            id="respondent-language"
            label={t(
              "clients.client.mailinglist.modals.create-respondent.language-label",
              "Prefered language"
            )}
            value={respondentLanguageId}
            onChange={setRespondentLanguageId}
            options={languages.map((language) => ({
              value: language.id,
              label: `${languageLabels[language.label].flags} ${
                languageLabels[language.label].label
              }`,
            }))}
            placeholderValue={"0"}
            placeholderLabel={t(
              "project.survey.publish.publish-task.response-task.language-placeholder",
              "Select language"
            )}
          />
        </form>
      </ActionModal>
      <ActionModal
        title={t(
          "clients.client.mailinglist.modals.add-csv-respondents.title",
          "Add respondents"
        )}
        modalOpen={csvRespondentModalOpen}
        closeModal={() => setCsvRespondentModalOpen(false)}
        actions={
          <Button
            onClick={onAddCsvRespondents}
            color="primary"
            size="sm"
            isLoading={
              createRespondentState.loading || updateRespondentState.loading
            }
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "clients.client.mailinglist.modals.add-csv-respondents.add-respondents",
              "Add respondents"
            )}
          </Button>
        }
        className="sm:w-11/12 md:w-3/4 2xl:w-7/12 sm:max-w-full"
      >
        <Checkbox
          id="overwrite-respondents"
          label={t(
            "clients.client.mailinglist.modals.add-csv-respondents.overwrite-respondents-label",
            "Overwrite duplicates"
          )}
          checked={overwriteRespondents}
          toggle={() => {
            setOverwriteRespondents(!overwriteRespondents);
          }}
        />
        <form
          className="form-control space-y-2 max-h-96 pt-2"
          onSubmit={onAddCsvRespondents}
        >
          {csvRespondents && csvRespondents.length ? (
            <Table
              head={
                <tr className="h-10 bg-base-200">
                  <TableHead>
                    {t(
                      "clients.client.mailinglist.modals.add-csv-respondents.table.headers.name",
                      "Name"
                    )}
                  </TableHead>
                  <TableHead>
                    {t(
                      "clients.client.mailinglist.modals.add-csv-respondents.table.headers.email",
                      "Email"
                    )}
                  </TableHead>
                  <TableHead>
                    {t(
                      "clients.client.mailinglist.modals.add-csv-respondents.table.headers.language",
                      "Language"
                    )}
                  </TableHead>
                </tr>
              }
            >
              {csvRespondents.map((respondent, index) => (
                <TableRow key={index}>
                  <TableData>
                    <Input
                      id="csv-respondent-name"
                      placeholder={t(
                        "clients.client.mailinglist.modals.create-respondent.name-placeholder",
                        "name"
                      )}
                      value={respondent.name}
                      onChange={(value) => {
                        onChangeCsvRespondent(
                          {
                            ...respondent,
                            name: value,
                          },
                          index
                        );
                      }}
                      className="w-full"
                      hasWarning={!respondent.name}
                    />
                  </TableData>
                  <TableData>
                    <Input
                      id="csv-respondent-email"
                      placeholder={t(
                        "clients.client.mailinglist.modals.create-respondent.email-placeholder",
                        "email"
                      )}
                      value={respondent.email}
                      hasWarning={!validateEmail(respondent.email)}
                      onChange={(value) => {
                        onChangeCsvRespondent(
                          {
                            ...respondent,
                            email: value,
                          },
                          index
                        );
                      }}
                      className="w-full"
                    />
                  </TableData>
                  <TableData>
                    <Select
                      id="csv-respondent-language"
                      value={respondent.languageId}
                      onChange={(value) => {
                        onChangeCsvRespondent(
                          {
                            ...respondent,
                            languageId: value,
                          },
                          index
                        );
                      }}
                      options={languages.map((language) => ({
                        value: language.id,
                        label: `${languageLabels[language.label].flags} ${
                          languageLabels[language.label].label
                        }`,
                      }))}
                      placeholderValue={"0"}
                      placeholderLabel={t(
                        "project.survey.publish.publish-task.response-task.language-placeholder",
                        "Select language"
                      )}
                      className="w-full"
                      hasWarning={
                        !respondent.languageId || respondent.languageId === "0"
                      }
                    />
                  </TableData>
                </TableRow>
              ))}
            </Table>
          ) : (
            t(
              "clients.client.mailinglist.modals.add-csv-respondents.no-respondents",
              "No respondents found"
            )
          )}
        </form>
      </ActionModal>
      <ConfirmModal
        toConfirmType={t(
          "clients.client.mailinglist.modals.delete-mailinglist.type",
          "mailing list"
        )}
        toConfirmLabel={mailingList ? mailingList.label : ""}
        modalOpen={deleteMailingListModalOpen}
        closeModal={() => setDeleteMailingListModalOpen(false)}
        onConfirm={onDeleteMailingList}
        action="delete"
        isLoading={deleteRespondentListState.loading}
      />
      <ConfirmModal
        toConfirmType={t(
          "clients.client.mailinglist.modals.delete-respondent.type",
          "respondent"
        )}
        toConfirmLabel={selectedRespondent ? selectedRespondent.name : ""}
        modalOpen={deleteRespondentModalOpen}
        closeModal={() => setDeleteRespondentModalOpen(false)}
        onConfirm={onDeleteRespondent}
        action="delete"
        isLoading={deleteRespondentState.loading}
      />
    </>
  );
}
