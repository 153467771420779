import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import calls from "../../graphql";
import toast from "react-hot-toast";
import {
  LoggedInLayout,
  Breadcrumbs,
  Input,
  TextArea,
  Select,
  Button,
  Icons,
  ConfirmModal,
} from "../../components";
const { GETPROJECT, GETCLIENTS, GETUSERS, UPDATEPROJECT, DELETEPROJECT } =
  calls;

export default function Settings() {
  // Hooks
  const { t } = useTranslation();
  const { projectId } = useParams();
  const history = useHistory();
  // State
  const [project, setProject] = useState(undefined);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectClientId, setProjectClientId] = useState("0");
  const [projectAssignedToId, setProjectAssignedToId] = useState("0");
  // Queries
  const getProjectState = useQuery(GETPROJECT, {
    variables: {
      id: projectId,
    },
  });
  const getClientsState = useQuery(GETCLIENTS);
  const getUsersState = useQuery(GETUSERS);
  // Mutations
  const [updateProject, updateProjectState] = useMutation(UPDATEPROJECT, {
    refetchQueries: [GETPROJECT],
  });
  const [deleteProject, deleteProjectState] = useMutation(DELETEPROJECT);
  // Handle errors
  if (getProjectState.error) toast.error(getProjectState.error.message);
  if (getClientsState.error) toast.error(getClientsState.error.message);
  if (getUsersState.error) toast.error(getUsersState.error.message);
  // Effects
  useEffect(() => {
    if (getProjectState.data) {
      const project = getProjectState.data.project;
      setProject(project);
      setProjectTitle(project.label);
      setProjectDescription(project.description);
      setProjectClientId(project.client.id);
      setProjectAssignedToId(project.assignedTo.id);
    }
  }, [getProjectState.data]);
  useEffect(() => {
    if (getClientsState.data) {
      const clients = getClientsState.data.clients;
      setClients(clients);
    }
  }, [getClientsState.data]);
  useEffect(() => {
    if (getUsersState.data) {
      const users = getUsersState.data.users;
      setUsers(users);
    }
  }, [getUsersState.data]);

  const onSaveProject = async () => {
    if (
      projectTitle &&
      projectClientId &&
      projectClientId !== "0" &&
      projectAssignedToId &&
      projectAssignedToId !== "0"
    ) {
      try {
        await updateProject({
          variables: {
            id: projectId,
            label: projectTitle,
            description: projectDescription,
            clientId: projectClientId,
            assignedToId: projectAssignedToId,
          },
        });
        toast.success(
          t("project.settings.messages.success.saved-project", "Project saved!")
        );
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.custom(
        t(
          "project.settings.messages.warning.complete-fields",
          "Please complete all fields"
        )
      );
    }
  };

  const onDeleteProject = async () => {
    try {
      await deleteProject({
        variables: {
          id: projectId,
        },
      });
      toast.success(
        t(
          "project.settings.messages.success.deleted-project",
          "Project deleted!"
        )
      );
      history.replace("/projects");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: `/projects`,
                label: t("project.settings.breadcrumbs.project", "Projects"),
              },
              {
                link: `/projects/${projectId}/settings`,
                label: t("project.settings.breadcrumbs.settings", "Settings"),
              },
            ]}
            back="/projects"
          />
        }
        actions={
          <Link
            to={`/projects/${projectId}/surveys`}
            className="text-2xl xl:text-3xl font-bold text-gray-500"
          >
            {t("project.settings.labels.surveys", "Surveys")}
          </Link>
        }
        isLoading={
          getProjectState.loading ||
          getClientsState.loading ||
          getUsersState.loading
        }
      >
        <div className="w-3/4 self-center flex flex-col space-y-5">
          <h1 className="text-2xl md:text-3xl font-bold">
            {project
              ? project.label
              : t("project.settings.labels.project", "Project")}
          </h1>
          <div className="w-full flex flex-col space-y-2">
            <h3 className="text-2xl font-bold">
              {t("project.settings.labels.settings", "Settings")}
            </h3>
            <Input
              id="project-title"
              label={t("project.settings.edit-project.title-label", "Title")}
              placeholder={t(
                "project.settings.edit-project.title-placeholder",
                "title"
              )}
              value={projectTitle}
              onChange={setProjectTitle}
            />
            <TextArea
              id="description"
              label={t(
                "project.settings.edit-project.description-label",
                "Description"
              )}
              placeholder={t(
                "project.settings.edit-project.description-placeholder",
                "description"
              )}
              value={projectDescription}
              onChange={setProjectDescription}
            />
            <Select
              id="client"
              label={t("project.settings.edit-project.client-label", "Client")}
              value={projectClientId}
              onChange={setProjectClientId}
              options={clients.map((client) => ({
                value: client.id,
                label: client.name,
              }))}
              placeholderValue="0"
              placeholderLabel={t(
                "project.settings.edit-project.client-placeholder",
                "Select Client"
              )}
            />
            <Select
              id="user"
              label={t(
                "project.settings.edit-project.user-label",
                "Assigned to"
              )}
              value={projectAssignedToId}
              onChange={setProjectAssignedToId}
              options={users.map((user) => ({
                value: user.id,
                label: user.userName,
              }))}
              placeholderValue="0"
              placeholderLabel={t(
                "project.settings.edit-project.user-placeholder",
                "Select User"
              )}
            />
            <Button
              onClick={onSaveProject}
              isLoading={updateProjectState.loading}
              size="sm"
              color="primary"
              start={<Icons.Save className="text-xl" />}
            >
              {t("project.settings.edit-project.save-project", "Save project")}
            </Button>
            <Button
              onClick={() => setDeleteProjectModalOpen(true)}
              isLoading={deleteProjectState.loading}
              size="sm"
              color="error"
              start={<Icons.Delete className="text-xl" />}
            >
              {t(
                "project.settings.edit-project.delete-project",
                "Delete project"
              )}
            </Button>
          </div>
        </div>
      </LoggedInLayout>
      <ConfirmModal
        toConfirmType={t(
          "project.settings.modals.delete-project.type",
          "project"
        )}
        toConfirmLabel={project ? project.label : ""}
        modalOpen={deleteProjectModalOpen}
        closeModal={() => setDeleteProjectModalOpen(false)}
        onConfirm={onDeleteProject}
        action="delete"
        isLoading={deleteProjectState.loading}
        warning={t(
          "project.settings.modals.delete-project.warning",
          "This operation will also delete all surveys related to this project!"
        )}
      />
    </>
  );
}
