import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { ReportBuilderPage, Breadcrumbs, MinLevel } from "../../../components";
import { userRoleLevels } from "../../../utils";

export default function Report() {
  const { t } = useTranslation();
  const { surveyId, reportStructureId } = useParams();

  return (
    <ReportBuilderPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t("templates.report.breadcrumbs.templates", "Templates"),
              link: `/templates`,
            },
            {
              label: t("templates.report.breadcrumbs.dashboard", "Dashboard"),
              link: `/templates/${surveyId}/dashboard`,
            },
            {
              label: t("templates.report.breadcrumbs.reports", "Reports"),
              link: `/templates/${surveyId}/reportstructures`,
            },
            {
              label: t("templates.report.breadcrumbs.report", "Report"),
              link: `/templates/${surveyId}/reportstructures/${reportStructureId}/reportbuilder`,
            },
          ]}
          back={`/projects/${surveyId}/reportstructures`}
        />
      }
      actions={
        <MinLevel minLevel={userRoleLevels.Manager}>
          <Link
            className="text-2xl xl:text-3xl font-bold text-gray-500"
            to={`/templates/${surveyId}/reportstructures/${reportStructureId}/settings`}
          >
            {t("project.survey.reports.labels.report-settings", "Settings")}
          </Link>
        </MinLevel>
      }
    />
  );
}
