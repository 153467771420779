import { gql } from "@apollo/client";

const CREATEQUESTIONTRANSLATION = gql`
  mutation CreateQuestionTranslation(
    $label: String!
    $description: String
    $questionId: ID!
    $languageId: ID!
  ) {
    createQuestionTranslation(
      questionTranslation: {
        label: $label
        description: $description
        questionId: $questionId
        languageId: $languageId
      }
    ) {
      id
    }
  }
`;

const UPDATEQUESTIONTRANSLATION = gql`
  mutation UpdateQuestionTranslation(
    $id: ID!
    $label: String
    $description: String
    $questionId: ID
    $languageId: ID
  ) {
    updateQuestionTranslation(
      id: $id
      questionTranslation: {
        label: $label
        description: $description
        questionId: $questionId
        languageId: $languageId
      }
    ) {
      id
    }
  }
`;

const DELETEQUESTIONTRANSLATION = gql`
  mutation DeleteQuestionTranslation($id: ID!) {
    deleteQuestionTranslation(id: $id)
  }
`;

const GETQUESTIONTRANSLATIONS = gql`
  query GetQuestionTranslations {
    questionTranslations {
      id
      label
      description
      createdAt
      updatedAt
      language {
        id
        label
      }
    }
  }
`;

const GETQUESTIONTRANSLATION = gql`
  query GetQuestionTranslation($id: ID!) {
    questionTranslation(id: $id) {
      id
      label
      description
      createdAt
      updatedAt
      language {
        id
        label
      }
    }
  }
`;

const calls = {
  CREATEQUESTIONTRANSLATION,
  UPDATEQUESTIONTRANSLATION,
  DELETEQUESTIONTRANSLATION,
  GETQUESTIONTRANSLATIONS,
  GETQUESTIONTRANSLATION,
};

export default calls;
