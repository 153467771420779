import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import fuzzysort from "fuzzysort";
import calls from "../../graphql";
import toast from "react-hot-toast";
import {
  LoggedInLayout,
  Breadcrumbs,
  Button,
  Icons,
  ActionModal,
  Status,
  Table,
  TableData,
  TableHead,
  TableRow,
  Input,
  MinLevel,
  Checkbox,
  Select,
} from "../../components";
import { formatDate, useLanguageLabels, userRoleLevels } from "../../utils";
const { GETPROJECTSURVEYS, CREATESURVEY, GETSURVEYTEMPLATES, GETLANGUAGES } =
  calls;

export default function Surveys() {
  // Hooks
  const { t } = useTranslation();
  const { projectId } = useParams();
  const languageLabels = useLanguageLabels();
  const history = useHistory();
  // State
  const [languages, setLanguages] = useState([]);
  const [surveyModalOpen, setSurveyModalOpen] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [surveyLanguageIds, setSurveyLanguageIds] = useState([]);
  const [search, setSearch] = useState("");
  const [project, setProject] = useState(undefined);
  const [surveys, setSurveys] = useState([]);
  const [useTemplate, setUseTemplate] = useState(false);
  const [surveyTemplates, setSurveyTemplates] = useState([]);
  const [surveyTemplateId, setSurveyTemplateId] = useState("0");
  // Queries
  const getProjectSurveysState = useQuery(GETPROJECTSURVEYS, {
    variables: {
      projectId,
    },
    fetchPolicy: "cache-and-network",
  });
  const getSurveyTemplatesState = useQuery(GETSURVEYTEMPLATES, {
    fetchPolicy: "cache-and-network",
  });
  const getLanguagesState = useQuery(GETLANGUAGES);
  // Mutations
  const [createSurvey, createSurveyState] = useMutation(CREATESURVEY, {
    refetchQueries: [GETPROJECTSURVEYS],
  });
  // Handle errors
  if (getSurveyTemplatesState.error)
    toast.error(getSurveyTemplatesState.error.message);
  if (getProjectSurveysState.error)
    toast.error(getProjectSurveysState.error.message);
  if (getLanguagesState.error) toast.error(getLanguagesState.error.message);
  // Effects
  useEffect(() => {
    if (getProjectSurveysState.data) {
      const project = getProjectSurveysState.data.project;
      setProject(project);
    }
  }, [getProjectSurveysState.data]);
  useEffect(() => {
    const surveys =
      getProjectSurveysState.data && getProjectSurveysState.data.project.surveys
        ? getProjectSurveysState.data.project.surveys
        : [];
    if (search) {
      const delayedSearch = setTimeout(() => {
        const results = fuzzysort.go(search, surveys, {
          keys: ["label", "description", "client.name", "createdBy.userName"],
        });
        setSurveys(results.map((result) => result.obj));
      }, 200);
      return () => clearTimeout(delayedSearch);
    } else {
      setSurveys(surveys);
    }
  }, [search, getProjectSurveysState.data]);
  useEffect(() => {
    if (
      getSurveyTemplatesState.data &&
      getSurveyTemplatesState.data.surveyTemplates
    ) {
      setSurveyTemplates(getSurveyTemplatesState.data.surveyTemplates);
    }
  }, [getSurveyTemplatesState.data]);
  useEffect(() => {
    if (getLanguagesState.data && getLanguagesState.data.languages) {
      const languages = getLanguagesState.data.languages.filter(
        (language) => !!languageLabels[language.label]
      );
      setLanguages(languages);
    }
  }, [getLanguagesState]);

  const resetState = () => {
    setSurveyModalOpen(false);
    setSurveyTitle("");
    setSurveyDescription("");
    setUseTemplate(false);
    setSurveyTemplateId("0");
  };

  const toggleLanguage = (id) => {
    let ids = [...surveyLanguageIds];
    if (ids.includes(id)) {
      ids = ids.filter((lId) => lId !== id);
    } else {
      ids = [...ids, id];
    }
    setSurveyLanguageIds(ids);
  };

  const onSaveSurvey = async () => {
    if (surveyTitle) {
      if (useTemplate || surveyLanguageIds.length) {
        try {
          const createSurveyResult = await createSurvey({
            variables: {
              label: surveyTitle,
              description: surveyDescription,
              isTemplate: false,
              projectId: projectId,
              derivedFromId:
                surveyTemplateId && surveyTemplateId !== "0"
                  ? surveyTemplateId
                  : undefined,
              languageIds: useTemplate ? [] : surveyLanguageIds,
            },
          });
          if (createSurveyResult.data && createSurveyResult.data.createSurvey) {
            const id = createSurveyResult.data.createSurvey.id;
            resetState();
            toast.success(
              t(
                "project.surveys.messages.success.create-survey",
                "Survey created!"
              )
            );
            history.replace(`/projects/${projectId}/surveys/${id}/dashboard`);
          }
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        toast.custom(
          t(
            "project.surveys.messages.warning.select-one-language",
            "You should select at least one language"
          )
        );
      }
    } else {
      toast.custom(
        t(
          "project.surveys.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: `/projects`,
                label: t("project.surveys.breadcrumbs.project", "Projects"),
              },
              {
                link: `/projects/${projectId}/surveys`,
                label: t("project.surveys.breadcrumbs.surveys", "Surveys"),
              },
            ]}
            back="/projects"
          />
        }
        actions={
          <MinLevel minLevel={userRoleLevels.Manager}>
            <Link
              to={`/projects/${projectId}/settings`}
              className="text-2xl xl:text-3xl font-bold text-gray-500"
            >
              {t("project.surveys.labels.settings", "Settings")}
            </Link>
          </MinLevel>
        }
        isLoading={getProjectSurveysState.loading}
      >
        <h1 className="text-2xl md:text-3xl font-bold">
          {project
            ? project.label
            : t("project.survey.labels.project", "Project")}
        </h1>
        {search || (surveys && surveys.length) ? (
          <>
            <div className="flex items-center">
              <div className="flex-grow">
                <Input
                  id="search"
                  placeholder={t("project.surveys.labels.search", "Search")}
                  value={search}
                  onChange={setSearch}
                  className="w-4/5"
                />
              </div>
              <MinLevel minLevel={userRoleLevels.Manager}>
                <Button
                  start={<Icons.Add className="text-xl" />}
                  isLoading={createSurveyState.loading}
                  onClick={() => setSurveyModalOpen(true)}
                >
                  {t("project.surveys.labels.new-survey", "New Survey")}
                </Button>
              </MinLevel>
            </div>
            <Table
              head={
                <tr className="h-10">
                  <TableHead>
                    {t("project.surveys.table.headers.survey", "Survey")}
                  </TableHead>
                  <TableHead>
                    {t("project.surveys.table.headers.updated", "Updated")}
                  </TableHead>
                  <TableHead>
                    {t("project.surveys.table.headers.status", "Status")}
                  </TableHead>
                  <TableHead />
                </tr>
              }
            >
              {surveys.map((survey) => (
                <TableRow key={survey.id}>
                  <TableData>
                    <div>
                      <div className="font-bold">{survey.label}</div>
                      <div className="font-normal">{survey.description}</div>
                    </div>
                  </TableData>
                  <TableData>{formatDate(survey.updatedAt)}</TableData>
                  <TableData>
                    <Status active={!survey.isArchived} />
                  </TableData>
                  <TableData>
                    <Link
                      to={`/projects/${projectId}/surveys/${survey.id}/dashboard`}
                      className="flex justify-end"
                    >
                      <Icons.Next className="text-4xl" />
                    </Link>
                  </TableData>
                </TableRow>
              ))}
            </Table>
          </>
        ) : (
          <div className="flex items-center">
            <div className="text-3xl flex-grow">
              {t(
                "project.surveys.messages.warning.no-surveys",
                "No surveys found, create one!"
              )}
            </div>
            <MinLevel minLevel={userRoleLevels.Manager}>
              <Button
                start={<Icons.Add className="text-xl" />}
                isLoading={createSurveyState.loading}
                onClick={() => setSurveyModalOpen(true)}
              >
                {t("project.surveys.labels.new-survey", "New Survey")}
              </Button>
            </MinLevel>
          </div>
        )}
      </LoggedInLayout>
      <ActionModal
        title={t("project.surveys.modals.create-survey.title", "New Survey")}
        modalOpen={surveyModalOpen}
        closeModal={() => setSurveyModalOpen(false)}
        actions={
          <Button
            onClick={onSaveSurvey}
            isLoading={createSurveyState.loading}
            size="sm"
            color="primary"
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "project.surveys.modals.create-survey.save-survey",
              "Save Survey"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveSurvey}>
          <Input
            id="survey-title"
            label={t(
              "project.surveys.modals.create-survey.title-label",
              "Title"
            )}
            placeholder={t(
              "project.surveys.modals.create-survey.title-placeholder",
              "title"
            )}
            value={surveyTitle}
            onChange={setSurveyTitle}
          />
          <Input
            id="survey-description"
            label={t(
              "project.surveys.modals.create-survey.description-label",
              "Description"
            )}
            placeholder={t(
              "project.surveys.modals.create-survey.description-placeholder",
              "description"
            )}
            value={surveyDescription}
            onChange={setSurveyDescription}
          />
          <Checkbox
            id="use-survey-template"
            label={t(
              "project.surveys.modals.create-survey.use-template-label",
              "Use template"
            )}
            checked={useTemplate}
            toggle={() => {
              setSurveyTemplateId("0");
              setUseTemplate(!useTemplate);
            }}
          />
          {useTemplate ? (
            <Select
              id="survey-template"
              label={t(
                "project.surveys.modals.create-survey.survey-template-label",
                "Survey template"
              )}
              value={surveyTemplateId}
              onChange={setSurveyTemplateId}
              options={surveyTemplates.map((template) => ({
                value: template.id,
                label: template.label,
              }))}
              placeholderValue={"0"}
              placeholderLabel={t(
                "project.surveys.modals.create-survey.survey-template-placeholder",
                "Select template"
              )}
            />
          ) : (
            <>
              <label>
                {t(
                  "project.surveys.modals.create-survey.languages-label",
                  "Languages"
                )}
              </label>
              {languages.map((language) => (
                <Checkbox
                  key={`survey-language-${language.id}`}
                  id={`survey-language-${language.id}`}
                  label={`${languageLabels[language.label].flags} ${
                    languageLabels[language.label].label
                  }`}
                  checked={surveyLanguageIds.includes(language.id)}
                  toggle={() => {
                    toggleLanguage(language.id);
                  }}
                />
              ))}
            </>
          )}
        </form>
      </ActionModal>
    </>
  );
}
