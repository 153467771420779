import { gql } from "@apollo/client";

const CREATERESPONSEOPTION = gql`
  mutation CreateResponseOption(
    $value: String!
    $order: Int!
    $questionId: ID!
  ) {
    createResponseOption(
      responseOption: { value: $value, order: $order, questionId: $questionId }
    ) {
      id
    }
  }
`;

const UPDATERESPONSEOPTION = gql`
  mutation UpdateResponseOption(
    $id: ID!
    $value: String
    $order: Int!
    $questionId: ID
  ) {
    updateResponseOption(
      id: $id
      responseOption: { value: $value, order: $order, questionId: $questionId }
    ) {
      id
    }
  }
`;

const DELETERESPONSEOPTION = gql`
  mutation DeleteResponseOption($id: ID!) {
    deleteResponseOption(id: $id)
  }
`;

const GETRESPONSEOPTIONS = gql`
  query GetResponseOptions {
    responseOptions {
      id
      value
      translations {
        id
        label
        description
        language {
          id
          label
        }
      }
    }
  }
`;

const GETRESPONSEOPTION = gql`
  query GetResponseOption($id: ID!) {
    responseOption(id: $id) {
      id
      value
      translations {
        id
        label
        description
        language {
          id
          label
        }
      }
    }
  }
`;

const calls = {
  CREATERESPONSEOPTION,
  UPDATERESPONSEOPTION,
  DELETERESPONSEOPTION,
  GETRESPONSEOPTIONS,
  GETRESPONSEOPTION,
};

export default calls;
