import { useMutation } from "@apollo/client";
import React, { useState, useContext } from "react";
import calls from "../graphql";
import { AppStateContext } from "../components/functional/Provider";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, LoginLayout, Icons, Input } from "../components";
import { validateEmail } from "../utils";
import hero from "../assets/images/login-hero.jpg";
import toast from "react-hot-toast";

const { LOGIN } = calls;

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { loading }] = useMutation(LOGIN);
  const { appSetLogin } = useContext(AppStateContext);
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await login({
        variables: {
          email,
          password,
        },
      });
      if (!data || !data.login || !data.login.token || !data.login.userId)
        throw new Error("Invalid credentials");
      appSetLogin(data.login.token, data.login.userId, data.login.userLevel);
      history.replace("/projects");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <LoginLayout hero={hero}>
      <h1 className="text-5xl xl:text-6xl font-bold">
        {t("login.labels.title", "Automate your")}
      </h1>
      <h1 className="text-primary text-5xl xl:text-6xl font-bold mb-5">
        {t("login.labels.subtitle", "online surveys")}
      </h1>
      <form className="form-control space-y-2" onSubmit={onSubmit}>
        <Input
          id="email"
          placeholder={t("login.form.email-placeholder", "email address")}
          value={email}
          hasWarning={!validateEmail(email) && !!email}
          onChange={setEmail}
        />
        <Input
          id="password"
          placeholder={t("login.form.password-placeholder", "password")}
          value={password}
          onChange={setPassword}
          isPassword={true}
        />
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <Button
            onClick={onSubmit}
            start={<Icons.Login className="text-xl" />}
            className="flex-grow"
            isLoading={loading}
          >
            {t("login.labels.login", "Log in")}
          </Button>
          <Button
            isLink={true}
            to="/register"
            color="neutral"
            start={<Icons.Register className="text-xl" />}
            className="flex-grow"
          >
            {t("login.labels.signup", "Sign up")}
          </Button>
        </div>
      </form>
    </LoginLayout>
  );
}
