import React, { useState, useEffect } from "react";
import fuzzysort from "fuzzysort";
import { useMutation, useQuery } from "@apollo/client";
import { HexColorInput, HexColorPicker } from "react-colorful";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import calls from "../../../graphql";
import {
  ActionModal,
  ConfirmModal,
  Input,
  Select,
  Icons,
  Button,
  Pill,
  Checkbox,
} from "../..";
const {
  CREATEQUESTIONCATEGORY,
  UPDATEQUESTIONCATEGORY,
  DELETEQUESTIONCATEGORY,
  GETQUESTIONCATEGORIES,
  CREATEQUESTIONTAG,
  UPDATEQUESTIONTAG,
  DELETEQUESTIONTAG,
} = calls;

const themeColor = "#000F9F";

/**
 * Question category settings
 * @returns {node} React component
 */
export default function QuestionCategorySettings() {
  // Hooks
  const { t } = useTranslation();
  // State
  const [categories, setCategories] = useState([]);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryColor, setCategoryColor] = useState(themeColor);
  const [tagTitle, setTagTitle] = useState("");
  const [tagCategoryId, setTagCategoryId] = useState(undefined);
  const [categoryIsDemographic, setCategoryIsDemographic] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [deleteTagModalOpen, setDeleteTagModalOpen] = useState(false);
  // Queries
  const getCategoriesState = useQuery(GETQUESTIONCATEGORIES);
  // Mutations
  const [createCategory, createCategoryState] = useMutation(
    CREATEQUESTIONCATEGORY,
    {
      refetchQueries: [GETQUESTIONCATEGORIES],
    }
  );
  const [updateCategory, updateCategoryState] = useMutation(
    UPDATEQUESTIONCATEGORY,
    {
      refetchQueries: [GETQUESTIONCATEGORIES],
    }
  );
  const [deleteCategory, deleteCategoryState] = useMutation(
    DELETEQUESTIONCATEGORY,
    {
      refetchQueries: [GETQUESTIONCATEGORIES],
    }
  );
  const [createTag, createTagState] = useMutation(CREATEQUESTIONTAG, {
    refetchQueries: [GETQUESTIONCATEGORIES],
  });
  const [updateTag, updateTagState] = useMutation(UPDATEQUESTIONTAG, {
    refetchQueries: [GETQUESTIONCATEGORIES],
  });
  const [deleteTag, deleteTagState] = useMutation(DELETEQUESTIONTAG, {
    refetchQueries: [GETQUESTIONCATEGORIES],
  });
  // Toast errors
  if (getCategoriesState.error) toast.error(getCategoriesState.error.message);
  // Effects
  useEffect(() => {
    if (getCategoriesState.data) {
      const questionCategories = getCategoriesState.data.questionCategories;
      setCategories(questionCategories);
      if (!selectedCategory) {
        setSelectedCategory(questionCategories[0]);
      }
    }
  }, [getCategoriesState.data]);

  const resetFields = () => {
    setCategoryTitle("");
    setCategoryColor(themeColor);
    setCategoryIsDemographic(false);
    setTagTitle("");
    setTagCategoryId(undefined);
    setCategoryModalOpen(false);
    setTagModalOpen(false);
    setDeleteTagModalOpen(false);
    setDeleteCategoryModalOpen(false);
  };

  const onSaveCategory = async () => {
    if (categoryTitle) {
      if (selectedCategory && selectedCategory.id) {
        // Update category
        try {
          await updateCategory({
            variables: {
              id: selectedCategory.id,
              label: categoryTitle,
              isDemographic: categoryIsDemographic,
              color: categoryColor,
            },
          });
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        // New category
        try {
          await createCategory({
            variables: {
              label: categoryTitle,
              isDemographic: categoryIsDemographic,
              color: categoryColor,
            },
          });
        } catch (error) {
          toast.error(error.message);
        }
      }
      resetFields();
      toast.success(
        t(
          "components.question-categories.messages.success.save-category",
          "Category saved!"
        )
      );
    } else {
      toast.custom(
        t(
          "components.question-categories.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  const onDeleteCategory = async () => {
    if (selectedCategory && selectedCategory.id) {
      try {
        await deleteCategory({
          variables: {
            id: selectedCategory.id,
          },
        });
        resetFields();
        toast.success(
          t(
            "components.question-categories.messages.success.delete-category",
            "Category deleted!"
          )
        );
        setSelectedCategory(undefined);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Computer says no!");
    }
  };

  const onSaveTag = async () => {
    if (tagTitle && tagCategoryId) {
      try {
        const category = categories.find(
          (category) => category.id === tagCategoryId
        );

        if (category) {
          if (selectedTag) {
            await updateTag({
              variables: {
                id: selectedTag.id,
                label: tagTitle,
                questionCategoryId: tagCategoryId,
              },
            });
            toast.success(
              t(
                "components.question-categories.messages.success.save-tag",
                "Tag saved!"
              )
            );
            setTagModalOpen(false);
          } else {
            let createNewTag = false;

            // Check if similar tag already exists using fuzzy matching
            const results = fuzzysort.go(tagTitle, category.tags, {
              key: "label",
            });
            if (results && results.length) {
              if (
                window.confirm(
                  t(
                    "components.question-categories.messages.warning.similar-tag",
                    "A similar tag exists, are you sure you want to create a new one?"
                  ) +
                    "\n" +
                    tagTitle +
                    " ~ " +
                    results[0].target
                )
              ) {
                createNewTag = true;
              }
            } else {
              createNewTag = true;
            }

            if (createNewTag) {
              await createTag({
                variables: {
                  label: tagTitle,
                  questionCategoryId: tagCategoryId,
                },
              });
              resetFields();
              toast.success(
                t(
                  "components.question-categories.messages.success.save-tag",
                  "Tag saved!"
                )
              );
            }
          }
        } else {
          throw new Error(
            t(
              "components.question-categories.messages.error.category-not-found",
              "No category seems to be selected"
            )
          );
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.custom(
        t(
          "components.question-categories.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  const onDeleteTag = async () => {
    if (selectedTag && selectedTag.id) {
      try {
        await deleteTag({
          variables: {
            id: selectedTag.id,
          },
        });
        resetFields();
        toast.success(
          t(
            "components.question-categories.messages.success.delete-tag",
            "Tag deleted!"
          )
        );
        setSelectedTag(undefined);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error(
        t(
          "components.question-categories.messages.error.no-selected-tag",
          "No tag seems to be selected."
        )
      );
    }
  };

  return (
    <>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center">
          <div className="text-xl flex-grow">
            {t(
              "components.question-categories.labels.title",
              "Question Categories & Tags"
            )}
          </div>
          <Button
            color="primary"
            start={<Icons.Add className="text-xl" />}
            onClick={() => {
              setSelectedCategory(undefined);
              setCategoryModalOpen(true);
            }}
          >
            {t(
              "components.question-categories.labels.add-category",
              "Add category"
            )}
          </Button>
        </div>
        <div className="">
          <p className="pb-0">
            {t(
              "components.question-categories.labels.category-explanation",
              "Questions can be grouped by assigning a category."
            )}
          </p>
          <p>
            {t(
              "components.question-categories.labels.tags-explanation",
              "Each category can be further specified by means of tags."
            )}
          </p>
        </div>
        <div className="flex-flex-col border rounded-box border-base-300">
          {categories && categories.length
            ? categories.map((category, index) => (
                <div
                  key={`category${category.id}`}
                  tabIndex={index}
                  className={`collapse bg-base-100 ${
                    selectedCategory && category.id === selectedCategory.id
                      ? "collapse-open"
                      : "collapse-close"
                  } ${index === 0 ? "rounded-t-box" : ""} ${
                    index === categories.length - 1 ? "rounded-b-box" : ""
                  }`}
                >
                  <div className="collapse-title w-full flex flex-row items-center">
                    <div className="flex-grow">{category.label}</div>
                    {selectedCategory && selectedCategory.id === category.id ? (
                      <Button
                        color="ghost"
                        size="sm"
                        onClick={() => setSelectedCategory(undefined)}
                      >
                        <Icons.Fold className="text-xl" />
                      </Button>
                    ) : (
                      <Button
                        color="ghost"
                        size="sm"
                        onClick={() => setSelectedCategory(category)}
                      >
                        <Icons.Unfold className="text-xl" />
                      </Button>
                    )}
                  </div>
                  <div className="collapse-content">
                    <div className="flex flex-row flex-wrap items-center mb-2">
                      {category.tags && category.tags.length
                        ? [...category.tags]
                            .sort((a, b) => a.label > b.label)
                            .map((tag) => (
                              <Pill
                                key={`tag${tag.id}`}
                                onDelete={() => {
                                  setSelectedTag(tag);
                                  setDeleteTagModalOpen(true);
                                }}
                                className="mr-1 mb-1"
                                style={{ backgroundColor: category.color }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    resetFields();
                                    setSelectedTag(tag);
                                    setTagTitle(tag.label);
                                    setTagCategoryId(category.id);
                                    setTagModalOpen(true);
                                  }}
                                >
                                  {tag.label}
                                </button>
                              </Pill>
                            ))
                        : undefined}
                    </div>

                    <div className="flex flex-col items-start md:flex-row">
                      <div className="flex-grow">
                        <Button
                          color="primary"
                          size="sm"
                          start={<Icons.Add className="text-xl" />}
                          onClick={() => {
                            resetFields();
                            setTagCategoryId(category.id);
                            setTagModalOpen(true);
                          }}
                        >
                          {t(
                            "components.question-categories.labels.add-tag",
                            "Add tag"
                          )}
                        </Button>
                      </div>
                      <div className="flex flex-col md:flex-row md:space-x-2">
                        <Button
                          color="warning"
                          size="sm"
                          start={<Icons.Edit className="text-xl" />}
                          onClick={() => {
                            setCategoryTitle(category.label);
                            setCategoryIsDemographic(category.isDemographic);
                            setCategoryColor(
                              category.color ? category.color : themeColor
                            );
                            setCategoryModalOpen(true);
                          }}
                        >
                          {t(
                            "components.question-categories.labels.edit-category",
                            "Edit category"
                          )}
                        </Button>
                        <Button
                          color="error"
                          size="sm"
                          start={<Icons.Delete className="text-xl" />}
                          onClick={() => setDeleteCategoryModalOpen(true)}
                        >
                          {t(
                            "components.question-categories.labels.delete-category",
                            "Delete category"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : undefined}
        </div>
      </div>
      <ActionModal
        title={
          selectedCategory
            ? t(
                "components.question-categories.modals.edit-category.title",
                "Edit Category"
              )
            : t(
                "components.question-categories.modals.create-category.title",
                "New Category"
              )
        }
        modalOpen={categoryModalOpen}
        closeModal={() => {
          resetFields();
        }}
        actions={
          <Button
            onClick={onSaveCategory}
            color="primary"
            size="sm"
            isLoading={
              createCategoryState.loading || updateCategoryState.loading
            }
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "components.question-categories.modals.create-category.save",
              "Save Category"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveCategory}>
          <Input
            id="category-title"
            label={t(
              "components.question-categories.modals.create-category.title-label",
              "Title"
            )}
            placeholder={t(
              "components.question-categories.modals.create-category.title-placeholder",
              "title"
            )}
            value={categoryTitle}
            onChange={setCategoryTitle}
          />
          <Checkbox
            id="isDemographic"
            label={t(
              "components.question-categories.modals.create-category.is-demographic-label",
              "Demographic category"
            )}
            checked={categoryIsDemographic}
            toggle={() => setCategoryIsDemographic(!categoryIsDemographic)}
          />
          <label>
            {t(
              "components.question-categories.modals.create-category.color-label",
              "Color"
            )}
          </label>
          <HexColorInput
            color={categoryColor}
            onChange={setCategoryColor}
            prefixed={true}
            className="input input-bordered"
          />
          <HexColorPicker color={categoryColor} onChange={setCategoryColor} />
        </form>
      </ActionModal>
      <ActionModal
        title={
          selectedTag
            ? t(
                "components.question-categories.modals.edit-tag.title",
                "Edit Tag"
              )
            : t(
                "components.question-categories.modals.create-tag.title",
                "New Tag"
              )
        }
        modalOpen={tagModalOpen}
        closeModal={() => setTagModalOpen(false)}
        actions={
          <Button
            onClick={onSaveTag}
            color="primary"
            size="sm"
            isLoading={createTagState.loading || updateTagState.loading}
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "components.question-categories.modals.create-tag.save-tag",
              "Save Tag"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveTag}>
          <Input
            id="tag-title"
            label={t(
              "components.question-categories.modals.create-tag.title-label",
              "Title"
            )}
            placeholder={t(
              "components.question-categories.modals.create-tag.title-placeholder",
              "title"
            )}
            value={tagTitle}
            onChange={setTagTitle}
          />
          <Select
            id="category"
            label={t(
              "components.question-categories.modals.create-tag.category-label",
              "Category"
            )}
            value={tagCategoryId}
            onChange={setTagCategoryId}
            options={categories.map((category) => ({
              value: category.id,
              label: category.label,
            }))}
          />
        </form>
      </ActionModal>
      <ConfirmModal
        toConfirmType={t(
          "components.question-categories.modals.delete-category.type",
          "category"
        )}
        toConfirmLabel={selectedCategory ? selectedCategory.label : ""}
        modalOpen={deleteCategoryModalOpen}
        closeModal={() => {
          setSelectedCategory(undefined);
          setDeleteCategoryModalOpen(false);
        }}
        onConfirm={onDeleteCategory}
        action="delete"
        isLoading={deleteCategoryState.loading}
      />
      <ConfirmModal
        toConfirmType={t(
          "components.question-categories.modals.delete-tag.type",
          "tag"
        )}
        toConfirmLabel={selectedTag ? selectedTag.label : ""}
        modalOpen={deleteTagModalOpen}
        closeModal={() => {
          setSelectedTag(undefined);
          setDeleteTagModalOpen(false);
        }}
        onConfirm={onDeleteTag}
        action="delete"
        isLoading={deleteTagState.loading}
      />
    </>
  );
}
