import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, QuestionPage } from "../../../components";

export default function Question() {
  // Hooks
  const { t } = useTranslation();
  const { surveyId, questionId } = useParams();
  // State
  const [sectionId, setSectionId] = useState("");
  // Effects
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sectionIdParam = query.get("sectionId");
    if (sectionIdParam) setSectionId(sectionIdParam);
  }, []);

  return (
    <QuestionPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t(
                "templates.survey.question.breadcrumbs.templates",
                "Templates"
              ),
              link: `/templates`,
            },
            {
              label: t(
                "templates.survey.questions.breadcrumbs.dashboard",
                "Dashboard"
              ),
              link: `/templates/${surveyId}/dashboard`,
            },
            {
              label: t(
                "templates.survey.question.breadcrumbs.questions",
                "Questions"
              ),
              link: {
                pathname: `/templates/${surveyId}/questions`,
                hash: sectionId ? `section-${sectionId}` : "",
              },
            },
            {
              label: t(
                "templates.survey.question.breadcrumbs.question",
                "Question"
              ),
              link: `/templates/${surveyId}/questions/${questionId}/question`,
            },
          ]}
          back={{
            pathname: `/templates/${surveyId}/questions`,
            hash: sectionId ? `section-${sectionId}` : "",
          }}
        />
      }
      backURL={{
        pathname: `/templates/${surveyId}/questions`,
        hash: sectionId ? `section-${sectionId}` : "",
      }}
      surveyId={surveyId}
      questionId={questionId}
    />
  );
}
