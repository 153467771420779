import { gql } from "@apollo/client";

const CREATEQUESTIONCATEGORY = gql`
  mutation CreateQuestionCategory(
    $label: String!
    $description: String
    $isDemographic: Boolean!
    $color: String
  ) {
    createQuestionCategory(
      questionCategory: {
        label: $label
        description: $description
        isDemographic: $isDemographic
        color: $color
      }
    ) {
      id
    }
  }
`;

const UPDATEQUESTIONCATEGORY = gql`
  mutation UpdateQuestionCategory(
    $id: ID!
    $label: String
    $description: String
    $isDemographic: Boolean
    $color: String
  ) {
    updateQuestionCategory(
      id: $id
      questionCategory: {
        label: $label
        description: $description
        isDemographic: $isDemographic
        color: $color
      }
    ) {
      id
    }
  }
`;

const DELETEQUESTIONCATEGORY = gql`
  mutation DeleteQuestionCategory($id: ID!) {
    deleteQuestionCategory(id: $id)
  }
`;

const GETQUESTIONCATEGORIES = gql`
  query GetQuestionCategories {
    questionCategories {
      id
      label
      color
      description
      isDemographic
      createdAt
      updatedAt
      tags {
        id
        label
        description
      }
    }
  }
`;

const GETQUESTIONCATEGORY = gql`
  query GetQuestionCategory($id: ID!) {
    questionCategory(id: $id) {
      id
      label
      color
      description
      isDemographic
      createdAt
      updatedAt
      tags {
        id
        label
        description
      }
    }
  }
`;

const calls = {
  CREATEQUESTIONCATEGORY,
  UPDATEQUESTIONCATEGORY,
  DELETEQUESTIONCATEGORY,
  GETQUESTIONCATEGORIES,
  GETQUESTIONCATEGORY,
};

export default calls;
