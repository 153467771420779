import { gql } from "@apollo/client";

const CREATEQUESTION = gql`
  mutation CreateQuestion(
    $order: Int!
    $isTemplate: Boolean!
    $derivedFromId: ID
    $direction: QuestionDirection
    $questionTypeId: ID!
    $questionCategoryIds: [ID!]
    $questionTagIds: [ID!]
    $surveySectionId: ID!
    $allowMultipleSelection: Boolean
  ) {
    createQuestion(
      question: {
        order: $order
        isTemplate: $isTemplate
        derivedFromId: $derivedFromId
        direction: $direction
        questionTypeId: $questionTypeId
        questionCategoryIds: $questionCategoryIds
        questionTagIds: $questionTagIds
        surveySectionId: $surveySectionId
        allowMultipleSelection: $allowMultipleSelection
      }
    ) {
      id
    }
  }
`;

const DUPLICATEQUESTION = gql`
  mutation DuplicateQuestion($id: ID!) {
    duplicateQuestion(id: $id) {
      id
    }
  }
`;

const UPDATEQUESTION = gql`
  mutation UpdateQuestion(
    $id: ID!
    $order: Int
    $isArchived: Boolean
    $direction: QuestionDirection
    $questionTypeId: ID
    $questionCategoryIds: [ID!]
    $questionTagIds: [ID!]
    $surveySectionId: ID
    $allowMultipleSelection: Boolean
  ) {
    updateQuestion(
      id: $id
      question: {
        order: $order
        isArchived: $isArchived
        direction: $direction
        questionTypeId: $questionTypeId
        questionCategoryIds: $questionCategoryIds
        questionTagIds: $questionTagIds
        surveySectionId: $surveySectionId
        allowMultipleSelection: $allowMultipleSelection
      }
    ) {
      id
    }
  }
`;

const DELETEQUESTION = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id)
  }
`;

const GETQUESTIONS = gql`
  query GetQuestions($options: String) {
    questions(options: $options) {
      id
      order
      isArchived
      direction
      isTemplate
      allowMultipleSelection
      questionType {
        id
        label
      }
      questionCategories {
        id
        label
        color
      }
      questionTags {
        id
        label
      }
      translations {
        id
        label
        description
        language {
          id
          label
        }
      }
      responseOptions {
        value
        translations {
          id
          label
          language {
            id
            label
          }
        }
      }
    }
  }
`;

const GETQUESTION = gql`
  query GetQuestion($id: ID!) {
    question(id: $id) {
      id
      order
      isArchived
      direction
      isTemplate
      allowMultipleSelection
      section {
        id
        label
      }
      questionType {
        id
        label
      }
      questionCategories {
        id
        label
        color
      }
      questionTags {
        id
        label
      }
      translations {
        id
        label
        description
        language {
          id
          label
        }
      }
      responseOptions {
        id
        value
        order
        translations {
          id
          label
          language {
            id
            label
          }
        }
      }
      routesFrom {
        id
        value
        questionTo {
          id
        }
        routeOperator {
          id
          label
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const GETQUESTIONTYPES = gql`
  query GetQuestionTypes {
    questionTypes {
      id
      label
      createdAt
      updatedAt
      routeOperators {
        id
        label
      }
    }
  }
`;

const GETQUESTIONTYPE = gql`
  query GetQuestionType($id: ID!) {
    questionType(id: $id) {
      id
      label
      createdAt
      updatedAt
    }
  }
`;

const calls = {
  CREATEQUESTION,
  DUPLICATEQUESTION,
  UPDATEQUESTION,
  DELETEQUESTION,
  GETQUESTIONS,
  GETQUESTION,
  GETQUESTIONTYPES,
  GETQUESTIONTYPE,
};

export default calls;
