import { useTranslation } from "react-i18next";

function useReportComponentTranslations() {
  const { t } = useTranslation();

  return {
    Heading: t("utils.use-component-translations.heading", "Title"),
    Paragraph: t("utils.use-component-translations.paragraph", "Paragraph"),
    Image: t("utils.use-component-translations.image", "Image"),
    Table: t("utils.use-component-translations.table", "Table"),
    Chart: t("utils.use-component-translations.chart", "Chart"),
    ScoreCard: t("utils.use-component-translations.scorecard", "Score Card"),
    NetPromotorScore: t(
      "utils.use-component-translations.nps",
      "Net Promotor Score"
    ),
    Row: t("utils.use-component-translations.row", "Row"),
    Conditional: t("utils.use-component-translations.conditional", "If, then"),
  };
}

export default useReportComponentTranslations;
