import React from "react";
import PropTypes from "prop-types";
import { Icons } from "..";

const colorClassNames = {
  primary: "bg-primary",
  secondary: "bg-secondary",
  accent: "bg-accent",
  ghost: "bg-ghost",
  link: "bg-link",
  info: "bg-info",
  success: "bg-success",
  warning: "bg-warning",
  error: "bg-error",
  neutral: "",
};

/**
 * Pill component (with optional close button)
 * @param {string} children - Pill content
 * @param {string} onDelete - Delete handler function (optional)
 * @param {string} color - Pill color
 * @param {string} className  - ClassName overwrite
 * @returns {node} React pill component
 */
function Pill({ children, onDelete, color, className, style }) {
  const colorClassName = colorClassNames[color];

  return (
    <div
      className={`flex flex-row items-center space-x-2 px-2 h-6 text-base-100 rounded-box ${colorClassName} ${className}`}
      style={style}
    >
      <div>{children}</div>
      <button onClick={onDelete} type="button">
        <Icons.Close />
      </button>
    </div>
  );
}

Pill.propTypes = {
  children: PropTypes.node,
  onDelete: PropTypes.func,
  color: PropTypes.oneOf(Object.keys(colorClassNames)),
  className: PropTypes.string,
  style: PropTypes.object,
};

Pill.defaultProps = {
  children: undefined,
  onDelete: () => {},
  color: "primary",
  className: "",
  style: {},
};

export default Pill;
