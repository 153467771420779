import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  useLanguageLabels,
  validateEmail,
  validatePasswordStrength,
} from "../../../utils";
import { Select, Input, Icons, Loading } from "../..";
import calls from "../../../graphql";
import { Button } from "../..";
import toast from "react-hot-toast";

const { PROFILE, GETLANGUAGES, UPDATEPROFILE } = calls;

/**
 * User settings collection component
 * @returns {node} React component
 */
export default function UserSettings() {
  // Hooks
  const { t, i18n } = useTranslation();
  const languageLabels = useLanguageLabels();
  // State
  const [userLanguage, setuserLanguage] = useState(i18n.language);
  const [accountChanged, setAccountChanged] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmation, setUserConfirmation] = useState("");
  // Queries
  const getProfileState = useQuery(PROFILE);
  const getLanguagesState = useQuery(GETLANGUAGES);
  // Mutations
  const [updateProfile] = useMutation(UPDATEPROFILE, {
    refetchQueries: [PROFILE],
  });

  if (getProfileState.error) toast.error(getProfileState.error.message);
  if (getLanguagesState.error) toast.error(getLanguagesState.error.message);

  const languages = getLanguagesState.data
    ? getLanguagesState.data.languages
    : [];

  useEffect(() => {
    if (getProfileState.data) {
      setUserName(getProfileState.data.me.userName);
      setUserEmail(getProfileState.data.me.email);
    }
  }, [getProfileState.data]);

  const onChangeLanguage = (language) => {
    setuserLanguage(language);
    i18n.changeLanguage(language);
  };

  const onSaveAccount = async () => {
    const variables = {};
    if (accountChanged) {
      if (userEmail && validateEmail(userEmail)) {
        variables.newEmail = userEmail;
      } else {
        toast.error(
          t(
            "components.user-settings.messages.error.invalid-email",
            "Invalid email address"
          )
        );
        return;
      }
      if (userName) variables.newUserName = userName;
      if (passwordChanged) {
        if (userPassword === userConfirmation) {
          if (validatePasswordStrength(userPassword)) {
            variables.newPassword = userPassword;
            variables.newPasswordConfirmation = userConfirmation;
          } else {
            toast.error(
              t(
                "components.user-settings.messages.error.weak-password",
                "Weak password.\nPassword should contain at least one lowercase letter, one uppercase letter, one number and one special character."
              )
            );
            return;
          }
        } else {
          toast.error(
            t(
              "components.user-settings.messages.error.passwords-dont-match",
              "Passwords don't match."
            )
          );
          return;
        }
      }
      if (accountChanged || passwordChanged) {
        try {
          variables.oldEmail = getProfileState.data.me.email;
          await updateProfile({
            variables: variables,
          });
          setUserName(getProfileState.data.me.userName);
          setUserEmail(getProfileState.data.me.email);
          setUserPassword("");
          setUserConfirmation("");
          setPasswordChanged(false);
          setAccountChanged(false);
          toast.success(
            t(
              "components.user-settings.messages.success.update-account",
              "Account updated!"
            )
          );
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        toast.custom(
          t(
            "components.user-settings.messages.error.no-changes",
            "No changes were made."
          )
        );
        return;
      }
    }
  };
  return (
    <div className="space-y-2 flex flex-col self-center w-full md:w-3/4">
      <h1 className="text-3xl font-bold">
        {t("components.user-settings.labels.title", "User settings")}
      </h1>
      {getProfileState.loading || getLanguagesState.loading ? (
        <Loading />
      ) : (
        <>
          <h3 className="text-xl">
            {t("components.user-settings.labels.language-title", "Language")}
          </h3>
          <Select
            id="language"
            value={userLanguage}
            onChange={onChangeLanguage}
            options={languages
              .filter((language) => languageLabels[language.label])
              .map((language) => ({
                value: language.label,
                label: `${languageLabels[language.label].flags} ${
                  languageLabels[language.label].label
                }`,
              }))}
          />

          <h3 className="text-xl">
            {t("components.user-settings.labels.account-title", "Account")}
          </h3>
          <form onSubmit={onSaveAccount} className="flex flex-col space-y-2">
            <Input
              id="username"
              label={t(
                "components.user-settings.account.user-name-label",
                "User name"
              )}
              placeholder={t(
                "components.user-settings.account.user-name-placeholder",
                "user name"
              )}
              value={userName}
              onChange={(value) => {
                setAccountChanged(true);
                setUserName(value);
              }}
            />
            <Input
              id="email"
              label={t("components.user-settings.account.email-label", "Email")}
              placeholder={t(
                "components.user-settings.account.email-placeholder",
                "email address"
              )}
              value={userEmail}
              onChange={(value) => {
                setAccountChanged(true);
                setUserEmail(value);
              }}
              hasWarning={!validateEmail(userEmail) && !!userEmail}
            />
            <Input
              id="password"
              label={t(
                "components.user-settings.account.password-label",
                "Password"
              )}
              placeholder={t(
                "components.user-settings.account.password-placeholder",
                "password"
              )}
              value={userPassword}
              onChange={(value) => {
                setPasswordChanged(true);
                setUserPassword(value);
              }}
              hasWarning={
                !validatePasswordStrength(userPassword) && !!userPassword
              }
              isPassword={true}
            />
            <Input
              id="password-confirmation"
              label={t(
                "components.user-settings.account.confirmation-label",
                "Confirm password"
              )}
              placeholder={t(
                "components.user-settings.account.confirmation-placeholder",
                "password"
              )}
              value={userConfirmation}
              onChange={(value) => {
                setUserConfirmation(value);
              }}
              hasWarning={
                !!userConfirmation && userConfirmation !== userPassword
              }
              isPassword={true}
            />

            <Button
              onClick={(e) => {
                e.preventDefault();
                onSaveAccount();
              }}
              start={<Icons.Save className="text-xl" />}
            >
              {t("components.user-settings.labels.save-account", "Save")}
            </Button>
          </form>
        </>
      )}
    </div>
  );
}
