const levels = {
  // Guest can neither se or do anything in the app.
  Guest: 0,
  // To be implemented
  Customer: 10,
  // Users can see projects, surveys, questions but cannot make changes
  User: 20,
  // Managers can make changes to projects & surveys as well as publish them
  Manager: 30,
  // Admins have the right to manage user roles
  Admin: 40,
  // Everything is admitted (developer role)
  SuperUser: 100,
};

export default levels;
