import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs,
  LoggedInLayout,
  ReportStructuresTable,
  ReportsTable,
} from "../../../components";

export default function Reports() {
  // Hooks
  const { projectId, surveyId } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                label: t(
                  "project.survey.reports.breadcrumbs.projects",
                  "Projects"
                ),
                link: `/projects`,
              },
              {
                label: t(
                  "project.survey.reports.breadcrumbs.surveys",
                  "Surveys"
                ),
                link: `/projects/${projectId}/surveys`,
              },
              {
                label: t(
                  "project.survey.reports.breadcrumbs.dashboard",
                  "Dashboard"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
              },
              {
                label: t(
                  "project.survey.reports.breadcrumbs.reports",
                  "Reports"
                ),
                link: `/projects/${projectId}/surveys/${surveyId}/reports`,
              },
            ]}
            back={`/projects/${projectId}/surveys/${surveyId}/dashboard`}
          />
        }
      >
        <ReportStructuresTable />
        <ReportsTable />
      </LoggedInLayout>
    </>
  );
}
