import React from "react";
import { useTranslation } from "react-i18next";
import { QuestionCategorySettings } from "../..";

/**
 * Application settings collection component
 * @returns {node} React component
 */
export default function ApplicationSettings() {
  const { t } = useTranslation();

  return (
    <div className="space-y-2 flex flex-col self-center w-full md:w-3/4">
      <h1 className="text-3xl font-bold">
        {t("components.app-settings.title", "Application settings")}
      </h1>
      <QuestionCategorySettings />
    </div>
  );
}
