import React from "react";
import PropTypes from "prop-types";
import { Select } from ".";
import { Pill } from "..";

/**
 * Multiple select component
 * Selections are shown with the use of Pills.
 * @param {string} id - Input base id
 * @param {string} options - Select options
 * @param {string} selectedValues - Selected values
 * @param {string} setSelectedValues - Selected values setter function
 * @param {string} selectLabel - Select input label
 * @param {string} selectPlaceholder - Select input placeholder
 * @returns {node} React multiple select component
 */
function MultipleSelect({
  id,
  options,
  selectedValues,
  setSelectedValues,
  selectLabel,
  selectPlaceholder,
  onAddCallback,
  onDeleteCallback,
}) {
  const onAddValue = (value) => {
    const values = [...selectedValues, value];
    setSelectedValues(values);
    if (onAddCallback) onAddCallback(value);
  };

  const onDeleteValue = (value) => {
    const values = [...selectedValues].filter((val) => val !== value);
    setSelectedValues(values);
    if (onDeleteCallback) onDeleteCallback(value);
  };

  return (
    <>
      <Select
        id={id}
        label={selectLabel}
        value="-1"
        onChange={onAddValue}
        options={options.filter(({ value }) => !selectedValues.includes(value))}
        placeholderValue="-1"
        placeholderLabel={selectPlaceholder}
      />
      <div className="flex flex-row flex-wrap">
        {selectedValues && selectedValues.length
          ? options
              .filter(({ value }) => selectedValues.includes(value))
              .map(({ value, label, color }) => (
                <Pill
                  key={`${id}pill${value}`}
                  onDelete={() => onDeleteValue(value)}
                  className="mb-1 mr-1"
                  style={{ backgroundColor: color }}
                >
                  {label}
                </Pill>
              ))
          : null}
      </div>
    </>
  );
}

MultipleSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedValues: PropTypes.func,
  selectLabel: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  onAddCallback: PropTypes.func,
  onDeleteCallback: PropTypes.func,
};

export default MultipleSelect;
