import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  MinLevel,
  Button,
  Icons,
  ActionModal,
  Input,
  Select,
  DatePicker,
  Checkbox,
  ConfirmModal,
  Table,
  TableHead,
  TableRow,
  TableData,
  Loading,
} from "../..";
import { formatDate, userRoleLevels } from "../../../utils";
import calls from "../../../graphql";
const {
  GETSURVEYREPORTS,
  GETSURVEYREPORTSTRUCTURES,
  GETSURVEYRESPONSES,
  CREATEREPORT,
  DELETEREPORT,
} = calls;

function ReportsTable() {
  // Hooks
  const { surveyId, projectId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  // State
  const [surveyReports, setSurveyReports] = useState([]);
  const [responses, setResponses] = useState([]);
  const [reportStructures, setResportStructures] = useState([]);
  const [isEditingReports, setIsEditingReports] = useState(false);
  const [selectedReportIds, setSelectedReportIds] = useState([]);
  const [deleteReportsModalOpen, setDeleteReportsModalOpen] = useState(false);
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const [reportLabel, setReportLabel] = useState("");
  const [selectedStructure, setSelectedStructure] = useState("-1");
  const [responsesStart, setResponsesStart] = useState(new Date());
  const [responsesEnd, setResponsesEnd] = useState(new Date());
  const [selectedResponseIds, setSelectedResponseIds] = useState([]);

  // Queries
  const getSurveyReportsState = useQuery(GETSURVEYREPORTS, {
    variables: {
      id: surveyId,
    },
    fetchPolicy: "cache-and-network",
  });
  const getSurveyResponsesState = useQuery(GETSURVEYRESPONSES, {
    variables: {
      id: surveyId,
    },
  });
  const getSurveyReportStructureState = useQuery(GETSURVEYREPORTSTRUCTURES, {
    variables: {
      id: surveyId,
    },
    fetchPolicy: "cache-and-network",
  });

  // Mutations
  const [createReport, createReportState] = useMutation(CREATEREPORT);
  const [deleteReport, deleteReportState] = useMutation(DELETEREPORT, {
    refetchQueries: [GETSURVEYREPORTS],
  });

  // Effects
  useEffect(() => {
    if (getSurveyReportsState.data) {
      const { survey } = getSurveyReportsState.data;
      setSurveyReports(survey.reports);
    }
  }, [getSurveyReportsState.data]);
  useEffect(() => {
    if (getSurveyResponsesState.data) {
      const { survey } = getSurveyResponsesState.data;
      const surveyResponses = survey.publishedSurveys
        .reduce(
          (group, publishedSurvey) => [
            ...group,
            ...publishedSurvey.surveyResponses,
          ],
          []
        )
        .sort((a, b) => a.submittedAt >= b.submittedAt);
      setResponses(surveyResponses);
    }
  }, [getSurveyResponsesState.data]);
  useEffect(() => {
    if (getSurveyReportStructureState.data) {
      const { survey } = getSurveyReportStructureState.data;
      setResportStructures(survey.reportStructures);
    }
  }, [getSurveyReportStructureState.data]);
  useEffect(() => {
    if (
      selectedStructure &&
      selectedStructure !== "-1" &&
      !selectedStructure.isIndividual
    ) {
      const start = responsesStart;
      start.setHours(0, 0, 0, 0);
      const end = responsesEnd;
      end.setHours(23, 59, 59, 999);
      const responseSelection = responses.filter((response) => {
        return (
          response.submittedAt >= start.toISOString() &&
          response.submittedAt <= end.toISOString()
        );
      });
      setSelectedResponseIds(responseSelection.map((res) => res.id));
    }
  }, [selectedStructure, responsesStart, responsesEnd, responses]);

  // Functions
  const onCreateReport = async () => {
    if (
      !(
        reportLabel &&
        selectedStructure &&
        selectedStructure !== "1" &&
        selectedResponseIds &&
        selectedResponseIds.length
      )
    ) {
      toast.custom(
        t(
          "components.reports-table.messages.warning.complete-all-fields",
          "Please complete all fields"
        )
      );
      return;
    }
    try {
      const { data } = await createReport({
        variables: {
          label: reportLabel,
          surveyId: surveyId,
          reportStructureId: selectedStructure.id,
          surveyResponseIds: selectedResponseIds,
        },
      });
      if (data && data.createReport) {
        const { id } = data.createReport;
        toast.success(
          t(
            "components.reports-table.messages.success.report-generated",
            "Report generated!"
          )
        );
        history.replace(
          `/projects/${projectId}/surveys/${surveyId}/reports/${id}/report`
        );
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onDeleteSelectedReports = async () => {
    await Promise.all(
      selectedReportIds.map(async (reportId) => {
        try {
          await deleteReport({
            variables: {
              id: reportId,
            },
          });
        } catch ({ message }) {
          toast.error(message);
        }
      })
    );
    toast.success(
      t(
        "components.reports-table.messages.success.reports-deleted",
        "Deleted reports!"
      )
    );
    setSelectedReportIds([]);
    setIsEditingReports(false);
    setDeleteReportsModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-2xl md:text-3xl font-bold">
          {t("components.reports-table.labels.reports", "Reports")}
        </h1>
        <MinLevel minLevel={userRoleLevels.Manager}>
          <div className="flex flex-row items-center space-x-2">
            {isEditingReports ? (
              <>
                <Button
                  start={<Icons.Close className="text-xl" />}
                  onClick={() => {
                    setSelectedReportIds([]);
                    setIsEditingReports(false);
                  }}
                  color="warning"
                >
                  {t(
                    "components.reports-table.labels.stop-edit-reports",
                    "Stop editing"
                  )}
                </Button>
                <Button
                  start={<Icons.Delete className="text-xl" />}
                  color="error"
                  onClick={() => {
                    if (selectedReportIds.length) {
                      setDeleteReportsModalOpen(true);
                    } else {
                      toast.custom(
                        t(
                          "components.reports-table.messages.warning.no-surveys-selected",
                          "Please select the reports you want to delete."
                        )
                      );
                    }
                  }}
                >
                  {t(
                    "components.reports-table.labels.delete-reports",
                    "Delete"
                  )}
                </Button>
              </>
            ) : (
              <>
                <Button
                  start={<Icons.Edit className="text-xl" />}
                  onClick={() => {
                    setIsEditingReports(true);
                  }}
                  color="warning"
                >
                  {t(
                    "components.reports-table.labels.edit-reports",
                    "Edit reports"
                  )}
                </Button>
                <Button
                  start={<Icons.Add className="text-xl" />}
                  onClick={() => {
                    setCreateReportModalOpen(true);
                  }}
                >
                  {t(
                    "components.reports-table.labels.create-report",
                    "Generate report"
                  )}
                </Button>
              </>
            )}
          </div>
        </MinLevel>
      </div>
      {getSurveyReportsState.loading ||
      getSurveyResponsesState.loading ||
      getSurveyReportStructureState.loading ? (
        <Loading />
      ) : surveyReports && surveyReports.length ? (
        <Table
          head={
            <tr className="h-10">
              <TableHead>
                {t("components.reports-table.table.headers.title", "Title")}
              </TableHead>
              <TableHead>
                {t(
                  "components.reports-table.table.headers.respondent",
                  "Respondent"
                )}
              </TableHead>
              <TableHead>
                {t(
                  "components.reports-table.table.headers.template",
                  "Template"
                )}
              </TableHead>
              <TableHead>
                {t("components.reports-table.table.headers.updated", "Updated")}
              </TableHead>
              <TableHead />
            </tr>
          }
        >
          {surveyReports.map((report) => (
            <TableRow key={`report${report.id}`}>
              <TableData>{report.label}</TableData>
              <TableData>
                {report.respondent
                  ? report.respondent.name
                  : t(
                      "components.reports-table.labels.respondent-anonymous",
                      "Anonymous"
                    )}
              </TableData>
              <TableData>
                {report.reportStructure
                  ? report.reportStructure.label
                  : t(
                      "components.reports-table.labels.custom-report",
                      "Custom"
                    )}
              </TableData>
              <TableData>{formatDate(report.updatedAt)}</TableData>
              <TableData>
                {isEditingReports ? (
                  <div className="flex justify-end text-3xl">
                    <p>&nbsp;</p>
                    <Checkbox
                      checked={selectedReportIds.includes(report.id)}
                      toggle={() => {
                        if (selectedReportIds.includes(report.id)) {
                          const reportIds = [...selectedReportIds].filter(
                            (id) => id !== report.id
                          );
                          setSelectedReportIds(reportIds);
                        } else {
                          setSelectedReportIds([
                            ...selectedReportIds,
                            report.id,
                          ]);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <Link
                    to={`/projects/${projectId}/surveys/${surveyId}/reports/${report.id}/report`}
                    className="flex justify-end"
                  >
                    <Icons.Next className="text-4xl" />
                  </Link>
                )}
              </TableData>
            </TableRow>
          ))}
        </Table>
      ) : (
        <div className="text-3xl">
          {t(
            "components.reports-table.messages.warning.no-reports",
            "No reports found, create one!"
          )}
        </div>
      )}
      <ActionModal
        title={t(
          "components.reports-table.modals.create-report.title",
          "Generate report"
        )}
        modalOpen={createReportModalOpen}
        closeModal={() => setCreateReportModalOpen(false)}
        actions={
          <Button
            onClick={onCreateReport}
            size="sm"
            isLoading={createReportState.loading}
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "components.reports-table.modals.create-report.save-report",
              "Generate report"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2">
          <Input
            id="report-label"
            value={reportLabel}
            onChange={setReportLabel}
            label={t(
              "components.reports-table.modals.create-report.title-label",
              "Title"
            )}
            placeholder={t(
              "components.reports-table.modals.create-report.title-placeholder",
              "title"
            )}
          />
          <Select
            id="report-template"
            label={t(
              "components.reports-table.modals.create-report.template-label",
              "Report template"
            )}
            placeholderLabel={t(
              "components.reports-table.modals.create-report.template-placeholder",
              "Pick report template"
            )}
            placeholderValue="-1"
            options={reportStructures.map((structure) => ({
              value: structure.id,
              label: structure.label,
            }))}
            value={
              selectedStructure && selectedStructure !== "-1"
                ? selectedStructure.id
                : "-1"
            }
            onChange={(newId) => {
              const structure = reportStructures.find(
                (structure) => structure.id === newId
              );
              if (structure) {
                setSelectedStructure(structure);
              } else {
                setSelectedStructure("-1");
              }
              setSelectedResponseIds([]);
            }}
          />
          {selectedStructure && selectedStructure !== "-1" ? (
            selectedStructure.isIndividual ? (
              <Select
                id="survey-response"
                label={t(
                  "components.reports-table.modals.create-report.response-label",
                  "Survey response"
                )}
                placeholderLabel={t(
                  "components.reports-table.modals.create-report.response-placeholder",
                  "Pick survey response"
                )}
                placeholderValue="-1"
                options={responses.map((response) => ({
                  value: response.id,
                  label: `${formatDate(response.submittedAt, true)} - ${
                    response.respondent
                      ? response.respondent.name
                      : t(
                          "components.reports-table.modals.create-report.respondent-anonymous",
                          "anonymous"
                        )
                  }`,
                }))}
                value={
                  selectedResponseIds && selectedResponseIds.length
                    ? selectedResponseIds[0]
                    : "-1"
                }
                onChange={(id) => {
                  setSelectedResponseIds([id]);
                }}
              />
            ) : (
              <>
                <p>
                  {t(
                    "components.reports-table.modals.create-report.responses-between-dates",
                    "Responses between dates"
                  )}
                </p>
                <DatePicker
                  id="responses-start"
                  value={responsesStart}
                  onChange={setResponsesStart}
                  max={responsesEnd}
                />
                <DatePicker
                  id="responses-end"
                  value={responsesEnd}
                  onChange={setResponsesEnd}
                  min={responsesStart}
                />
                {selectedResponseIds ? selectedResponseIds.length : 0}{" "}
                {t(
                  "components.reports-table.modals.create-report.responses-selected",
                  "responses selected"
                )}
              </>
            )
          ) : null}
        </form>
      </ActionModal>

      <ConfirmModal
        toConfirmType={t(
          "components.reports-table.modals.delete-report.type",
          "reports"
        )}
        modalOpen={deleteReportsModalOpen}
        closeModal={() => setDeleteReportsModalOpen(false)}
        onConfirm={onDeleteSelectedReports}
        action="delete"
        isLoading={deleteReportState.loading}
      />
    </>
  );
}

export default ReportsTable;
