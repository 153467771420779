import { useTranslation } from "react-i18next";

/**
 * React hook for question type constants and translations in the user language
 * @returns {object} Question types
 */
export default function useQuestionTypes() {
  const { t } = useTranslation();

  const questionTypeLabels = {
    EmailAddress: {
      label: t(
        "utils.use-question-types.types.email-address.label",
        "Email address"
      ),
      explanation: t(
        "utils.use-question-types.types.email-address.explanation",
        "Ask a respondent for their email address. This field can be used to send them emails when they fill out the survey."
      ),
    },
    MultipleChoice: {
      label: t(
        "utils.use-question-types.types.multiple-choice.label",
        "Multiple Choice (not scored!)"
      ),
      explanation: t(
        "utils.use-question-types.types.multiple-choice.explanation",
        "A question with multiple, unscored response options. Suitable for categorical multiple choice questions."
      ),
    },
    ScoredMultipleChoice: {
      label: t(
        "utils.use-question-types.types.scored-multiple-choice.label",
        "Scored Multiple Choice"
      ),
      explanation: t(
        "utils.use-question-types.types.scored-multiple-choice.explanation",
        "A question with multiple, scored (numerical) response options."
      ),
    },
    OpenText: {
      label: t("utils.use-question-types.types.open-text.label", "Open Text"),
      explanation: t(
        "utils.use-question-types.types.open-text.explanation",
        "A question with an open text answer."
      ),
    },
    Likert: {
      label: t(
        "utils.use-question-types.types.likert-scale.label",
        "Likert Scale"
      ),
      explanation: t(
        "utils.use-question-types.types.likert-scale.explanation",
        `A question with a 5-point agreement scale as an answer, ranging from "strongly disagree" to "strongly agree".`
      ),
    },
    NetPromotorScore: {
      label: t(
        "utils.use-question-types.types.net-promotor-score.label",
        "Net Promotor Score"
      ),
      explanation: t(
        "utils.use-question-types.types.net-promotor-score.explanation",
        "How likely is it that the surveyee would recommend something?"
      ),
    },
    Score10: {
      label: t(
        "utils.use-question-types.types.score-out-of-10.label",
        "Score out of 10"
      ),
      explanation: t(
        "utils.use-question-types.types.score-out-of-10.explanation",
        "A question with a 10-point score as an answer."
      ),
    },
    YesNo: {
      label: t("utils.use-question-types.types.yes-or-no.label", "Yes / No"),
      explanation: t(
        "utils.use-question-types.types.yes-or-no.explanation",
        "A question with a polar (yes or no) answer."
      ),
    },
    Statement: {
      label: t("utils.use-question-types.types.statement.label", "Statement"),
      explanation: t(
        "utils.use-question-types.types.statement.explanation",
        "Not a question. Rather a statement / message to the surveyee on a separate slide."
      ),
    },
    Integer: {
      label: t("utils.use-question-types.types.integer.label", "Integer"),
      explanation: t(
        "utils.use-question-types.types.integer.explanation",
        "A question with an integer number as an answer."
      ),
    },
  };

  return questionTypeLabels;
}
