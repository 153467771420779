import React, { useState, useEffect } from "react";
import {
  LoggedInLayout,
  Breadcrumbs,
  Icons,
  Button,
  ActionModal,
  Table,
  TableData,
  TableHead,
  TableRow,
  Input,
  MinLevel,
} from "../components";
import { useHistory } from "react-router-dom";
import fuzzysort from "fuzzysort";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import calls from "../graphql";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { validateEmail, formatDate, initials, userRoleLevels } from "../utils";
const { GETCLIENTS, CREATECLIENT } = calls;

export default function Clients() {
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  // State
  const [clientModalOpen, setClientModalOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientIndustry, setClientIndustry] = useState("");
  const [clientURL, setClientURL] = useState("");
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");
  // Queries
  const getClientsState = useQuery(GETCLIENTS, {
    fetchPolicy: "cache-and-network",
  });
  // Mutations
  const [createClient, createClientState] = useMutation(CREATECLIENT, {
    refetchQueries: [GETCLIENTS],
  });
  // Handle errors
  if (getClientsState.error) toast.error(getClientsState.error.message);
  // Effects
  useEffect(() => {
    const clients =
      getClientsState.data && getClientsState.data.clients
        ? getClientsState.data.clients
        : [];
    if (search) {
      const delayedSearch = setTimeout(() => {
        const results = fuzzysort.go(search, clients, {
          keys: ["name", "email", "industry", "url"],
        });
        setClients(results.map((result) => result.obj));
      }, 200);
      return () => clearTimeout(delayedSearch);
    } else {
      setClients(clients);
    }
  }, [search, getClientsState.data]);

  const resetState = () => {
    setClientModalOpen(false);
    setClientName("");
    setClientEmail("");
    setClientIndustry("");
    setClientURL("");
  };

  const onSaveClient = async () => {
    if (clientName && clientEmail) {
      try {
        const { data } = await createClient({
          variables: {
            name: clientName,
            email: clientEmail,
            industry: clientIndustry,
            url: clientURL,
          },
        });
        if (data && data.createClient && data.createClient.id) {
          resetState();
          toast.success(
            t("clients.messages.success.create-client", "Client created!")
          );
          history.replace(`/clients/${data.createClient.id}/mailinglists`);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.custom(
        t(
          "clients.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  return (
    <>
      <LoggedInLayout
        page="Clients"
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: "/clients",
                label: t("clients.labels.clients", "Clients"),
              },
            ]}
          />
        }
        isLoading={getClientsState.loading}
      >
        {search || (clients && clients.length) ? (
          <>
            <div className="flex items-center">
              <div className="flex-grow">
                <Input
                  id="search"
                  placeholder={t("project.surveys.labels.search", "Search")}
                  value={search}
                  onChange={setSearch}
                  className="w-4/5"
                />
              </div>
              <MinLevel minLevel={userRoleLevels.Manager}>
                <Button
                  color="primary"
                  onClick={() => {
                    resetState();
                    setClientModalOpen(true);
                  }}
                  loading={createClientState.loading}
                  start={<Icons.Add className="text-2xl" />}
                >
                  {t("clients.labels.create-client", "New client")}
                </Button>
              </MinLevel>
            </div>
            <Table
              head={
                <tr className="h-10">
                  <TableHead>
                    {t("clients.table.headers.client", "Client")}
                  </TableHead>
                  <TableHead>
                    {t("clients.table.headers.industry", "Industry")}
                  </TableHead>
                  <TableHead>
                    {t("clients.table.headers.updated", "Updated")}
                  </TableHead>
                  <TableHead />
                </tr>
              }
            >
              {clients.map((client) => (
                <TableRow key={client.id}>
                  <TableData>
                    <div className="flex items-center space-x-4">
                      <div className="avatar placeholder">
                        <div className="bg-base-300 text-gray-600 rounded-full w-10 h-10">
                          {initials(client.name)}
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">{client.name}</div>
                        <div className="font-normal">{client.email}</div>
                      </div>
                    </div>
                  </TableData>
                  <TableData>{client.industry}</TableData>
                  <TableData>{formatDate(client.updatedAt)}</TableData>
                  <TableData>
                    <Link
                      to={`clients/${client.id}/mailinglists`}
                      className="flex justify-end"
                    >
                      <Icons.Next className="text-4xl" />
                    </Link>
                  </TableData>
                </TableRow>
              ))}
            </Table>
          </>
        ) : (
          <div className="flex flex-row items-center justify-between">
            <div className="text-3xl">
              {t(
                "clients.messages.warning.no-clients",
                "No clients found, create one!"
              )}
            </div>
            <MinLevel minLevel={userRoleLevels.Manager}>
              <Button
                color="primary"
                onClick={() => {
                  resetState();
                  setClientModalOpen(true);
                }}
                loading={createClientState.loading}
                start={<Icons.Add className="text-2xl" />}
              >
                {t("clients.labels.create-client", "New client")}
              </Button>
            </MinLevel>
          </div>
        )}
      </LoggedInLayout>
      <ActionModal
        title={t("clients.modals.create-client.title", "New Client")}
        modalOpen={clientModalOpen}
        closeModal={() => setClientModalOpen(false)}
        actions={
          <Button
            onClick={onSaveClient}
            color="primary"
            size="sm"
            isLoading={createClientState.loading}
            start={<Icons.Save className="text-xl" />}
          >
            {t("clients.modals.create-client.save", "Save Client")}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveClient}>
          <Input
            id="client-name"
            label={t("clients.modals.create-client.name-label", "Name")}
            placeholder={t(
              "clients.modals.create-client.name-placeholder",
              "name"
            )}
            value={clientName}
            onChange={setClientName}
          />
          <Input
            id="email"
            label={t(
              "clients.modals.create-client.email-label",
              "Email address"
            )}
            placeholder={t(
              "clients.modals.create-client.email-placeholder",
              "email"
            )}
            value={clientEmail}
            hasWarning={!validateEmail(clientEmail) && !!clientEmail}
            onChange={setClientEmail}
          />
          <Input
            id="industry"
            label={t("clients.modals.create-client.industry-label", "Industry")}
            placeholder={t(
              "clients.modals.create-client.industry-placeholder",
              "industry"
            )}
            value={clientIndustry}
            onChange={setClientIndustry}
          />
          <Input
            id="url"
            label={t("clients.modals.create-client.url-label", "Website")}
            placeholder={t(
              "clients.modals.create-client.url-placeholder",
              "url"
            )}
            value={clientURL}
            onChange={setClientURL}
          />
        </form>
      </ActionModal>
    </>
  );
}
