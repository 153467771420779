import React from "react";
import PropTypes from "prop-types";

/**
 * Modal window
 * @param {node} children - Modal content
 * @param {boolean} modalOpen - Is modal open
 * @param {function} closeModal - Close modal function
 * @param {string} className - ClassName overwrite
 * @returns {node} React modal window component
 */
function Modal({ children, modalOpen, closeModal, className }) {
  const clickOutsideModal = (e) => {
    if (
      e &&
      e.target &&
      e.target.className &&
      e.target.className.split &&
      e.target.className.split(" ")[0] === "modal"
    )
      closeModal();
  };

  if (modalOpen) {
    return (
      <div
        className="modal modal-open z-40 overflow-visible"
        onClick={clickOutsideModal}
      >
        <div
          className={`modal-box overflow-visible ${className ? className : ""}`}
        >
          {children}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  className: PropTypes.string,
};

export default Modal;
