import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs,
  LoggedInLayout,
  ReportStructuresTable,
} from "../../components";

export default function Reports() {
  // Hooks
  const { surveyId } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                label: t("template.reports.breadcrumbs.templates", "Templates"),
                link: `/templates`,
              },
              {
                label: t("template.reports.breadcrumbs.dashboard", "Dashboard"),
                link: `/templates/${surveyId}/dashboard`,
              },
              {
                label: t("template.reports.breadcrumbs.reports", "Reports"),
                link: `/templates/${surveyId}/reportstructures`,
              },
            ]}
            back={`/templates/${surveyId}/dashboard`}
          />
        }
      >
        <ReportStructuresTable />
      </LoggedInLayout>
    </>
  );
}
