import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MultipleSelect } from "../..";

function SelectCategories({
  questionCategories,
  selectedCategoryIds,
  setSelectedCategoryIds,
}) {
  const { t } = useTranslation();

  return (
    <MultipleSelect
      id="add-categories"
      selectLabel={t(
        "components.select-categories.add-categories-label",
        "Add categories"
      )}
      options={[...questionCategories]
        .sort((a, b) => a.label >= b.label)
        .map((category) => ({
          value: category.id,
          label: category.label,
          color: category.color,
        }))}
      selectedValues={selectedCategoryIds}
      setSelectedValues={setSelectedCategoryIds}
      selectPlaceholder={t(
        "components.select-categories.add-categories",
        "Add categories"
      )}
    />
  );
}

SelectCategories.propTypes = {
  questionCategories: PropTypes.array,
  selectedCategoryIds: PropTypes.array,
  setSelectedCategoryIds: PropTypes.func,
};

export default SelectCategories;
