import { gql } from "@apollo/client";

const CREATERESPONDENTLIST = gql`
  mutation CreateRespondentList($label: String!, $clientId: ID!) {
    createRespondentList(
      respondentList: { label: $label, clientId: $clientId }
    ) {
      id
    }
  }
`;

const UPDATERESPONDENTLIST = gql`
  mutation UpdateRespondentList($id: ID!, $label: String, $clientId: ID) {
    updateRespondentList(
      id: $id
      respondentList: { label: $label, clientId: $clientId }
    ) {
      id
    }
  }
`;

const DELETERESPONDENTLIST = gql`
  mutation DeleteRespondentList($id: ID!) {
    deleteRespondentList(id: $id)
  }
`;

const GETRESPONDENTLISTS = gql`
  query GetRespondentLists {
    respondentLists {
      id
      label
      createdAt
      updatedAt
      respondents {
        id
        name
        email
        language {
          id
          label
        }
      }
      client {
        id
        name
      }
    }
  }
`;

const GETRESPONDENTLIST = gql`
  query GetRespondentList($id: ID!) {
    respondentList(id: $id) {
      id
      label
      createdAt
      updatedAt
      respondents {
        id
        name
        email
        language {
          id
          label
        }
      }
    }
  }
`;

const calls = {
  CREATERESPONDENTLIST,
  UPDATERESPONDENTLIST,
  DELETERESPONDENTLIST,
  GETRESPONDENTLISTS,
  GETRESPONDENTLIST,
};

export default calls;
