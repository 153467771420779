import { gql } from "@apollo/client";

const CREATEQUESTIONTAG = gql`
  mutation CreateQuestionTag(
    $label: String!
    $description: String
    $questionCategoryId: ID!
  ) {
    createQuestionTag(
      questionTag: {
        label: $label
        description: $description
        questionCategoryId: $questionCategoryId
      }
    ) {
      id
    }
  }
`;

const UPDATEQUESTIONTAG = gql`
  mutation UpdateQuestionTag(
    $id: ID!
    $label: String
    $description: String
    $questionCategoryId: ID
  ) {
    updateQuestionTag(
      id: $id
      questionTag: {
        label: $label
        description: $description
        questionCategoryId: $questionCategoryId
      }
    ) {
      id
    }
  }
`;

const DELETEQUESTIONTAG = gql`
  mutation DeleteQuestionTag($id: ID!) {
    deleteQuestionTag(id: $id)
  }
`;

const GETQUESTIONTAGS = gql`
  query GetQuestionTags {
    questionTags {
      id
      label
      description
      createdAt
      updatedAt
    }
  }
`;

const GETQUESTIONTAG = gql`
  query GetQuestionTag($id: ID!) {
    questionTag(id: $id) {
      id
      label
      description
      createdAt
      updatedAt
    }
  }
`;

const calls = {
  CREATEQUESTIONTAG,
  UPDATEQUESTIONTAG,
  DELETEQUESTIONTAG,
  GETQUESTIONTAGS,
  GETQUESTIONTAG,
};

export default calls;
