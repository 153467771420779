import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import { env } from "../utils/environment";

import en from "./en.json";
import nl from "./nl.json";
import fr from "./fr.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    supportedLngs: ["en", "nl", "fr"],
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false, // XSS protection built into react
    },
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      nl: {
        translation: nl,
      },
    },
  });

export default i18n;
