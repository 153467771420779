import React from "react";
import PropTypes from "prop-types";
import { Icons, Select } from "..";

/**
 * Select component wrapper with info tooltip/modal
 * @param {string} label - Input label
 * @param {string} id - Input id
 * @param {string} setInfoModalOpen - Open info modal function
 * @param {string} setInfoModalType - Set info modal type (TODO: needs refactor)
 * @param {string} infoMessage - Info tooltip message
 * @param {string} value - Select input value
 * @param {string} options - Select input options
 * @param {string} onChange - Select input change handler
 * @param {string} placeholderValue - Select input placeholder value
 * @param {string} placeholderLabel - Select input placeholder label
 * @returns {node} React info select component
 */
function InfoSelect({
  label,
  id,
  setInfoModalOpen,
  setInfoModalType,
  infoMessage,
  value,
  options,
  onChange,
  placeholderValue,
  placeholderLabel,
}) {
  return (
    <>
      {label ? (
        <div className="flex flex-row items-center">
          <label htmlFor={id} className="flex-grow">
            {label}
          </label>
          {infoMessage ? (
            <button
              type="button"
              data-tip={infoMessage}
              onClick={() => {
                setInfoModalType(id);
                setInfoModalOpen(true);
              }}
              className="tooltip tooltip-left"
            >
              <Icons.Info className="text-xl text-gray-600 hover:text-black" />
            </button>
          ) : undefined}
        </div>
      ) : undefined}

      <Select
        id={id}
        value={value}
        onChange={onChange}
        options={options}
        placeholderValue={placeholderValue}
        placeholderLabel={placeholderLabel}
      />
    </>
  );
}

InfoSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  setInfoModalOpen: PropTypes.func,
  setInfoModalType: PropTypes.func,
  infoMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholderValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholderLabel: PropTypes.string,
};

export default InfoSelect;
