import { gql } from "@apollo/client";

const CREATEPUBLISHTASK = gql`
  mutation CreatePublishTask(
    $isRecurring: Boolean!
    $scheduleAt: Date!
    $endAt: Date
    $schedulePeriod: String
    $publishedSurveyId: ID!
    $respondentListIds: [ID!]!
  ) {
    createPublishTask(
      publishTask: {
        isRecurring: $isRecurring
        scheduleAt: $scheduleAt
        endAt: $endAt
        schedulePeriod: $schedulePeriod
        publishedSurveyId: $publishedSurveyId
        respondentListIds: $respondentListIds
      }
    ) {
      id
    }
  }
`;

const UPDATEPUBLISHTASK = gql`
  mutation UpdatePublishTask(
    $isRecurring: Boolean
    $scheduleAt: Date
    $endAt: Date
    $schedulePeriod: String
    $publishedSurveyId: ID
    $respondentListId: [ID!]
  ) {
    updatePublishTask(
      id: $id
      publishTask: {
        isRecurring: $isRecurring
        scheduleAt: $scheduleAt
        endAt: $endAt
        schedulePeriod: $schedulePeriod
        publishedSurveyId: $publishedSurveyId
        respondentListIds: $respondentListIds
      }
    ) {
      id
    }
  }
`;

const DELETEPUBLISHTASK = gql`
  mutation DeletePublishTask($id: ID!) {
    deletePublishTask(id: $id)
  }
`;

const PERFORMPUBLISHTASK = gql`
  mutation PerformPublishTask($id: ID!) {
    performPublishTask(id: $id)
  }
`;

const GETPUBLISHTASKS = gql`
  query GetPublishTasks {
    publishTasks {
      id
      isRecurring
      scheduleAt
      endAt
      schedulePeriod
      createdAt
      updatedAt
      respondentList {
        id
        label
      }
      emails {
        id
        language {
          id
          label
        }
        from
        subject
        message
      }
    }
  }
`;

const GETPUBLISHTASK = gql`
  query GetPublishTask($id: ID!) {
    publishTask(id: $id) {
      id
      isRecurring
      scheduleAt
      endAt
      schedulePeriod
      createdAt
      updatedAt
      respondentList {
        id
        label
      }
      emails {
        id
        language {
          id
          label
        }
        from
        subject
        message
      }
    }
  }
`;

const calls = {
  CREATEPUBLISHTASK,
  UPDATEPUBLISHTASK,
  DELETEPUBLISHTASK,
  PERFORMPUBLISHTASK,
  GETPUBLISHTASKS,
  GETPUBLISHTASK,
};

export default calls;
