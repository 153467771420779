/**
 * Finds translation in a specfic language from an array
 * @param {array} translations - Array with translations to get a specific translation from [{label: "", description: "", language: {id: "", label: ""}}]
 * @param {string} language - Language label to find translation for
 * @returns {object} translation {label: "", description: ""}
 */
export default function getTranslation(translations, language) {
  if (translations && translations.length) {
    let translation = translations.find(
      (translation) => translation.language.label === language
    );
    if (!translation) {
      translation = translations.find(
        (translation) => translation.language.label === "nl"
      ); // Fall back to Dutch (TODO: Review)
      if (!translation) {
        translation = translations[0]; // Fall back to any available translation
      }
    }
    return {
      label: translation.label,
      description: translation.description,
    };
  } else {
    return {
      label: "No translation found",
      description: "No translation found",
    };
  }
}
