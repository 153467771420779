import React from "react";
import PropTypes from "prop-types";

/**
 * Radio selection input
 * @param {string} id - Input Id
 * @param {string} label - Input label
 * @param {string} value - Input value
 * @param {function} onChange - Change handler callback
 * @param {array} options - Options
 * @param {string} className - ClassName overwrite
 * @returns {node} React component
 */
function Radio({ id, label, value, onChange, options, className }) {
  return (
    <div className={`space-y-2 ${className}`}>
      <label>{label}</label>
      {options.map((option) => (
        <div
          key={`${id}${option.value}`}
          className="flex flex-row items-center"
        >
          <input
            id={`${id}-${option.value}`}
            type="radio"
            value={option.value}
            name={`${id}-${option.value}`}
            checked={value === option.value}
            onChange={(e) => onChange(e.target.value)}
            className="mr-2 radio radio-sm radio-primary"
          />
          <label htmlFor={`${id}-${option.value}`} className="cursor-pointer">
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  className: PropTypes.string,
};

export default Radio;
