import { useTranslation } from "react-i18next";

/**
 * React hook to obtain route operator constants and translations in the user language
 * @returns {object} Route operators
 */
export default function useRouteOperators() {
  const { t } = useTranslation();

  const routeOperators = {
    always: {
      label: t("utils.use-route-operators.always.label", "always"),
    },
    equal: {
      label: t("utils.use-route-operators.equal.label", "response equals"),
    },
    not_equal: {
      label: t(
        "utils.use-route-operators.not_equal.label",
        "response does not equal"
      ),
    },
    begins_with: {
      label: t(
        "utils.use-route-operators.begins_with.label",
        "response begins with"
      ),
    },
    ends_with: {
      label: t(
        "utils.use-route-operators.ends_with.label",
        "response ends with"
      ),
    },
    contains: {
      label: t("utils.use-route-operators.contains.label", "response contains"),
    },
    not_contains: {
      label: t(
        "utils.use-route-operators.not_contains.label",
        "response does not contain"
      ),
    },
    lower_than: {
      label: t(
        "utils.use-route-operators.lower_than.label",
        "resonse is lower than"
      ),
    },
    lower_equal_than: {
      label: t(
        "utils.use-route-operators.lower_equal_than.label",
        "response is lower than or equal to"
      ),
    },
    greater_than: {
      label: t(
        "utils.use-route-operators.greater_than.label",
        "responses is greater than"
      ),
    },
    greater_equal_than: {
      label: t(
        "utils.use-route-operators.greater_equal_than.label",
        "response is greater than or equal to"
      ),
    },
    is: {
      label: t("utils.use-route-operators.is.label", "response is"),
    },
    is_not: {
      label: t("utils.use-route-operators.is_not.label", "response is not"),
    },
  };

  return routeOperators;
}
