import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  useReportComponentTranslations,
  trimString,
  userRoleLevels,
} from "../../../utils";
import { Button, Icons, MinLevel } from "../..";

function PageComponent({ component, onEditComponent, onDeleteComponent }) {
  // Hooks
  const componentTranslations = useReportComponentTranslations();
  const { t } = useTranslation();
  // State
  const [content, setContent] = useState("");
  // Effect
  useEffect(() => {
    const { componentType, options, childComponents, content } = component;
    const opts = JSON.parse(options);
    const { label } = componentType;
    if (
      label === "Heading" ||
      label === "Paragraph" ||
      label === "ScoreCard" ||
      label === "NetPromotorScore"
    ) {
      setContent(trimString(content));
    } else if (label === "Image") {
      const parts = content.split("-");
      const fileName = parts[parts.length - 1];
      setContent(fileName);
    } else if (label === "Table") {
      setContent(`${opts.table.label ? opts.table.label : ""}`);
    } else if (label === "Chart") {
      setContent(
        `${opts.chart.type}${opts.chart.label ? ` - ${opts.chart.label}` : ""}`
      );
    } else {
      setContent(
        `${childComponents.length} ${t(
          "components.page-component.labels.children",
          "subcomponents"
        )}`
      );
    }
  }, [component]);

  return (
    <div className="w-full card bg-base-100 border shadow-md rounded-box px-5 py-5">
      <div className="card-content">
        <div className="w-full flex flex-row items-center">
          <div className="text-xl font-bold flex-grow">
            {componentTranslations[component.componentType.label]}
          </div>
          <MinLevel minLevel={userRoleLevels.Manager}>
            <div className="space-x-1">
              <Button
                color="primary"
                size="sm"
                onClick={() => onEditComponent(component)}
              >
                <Icons.Edit className="text-xl" />
              </Button>
              <Button
                color="error"
                size="sm"
                onClick={() => onDeleteComponent(component)}
              >
                <Icons.Delete className="text-xl" />
              </Button>
            </div>
          </MinLevel>
        </div>
        <div className="text-sm text-gray-600 pb-2">{content}</div>
      </div>
    </div>
  );
}

PageComponent.propTypes = {
  component: PropTypes.object,
  onEditComponent: PropTypes.func,
  onDeleteComponent: PropTypes.func,
};

export default PageComponent;
