import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { toast } from "react-hot-toast";
import { Checkbox, EmailInput, Select, Radio } from "../..";
import { getTranslation } from "../../../utils";
import calls from "../../../graphql";
const { GETQUESTIONS, GETSURVEYREPORTSTRUCTURES } = calls;

/**
 * Response task publish form
 * Step three in the publication window flow
 * @param {array} selectedLanguages - Languages supported by the survey [{id: "", label: ""}]
 * @param {string} surveyId - Id of the survey to be published
 * @param {boolean} sendResponseEmail - True if respondents should be mailed after completing the survey
 * @param {function} setSendResponseEmail - Setter function
 * @param {string} responseEmailFrom - Where to send response emails from (either alert or impuls)
 * @param {function} setResponseEmailFrom - Setter function
 * @param {string} responseEmailFieldId - Id of the question field from which an email address should be extracted
 * @param {function} setResponseEmailFieldId - Setter function
 * @param {object} responseEmailSubjects - Email subjects object {["langageLabel"]: "subject"}
 * @param {function} setResponseEmailSubjects - Setter function
 * @param {object} responseEmailMessages - Email messages object {["langageLabel"]: "message"}
 * @param {function} setResponseEmailMessages - Setter function
 * @param {string} responseReportTypeId - Id of the report type to generate from the response
 * @param {function} setResponseReportTypeId - Setter function
 * @returns {node} React component
 */
function ResponseTaskForm({
  selectedLanguages,
  surveyId,
  sendResponseEmail,
  setSendResponseEmail,
  responseEmailFrom,
  setResponseEmailFrom,
  responseEmailFieldId,
  setResponseEmailFieldId,
  responseEmailSubjects,
  setResponseEmailSubjects,
  responseEmailMessages,
  setResponseEmailMessages,
  responseReportStructureId,
  setResponseReportStructureId,
}) {
  // Hooks
  const { t, i18n } = useTranslation();
  // States
  const [reportStructures, setReportStructures] = useState([]);
  const [emailFields, setEmailFields] = useState([]);
  // Queries
  const getReportStructuresState = useQuery(GETSURVEYREPORTSTRUCTURES, {
    variables: {
      id: surveyId,
    },
  });
  const getQuestionsState = useQuery(GETQUESTIONS, {
    variables: {
      options: JSON.stringify({
        where: {
          "$questionType.label$": "EmailAddress",
          "$surveySection.surveyId$": surveyId,
        },
        include: ["questionType", "surveySection"],
      }),
    },
  });
  // Handle errors
  if (getReportStructuresState.error)
    toast.error(getReportStructuresState.error.message);
  if (getQuestionsState.error) toast.error(getQuestionsState.error.message);
  // Effects
  useEffect(() => {
    if (getQuestionsState.data && getQuestionsState.data.questions) {
      setEmailFields(getQuestionsState.data.questions);
    }
  }, [getQuestionsState.data]);
  useEffect(() => {
    if (getReportStructuresState.data) {
      const { survey } = getReportStructuresState.data;
      setReportStructures(
        survey.reportStructures.filter((structure) => structure.isIndividual) // Reports for individual responses
      );
    }
  }, [getReportStructuresState.data]);

  return (
    <div className="space-y-2 flex flex-col w-full">
      <Checkbox
        id="send-response-email"
        label={t(
          "components.publish-response-task.reply-respondents-label",
          "Reply respondents"
        )}
        checked={sendResponseEmail}
        toggle={() => setSendResponseEmail(!sendResponseEmail)}
      />
      {sendResponseEmail ? (
        <>
          <Select
            id="response-email-field"
            label={t(
              "components.publish-response-task.emailfield-label",
              "Email address field"
            )}
            value={responseEmailFieldId}
            onChange={setResponseEmailFieldId}
            options={emailFields.map((question) => ({
              value: question.id,
              label: getTranslation(question.translations, i18n.language).label,
            }))}
            placeholderValue={"0"}
            placeholderLabel={t(
              "components.publish-response-task.emailfield-placeholder",
              "Select field"
            )}
          />
          <Radio
            id="send-mail-from"
            label={t(
              "components.publish-response-task.send-from-label",
              "Send from"
            )}
            options={[
              { label: "Impuls", value: "impuls" },
              { label: "Alert", value: "alert" },
            ]}
            value={responseEmailFrom}
            onChange={setResponseEmailFrom}
          />
          <EmailInput
            selectedLanguages={selectedLanguages}
            id="response"
            emailSubjects={responseEmailSubjects}
            setEmailSubjects={setResponseEmailSubjects}
            emailMessages={responseEmailMessages}
            setEmailMessages={setResponseEmailMessages}
          />
          <Select
            id="report"
            label={t(
              "components.publish-response-task.report-label",
              "Attach report"
            )}
            value={responseReportStructureId}
            onChange={setResponseReportStructureId}
            options={[
              {
                value: "0",
                label: t(
                  "components.publish-response-task.report-select-none",
                  "No report"
                ),
              },
              ...reportStructures.map((structure) => ({
                value: structure.id,
                label: structure.label,
              })),
            ]}
          />
        </>
      ) : undefined}
    </div>
  );
}

ResponseTaskForm.propTypes = {
  selectedLanguages: PropTypes.array,
  surveyId: PropTypes.string,
  sendResponseEmail: PropTypes.bool,
  setSendResponseEmail: PropTypes.func,
  responseEmailFrom: PropTypes.oneOf(["impuls", "alert"]),
  setResponseEmailFrom: PropTypes.func,
  responseEmailFieldId: PropTypes.string,
  setResponseEmailFieldId: PropTypes.func,
  responseEmailSubjects: PropTypes.object,
  setResponseEmailSubjects: PropTypes.func,
  responseEmailMessages: PropTypes.object,
  setResponseEmailMessages: PropTypes.func,
  responseReportStructureId: PropTypes.string,
  setResponseReportStructureId: PropTypes.func,
};

export default ResponseTaskForm;
