import { useTranslation } from "react-i18next";

/**
 * React hook to obtain question direction constants and translations in the user language
 * @returns {object} Question directions
 */
export default function useQuestionDirections() {
  const { t } = useTranslation();

  const questionDirections = {
    not_applicable: {
      value: "NOTAPPLICABLE",
      label: t(
        "utils.use-question-directions.directions.not-applicable",
        "Not Applicable"
      ),
    },
    positive: {
      value: "POSITIVE",
      label: t("utils.use-question-directions.directions.positive", "Positive"),
    },
    negative: {
      value: "NEGATIVE",
      label: t("utils.use-question-directions.directions.negative", "Negative"),
    },
  };
  return questionDirections;
}
