import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Icons, MultipleSelect } from "../..";

/**
 * Question category and tags form component
 * @param {array} questionCategories - Available question categories
 * @param {array} questionCategoryIds - Selected question category ids
 * @param {function} setQuestionCategoryIds - Setter function
 * @param {array} questionTagIds - Selected question tag ids
 * @param {function} setQuestionTagIds - Setter function
 * @param {function} setCategoryModalOpen - Setter function for category management modal
 * @returns {node} React component
 */
function CategoryForm({
  questionCategories,
  questionCategoryIds,
  setQuestionCategoryIds,
  questionTagIds,
  setQuestionTagIds,
  setCategoryModalOpen,
  setChanged,
}) {
  // Hooks
  const { t } = useTranslation();
  // State
  const [questionTags, setQuestionTags] = useState([]);
  // Effects
  useEffect(() => {
    const tags = questionCategoryIds.reduce((tagArray, id) => {
      const category = questionCategories.find((cat) => cat.id === id);
      if (category) {
        return [
          ...tagArray,
          ...category.tags.map(({ id, label }) => ({
            id,
            label,
            color: category.color,
          })),
        ];
      } else {
        return tagArray;
      }
    }, []);
    setQuestionTags(tags);
  }, [questionCategoryIds, questionCategories]);

  const onDeleteCategoryId = (id) => {
    const category = questionCategories.find((cat) => cat.id === id);
    if (category) {
      const { tags } = category;
      const tagIds = tags.map((tag) => tag.id);
      const selectedTagIds = [...questionTagIds].filter(
        (id) => !tagIds.includes(id)
      );
      setQuestionTagIds(selectedTagIds);
      setChanged(true);
    }
  };

  const onAddCategoryId = () => {
    setChanged(true);
  };

  return (
    <>
      <div className="flex flex-row items-center">
        <label htmlFor="category" className="flex-grow">
          {t("project.survey.question.form.category-label", "Category")}
        </label>
        <div
          data-tip={t(
            "project.survey.question.form.category-explanation",
            "Questions can be grouped by assigning a category."
          )}
          className="tooltip tooltip-left"
        >
          <Icons.Info className="text-xl text-gray-600 hover:text-black" />
        </div>
        <button
          data-tip={t(
            "project.survey.question.form.category-settings-label",
            "Manage categories and tags."
          )}
          className="tooltip tooltip-left"
          type="button"
          onClick={() => setCategoryModalOpen(true)}
        >
          <Icons.Settings className="text-xl text-gray-600 hover:text-black" />
        </button>
      </div>
      <MultipleSelect
        id="question-categories"
        selectedValues={questionCategoryIds}
        setSelectedValues={setQuestionCategoryIds}
        options={questionCategories.map((category) => ({
          value: category.id,
          label: category.label,
          color: category.color,
        }))}
        selectPlaceholder={t(
          "project.survey.question.form.categories-placeholder",
          "Add Categories"
        )}
        onAddCallback={onAddCategoryId}
        onDeleteCallback={onDeleteCategoryId}
      />
      {questionCategoryIds && questionCategoryIds.length ? (
        <>
          <div className="flex flex-row items-center">
            <label htmlFor="question-tags" className="flex-grow">
              {t("project.survey.question.form.tags-label", "Tags")}
            </label>
            <div
              data-tip={t(
                "project.survey.question.form.tags-explanation",
                "A question category can be further specified by means of tags."
              )}
              className="tooltip tooltip-left"
            >
              <Icons.Info className="text-xl text-gray-600 hover:text-black" />
            </div>
          </div>
          <MultipleSelect
            id="question-tags"
            selectedValues={questionTagIds}
            setSelectedValues={(ids) => {
              setQuestionTagIds(ids);
              setChanged(true);
            }}
            options={questionTags.map((tag) => ({
              value: tag.id,
              label: tag.label,
              color: tag.color,
            }))}
            selectPlaceholder={t(
              "project.survey.question.form.tags-placeholder",
              "Add Tags"
            )}
          />
        </>
      ) : undefined}
    </>
  );
}

CategoryForm.propTypes = {
  questionCategories: PropTypes.array,
  questionCategoryIds: PropTypes.array,
  setQuestionCategoryIds: PropTypes.func,
  questionTagIds: PropTypes.array,
  setQuestionTagIds: PropTypes.func,
  setCategoryModalOpen: PropTypes.func,
  setChanged: PropTypes.func,
};

export default CategoryForm;
