import {
  HiArchive,
  HiInformationCircle,
  HiCheck,
  HiPencil,
  HiChevronLeft,
  HiChevronRight,
  HiChevronDown,
  HiChevronUp,
  HiCog,
  HiExclamation,
  HiBan,
  HiUser,
  HiUserRemove,
  HiMinus,
  HiLockClosed,
  HiOutlineX,
  HiMenu,
  HiPlus,
  HiTrash,
  HiPaperAirplane,
  HiCloudDownload,
  HiQuestionMarkCircle,
  HiClipboardCheck,
  HiChartBar,
  HiX,
  HiRefresh,
  HiDuplicate,
  HiUserGroup,
  HiUpload,
  HiClipboardList,
} from "react-icons/hi";

import {
  AiOutlineLoading,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
} from "react-icons/ai";

const Icons = {
  Add: HiPlus,
  Archive: HiArchive,
  Breadcrumb: HiChevronRight,
  Cancel: HiX,
  Close: HiOutlineX,
  Copy: HiDuplicate,
  Delete: HiTrash,
  Download: HiCloudDownload,
  Edit: HiPencil,
  Error: HiBan,
  Fold: HiChevronUp,
  FullScreen: AiOutlineFullscreen,
  ExitFullScreen: AiOutlineFullscreenExit,
  Group: HiUserGroup,
  Info: HiInformationCircle,
  Loading: AiOutlineLoading,
  Login: HiUser,
  Logout: HiUserRemove,
  Menu: HiMenu,
  Next: HiChevronRight,
  Previous: HiChevronLeft,
  Publication: HiClipboardCheck,
  Question: HiQuestionMarkCircle,
  Register: HiLockClosed,
  Remove: HiMinus,
  Report: HiChartBar,
  Reset: HiRefresh,
  Save: HiCheck,
  Send: HiPaperAirplane,
  Settings: HiCog,
  Success: HiCheck,
  Survey: HiClipboardList,
  Sync: HiRefresh,
  Unfold: HiChevronDown,
  Upload: HiUpload,
  User: HiUser,
  Warning: HiExclamation,
};

export default Icons;
