import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuestionTypes, useUserRoles } from "../../utils";
import { Modal, Icons, QuestionCategorySettings } from "..";

/**
 * Info message modal
 * @param {boolean} modalOpen - Is modal open
 * @param {function} closeModal - Close modal function
 * @param {label} infoModalType - Defines the message which will be shown in the info modal (TODO: needs refactor)
 * @returns {node} React modal component
 */
function InfoModal({ modalOpen, closeModal, infoModalType }) {
  const { t } = useTranslation();
  return (
    <Modal modalOpen={modalOpen} closeModal={closeModal}>
      <button onClick={closeModal} className="absolute right-6 top-6">
        <Icons.Close className="text-xl" />
      </button>
      <div className="flex flex-row items-center w-full justify-center mb-2 space-x-1">
        <Icons.Info className="text-xl" />
        <h1 className="text-xl font-bold text-center">
          {t("components.question-info-modal.labels.title", "Info")}
        </h1>
      </div>
      <div>
        <InfoModalMessage type={infoModalType} />
      </div>
    </Modal>
  );
}

InfoModal.propTypes = {
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  infoModalType: PropTypes.string,
};

export default InfoModal;

/**
 * Info modal content component
 * @param {string} type - Defines info modal content (TODO: needs refactor)
 * @returns {node} Content for the info modal
 */
const InfoModalMessage = ({ type }) => {
  const { t } = useTranslation();
  const questionTypes = useQuestionTypes();
  const userRoles = useUserRoles();
  let message = null;
  switch (type) {
    case "type":
      message = (
        <div>
          {Object.entries(questionTypes).map(([key, value]) => (
            <div key={`questiontype${key}`}>
              <h3 className="pt-2 font-bold">{value.label}:</h3>
              <p>{value.explanation}</p>
            </div>
          ))}
        </div>
      );
      break;
    case "category":
      message = <QuestionCategorySettings />;
      break;
    case "direction":
      message = (
        <div>
          <p>
            {t(
              "components.question-info-modal.messages.direction.explanation",
              "This question type requires you to specify the scoring direction."
            )}
          </p>
          <p className="pt-2 font-bold">
            {t(
              "components.question-info-modal.messages.direction.examples-positive",
              "Positive question examples"
            )}
            :
          </p>
          <p>
            &qout;
            {t(
              "components.question-info-modal.messages.direction.example-positive-1",
              `Are you happy?`
            )}
            &qout;
          </p>
          <p>
            &qout;
            {t(
              "components.question-info-modal.messages.direction.example-positive-2",
              `How many positive encounters have you had in the last week?`
            )}
            &qout;
          </p>
          <p className="pt-2 font-bold">
            {t(
              "components.question-info-modal.messages.direction.examples-negative",
              "Negative question examples"
            )}
            :
          </p>
          <p>
            &qout;
            {t(
              "components.question-info-modal.messages.direction.example-negative-1",
              `Are you being bullied?`
            )}
            &qout;
          </p>
          <p>
            &qout;
            {t(
              "components.question-info-modal.messages.direction.example-negative-2",
              `How many times have you been uncomfortable at work in the last quarter? `
            )}
            &qout;
          </p>
        </div>
      );
      break;
    case "userroles":
      message = (
        <div>
          {Object.entries(userRoles).map(([key, value]) => (
            <div key={`questiontype${key}`}>
              <h3 className="pt-2 font-bold">{value.label}:</h3>
              <p>{value.explanation}</p>
            </div>
          ))}
        </div>
      );
      break;
    case "publish-email-message":
      message = (
        <div className="space-y-2">
          <h3 className="font-bold">
            {t(
              "components.question-info-modal.messages.publish-email-message.title",
              "Use templating in email message"
            )}
          </h3>
          <p>
            {t(
              "components.question-info-modal.messages.publish-email-message.explanation",
              "By adding certain keywords in curly braces to the message, you can add the respondent name and typeform."
            )}
          </p>
          <div className="mockup-code flex flex-row">
            <div className="pt-6 flex-grow space-y-1">
              <p>{"{{url}}"}</p>
              <p>{"{{name}}"}</p>
              <p>{"{{email}}"}</p>
            </div>
            <div className="pt-6 flex-grow space-y-1">
              <p>
                {t(
                  "components.question-info-modal.messages.publish-email-message.url-tag",
                  "Typeform url"
                )}
              </p>
              <p>
                {t(
                  "components.question-info-modal.messages.publish-email-message.name-tag",
                  "Respondent name"
                )}
              </p>
              <p>
                {t(
                  "components.question-info-modal.messages.publish-email-message.email-tag",
                  "Respondent email"
                )}
              </p>
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }

  return message;
};
