import React, { useState, useEffect } from "react";
import fuzzysort from "fuzzysort";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import calls from "../graphql";
import toast from "react-hot-toast";
import {
  formatDate,
  initials,
  userRoleLevels,
  useUserRoles,
  validatePasswordStrength,
} from "../utils";
import {
  LoggedInLayout,
  Breadcrumbs,
  Button,
  Icons,
  ActionModal,
  Table,
  TableRow,
  TableHead,
  TableData,
  Input,
  InfoSelect,
  InfoModal,
} from "../components";
const { GETUSERS, GETUSERROLES, UPDATEUSER } = calls;

export default function Users() {
  // Hooks
  const { t } = useTranslation();
  const userRoles = useUserRoles();
  // State
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userRoleId, setUserRoleId] = useState("0");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Queries
  const getUsersState = useQuery(GETUSERS);
  const getRolesState = useQuery(GETUSERROLES);
  // Mutations
  const [updateUser, updateUserState] = useMutation(UPDATEUSER, {
    refetchQueries: [GETUSERS],
  });
  // Handle errors
  if (getUsersState.error) toast.error(getUsersState.error.message);
  if (getRolesState.error) toast.error(getRolesState.error.message);
  // Effects
  useEffect(() => {
    const users =
      getUsersState.data && getUsersState.data.users
        ? getUsersState.data.users
        : [];
    if (search) {
      const delayedSearch = setTimeout(() => {
        const results = fuzzysort.go(search, users, {
          keys: ["userName", "email", "role.label"],
        });
        setUsers(results.map((result) => result.obj));
      }, 200);
      return () => clearTimeout(delayedSearch);
    } else {
      setUsers(users);
    }
  }, [search, getUsersState.data]);
  useEffect(() => {
    if (getRolesState.data && getRolesState.data.userRoles) {
      const roles = getRolesState.data.userRoles;
      setRoles(roles.filter((role) => !!userRoles[role.label]));
    }
  }, [getRolesState.data]);

  const onSaveUser = async () => {
    const variables = {
      id: selectedUser.id,
    };
    if (userName) {
      variables.newUserName = userName;
      variables.newUserRoleId = userRoleId !== "0" ? userRoleId : undefined;
      if (password && confirmPassword) {
        if (password !== confirmPassword) {
          toast.custom(
            t(
              "users.messages.warning.passwords-dont-match",
              "Passwords don't match."
            )
          );
          return;
        }
        if (!validatePasswordStrength(password)) {
          toast.custom(
            t(
              "users.messages.warning.password not safe",
              "Password must contain 1 lowercase, 1 uppercase, 1 special character and 1 number."
            )
          );
          return;
        }
        variables.newPassword = password;
        variables.newPasswordConfirmation = confirmPassword;
      }

      try {
        await updateUser({ variables: variables });
        toast.success(t("users.messages.success.update-user", "Saved user!"));
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.custom(
        t(
          "users.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: "/users",
                label: t("users.labels.users", "Users"),
              },
            ]}
          />
        }
        isLoading={getUsersState.loading}
        minLevel={userRoleLevels.Admin}
      >
        {search || (users && users.length) ? (
          <>
            <div className="flex items-center">
              <div className="flex-grow">
                <Input
                  id="search"
                  placeholder={t("user.surveys.labels.search", "Search")}
                  value={search}
                  onChange={setSearch}
                  className="w-4/5"
                />
              </div>
            </div>
            <Table
              head={
                <tr className="h-10">
                  <TableHead>{t("users.table.headers.user", "User")}</TableHead>
                  <TableHead>{t("users.table.headers.role", "Role")}</TableHead>
                  <TableHead>
                    {t("users.table.headers.signup-date", "First login")}
                  </TableHead>
                  <TableHead>
                    {t("users.table.headers.login-date", "Last login")}
                  </TableHead>
                  <TableHead />
                </tr>
              }
            >
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableData>
                    <div className="flex items-center space-x-4">
                      <div className="avatar placeholder">
                        <div className="bg-base-300 text-gray-600 rounded-full w-10 h-10">
                          {initials(user.userName)}
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">{user.userName}</div>
                        <div className="font-normal">{user.email}</div>
                      </div>
                    </div>
                  </TableData>
                  <TableData>
                    {user.role && user.role.level <= 40
                      ? userRoles[user.role.label].label
                      : t("users.table.labels.developer", "Developer")}
                  </TableData>
                  <TableData>{formatDate(user.createdAt)}</TableData>
                  <TableData>{formatDate(user.updatedAt)}</TableData>
                  <TableData>
                    <div className="font-normal flex justify-end space-x-2">
                      <Button
                        onClick={() => {
                          setSelectedUser(user);
                          setUserName(user.userName);
                          setUserRoleId(user.role.id);
                          setPassword("");
                          setConfirmPassword("");
                          setEditUserModalOpen(true);
                        }}
                        isLoading={updateUserState.loading}
                        color="primary"
                        size="sm"
                        start={<Icons.Edit className="text-xl" />}
                      >
                        {t("users.labels.edit-user", "Edit")}
                      </Button>
                    </div>
                  </TableData>
                </TableRow>
              ))}
            </Table>
          </>
        ) : (
          <div className="text-3xl">
            {t("users.messages.warning.no-users", "No users found")}
          </div>
        )}
      </LoggedInLayout>
      <ActionModal
        title={
          selectedUser
            ? selectedUser.userName
            : t("users.modals.edit-user.title", "Edit user")
        }
        modalOpen={editUserModalOpen}
        closeModal={() => setEditUserModalOpen(false)}
        actions={
          <Button
            onClick={onSaveUser}
            isLoading={updateUserState.loading}
            size="sm"
            start={<Icons.Save className="text-lg" />}
          >
            {t("users.modals.edit-user.save-user", "Save")}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveUser}>
          <Input
            id="username"
            label={t("users.modals.edit-user.username", "User name")}
            placeholder={t(
              "users.modals.edit-user.username-placeholder",
              "username"
            )}
            value={userName}
            onChange={setUserName}
          />
          <InfoSelect
            label={t("user.modals.edit-user.userrole-label", "Role")}
            setInfoModalOpen={setInfoModalOpen}
            setInfoModalType={() => {}}
            infoMessage={t(
              "user.modals.edit-user.userrole-explanation",
              "A user's permission depends on their role. Click for more information."
            )}
            id="userrole"
            value={userRoleId}
            onChange={setUserRoleId}
            options={roles.map((role) => ({
              value: role.id,
              label: role.label,
            }))}
            placeholderValue="0"
            placeholderLabel={t(
              "user.modals.edit-user.userrole-placeholder",
              "Select role"
            )}
          />
          <Input
            id="password"
            label={t("users.modals.edit-user.password", "Password")}
            placeholder={t(
              "users.modals.edit-user.password-placeholder",
              "password"
            )}
            value={password}
            onChange={setPassword}
            isPassword={true}
          />
          <Input
            id="confirmpassword"
            label={t(
              "users.modals.edit-user.confirm-password",
              "Confirm password"
            )}
            placeholder={t(
              "users.modals.edit-user.confirm-password-placeholder",
              "password"
            )}
            value={confirmPassword}
            onChange={setConfirmPassword}
            isPassword={true}
          />
        </form>
      </ActionModal>
      <InfoModal
        modalOpen={infoModalOpen}
        closeModal={() => setInfoModalOpen(false)}
        infoModalType={"userroles"}
      />
    </>
  );
}
