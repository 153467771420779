import { useEffect } from "react";

/**
 * Scrolls to the element with id refered to in the hash
 * @param {int} offset - With how much offset we should scroll
 * @returns Element to which has been scrolled
 */
const scrollToHashElement = (offset) => {
  const elementToScroll = window.location.hash
    ? document.querySelector(window.location.hash)
    : undefined;

  return elementToScroll
    ? window.scrollTo({
        top: elementToScroll.offsetTop - offset,
        // behavior: "smooth",
      })
    : undefined;
};

/**
 * Effect which ensures scrolling to the hash id element
 * @param {int} offset - How much offset should be applied when scrolling
 * @param {boolean} trigger - Whether or not the scroll should be applied already
 */
export default function useHashFragment(offset = 0, trigger = true) {
  useEffect(() => {
    if (trigger) {
      scrollToHashElement(offset);
    }
  }, [trigger, offset]);
}
