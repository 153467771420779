import React from "react";
import PropTypes from "prop-types";

const sizeClassNames = {
  xs: "select-xs",
  sm: "select-sm",
  md: "select-md",
  lg: "select-lg",
};

/**
 * Select input component
 * @param {string} id - Input id
 * @param {string} label - Input label
 * @param {string} value - Input value
 * @param {string} placeholderLabel - Input placeholder label
 * @param {string} placeholderValue - Input placeholder value
 * @param {string} onChange - Input change handler
 * @param {string} hasError - Error state
 * @param {string} hasWarning - Warning state
 * @param {string} options - Select options
 * @param {string} size - Input size
 * @param {string} isDisabled - True if input should be disabled
 * @param {string} className - ClassName overwrite
 * @returns {node} React select input component
 */
function Select({
  id,
  label,
  value,
  placeholderLabel,
  placeholderValue,
  onChange,
  hasError,
  hasWarning,
  options,
  size,
  isDisabled,
  className,
}) {
  const sizeClassName = sizeClassNames[size];

  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      {label ? <label htmlFor={id}>{label}</label> : undefined}
      <select
        id={id}
        className={`select select-bordered font-normal ${
          hasWarning ? "select-warning" : ""
        } ${hasError ? "select-error" : ""} ${sizeClassName || ""}`}
        value={value}
        onChange={isDisabled ? () => {} : (e) => onChange(e.target.value)}
        disabled={isDisabled}
      >
        {placeholderLabel && value === placeholderValue ? (
          <option disabled="disabled" value={placeholderValue}>
            {placeholderLabel}
          </option>
        ) : undefined}
        {options.map((option, index) =>
          option.options && option.options.length ? (
            <optgroup
              label={option.label}
              key={`optgroup${option.label}${index}`}
            >
              {option.options.map((opt) => (
                <option
                  key={`option${id}${option.label}${opt.value}${index}`}
                  value={opt.value}
                >
                  {opt.label}
                </option>
              ))}
            </optgroup>
          ) : (
            <option
              key={`option${id}${option.value}${index}`}
              value={option.value}
            >
              {option.label}
            </option>
          )
        )}
      </select>
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  placeholderLabel: PropTypes.string,
  placeholderValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
    })
  ),
  size: PropTypes.oneOf(Object.keys(sizeClassNames)),
  className: PropTypes.string,
};

Select.defaultProps = {
  id: "",
  label: "",
  value: "",
  placeholderLabel: "",
  placeholderValue: "-1",
  onChange: () => {},
  hasError: false,
  hasWarning: false,
  isDisabled: false,
  options: [],
  size: "md",
  className: "",
};

export default Select;
