import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import pages from "./pages";
import {
  AppStateContext,
  fetchAccessToken,
} from "./components/functional/Provider";
import { Notifications, Loading } from "./components";

function App() {
  const [loading, setLoading] = useState(true);
  const { appSetLogin, appSetLogout } = useContext(AppStateContext);

  useEffect(() => {
    fetchAccessToken()
      .then(({ token, userId, userLevel }) => {
        if (token) {
          appSetLogin(token, userId, userLevel);
        } else {
          appSetLogout();
        }
      })
      .catch(() => appSetLogout())
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <div className="h-screen flex flex-col">
        <Loading />
      </div>
    );

  return (
    <Router>
      <Notifications />
      <Switch>
        {pages.map(({ path, page }) =>
          path ? (
            <Route key={path} exact path={path} component={page} />
          ) : (
            <Route key="notFound" component={page} />
          )
        )}
      </Switch>
    </Router>
  );
}

export default App;
