import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import calls from "../../graphql";
import { AppStateContext } from "./Provider";
import { Header, Loading } from "..";
import toast from "react-hot-toast";
import { initials as makeInitials, userRoleLevels } from "../../utils";
const { PROFILE } = calls;

/**
 * Layout for the pages which requires login
 * Redirects if not logged in or unauthorized
 * @param {node} children - Page content
 * @param {string} page - Page title
 * @param {node} breadCrumbs - Navigation links (react node)
 * @param {string} actions - Right-hand navbar content
 * @param {string} isLoading - Loading state
 * @param {string} minLevel - Minimal level required to view the page
 *  @returns {node} React logged in layout wrapper
 */
export default function LoggedInLayout({
  children,
  page,
  breadCrumbs,
  actions,
  isLoading,
  minLevel,
}) {
  const history = useHistory();
  const { appState } = useContext(AppStateContext);
  const { loggedIn, userLevel } = appState;
  if (!loggedIn) {
    history.replace("/login");
  }
  if (minLevel && userLevel < minLevel) {
    history.replace("/forbidden");
  }
  let initials = "?";
  const { data, error } = useQuery(PROFILE);
  if (error) toast.error(error.message);
  if (data && data.me) {
    if (data.me.userName) {
      initials = makeInitials(data.me.userName);
    } else {
      initials = makeInitials(data.me.email);
    }
  }

  return (
    <div className="min-h-screen flex flex-col shadow-sm">
      <Header page={page} initials={initials} breadcrumbs={breadCrumbs}>
        {actions}
      </Header>
      <div className="px-5 md:px-12 py-5 md:py-12 bg-base-200 flex flex-col flex-grow space-y-5">
        {isLoading ? <Loading /> : children}
      </div>
    </div>
  );
}

LoggedInLayout.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
  breadCrumbs: PropTypes.node,
  actions: PropTypes.node,
  isLoading: PropTypes.bool,
  minLevel: PropTypes.number,
};

LoggedInLayout.defaultProps = {
  children: undefined,
  page: undefined,
  breadCrumbs: undefined,
  actions: undefined,
  isLoading: false,
  minLevel: userRoleLevels.User,
};
