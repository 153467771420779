import React, { useState, useEffect } from "react";
import {
  LoggedInLayout,
  Breadcrumbs,
  ConfirmModal,
  Icons,
  Button,
  ActionModal,
  Table,
  TableData,
  TableRow,
  Input,
  MinLevel,
} from "../../components";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import calls from "../../graphql";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { validateEmail, userRoleLevels } from "../../utils";
import { useParams } from "react-router-dom";
const { GETCLIENT, UPDATECLIENT, DELETECLIENT, CREATERESPONDENTLIST } = calls;

export default function Client() {
  // Hooks
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();
  // State
  const [client, setClient] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientIndustry, setClientIndustry] = useState("");
  const [clientURL, setClientURL] = useState("");
  const [deleteClientModalOpen, setDeleteClientModalOpen] = useState(false);
  const [createMailingListModalOpen, setCreateMailingListModalOpen] =
    useState(false);
  const [mailingListTitle, setMailingListTitle] = useState("");
  // Queries
  const getClientState = useQuery(GETCLIENT, {
    variables: {
      id: clientId,
    },
    fetchPolicy: "cache-and-network",
  });
  // Mutations
  const [updateClient, updateClientState] = useMutation(UPDATECLIENT, {
    refetchQueries: [GETCLIENT],
  });
  const [deleteClient, deleteClientState] = useMutation(DELETECLIENT);
  const [createRespondentList, createRespondentListState] = useMutation(
    CREATERESPONDENTLIST,
    {
      refetchQueries: [GETCLIENT],
    }
  );
  // Handle errors
  if (getClientState.error) toast.error(getClientState.error.message);
  // Effects
  useEffect(() => {
    if (getClientState.data && getClientState.data.client) {
      const client = getClientState.data.client;
      setClient(client);
      setClientName(client.name);
      setClientEmail(client.email);
      setClientIndustry(client.industry);
      setClientURL(client.url);
    }
  }, [getClientState.data]);

  const onSaveMailingList = async () => {
    if (mailingListTitle) {
      try {
        const { data } = await createRespondentList({
          variables: {
            label: mailingListTitle,
            clientId: clientId,
          },
        });
        toast.success(
          t(
            "clients.client.messages.success.mailing-list-created",
            "Mailing list saved!"
          )
        );
        setCreateMailingListModalOpen(false);
        if (data && data.createRespondentList.id) {
          history.replace(
            `/clients/${client.id}/mailinglists/${data.createRespondentList.id}/mailinglist`
          );
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.custom(
        t(
          "clients.client.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  const onSaveClient = async () => {
    if (clientName && clientEmail) {
      try {
        const { data } = await updateClient({
          variables: {
            id: clientId,
            name: clientName ? clientName : client.name,
            email: clientEmail ? clientEmail : client.email,
            industry: clientIndustry ? clientIndustry : client.industry,
            url: clientURL ? clientURL : client.url,
          },
        });
        if (data && data.updateClient && data.updateClient.id) {
          toast.success(
            t("clients.client.messages.success.update-client", "Client saved!")
          );
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.custom(
        t(
          "clients.client.messages.warning.complete-fields",
          "Please complete all fields."
        )
      );
    }
  };

  const onDeleteClient = async () => {
    if (clientId) {
      try {
        const { data } = await deleteClient({
          variables: {
            id: clientId,
          },
        });
        if (data.deleteClient) {
          setDeleteClientModalOpen(false);
          toast.success(
            t(
              "clients.client.messages.success.delete-client",
              "Client deleted!"
            )
          );
          history.replace("/clients");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: "/clients",
                label: t("clients.client.labels.clients", "Clients"),
              },
              {
                link: `/clients/${clientId}/mailinglists`,
                label: t(
                  "clients.client.labels.mailing-lists",
                  "Mailing lists"
                ),
              },
            ]}
            back="/clients"
          />
        }
        isLoading={getClientState.loading}
      >
        <div className="w-3/4 self-center flex flex-col space-y-10">
          <h2 className="text-2xl md:text-3xl font-bold">
            {client ? client.name : t("clients.client.labels.title", "Client")}
          </h2>
          <div className="w-full space-y-2">
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-2xl font-bold">
                {t("clients.client.mailing-lists.title", "Mailing lists")}
              </h3>
              <MinLevel minLevel={userRoleLevels.Manager}>
                <Button
                  color="primary"
                  onClick={() => setCreateMailingListModalOpen(true)}
                  start={<Icons.Add className="text-xl" />}
                >
                  {t("clients.client.mailing-lists.new", "New mailing list")}
                </Button>
              </MinLevel>
            </div>
            {client &&
            client.respondentLists &&
            client.respondentLists.length ? (
              <Table>
                {client.respondentLists.map((list) => (
                  <TableRow key={`respondentList${list.id}`}>
                    <TableData>{list.label}</TableData>
                    <TableData>
                      <Link
                        to={`/clients/${client.id}/mailinglists/${list.id}/mailinglist`}
                        className="flex justify-end"
                      >
                        <Icons.Next className="text-4xl" />
                      </Link>
                    </TableData>
                  </TableRow>
                ))}
              </Table>
            ) : (
              <p>
                {t(
                  "clients.client.mailing-lists.no-lists-found",
                  "No mailing lists found, create one!"
                )}
              </p>
            )}
          </div>
          <MinLevel minLevel={userRoleLevels.Manager}>
            <form
              className="form-control space-y-2 w-full"
              onSubmit={onSaveClient}
            >
              <h3 className="text-3xl font-bold">
                {t("clients.client.edit-client-form.title", "Edit client")}
              </h3>
              <Input
                id="client-name"
                label={t("clients.client.edit-client-form.name-label", "Name")}
                placeholder={t(
                  "clients.client.edit-client-form.name-placeholder",
                  "name"
                )}
                value={clientName}
                onChange={setClientName}
              />
              <Input
                id="email"
                label={t(
                  "clients.client.edit-client-form.email-label",
                  "Email address"
                )}
                placeholder={t(
                  "clients.client.edit-client-form.email-placeholder",
                  "email"
                )}
                value={clientEmail}
                hasWarning={!validateEmail(clientEmail) && !!clientEmail}
                onChange={setClientEmail}
              />
              <Input
                id="industry"
                label={t(
                  "clients.client.edit-client-form.industry-label",
                  "Industry"
                )}
                placeholder={t(
                  "clients.client.edit-client-form.industry-placeholder",
                  "industry"
                )}
                value={clientIndustry}
                onChange={setClientIndustry}
              />
              <Input
                id="url"
                label={t(
                  "clients.client.edit-client-form.url-label",
                  "Website"
                )}
                placeholder={t(
                  "clients.client.edit-client-form.url-placeholder",
                  "url"
                )}
                value={clientURL}
                onChange={setClientURL}
              />
              <Button
                onClick={onSaveClient}
                color="primary"
                isLoading={updateClientState.loading}
                start={<Icons.Save className="text-xl" />}
              >
                {t("clients.client.edit-client-form.save", "Save Client")}
              </Button>
              <Button
                onClick={() => setDeleteClientModalOpen(true)}
                color="error"
                isLoading={deleteClientState.loading}
                start={<Icons.Delete className="text-xl" />}
              >
                {t("clients.client.edit-client-form.delete", "Delete Client")}
              </Button>
            </form>
          </MinLevel>
        </div>
      </LoggedInLayout>
      <ActionModal
        title={t(
          "clients.client.modals.create-mailing-list.title",
          "New mailing list"
        )}
        modalOpen={createMailingListModalOpen}
        closeModal={() => setCreateMailingListModalOpen(false)}
        actions={
          <Button
            onClick={onSaveMailingList}
            color="primary"
            size="sm"
            isLoading={createRespondentListState.loading}
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "clients.client.modals.create-mailing-list.save",
              "Save mailing list"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveClient}>
          <Input
            id="mailing-list-title"
            label={t(
              "clients.client.modals.create-mailing-list.title-label",
              "Title"
            )}
            placeholder={t(
              "clients.client.modals.create-mailing-list.title-placeholder",
              "title"
            )}
            value={mailingListTitle}
            onChange={setMailingListTitle}
          />
        </form>
      </ActionModal>
      <ConfirmModal
        toConfirmType={t("clients.client.modals.delete-client.type", "client")}
        toConfirmLabel={client ? client.name : ""}
        modalOpen={deleteClientModalOpen}
        closeModal={() => setDeleteClientModalOpen(false)}
        onConfirm={onDeleteClient}
        action="delete"
        isLoading={deleteClientState.loading}
        warning={t(
          "clients.client.modals.delete-client.warning",
          "This operation will also delete all projects related to this client!"
        )}
      />
    </>
  );
}
