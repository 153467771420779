import React from "react";
import PropTypes from "prop-types";

/**
 * Checkbox input
 * @param {string} id - Input id
 * @param {string} label - Input label
 * @param {boolean} checked - Checkbox state
 * @param {function} toggle - Toggle function
 * @param {string} className - ClassName overwrite
 * @returns {node} React checkbox component
 */
function Checkbox({ id, label, checked, toggle, className }) {
  return (
    <div className={`space-x-2 flex flex-row items-center ${className}`}>
      <input
        className="checkbox checkbox-primary"
        name={id}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={toggle}
      />
      {label ? (
        <label htmlFor={id} className="flex-1">
          {label}
        </label>
      ) : undefined}
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  toggle: PropTypes.func,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  id: "",
  label: "",
  checked: false,
  toggle: () => {},
  className: "",
};

export default Checkbox;
