import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { ReportStructureSettingsPage, Breadcrumbs } from "../../../components";

export default function ReportStructureSettings() {
  const { t } = useTranslation();
  const { surveyId, reportStructureId } = useParams();

  return (
    <ReportStructureSettingsPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t("templates.report.breadcrumbs.templates", "Templates"),
              link: `/templates`,
            },
            {
              label: t("templates.report.breadcrumbs.dashboard", "Dashboard"),
              link: `/templates/${surveyId}/dashboard`,
            },
            {
              label: t("templates.report.breadcrumbs.reports", "Reports"),
              link: `/templates/${surveyId}/reportstructures`,
            },
            {
              label: t(
                "templates.report.breadcrumbs.report-settings",
                "Report"
              ),
              link: `/templates/${surveyId}/reportstructures/${reportStructureId}/settings`,
            },
          ]}
          back={`/projects/${surveyId}/reportstructures`}
        />
      }
      actions={
        <Link
          className="text-2xl xl:text-3xl font-bold text-gray-500"
          to={`/templates/${surveyId}/reportstructures/${reportStructureId}/reportbuilder`}
        >
          {t("project.survey.reports.labels.report", "Report")}
        </Link>
      }
      reportsURL={`/templates/${surveyId}/reportstructures`}
    />
  );
}
