import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Prompt } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Button,
  LoggedInLayout,
  Icons,
  ActionModal,
  ConfirmModal,
  Select,
  Input,
  Checkbox,
  MinLevel,
} from "../..";
import calls from "../../../graphql";
import toast from "react-hot-toast";
import {
  useQuestionTypes,
  getTranslation,
  userRoleLevels,
  useHashFragment,
} from "../../../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AppStateContext } from "../../functional/Provider";
const {
  GETSURVEYSURVEYSECTIONSQUESTIONS,
  CREATESURVEYSECTION,
  UPDATESURVEYSECTION,
  DELETESURVEYSECTION,
  UPDATEQUESTION,
  DELETEQUESTION,
  DUPLICATEQUESTION,
} = calls;

/**
 * Page to manage sections and questions
 * Used for surveys and survey templates
 * @param {string} questionURL - Base url of the survey page
 * @param {string} surveyId - Id of the selected survey
 * @param {node} breadCrumbs - Navigation breadcrumbs component
 * @returns {node} React component
 */
export default function QuestionsPage({ questionURL, surveyId, breadCrumbs }) {
  // Hooks
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const questionTypes = useQuestionTypes();
  const { appState } = useContext(AppStateContext);
  const { userLevel } = appState;
  // State
  const [sections, setSections] = useState([]);
  const [survey, setSurvey] = useState(undefined);
  const [changed, setChanged] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionModalOpen, setSectionModalOpen] = useState(false);
  const [sectionPosition, setSectionPosition] = useState(-1);
  const [sectionPositionBefore, setSectionPositionBefore] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(undefined);
  const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);
  const [deletedSectionIds, setDeletedSectionIds] = useState([]);
  const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);
  // Queries
  const getQuestionsState = useQuery(GETSURVEYSURVEYSECTIONSQUESTIONS, {
    variables: {
      surveyId,
    },
    fetchPolicy: "network-only",
  });
  // Mutations
  const [createSurveySection, createSurveySectionState] =
    useMutation(CREATESURVEYSECTION);
  const [updateSurveySection, updateSurveySectionState] =
    useMutation(UPDATESURVEYSECTION);
  const [deleteSurveySection, deleteSurveySectionState] =
    useMutation(DELETESURVEYSECTION);
  const [updateQuestion, updateQuestionState] = useMutation(UPDATEQUESTION);
  const [duplicateQuestion, duplicateQuestionState] =
    useMutation(DUPLICATEQUESTION);
  const [deleteQuestion, deleteQuestionState] = useMutation(DELETEQUESTION);
  // Handle errors
  if (getQuestionsState.error) toast.error(getQuestionsState.error.message);
  // Effects
  useHashFragment(0, !getQuestionsState.loading);
  useEffect(() => {
    resetState();
    return () => {};
  }, [getQuestionsState.data, i18n.language]);
  const resetFields = () => {
    setSectionPosition(-1);
    setSelectedSection(undefined);
    setSectionPositionBefore(false);
    setSectionTitle("");
    setSectionModalOpen(false);
    setResetModalOpen(false);
    setSaveModalOpen(false);
    setDeleteSectionModalOpen(false);
    setDeleteQuestionModalOpen(false);
  };

  const resetState = async () => {
    resetFields();
    setChanged(false);
    const getQuestionsResult = await getQuestionsState.refetch();
    if (
      getQuestionsResult &&
      getQuestionsResult.data &&
      getQuestionsResult.data.survey
    ) {
      const survey = getQuestionsResult.data.survey;
      let sections = [];
      if (survey.sections && survey.sections.length) {
        for (const section of survey.sections) {
          sections.push({
            id: section.id,
            order: section.order ? section.order : -1,
            label: section.label ? section.label : "",
            questions:
              section.questions && section.questions.length
                ? [...section.questions]
                : [],
          });
        }
      }
      setDeletedSectionIds([]);
      setDeletedQuestionIds([]);
      setSurvey(survey);
      setSections(sections);
      setChanged(false);
    }
  };

  const onAddSection = async () => {
    if (sections && sections.length) {
      if (sectionPosition >= 0 && sectionTitle) {
        setChanged(true);
        const newSections = [...sections];
        const index = sectionPositionBefore
          ? parseInt(sectionPosition)
          : parseInt(sectionPosition) + 1;
        newSections.splice(index, 0, {
          id: sectionTitle + Math.round(Math.random() * 1000),
          order: index,
          label: sectionTitle,
          questions: [],
          new: true,
        });
        setSections(newSections);
        resetFields();
      } else {
        toast.custom(
          t(
            "components.questions-page.messages.warning.complete-fields",
            "Please complete all fields."
          )
        );
      }
    } else {
      setChanged(true);
      setSections([
        {
          id: sectionTitle + Math.round(Math.random() * 1000),
          order: sectionPosition,
          label: sectionTitle,
          questions: [],
          new: true,
        },
      ]);
      resetFields();
    }
  };

  const onEditSection = () => {
    if (selectedSection) {
      const selectedIndex = sections.findIndex(
        (section) => section.id === selectedSection.id
      );
      const movedSections = [...sections];
      if (selectedIndex > -1) {
        setChanged(true);
        movedSections.splice(selectedIndex, 1);
        let index = parseInt(sectionPosition);
        if (!sectionPositionBefore) index = index + 1;
        movedSections.splice(index, 0, {
          id: selectedSection.id,
          label: sectionTitle,
          order: index,
          questions: selectedSection.questions,
          new: selectedSection.new,
        });
        setSections(movedSections);
        setSelectedSection(undefined);
        resetFields();
      } else {
        toast.error(
          t(
            "project.survey.question.messages.error.selected-invalid",
            "The selected section seems to be invalid."
          )
        );
      }
    } else {
      toast.error(
        t(
          "project.survey.question.messages.error.no-selected",
          "No section seems to be selected."
        )
      );
    }
  };

  const onDeleteSection = () => {
    if (selectedSection) {
      setChanged(true);
      if (!selectedSection.new) {
        const deleted = [...deletedSectionIds, selectedSection.id];
        setDeletedSectionIds(deleted);
      }
      const sectionsCopy = sections.filter(
        (section) => section.id !== selectedSection.id
      );
      setSections(sectionsCopy);
      resetFields();
      toast.success(
        t(
          "components.questions-page.messages.success.delete-section",
          "Section deleted!"
        )
      );
    } else {
      toast.error(
        t(
          "components.questions-page.messages.errors.no-section-selected",
          "No section seems to be selected."
        )
      );
    }
  };

  const onAddQuestion = (sectionId) => {
    if (sectionId) {
      history.replace(`${questionURL}/new/question?sectionId=${sectionId}`);
    } else {
      toast.custom(
        t(
          "components.questions-page.messages.warning.unsaved-section",
          "Cannot add a question to an unsaved section. Please save your changes and try again."
        )
      );
    }
  };

  const onDuplicateQuestion = async (id) => {
    if (changed) {
      toast.custom(
        t(
          "components.questions-page.messages.warning.save-changes-first",
          "There are unsaved changes, please save first."
        )
      );
    } else {
      try {
        const { data } = await duplicateQuestion({
          variables: {
            id,
          },
        });

        if (data && data.duplicateQuestion && data.duplicateQuestion.id) {
          history.replace(
            `${questionURL}/${data.duplicateQuestion.id}/question`
          );
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const onDeleteQuestion = () => {
    if (selectedQuestion && selectedSection) {
      const deleted = [...deletedQuestionIds, selectedQuestion.id];
      setDeletedQuestionIds(deleted);
      setChanged(true);
      const questions = [...selectedSection.questions].filter(
        (question) => question.id !== selectedQuestion.id
      );
      selectedSection.questions = questions;
      selectedSection.changed = true;
      resetFields();
      toast.success(
        t(
          "components.questions-page.messages.success.delete-question",
          "Question deleted!"
        )
      );
    } else {
      toast.error(
        t(
          "components.questions-page.messages.error.no-question-selected",
          "No question seems to be selected."
        )
      );
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (userLevel < userRoleLevels.Manager) {
      toast.custom(
        t(
          "components.questions-page.messages.warning.no-edits-allowed",
          "You are not allowed to make edits. Contact your system administrator if you require more permissions."
        )
      );
      return;
    }
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    ) {
      return;
    }
    setChanged(true);
    const droppedSections = [...sections];
    let sourceIndex;
    let targetIndex;
    for (var i = 0; i < sections.length; i++) {
      if (sections[i].id === result.source.droppableId) {
        sourceIndex = i;
      }
      if (sections[i].id === result.destination.droppableId) {
        targetIndex = i;
      }
    }
    const sourceQuestions = [...droppedSections[sourceIndex].questions];
    const [movedQuestion] = sourceQuestions.splice(result.source.index, 1);
    droppedSections[sourceIndex].questions = sourceQuestions;
    droppedSections[sourceIndex].changed = true;
    const targetQuestions = [...droppedSections[targetIndex].questions];
    targetQuestions.splice(result.destination.index, 0, movedQuestion);
    droppedSections[targetIndex].questions = targetQuestions;
    droppedSections[targetIndex].changed = true;
    setSections(droppedSections);
  };

  const updateQuestionsSection = async (section) => {
    if (section && section.questions && section.questions.length) {
      return Promise.all(
        section.questions.map(async (question, index) => {
          try {
            await updateQuestion({
              variables: {
                id: question.id,
                order: index,
                surveySectionId: section.id,
              },
            });
          } catch (error) {
            toast.error(error.message);
          }
        })
      );
    }
  };

  const saveSections = async () => {
    await Promise.all([
      Promise.all(
        sections.map(async (section, i) => {
          try {
            if (section.new) {
              const { data } = await createSurveySection({
                variables: {
                  label: section.label,
                  order: i,
                  surveyId,
                },
              });
              if (
                data &&
                data.createSurveySection &&
                data.createSurveySection.id
              ) {
                section.id = data.createSurveySection.id;
              } else {
                throw new Error(
                  t(
                    "components.questions-page.messages.error.no-section-id",
                    "There was a problem while creating one of the sections."
                  )
                );
              }
            } else {
              await updateSurveySection({
                variables: {
                  id: section.id,
                  label: section.label,
                  order: i,
                },
              });
            }
            if (
              section.changed &&
              section.questions &&
              section.questions.length
            ) {
              await updateQuestionsSection(section);
            }
          } catch (error) {
            toast.error(error.message);
          }
        })
      ),
      Promise.all(
        deletedQuestionIds.map(async (id) => {
          try {
            await deleteQuestion({
              variables: {
                id,
              },
            });
          } catch (error) {
            toast.error(error.message);
          }
        })
      ),
      Promise.all(
        deletedSectionIds.map(async (id) => {
          try {
            await deleteSurveySection({
              variables: {
                id,
              },
            });
          } catch (error) {
            toast.error(error.message);
          }
        })
      ),
    ]);
    await resetState();
    toast.success(
      t("project.survey.messages.success.save-changes", "Saved changes!")
    );
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={breadCrumbs}
        isLoading={getQuestionsState.loading}
      >
        <div className="w-full flex flex-col md:flex-row  md:items-center">
          <div className="text-2xl md:text-3xl font-bold flex-grow">
            {survey ? survey.label : ""}
          </div>
          <div className="space-x-2">
            <MinLevel minLevel={userRoleLevels.Manager}>
              {changed ? (
                <>
                  <Button
                    onClick={() => setResetModalOpen(true)}
                    color="warning"
                    start={<Icons.Reset className="text-xl" />}
                  >
                    {t("components.questions-page.labels.reset", "Reset")}
                  </Button>
                  <Button
                    onClick={() => setSaveModalOpen(true)}
                    color="success"
                    isLoading={
                      updateSurveySectionState.loading ||
                      createSurveySectionState.loading ||
                      updateQuestionState.loading
                    }
                    start={<Icons.Save className="text-xl" />}
                  >
                    {t("components.questions-page.labels.save", "Save")}
                  </Button>
                </>
              ) : undefined}
              <Button
                onClick={() => {
                  setSelectedSection(undefined);
                  setSectionTitle("");
                  setSectionPosition(-1);
                  setSectionPositionBefore(false);
                  setSectionModalOpen(true);
                }}
                color="primary"
                start={<Icons.Add className="text-xl" />}
              >
                {t(
                  "components.questions-page.labels.create-section",
                  "New Section"
                )}
              </Button>
            </MinLevel>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="w-full flex-grow grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 overflow-auto items-center">
            {sections && sections.length
              ? sections.map((section, i) => (
                  <div
                    key={section.id}
                    className="flex flex-col h-full p-5 w-full items-center space-y-2 bg-base-300 rounded-box"
                    id={`section-${section.id}`}
                  >
                    <h2 className="text-xl font-bold w-full">
                      {t("components.questions-page.labels.section", "Section")}
                      {" - "}
                      {section.label}
                    </h2>
                    <MinLevel minLevel={userRoleLevels.Manager}>
                      <div className="w-full flex flex-row justify-between">
                        <div className="space-x-2">
                          <Button
                            color="primary"
                            size="sm"
                            start={<Icons.Edit className="text-xl" />}
                            onClick={() => {
                              setSelectedSection(section);
                              setSectionTitle(section.label);
                              if (i === 0) {
                                setSectionPosition(0);
                                setSectionPositionBefore(true);
                              } else {
                                setSectionPosition(i - 1);
                                setSectionPositionBefore(false);
                              }
                              setSectionModalOpen(true);
                            }}
                            isLoading={updateSurveySectionState.loading}
                          >
                            {t(
                              "components.questions-page.labels.edit-section",
                              "Edit"
                            )}
                          </Button>
                          <Button
                            color="error"
                            size="sm"
                            start={<Icons.Delete className="text-xl" />}
                            onClick={() => {
                              setSelectedSection(section);
                              setDeleteSectionModalOpen(true);
                            }}
                            isLoading={deleteSurveySectionState.loading}
                          >
                            {t(
                              "components.questions-page.labels.delete-section",
                              "Delete"
                            )}
                          </Button>
                        </div>
                        <Button
                          color="primary"
                          size="sm"
                          start={<Icons.Add className="text-xl" />}
                          onClick={() => {
                            onAddQuestion(section.new ? "" : section.id);
                          }}
                          isLoading={deleteSurveySectionState.loading}
                        >
                          {t(
                            "components.questions-page.labels.new-question",
                            "New question"
                          )}
                        </Button>
                      </div>
                    </MinLevel>
                    <Droppable key={section.id} droppableId={section.id}>
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="w-full space-y-2 flex-grow"
                        >
                          {section.questions && section.questions.length
                            ? section.questions.map((question, j) => (
                                <Draggable
                                  key={question.id}
                                  draggableId={question.id}
                                  index={j}
                                >
                                  {(provided) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      className="w-full card overflow-visible bg-base-100 border shadow-md rounded-box px-5 py-5"
                                      style={{
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <div className="card-content">
                                        <div className="w-full flex flex-row items-center">
                                          <div className="text-xl font-bold flex-grow">
                                            {t(
                                              "components.questions-page.labels.question",
                                              "Question"
                                            )}{" "}
                                            {parseInt(question.order) + 1}
                                          </div>
                                          <MinLevel
                                            minLevel={userRoleLevels.Manager}
                                          >
                                            <div className="space-x-1">
                                              <Button
                                                color="accent"
                                                size="sm"
                                                isLoading={
                                                  duplicateQuestionState.loading
                                                }
                                                onClick={() =>
                                                  onDuplicateQuestion(
                                                    question.id
                                                  )
                                                }
                                              >
                                                <Icons.Copy className="text-xl" />
                                              </Button>
                                              <Button
                                                color="primary"
                                                size="sm"
                                                isLoading={
                                                  updateQuestionState.loading
                                                }
                                                isLink={true}
                                                to={{
                                                  pathname: `${questionURL}/${question.id}/question`,
                                                  search: `?sectionId=${section.id}`,
                                                }}
                                              >
                                                <Icons.Edit className="text-xl" />
                                              </Button>
                                              <Button
                                                color="error"
                                                size="sm"
                                                isLoading={
                                                  deleteQuestionState.loading
                                                }
                                                onClick={() => {
                                                  setSelectedQuestion(question);
                                                  setSelectedSection(section);
                                                  setDeleteQuestionModalOpen(
                                                    true
                                                  );
                                                }}
                                              >
                                                <Icons.Delete className="text-xl" />
                                              </Button>
                                            </div>
                                          </MinLevel>
                                        </div>
                                        <div className="text-sm text-gray-600 pb-2">
                                          {
                                            questionTypes[
                                              question.questionType.label
                                            ].label
                                          }
                                        </div>
                                        <div className="flex flex-row justify-between">
                                          {
                                            getTranslation(
                                              question.translations,
                                              i18n.language
                                            ).label
                                          }
                                          <div className="flex flex-col justify-end mr-1">
                                            <p
                                              className={`badge badge-md badge-ghost ${
                                                question.questionTags.length
                                                  ? "tooltip tooltip-left"
                                                  : ""
                                              }`}
                                              data-tip={question.questionCategories
                                                .map(
                                                  (cat) =>
                                                    `${
                                                      cat.label
                                                    }${question.questionTags
                                                      .filter(
                                                        (tag) =>
                                                          tag.category.id ===
                                                          cat.id
                                                      )
                                                      .map(
                                                        (tag) =>
                                                          `\n - ${tag.label}`
                                                      )
                                                      .join("")}`
                                                )
                                                .join("\n")}
                                              style={{
                                                whiteSpace: "pre-wrap",
                                                textAlign: "left",
                                              }}
                                            >
                                              {question.questionTags.length}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))
                            : undefined}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))
              : undefined}
          </div>
        </DragDropContext>
      </LoggedInLayout>
      <ActionModal
        modalOpen={sectionModalOpen}
        closeModal={() => setSectionModalOpen(false)}
        title={
          selectedSection
            ? t(
                "components.questions-page.modals.edit-section.title",
                "Edit Section"
              )
            : t(
                "components.questions-page.modals.create-section.title",
                "New Section"
              )
        }
        actions={
          selectedSection ? (
            <Button
              size="sm"
              onClick={onEditSection}
              color="primary"
              start={<Icons.Save className="text-xl" />}
              isLoading={updateSurveySectionState.loading}
            >
              {t(
                "components.questions-page.modals.edit-section.edit-section",
                "Edit Section"
              )}
            </Button>
          ) : (
            <Button
              size="sm"
              onClick={onAddSection}
              color="primary"
              start={<Icons.Save className="text-xl" />}
              isLoading={createSurveySectionState.loading}
            >
              {t(
                "components.questions-page.modals.create-section.add-section",
                "Add Section"
              )}
            </Button>
          )
        }
      >
        <form className="form-control space-y-2" onSubmit={onAddSection}>
          <Input
            id="section-title"
            label={t(
              "components.questions-page.modals.create-section.title-label",
              "Title"
            )}
            placeholder={t(
              "components.questions-page.modals.create-section.title-placeholder",
              "title"
            )}
            value={sectionTitle}
            onChange={setSectionTitle}
          />
          {sections && sections.length ? (
            <>
              <label htmlFor="position">
                {t(
                  "components.questions-page.modals.create-section.position-label",
                  "Position"
                )}
              </label>
              <Checkbox
                id="beforeOrAfter"
                label={
                  sectionPositionBefore
                    ? t(
                        "components.questions-page.modals.create-section.position-before",
                        "Before"
                      )
                    : t(
                        "components.questions-page.modals.create-section.position-after",
                        "After"
                      )
                }
                checked={sectionPositionBefore}
                toggle={() => setSectionPositionBefore(!sectionPositionBefore)}
              />
              <Select
                id="position"
                value={sectionPosition}
                onChange={setSectionPosition}
                options={sections
                  .filter(
                    (section) =>
                      !selectedSection || section.id !== selectedSection.id
                  )
                  .map((section, index) => ({
                    value: index,
                    label: section.label,
                  }))}
                placeholderValue={-1}
                placeholderLabel={t(
                  "components.questions-page.modals.create-section.section-placeholder",
                  "Select a section"
                )}
              />
            </>
          ) : undefined}
        </form>
      </ActionModal>
      <ActionModal
        modalOpen={resetModalOpen}
        closeModal={() => setResetModalOpen(false)}
        title={t(
          "components.questions-page.modals.reset-changes.title",
          "Reset"
        )}
        actions={
          <Button
            size="sm"
            onClick={resetState}
            color="warning"
            start={<Icons.Reset className="text-xl" />}
          >
            {t("components.questions-page.modals.reset-changes.reset", "Reset")}
          </Button>
        }
      >
        <div className="text-center">
          {t(
            "components.questions-page.modals.reset-changes.message",
            "Are you sure you want to discard all changes?"
          )}
        </div>
      </ActionModal>
      <ActionModal
        modalOpen={saveModalOpen}
        closeModal={() => setSaveModalOpen(false)}
        title={t(
          "components.questions-page.modals.save-changes.title",
          "Save Changes"
        )}
        actions={
          <Button
            size="sm"
            onClick={saveSections}
            color="success"
            start={<Icons.Save className="text-xl" />}
            isLoading={
              updateSurveySectionState.loading ||
              createSurveySectionState.loading ||
              updateQuestionState.loading
            }
          >
            {t("components.questions-page.modals.save-changes.save", "Save")}
          </Button>
        }
      >
        <div className="text-center">
          {t(
            "components.questions-page.modals.save-changes.form.message",
            "Save all changes?"
          )}
        </div>
      </ActionModal>
      <ConfirmModal
        toConfirmType={
          deleteSectionModalOpen
            ? t(
                "components.questions-page.modals.delete-section.type",
                "section"
              )
            : t(
                "components.questions-page.modals.delete-question.type",
                "question"
              )
        }
        toConfirmLabel={
          selectedSection
            ? selectedSection.label
            : selectedQuestion
            ? getTranslation(selectedQuestion.translations, i18n.language).label
            : ""
        }
        action="delete"
        modalOpen={deleteSectionModalOpen || deleteQuestionModalOpen}
        closeModal={
          deleteSectionModalOpen
            ? () => {
                setDeleteSectionModalOpen(false);
                setSelectedSection(undefined);
              }
            : () => {
                setDeleteQuestionModalOpen(false);
                setSelectedQuestion(undefined);
              }
        }
        onConfirm={deleteSectionModalOpen ? onDeleteSection : onDeleteQuestion}
        isLoading={
          deleteSurveySectionState.loading || deleteQuestionState.loading
        }
        warning={
          deleteSectionModalOpen
            ? t(
                "components.questions-page.modals.delete-section.warning",
                "This operation will also delete all questions in this section!"
              )
            : ""
        }
      />
      <Prompt
        when={changed}
        message={t(
          "components.questions-page.messages.warning.unsaved-changes",
          "There are unsaved changes, are you sure you want to leave?"
        )}
      />
    </>
  );
}

QuestionsPage.propTypes = {
  questionURL: PropTypes.string,
  surveyId: PropTypes.string,
  breadCrumbs: PropTypes.node,
};
