import { gql } from "@apollo/client";

let calls = {};
const types = ["Project", "Client"];

for (const Type of types) {
  const type = Type.toLowerCase();
  const TYPE = Type.toUpperCase();

  calls[`GET${TYPE}ATTRIBUTES`] = gql`
    query Get${Type}Attributes($${type}Id: ID!) {
      attributes(attribute: { objectId: $${type}Id, objectType: ${TYPE} }) {
        id
        key
        value
      }
    }
  `;

  calls[`CREATE${TYPE}ATTRIBUTE`] = gql`
    mutation Create${Type}Attribute(
      $${type}Id: ID!
      $key: String!
      $value: String!
    ) {
      createAttribute(
        attribute: {
          key: $key
          value: $value
          objectId: $${type}Id
          objectType: ${TYPE}
        }
      ) {
        id
      }
    }
  `;

  calls[`UPDATE${TYPE}ATTRIBUTE`] = gql`
    mutation Update${Type}Attribute(
      $id: ID!
      $${type}Id: ID!
      $key: String
      $value: String
    ) {
      updateAttribute(
        id: $id
        atribute: {
          key: $key
          value: $value
          objectId: $${type}Id
          objectType: ${TYPE}
        }
      ) {
        id
      }
    }
  `;

  calls[`DELETE${TYPE}ATTRIBUTE`] = gql`
    mutation Delete${Type}Attribute($id: ID!, $${type}Id: ID!) {
      deleteAttribute(
        id: $id
        atribute: { objectId: $${type}Id, objectType: ${TYPE} }
      ) {
        id
      }
    }
  `;
}

export default calls;
