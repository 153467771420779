import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import {
  ReportStructureSettingsPage,
  Breadcrumbs,
} from "../../../../components";

export default function ReportStructureSettings() {
  const { t } = useTranslation();
  const { surveyId, projectId, reportStructureId } = useParams();

  return (
    <ReportStructureSettingsPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t(
                "project.survey.reports.breadcrumbs.projects",
                "Projects"
              ),
              link: `/projects`,
            },
            {
              label: t("project.survey.reports.breadcrumbs.surveys", "Surveys"),
              link: `/projects/${projectId}/surveys`,
            },
            {
              label: t(
                "project.survey.reports.breadcrumbs.dashboard",
                "Dashboard"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
            },
            {
              label: t("project.survey.reports.breadcrumbs.reports", "Reports"),
              link: `/projects/${projectId}/surveys/${surveyId}/reports`,
            },
            {
              label: t(
                "project.survey.reports.breadcrumbs.report-settings",
                "Settings"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/reportstructures/${reportStructureId}/settings`,
            },
          ]}
          back={`/projects/${projectId}/surveys/${surveyId}/reports`}
        />
      }
      actions={
        <Link
          className="text-2xl xl:text-3xl font-bold text-gray-500"
          to={`/projects/${projectId}/surveys/${surveyId}/reportstructures/${reportStructureId}/reportbuilder`}
        >
          {t("project.survey.reports.labels.settings", "Settings")}
        </Link>
      }
      reportsURL={`/projects/${projectId}/surveys/${surveyId}/reports`}
    />
  );
}
