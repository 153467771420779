import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import {
  LoggedInLayout,
  Input,
  Checkbox,
  Button,
  Icons,
  ConfirmModal,
} from "../..";
import { userRoleLevels } from "../../../utils";
import calls from "../../../graphql";

const { GETREPORTSTRUCTURE, UPDATEREPORTSTRUCTURE, DELETEREPORTSTRUCTURE } =
  calls;

function ReportStructureSettings({ breadCrumbs, actions, reportsURL }) {
  // Hooks
  const { reportStructureId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  // State
  const [structure, setStructure] = useState(undefined);
  const [structureLabel, setStructureLabel] = useState("");
  const [structureIsIndividual, setStructureIsIndividual] = useState(false);
  const [deleteStructureModalOpen, setDeleteStructureModalOpen] =
    useState(false);
  // Queries
  const getStructuresState = useQuery(GETREPORTSTRUCTURE, {
    variables: {
      id: reportStructureId,
    },
  });
  // Mutations
  const [updateStructure, updateStrucureState] = useMutation(
    UPDATEREPORTSTRUCTURE
  );
  const [deleteStructure, deleteStructureState] = useMutation(
    DELETEREPORTSTRUCTURE
  );
  // Effects
  useEffect(() => {
    const { data } = getStructuresState;
    if (data) {
      const { reportStructure } = data;
      setStructure(reportStructure);
      setStructureLabel(reportStructure.label);
      setStructureIsIndividual(reportStructure.isIndividual);
    }
  }, [getStructuresState]);

  const onUpdateStructure = async () => {
    try {
      await updateStructure({
        variables: {
          id: reportStructureId,
          label: structureLabel,
          isIndividual: structureIsIndividual,
        },
      });
      toast.success(
        t(
          "components.report-structure-settings.messages.success.structure-saved",
          "Template saved!"
        )
      );
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onDeleteStructure = async () => {
    try {
      await deleteStructure({
        variables: {
          id: reportStructureId,
        },
      });
      history.replace(reportsURL);
      toast.success(
        t(
          "components.report-structure-settings.messages.success.structure-deleted",
          "Template deleted!"
        )
      );
    } catch ({ message }) {
      toast.error(message);
    }
  };

  return (
    <>
      <LoggedInLayout
        breadCrumbs={breadCrumbs}
        actions={actions}
        minLevel={userRoleLevels.Manager}
      >
        <div className="w-3/4 self-center flex flex-col space-y-2">
          <div className="text-2xl md:text-3xl font-bold">
            {structure
              ? structure.label
              : t(
                  "components.report-structure-settings.labels.structure-label",
                  "Template title"
                )}
          </div>

          <Input
            id="structure-label"
            label={t(
              "components.report-structure-settings.labels.label-label",
              "Title"
            )}
            placeholder={t(
              "components.report-structure-settings.labels.label-placeholder",
              "title"
            )}
            value={structureLabel}
            onChange={setStructureLabel}
          />
          <Checkbox
            id="structure-is-individual"
            label={t(
              "components.report-structure-settings.labels.aggregate-label",
              "Aggregate report"
            )}
            checked={!structureIsIndividual}
            toggle={() => setStructureIsIndividual(!structureIsIndividual)}
          />
          <Button
            color="primary"
            isLoading={updateStrucureState.loading}
            onClick={onUpdateStructure}
            start={<Icons.Save className="text-xl" />}
            size="sm"
          >
            {t(
              "components.report-structure-settings.labels.save-structure",
              "Save template"
            )}
          </Button>
          <Button
            color="error"
            isLoading={deleteStructureState.loading}
            onClick={() => setDeleteStructureModalOpen(true)}
            start={<Icons.Delete className="text-xl" />}
            size="sm"
          >
            {t(
              "components.report-structure-settings.labels.delete-structure",
              "Delete template"
            )}
          </Button>
        </div>
      </LoggedInLayout>
      <ConfirmModal
        modalOpen={deleteStructureModalOpen}
        closeModal={() => setDeleteStructureModalOpen(false)}
        µ
        onConfirm={() => onDeleteStructure()}
        action="delete"
        toConfirmType="template"
      />
    </>
  );
}

ReportStructureSettings.propTypes = {
  breadCrumbs: PropTypes.node,
  actions: PropTypes.node,
  reportsURL: PropTypes.string,
};

export default ReportStructureSettings;
