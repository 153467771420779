import Clients from "./Clients";
import Client from "./Client/Client.js";
import MailingList from "./Client/MailingList/MailingList";
import Home from "./Home";
import Login from "./Login";
import NotFound from "./NotFound";
import Forbidden from "./Forbidden";
import Projects from "./Projects";
import ProjectSettings from "./Project/Settings";
import ProjectSurveys from "./Project/Surveys";
import Register from "./Register";
import Settings from "./Settings";
import SurveyDashboard from "./Project/Survey/Dashboard";
import SurveyQuestions from "./Project/Survey/Questions";
import SurveyQuestion from "./Project/Survey/Question/Question";
import SurveyPublications from "./Project/Survey/Publications";
import SurveyPublish from "./Project/Survey/Publish";
import SurveyReports from "./Project/Survey/Reports";
import SurveyReport from "./Project/Survey/Report/Report";
import SurveyReportBuilder from "./Project/Survey/Report/ReportBuilder";
import SurveyReportStructureSettings from "./Project/Survey/Report/ReportStructureSettings";
import Templates from "./Templates";
import TemplateDashboard from "./Templates/Dashboard";
import TemplateQuestions from "./Templates/Questions";
import TemplateQuestion from "./Templates/Question/Question";
import TemplateReports from "./Templates/Reports";
import TemplateReport from "./Templates/Report/Report";
import TemplateReportSettings from "./Templates/Report/ReportSettings";
import Users from "./Users";

const pages = [
  { path: "/", page: Home },
  { path: "/register", page: Register },
  { path: "/login", page: Login },
  { path: "/settings", page: Settings },
  { path: "/templates", page: Templates },
  { path: "/templates/:surveyId/dashboard", page: TemplateDashboard },
  { path: "/templates/:surveyId/questions", page: TemplateQuestions },
  {
    path: "/templates/:surveyId/questions/:questionId/question",
    page: TemplateQuestion,
  },
  { path: "/templates/:surveyId/reportstructures", page: TemplateReports },
  {
    path: "/templates/:surveyId/reportstructures/:reportStructureId/reportbuilder",
    page: TemplateReport,
  },
  {
    path: "/templates/:surveyId/reportstructures/:reportStructureId/settings",
    page: TemplateReportSettings,
  },
  { path: "/users", page: Users },
  { path: "/clients", page: Clients },
  { path: "/clients/:clientId/mailinglists", page: Client },
  {
    path: "/clients/:clientId/mailinglists/:mailingListId/mailinglist",
    page: MailingList,
  },
  { path: "/projects", page: Projects },
  { path: "/projects/:projectId/settings", page: ProjectSettings },
  { path: "/projects/:projectId/surveys", page: ProjectSurveys },
  {
    path: "/projects/:projectId/surveys/:surveyId/dashboard",
    page: SurveyDashboard,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/questions",
    page: SurveyQuestions,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/questions/:questionId/question",
    page: SurveyQuestion,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/publications",
    page: SurveyPublications,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/publish",
    page: SurveyPublish,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/reports",
    page: SurveyReports,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/reports/:reportId/report",
    page: SurveyReport,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/reportstructures/:reportStructureId/reportbuilder",
    page: SurveyReportBuilder,
  },
  {
    path: "/projects/:projectId/surveys/:surveyId/reportstructures/:reportStructureId/settings",
    page: SurveyReportStructureSettings,
  },
  { path: "/forbidden", page: Forbidden },
  { page: NotFound },
];
export default pages;
