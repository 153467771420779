import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import {
  ReportBuilderPage,
  Breadcrumbs,
  MinLevel,
} from "../../../../components";
import { userRoleLevels } from "../../../../utils";

export default function ReportBuilder() {
  const { t } = useTranslation();
  const { surveyId, projectId, reportStructureId } = useParams();

  return (
    <ReportBuilderPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t(
                "project.survey.reports.breadcrumbs.projects",
                "Projects"
              ),
              link: `/projects`,
            },
            {
              label: t("project.survey.reports.breadcrumbs.surveys", "Surveys"),
              link: `/projects/${projectId}/surveys`,
            },
            {
              label: t(
                "project.survey.reports.breadcrumbs.dashboard",
                "Dashboard"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
            },
            {
              label: t("project.survey.reports.breadcrumbs.reports", "Reports"),
              link: `/projects/${projectId}/surveys/${surveyId}/reports`,
            },
            {
              label: t(
                "project.survey.reports.breadcrumbs.report-template",
                "Template"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/reportstructures/${reportStructureId}/reportbuilder`,
            },
          ]}
          back={`/projects/${projectId}/surveys/${surveyId}/reports`}
        />
      }
      actions={
        <MinLevel minLevel={userRoleLevels.Manager}>
          <Link
            className="text-2xl xl:text-3xl font-bold text-gray-500"
            to={`/projects/${projectId}/surveys/${surveyId}/reportstructures/${reportStructureId}/settings`}
          >
            {t("project.survey.reports.labels.settings", "Settings")}
          </Link>
        </MinLevel>
      }
    />
  );
}
