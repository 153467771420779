import React from "react";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs,
  LoggedInLayout,
  UserSettings,
  ApplicationSettings,
  MinLevel,
} from "../components";
import { userRoleLevels } from "../utils";

export default function Settings() {
  // Hooks
  const { t } = useTranslation();

  return (
    <LoggedInLayout
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              link: "/settings",
              label: t("settings.labels.settings", "Settings"),
            },
          ]}
        />
      }
      minLevel={0}
    >
      <MinLevel minLevel={userRoleLevels.Manager}>
        <ApplicationSettings />
      </MinLevel>
      <UserSettings />
    </LoggedInLayout>
  );
}
