import { gql } from "@apollo/client";

const CREATECLIENT = gql`
  mutation CreateClient(
    $name: String!
    $email: String!
    $industry: String!
    $url: String!
  ) {
    createClient(
      client: { name: $name, email: $email, industry: $industry, url: $url }
    ) {
      id
    }
  }
`;

const UPDATECLIENT = gql`
  mutation UpdateClient(
    $id: ID!
    $name: String
    $email: String
    $industry: String
    $url: String
  ) {
    updateClient(
      id: $id
      client: { name: $name, email: $email, industry: $industry, url: $url }
    ) {
      id
    }
  }
`;

const DELETECLIENT = gql`
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id)
  }
`;

const GETCLIENTS = gql`
  query GetClients {
    clients {
      id
      name
      email
      industry
      url
      createdAt
      updatedAt
    }
  }
`;

const GETCLIENT = gql`
  query GetClient($id: ID!) {
    client(id: $id) {
      id
      name
      email
      industry
      url
      createdAt
      updatedAt
      respondentLists {
        id
        label
      }
    }
  }
`;

const GETCLIENTATTRIBUTES = gql`
  query GetClientAttributes($objectId: ID!) {
    attributes(objectID: $objectID, objectType: CLIENT) {
      id
      key
      value
    }
  }
`;

const calls = {
  CREATECLIENT,
  UPDATECLIENT,
  DELETECLIENT,
  GETCLIENTS,
  GETCLIENT,
  GETCLIENTATTRIBUTES,
};

export default calls;
