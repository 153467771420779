import { useTranslation } from "react-i18next";

/**
 * React hook for the language labels and flags in the user language
 * @returns {object} Language labels {["language_label"]: {flags: "", label: ""}}
 */
export default function useLanguageLabels() {
  const { t } = useTranslation();

  const languageLabels = {
    en: {
      flags: "🇬🇧 🇺🇸",
      label: t("utils.use-language-labels.languages.en", "English"),
    },
    nl: {
      flags: "🇧🇪 🇳🇱",
      label: t("utils.use-language-labels.languages.nl", "Dutch"),
    },
    fr: {
      flags: "🇧🇪 🇫🇷",
      label: t("utils.use-language-labels.languages.fr", "French"),
    },
    // de: t("utils.use-language-labels.languages.de", "German"),
  };

  return languageLabels;
}
