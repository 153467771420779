import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";
import { Icons } from "..";

/**
 * Breadcrumbs navigation component
 * @param {array} links - List of link objects {label, link}
 * @param {string} back - Backlink (optional)
 * @returns {node} React component
 */
function Breadcrumbs({ links, back }) {
  return (
    <div className="flex flex-row text-2xl xl:text-3xl font-bold items-center">
      {back ? (
        <Link to={back} className="md:hidden text-3xl pr-2">
          <Icons.Previous />
        </Link>
      ) : undefined}
      {links.map(({ label, link }, index) => (
        <div
          key={link + label}
          className={`${
            index < links.length - 1 ? "hidden md:flex" : "flex"
          } flex-row items-center`}
        >
          <Link to={link} className="flex flex-grow">
            {label}
          </Link>
          {index < links.length - 1 ? <Icons.Breadcrumb /> : undefined}
        </div>
      ))}
    </div>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
  back: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Breadcrumbs;
