import React from "react";
import { Icons } from ".";

/**
 * Loading spinner
 * @returns react component
 */
export default function Loading() {
  return (
    <div className="w-100 h-100 text-3xl flex flex-grow items-center justify-center">
      <Icons.Loading className="text-5xl animate-spin" />
    </div>
  );
}
