import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  MinLevel,
  Button,
  Icons,
  Table,
  TableHead,
  TableRow,
  TableData,
  ActionModal,
  Input,
  Checkbox,
  Select,
  Loading,
} from "../..";
import { formatDate, userRoleLevels } from "../../../utils";
import calls from "../../../graphql";
const {
  GETSURVEYSREPORTSTRUCTURES,
  GETSURVEYREPORTSTRUCTURES,
  CREATEREPORTSTRUCTURE,
  COPYREPORTSTRUCTURE,
} = calls;

function ReportStructuresTable() {
  // Hooks
  const { surveyId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  // State
  const [surveys, setSurveys] = useState([]);
  const [reportStructures, setResportStructures] = useState([]);
  const [reportTemplateModalOpen, setReportTemplateModalOpen] = useState(false);
  const [templateLabel, setTemplateLabel] = useState("");
  const [templateIsIndividual, setTemplateIsIndividual] = useState(true);
  const [importTemplateModelOpen, setImportTemplateModalOpen] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState("-1");
  const [selectedImportId, setSelectedImportId] = useState("-1");

  // Queries
  const getSurveysReportStructuresState = useQuery(GETSURVEYSREPORTSTRUCTURES);
  const getSurveyReportStructureState = useQuery(GETSURVEYREPORTSTRUCTURES, {
    variables: {
      id: surveyId,
    },
    fetchPolicy: "cache-and-network",
  });

  // Mutations
  const [createReportStructure, createReportStructureState] = useMutation(
    CREATEREPORTSTRUCTURE
  );
  const [copyReportStructure, copyReportStructureState] =
    useMutation(COPYREPORTSTRUCTURE);

  // Effects
  useEffect(() => {
    if (getSurveysReportStructuresState.data) {
      // Fetch report structures
      const { surveys, surveyTemplates } = getSurveysReportStructuresState.data;
      const surveysWithReportStructures = [...surveys, ...surveyTemplates]
        .filter((survey) => survey.reportStructures.length > 0)
        .sort((a, b) => (a.label >= b.label ? 1 : -1));
      setSurveys(surveysWithReportStructures);
    }
  }, [getSurveysReportStructuresState.data]);
  useEffect(() => {
    if (getSurveyReportStructureState.data) {
      const { survey } = getSurveyReportStructureState.data;
      setResportStructures(survey.reportStructures);
    }
  }, [getSurveyReportStructureState.data]);

  // Functions
  const onSaveTemplate = async () => {
    try {
      const { data } = await createReportStructure({
        variables: {
          label: templateLabel,
          isTemplate: true,
          isIndividual: templateIsIndividual,
          surveyId: surveyId,
        },
      });
      if (data && data.createReportStructure) {
        const { id } = data.createReportStructure;
        toast.success(
          t(
            "components.report-structures-table.messages.success.create-report-structure",
            "Report template saved!"
          )
        );
        history.replace(`reportstructures/${id}/reportbuilder`);
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onImportTemplate = async () => {
    if (selectedImportId === "-1" || selectedSurveyId === "-1") {
      toast.custom(
        t(
          "components.report-structures-table.messages.warning.complete-all-fields",
          "Please complete all fields"
        )
      );
      return;
    }

    try {
      const { data } = await copyReportStructure({
        variables: {
          id: selectedImportId,
          surveyId: surveyId,
        },
      });

      if (data?.copyReportStructure) {
        const { id } = data.copyReportStructure;
        toast.success(
          "components.report-structures-table.messages.success.import-report-structure",
          "Report template imported!"
        );
        history.replace(`reportstructures/${id}/reportbuilder`);
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-2xl md:text-3xl font-bold">
          {t(
            "components.report-structures-table.labels.report-templates",
            "Report Templates"
          )}
        </h1>
        <MinLevel minLevel={userRoleLevels.Manager}>
          <div className="flex flex-row items-center space-x-2">
            <Button
              start={<Icons.Copy className="text-xl" />}
              onClick={() => {
                setImportTemplateModalOpen(true);
                setSelectedSurveyId("-1");
              }}
              color="accent"
            >
              {t(
                "components.report-structures-table.labels.import-report-template",
                "Import template"
              )}
            </Button>
            <Button
              start={<Icons.Add className="text-xl" />}
              onClick={() => setReportTemplateModalOpen(true)}
            >
              {t(
                "components.report-structures-table.labels.add-report-template",
                "New template"
              )}
            </Button>
          </div>
        </MinLevel>
      </div>
      {getSurveysReportStructuresState.loading ||
      getSurveyReportStructureState.loading ? (
        <Loading />
      ) : reportStructures && reportStructures.length ? (
        <Table
          head={
            <tr className="h-10">
              <TableHead>
                {t(
                  "components.report-structures-table.template-table.headers.title",
                  "Title"
                )}
              </TableHead>
              <TableHead>
                {t(
                  "components.report-structures-table.template-table.headers.type",
                  "Type"
                )}
              </TableHead>
              <TableHead>
                {t(
                  "components.report-structures-table.template-table.headers.updated",
                  "Updated"
                )}
              </TableHead>
              <TableHead />
            </tr>
          }
        >
          {reportStructures.map((structure) => (
            <TableRow key={`structure${structure.id}`}>
              <TableData>{structure.label}</TableData>
              <TableData>
                {structure.isIndividual
                  ? t(
                      "components.report-structures-table.template-table.labels.individual",
                      "Single-answer Report"
                    )
                  : t(
                      "components.report-structures-table.template-table.labels.aggregate",
                      "Aggregate Report"
                    )}
              </TableData>
              <TableData>{formatDate(structure.updatedAt)}</TableData>
              <TableData>
                <Link
                  to={`reportstructures/${structure.id}/reportbuilder`}
                  className="flex justify-end"
                >
                  <Icons.Next className="text-4xl" />
                </Link>
              </TableData>
            </TableRow>
          ))}
        </Table>
      ) : (
        t(
          "components.report-structures-table.messages.warning.no-templates",
          "No templates found, create one!"
        )
      )}
      <ActionModal
        title={t(
          "components.report-structures-table.modals.create-template.title",
          "New Template"
        )}
        modalOpen={reportTemplateModalOpen}
        closeModal={() => setReportTemplateModalOpen(false)}
        actions={
          <Button
            onClick={onSaveTemplate}
            color="primary"
            size="sm"
            isLoading={createReportStructureState.loading}
            start={<Icons.Save className="text-xl" />}
          >
            {t(
              "components.report-structures-table.modals.create-template.save",
              "Save Template"
            )}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveTemplate}>
          <Input
            id="template-name"
            label={t(
              "components.report-structures-table.modals.create-template.name-label",
              "Title"
            )}
            placeholder={t(
              "components.report-structures-table.modals.create-template.name-placeholder",
              "title"
            )}
            value={templateLabel}
            onChange={setTemplateLabel}
          />
          <Checkbox
            id="template-aggregate-report"
            label={t(
              "components.report-structures-table.modals.create-template.aggregate-label",
              "Aggregate report"
            )}
            checked={!templateIsIndividual}
            toggle={() => setTemplateIsIndividual(!templateIsIndividual)}
          />
        </form>
      </ActionModal>
      <ActionModal
        title={t(
          "components.report-structures-table.modals.import-template.title",
          "Import Template"
        )}
        modalOpen={importTemplateModelOpen}
        closeModal={() => setImportTemplateModalOpen(false)}
        actions={
          <Button
            onClick={onImportTemplate}
            color="accent"
            size="sm"
            isLoading={copyReportStructureState.loading}
            start={<Icons.Copy className="text-xl" />}
          >
            {t(
              "components.report-structures-table.modals.import-template.save",
              "Import Template"
            )}
          </Button>
        }
      >
        <form action="form-control space-y-2">
          <Select
            id="import-template-select-survey"
            label={t(
              "components.report-structures-table.modals.import-template.select-survey-label",
              "Survey"
            )}
            placeholderLabel={t(
              "components.report-structures-table.modals.import-template.select-survey-placeholder",
              "Select a survey to import from"
            )}
            placeholderValue="-1"
            options={[
              {
                label: t(
                  "components.report-structures-table.modals.import-template.select-survey-option-surveys",
                  "Surveys"
                ),
                options: surveys
                  .filter((survey) => !survey.isTemplate)
                  .map(({ id, label }) => ({ value: id, label })),
              },
              {
                label: t(
                  "components.report-structures-table.modals.import-template.select-survey-option-survey-templates",
                  "Survey Templates"
                ),
                options: surveys
                  .filter((survey) => survey.isTemplate)
                  .map(({ id, label }) => ({ value: id, label })),
              },
            ]}
            value={selectedSurveyId}
            onChange={(value) => {
              setSelectedImportId("-1");
              setSelectedSurveyId(value);
            }}
          />
          {selectedSurveyId !== "-1" ? (
            <Select
              id="import-template-select-structure"
              label={t(
                "components.report-structures-table.modals.import-template.select-template-label",
                "Report Template"
              )}
              placeholderLabel={t(
                "components.report-structures-table.modals.import-template.select-template-placeholder",
                "Select report template"
              )}
              placeholderValue="-1"
              options={surveys
                .find(({ id }) => id === selectedSurveyId)
                ?.reportStructures.map(({ id, label }) => ({
                  label: label,
                  value: id,
                }))}
              value={selectedImportId}
              onChange={setSelectedImportId}
            />
          ) : null}
        </form>
      </ActionModal>
    </>
  );
}

export default ReportStructuresTable;
