import { gql } from "@apollo/client";

const GETREPORTPAGES = gql`
  query getReportPages {
    reportPages {
      id
      order
    }
  }
`;

const GETREPORTPAGE = gql`
  query getReportPage($id: ID!) {
    reportPage(id: $id) {
      id
      order
      reportComponents {
        id
        order
        content
        options
        componentType {
          id
          label
        }
      }
    }
  }
`;

const DELETEREPORTPAGE = gql`
  mutation DeleteReportPage($id: ID!) {
    deleteReportPage(id: $id)
  }
`;

const CREATEREPORTPAGE = gql`
  mutation CreateReportPage($order: Int!, $reportStructureId: ID!) {
    createReportPage(
      reportPage: { order: $order, reportStructureId: $reportStructureId }
    ) {
      id
    }
  }
`;

const UPDATEREPORTPAGE = gql`
  mutation UpdateReportPage($id: ID!, $order: Int, $reportStructureId: ID) {
    updateReportPage(
      id: $id
      reportPage: { order: $order, reportStructureId: $reportStructureId }
    ) {
      id
    }
  }
`;

const COPYREPORTPAGE = gql`
  mutation CopyReportPage($id: ID!) {
    copyReportPage(id: $id) {
      id
    }
  }
`;

const calls = {
  GETREPORTPAGES,
  GETREPORTPAGE,
  DELETEREPORTPAGE,
  CREATEREPORTPAGE,
  UPDATEREPORTPAGE,
  COPYREPORTPAGE,
};

export default calls;
