import React, { useState, useContext, useEffect } from "react";
import fuzzysort from "fuzzysort";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { AppStateContext } from "../components/functional/Provider";
import calls from "../graphql";
import toast from "react-hot-toast";
import { formatDate, initials, userRoleLevels } from "../utils";
import {
  LoggedInLayout,
  Breadcrumbs,
  Button,
  Icons,
  ActionModal,
  Table,
  TableRow,
  TableHead,
  TableData,
  Select,
  Input,
  TextArea,
  MinLevel,
} from "../components";
const { GETCLIENTS, GETPROJECTS, CREATEPROJECT } = calls;

export default function Projects() {
  // Hooks
  const { t } = useTranslation();
  const { appState } = useContext(AppStateContext);
  const history = useHistory();
  // State
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectClient, setProjectClient] = useState("");
  const [search, setSearch] = useState("");
  const [projects, setProjects] = useState([]);
  // Queries
  const getProjectsState = useQuery(GETPROJECTS, {
    fetchPolicy: "cache-and-network",
  });
  const getClientsState = useQuery(GETCLIENTS, {
    fetchPolicy: "cache-and-network",
  });
  // Mutations
  const [createProject, createProjectState] = useMutation(CREATEPROJECT, {
    refetchQueries: [GETPROJECTS],
  });

  if (getProjectsState.error) toast.error(getProjectsState.error.message);
  if (getClientsState.error) toast.error(getClientsState.error.message);
  const clients =
    getClientsState.data && getClientsState.data.clients
      ? getClientsState.data.clients
      : [];

  // Effects
  useEffect(() => {
    const projects =
      getProjectsState.data && getProjectsState.data.projects
        ? getProjectsState.data.projects
        : [];
    if (search) {
      const delayedSearch = setTimeout(() => {
        const results = fuzzysort.go(search, projects, {
          keys: ["label", "description", "client.name", "createdBy.userName"],
        });
        setProjects(results.map((result) => result.obj));
      }, 200);
      return () => clearTimeout(delayedSearch);
    } else {
      setProjects(projects);
    }
  }, [search, getProjectsState.data]);

  const resetState = () => {
    setProjectModalOpen(false);
    setProjectTitle("");
    setProjectDescription("");
    setProjectClient("");
  };

  const openProjectModal = () => {
    if (clients.length) {
      setProjectModalOpen(true);
    } else {
      toast.custom(
        t(
          "projects.messages.warning.no-clients",
          "No clients found, please create one first."
        )
      );
    }
  };

  const onSaveProject = async () => {
    const { userId } = appState;
    if (userId) {
      if (projectTitle && projectClient) {
        const { data } = await createProject({
          variables: {
            label: projectTitle,
            description: projectDescription,
            clientId: projectClient,
            createdById: userId,
            assignedToId: userId,
          },
        });
        if (data && data.createProject) {
          const id = data.createProject.id;
          resetState();
          toast.success(
            t("projects.messages.success.create-project", "Project created!")
          );
          history.replace(`/projects/${id}/surveys`);
        }
      } else {
        toast.custom(
          t(
            "projects.messages.error.complete-fields",
            "Please complete all fields."
          )
        );
      }
    } else {
      toast.error(
        t(
          "projects.messages.error.no-user-id",
          "Something went wrong.\nPlease try logging out and back in again."
        )
      );
    }
  };

  return (
    <>
      <LoggedInLayout
        page="Projects"
        breadCrumbs={
          <Breadcrumbs
            links={[
              {
                link: "/projects",
                label: t("projects.labels.projects", "Projects"),
              },
            ]}
          />
        }
        isLoading={getProjectsState.loading || getClientsState.loading}
      >
        {search || (projects && projects.length) ? (
          <>
            <div className="flex items-center">
              <div className="flex-grow">
                <Input
                  id="search"
                  placeholder={t("project.surveys.labels.search", "Search")}
                  value={search}
                  onChange={setSearch}
                  className="w-4/5"
                />
              </div>
              <MinLevel minLevel={userRoleLevels.Manager}>
                <Button
                  isLoading={
                    getClientsState.loading ||
                    getProjectsState.loading ||
                    createProjectState.loading
                  }
                  onClick={() => {
                    resetState();
                    openProjectModal();
                  }}
                  start={<Icons.Add className="text-2xl" />}
                >
                  {t("projects.labels.new", "New project")}
                </Button>
              </MinLevel>
            </div>
            <Table
              head={
                <tr className="h-10">
                  <TableHead>
                    {t("projects.table.headers.project", "Project")}
                  </TableHead>
                  <TableHead>
                    {t("projects.table.headers.client", "Client")}
                  </TableHead>
                  <TableHead>
                    {t("projects.table.headers.assigned-to", "Assigned To")}
                  </TableHead>
                  <TableHead>
                    {t("projects.table.headers.updated", "Updated")}
                  </TableHead>
                  <TableHead />
                </tr>
              }
            >
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableData>{project.label}</TableData>
                  <TableData>
                    <div className="flex items-center space-x-4">
                      <div className="avatar placeholder">
                        <div className="bg-base-300 text-gray-600 rounded-full w-10 h-10">
                          {initials(project.client.name)}
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">{project.client.name}</div>
                        <div className="font-normal">
                          {project.client.email}
                        </div>
                      </div>
                    </div>
                  </TableData>
                  <TableData>
                    <div className="flex items-center space-x-4">
                      <div className="avatar placeholder">
                        <div className="bg-base-300 text-gray-600 rounded-full w-10 h-10">
                          {initials(project.assignedTo.userName)}
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">
                          {project.assignedTo.userName}
                        </div>
                        <div className="font-normal">
                          {project.assignedTo.email}
                        </div>
                      </div>
                    </div>
                  </TableData>
                  <TableData>{formatDate(project.updatedAt)}</TableData>
                  <TableData>
                    <Link
                      to={`projects/${project.id}/surveys`}
                      className="flex justify-end"
                    >
                      <Icons.Next className="text-4xl" />
                    </Link>
                  </TableData>
                </TableRow>
              ))}
            </Table>
          </>
        ) : clients && clients.length ? (
          <div className="flex flex-row items-center justify-between">
            <div className="text-3xl">
              {t(
                "projects.messages.warning.no-projects",
                "No projects found, create one!"
              )}
            </div>
            <MinLevel minLevel={userRoleLevels.Manager}>
              <Button
                isLoading={
                  getClientsState.loading ||
                  getProjectsState.loading ||
                  createProjectState.loading
                }
                onClick={() => {
                  resetState();
                  openProjectModal();
                }}
                start={<Icons.Add className="text-2xl" />}
              >
                {t("projects.labels.new", "New project")}
              </Button>
            </MinLevel>
          </div>
        ) : (
          <div className="text-3xl">
            <p>
              {t(
                "projects.messages.warning.no-projects-or-clients",
                "You should create a client before starting a project."
              )}
            </p>
            <Link to="/clients" className="text-primary font-bold">
              {t(
                "projects.messages.warning.no-projects-or-clients-link",
                "Go create one!"
              )}
            </Link>
          </div>
        )}
      </LoggedInLayout>
      <ActionModal
        title={t("projects.modals.create-project.title", "New Project")}
        modalOpen={projectModalOpen}
        closeModal={() => setProjectModalOpen(false)}
        actions={
          <Button
            onClick={onSaveProject}
            isLoading={createProjectState.loading}
            size="sm"
            start={<Icons.Save className="text-lg" />}
          >
            {t("projects.modals.create-project.save-project", "Save project")}
          </Button>
        }
      >
        <form className="form-control space-y-2" onSubmit={onSaveProject}>
          <Input
            id="project-title"
            label={t("projects.modals.create-project.title-label", "Title")}
            placeholder={t(
              "projects.modals.create-project.title-placeholder",
              "title"
            )}
            value={projectTitle}
            onChange={setProjectTitle}
          />
          <TextArea
            id="description"
            label={t(
              "projects.modals.create-project.description-label",
              "Description"
            )}
            placeholder={t(
              "projects.modals.create-project.description-placeholder",
              "description"
            )}
            value={projectDescription}
            onChange={setProjectDescription}
          />
          <Select
            id="client"
            label={t("projects.modals.create-project.client-label", "Client")}
            value={projectClient}
            onChange={setProjectClient}
            options={clients.map((client) => ({
              value: client.id,
              label: client.name,
            }))}
            placeholderValue=""
            placeholderLabel={t(
              "projects.modals.create-project.client-placeholder",
              "Select Client"
            )}
          />
        </form>
      </ActionModal>
    </>
  );
}
