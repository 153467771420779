import React from "react";
import PropTypes from "prop-types";
import { useLanguageLabels } from "../../utils";
import { Icons } from "..";

/**
 * Language tabs
 * Navigation component for internationalisation purposes.
 * Adds tabs to switch between languages.
 * Optionally, + and - buttons can be shown as well.
 * @param {string} languages - Language options to be shown
 * @param {string} selectedLanguageLabel - Current option
 * @param {string} onSelectLanguage - Language select handler
 * @param {string} onAddLanguage - Plus button handler
 * @param {string} onRemoveLanguage - Minus button handler
 * @returns {node} React component
 */
function LanguageTabs({
  languages,
  selectedLanguageLabel,
  onSelectLanguage,
  onAddLanguage,
  onRemoveLanguage,
}) {
  const languageLabels = useLanguageLabels();

  return (
    <div className="tabs">
      {languages.map((language) => (
        <button
          type="button"
          key={`language-tab-${language.id}-${Math.round(
            Math.random() * 1000
          )}`}
          className={`tab tab-lifted ${
            language.label === selectedLanguageLabel ? "tab-active" : ""
          }`}
          onClick={() => onSelectLanguage(language.id)}
        >
          {languageLabels[language.label].flags} {language.label}
        </button>
      ))}
      {onAddLanguage ? (
        <button
          type="button"
          className="tab tab-lifted"
          onClick={() => onAddLanguage()}
        >
          <Icons.Add className="text-xl" />
        </button>
      ) : undefined}
      {onRemoveLanguage ? (
        <button
          type="button"
          className="tab tab-lifted"
          onClick={() => onRemoveLanguage()}
        >
          <Icons.Remove className="text-xl" />
        </button>
      ) : undefined}
    </div>
  );
}

LanguageTabs.propTypes = {
  languages: PropTypes.array,
  selectedLanguageLabel: PropTypes.string,
  onSelectLanguage: PropTypes.func,
  onAddLanguage: PropTypes.func,
  onRemoveLanguage: PropTypes.func,
};

export default LanguageTabs;
