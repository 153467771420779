import { gql } from "@apollo/client";

const UPLOADFILE = gql`
  mutation Upload($file: Upload!) {
    uploadFile(file: $file)
  }
`;

const calls = {
  UPLOADFILE,
};

export default calls;
