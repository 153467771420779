import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import calls from "../graphql";
import { useHistory } from "react-router-dom";
import { LoginLayout, Button, Icons, Input } from "../components";
import { useTranslation } from "react-i18next";
import hero from "../assets/images/login-hero.jpg";
import { validateEmail, validatePasswordStrength } from "../utils";
import toast from "react-hot-toast";
const { REGISTER } = calls;

export default function Register() {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [register, { loading }] = useMutation(REGISTER);
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await register({
        variables: {
          userName,
          email,
          password,
          confirmation,
        },
      });
      if (!data || !data.register) throw new Error("Invalid credentials");
      history.replace("/login");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <LoginLayout hero={hero}>
      <h1 className="text-5xl xl:text-6xl font-bold">
        {t("register.labels.title", "Automate your")}
      </h1>
      <h1 className="text-primary text-5xl xl:text-6xl font-bold mb-5">
        {t("register.labels.subtitle", "online surveys")}
      </h1>
      <form className="form-control space-y-2" onSubmit={onSubmit}>
        <Input
          id="username"
          placeholder={t("register.form.username-placeholder", "user name")}
          value={userName}
          onChange={setUserName}
        />
        <Input
          id="email"
          placeholder={t("register.form.email-placeholder", "email address")}
          value={email}
          hasWarning={!validateEmail(email) && !!email}
          onChange={setEmail}
        />
        <Input
          id="password"
          placeholder={t("register.form.password-placeholder", "password")}
          value={password}
          onChange={setPassword}
          hasWarning={!validatePasswordStrength(password) && !!password}
          isPassword={true}
        />
        <Input
          id="confirm-password"
          placeholder={t(
            "register.form.confirmation-placeholder",
            "confirm password"
          )}
          value={confirmation}
          onChange={setConfirmation}
          hasWarning={!!confirmation && confirmation !== password}
          isPassword={true}
        />
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <Button
            onClick={onSubmit}
            color="primary"
            start={<Icons.Register className="text-xl" />}
            className="flex-grow"
          >
            {t("register.labels.signup", "Sign up")}
          </Button>
          <Button
            isLink={true}
            color="neutral"
            to="/login"
            start={<Icons.Login className="text-xl" />}
            className="flex-grow"
            isLoading={loading}
          >
            {t("register.labels.login", "Log in")}
          </Button>
        </div>
      </form>
    </LoginLayout>
  );
}
