import { gql } from "@apollo/client";

const GETREPORTCOMPONENTS = gql`
  query getReportComponents {
    reportComponents {
      id
      content
      options
      order
      reportPage
      componentType {
        id
        label
      }
      questionCategories {
        id
        label
        color
      }
      questionTags {
        id
        label
      }
    }
  }
`;

const GETREPORTCOMPONENT = gql`
  query getReportComponent($id: ID!) {
    reportComponent(id: $id) {
      id
      content
      options
      order
      reportPage
      componentType {
        id
        label
      }
      questionCategories {
        id
        label
        color
      }
      questionTags {
        id
        label
      }
    }
  }
`;

const DELETEREPORTCOMPONENT = gql`
  mutation DeleteReportComponent($id: ID!) {
    deleteReportComponent(id: $id)
  }
`;

const CREATEREPORTCOMPONENT = gql`
  mutation CreateReportComponent(
    $content: String!
    $options: String!
    $order: Int!
    $reportPageId: ID
    $reportComponentId: ID
    $componentTypeId: ID!
    $questionCategoryIds: [ID!]!
    $questionTagIds: [ID!]!
    $questionId: ID
  ) {
    createReportComponent(
      reportComponent: {
        content: $content
        options: $options
        order: $order
        reportPageId: $reportPageId
        reportComponentId: $reportComponentId
        componentTypeId: $componentTypeId
        questionCategoryIds: $questionCategoryIds
        questionTagIds: $questionTagIds
        questionId: $questionId
      }
    ) {
      id
    }
  }
`;

const UPDATEREPORTCOMPONENT = gql`
  mutation UpdateReportComponent(
    $id: ID!
    $content: String
    $options: String
    $order: Int
    $reportPageId: ID
    $reportComponentId: ID
    $componentTypeId: ID
    $questionCategoryIds: [ID!]
    $questionTagIds: [ID!]
    $questionId: ID
  ) {
    updateReportComponent(
      id: $id
      reportComponent: {
        content: $content
        options: $options
        order: $order
        reportPageId: $reportPageId
        reportComponentId: $reportComponentId
        componentTypeId: $componentTypeId
        questionCategoryIds: $questionCategoryIds
        questionTagIds: $questionTagIds
        questionId: $questionId
      }
    ) {
      id
    }
  }
`;

const GETCOMPONENTTYPES = gql`
  query GetComponentTypes {
    componentTypes {
      id
      label
      allowSubcomponents
    }
  }
`;

const GETCOMPONENTTYPE = gql`
  query GetComponentType($id: ID!) {
    componentType(id: $id) {
      id
      label
      allowSubcomponents
    }
  }
`;

const calls = {
  GETREPORTCOMPONENTS,
  GETREPORTCOMPONENT,
  DELETEREPORTCOMPONENT,
  CREATEREPORTCOMPONENT,
  UPDATEREPORTCOMPONENT,
  GETCOMPONENTTYPES,
  GETCOMPONENTTYPE,
};

export default calls;
