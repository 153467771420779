import React from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

/**
 * Date picker input
 * @param {function} onChange - Returns Date object upon selection
 * @param {date} value - Current selected JS date object
 * @param {string} id - Input id
 * @param {string} label - Input label
 * @param {date} min - Min allowed date
 * @param {date} max - Max allowed date
 * @param {string} className - ClassName overwrite
 * @returns {node} Date input react component
 */
function DatePicker({ onChange, value, id, label, min, max, className }) {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      {label ? <label htmlFor={id}>{label}</label> : undefined}
      <input
        id={id}
        name={id}
        type="date"
        className={`input input-bordered ${className}`}
        min={min ? formatDate(min) : undefined}
        max={max ? formatDate(max) : undefined}
        value={value ? formatDate(value) : undefined}
        onChange={(e) => {
          const date = e.target.value;
          if (!date) {
            onChange(new Date());
            return;
          }
          if (
            (min && formatDate(min) > date) ||
            (max && formatDate(max) < date)
          ) {
            toast.custom(
              t(
                "components.date-picker.messages.warning.pick-in-range",
                "Please select a date in the range."
              )
            );
            return;
          }
          onChange(new Date(date));
        }}
      />
    </div>
  );
}

function formatDate(date) {
  try {
    return date.toLocaleDateString("sv");
  } catch {
    return new Date().toLocaleDateString("sv");
  }
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.object,
  max: PropTypes.object,
  className: PropTypes.string,
};

export default DatePicker;
