import { gql } from "@apollo/client";

const CREATERESPONSETASK = gql`
  mutation CreateResponseTask(
    $publishedSurveyId: ID!
    $emailFieldId: ID!
    $reportStructureId: ID
  ) {
    createResponseTask(
      responseTask: {
        publishedSurveyId: $publishedSurveyId
        emailFieldId: $emailFieldId
        reportStructureId: $reportStructureId
      }
    ) {
      id
    }
  }
`;

const UPDATERESPONSETASK = gql`
  mutation UpdateResponseTask(
    $publishedSurveyId: ID!
    $emailFieldId: ID!
    $reportStructureId: ID
  ) {
    updateResponseTask(
      id: $id
      responseTask: {
        publishedSurveyId: $publishedSurveyId
        emailFieldId: $emailFieldId
        reportStructureId: $reportStructureId
      }
    ) {
      id
    }
  }
`;

const DELETERESPONSETASK = gql`
  mutation DeleteResponseTask($id: ID!) {
    deleteResponseTask(id: $id)
  }
`;

const GETRESPONSETASKS = gql`
  query GetResponseTasks {
    responseTasks {
      id
      isRecurring
      scheduleAt
      endAt
      schedulePeriod
      createdAt
      updatedAt
      respondentList {
        id
        label
      }
      emails {
        id
        language {
          id
          label
        }
        from
        subject
        message
      }
    }
  }
`;

const GETRESPONSETASK = gql`
  query GetResponseTask($id: ID!) {
    responseTask(id: $id) {
      id
      isRecurring
      scheduleAt
      endAt
      schedulePeriod
      createdAt
      updatedAt
      respondentList {
        id
        label
      }
      emails {
        id
        language {
          id
          label
        }
        from
        subject
        message
      }
    }
  }
`;

const calls = {
  CREATERESPONSETASK,
  UPDATERESPONSETASK,
  DELETERESPONSETASK,
  GETRESPONSETASKS,
  GETRESPONSETASK,
};

export default calls;
