import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, QuestionPage } from "../../../../components";

export default function Question() {
  // Hooks
  const { t } = useTranslation();
  const { projectId, surveyId, questionId } = useParams();
  // State
  const [sectionId, setSectionId] = useState("");
  // Effects
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sectionIdParam = query.get("sectionId");
    if (sectionIdParam) setSectionId(sectionIdParam);
  }, []);

  return (
    <QuestionPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t(
                "project.survey.question.breadcrumbs.projects",
                "Projects"
              ),
              link: `/projects`,
            },
            {
              label: t(
                "project.survey.question.breadcrumbs.surveys",
                "Surveys"
              ),
              link: `/projects/${projectId}/surveys`,
            },
            {
              label: t(
                "project.survey.question.breadcrumbs.dashboard",
                "Dashboard"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
            },
            {
              label: t(
                "project.survey.question.breadcrumbs.questions",
                "Questions"
              ),
              link: {
                pathname: `/projects/${projectId}/surveys/${surveyId}/questions`,
                hash: sectionId ? `#section-${sectionId}` : "",
              },
            },
            {
              label: t(
                "project.survey.question.breadcrumbs.question",
                "Question"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/questions/${questionId}/question`,
            },
          ]}
          back={{
            pathname: `/projects/${projectId}/surveys/${surveyId}/questions`,
            hash: sectionId ? `#section-${sectionId}` : "",
          }}
        />
      }
      backURL={{
        pathname: `/projects/${projectId}/surveys/${surveyId}/questions`,
        hash: sectionId ? `#section-${sectionId}` : "",
      }}
      surveyId={surveyId}
      questionId={questionId}
    />
  );
}
