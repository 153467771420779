import { gql } from "@apollo/client";

const CREATESURVEY = gql`
  mutation CreateSurvey(
    $label: String!
    $description: String
    $isTemplate: Boolean!
    $derivedFromId: ID
    $projectId: ID
    $languageIds: [ID!]
  ) {
    createSurvey(
      survey: {
        label: $label
        description: $description
        isTemplate: $isTemplate
        derivedFromId: $derivedFromId
        projectId: $projectId
        languageIds: $languageIds
      }
    ) {
      id
    }
  }
`;

const SAVESURVEYASTEMPLATE = gql`
  mutation SaveSurveyAsTemplate(
    $id: ID!
    $label: String!
    $description: String
  ) {
    saveSurveyAsTemplate(
      id: $id
      survey: { label: $label, description: $description }
    ) {
      id
    }
  }
`;

const UPDATESURVEY = gql`
  mutation UpdateSurvey(
    $id: ID!
    $label: String
    $description: String
    $isArchived: Boolean
    $projectId: ID
    $languageIds: [ID!]
  ) {
    updateSurvey(
      id: $id
      survey: {
        label: $label
        description: $description
        projectId: $projectId
        isArchived: $isArchived
        languageIds: $languageIds
      }
    ) {
      id
    }
  }
`;

const ARCHIVESURVEY = gql`
  mutation UpdateSurvey($id: ID!) {
    updateSurvey(id: $id, survey: { isArchived: true }) {
      id
    }
  }
`;

const UNARCHIVESURVEY = gql`
  mutation UpdateSurvey($id: ID!) {
    updateSurvey(id: $id, survey: { isArchived: false }) {
      id
    }
  }
`;

const DELETESURVEY = gql`
  mutation DeleteSurvey($id: ID!) {
    deleteSurvey(id: $id)
  }
`;

const GETSURVEYS = gql`
  query GetSurveys {
    surveys {
      id
      label
      description
      isArchived
      createdAt
      updatedAt
    }
  }
`;

const GETSURVEYTEMPLATES = gql`
  query GetSurveyTemplates {
    surveyTemplates {
      id
      label
      description
      isArchived
      createdAt
      updatedAt
    }
  }
`;

const GETSURVEY = gql`
  query GetSurvey($id: ID!) {
    survey(id: $id) {
      id
      label
      description
      createdAt
      updatedAt
      isArchived
    }
  }
`;

const EXPORTSURVEY = gql`
  mutation ExportSurvey(
    $id: ID!
    $format: String!
    $surveyResponseIds: [ID!]!
  ) {
    exportSurvey(
      id: $id
      options: { format: $format, surveyResponseIds: $surveyResponseIds }
    )
  }
`;

const GETSURVEYCLIENT = gql`
  query GetSurvey($id: ID!) {
    survey(id: $id) {
      id
      label
      description
      createdAt
      updatedAt
      isArchived
      languages {
        id
        label
      }
      project {
        id
        label
        client {
          id
          name
          email
          industry
          url
          createdAt
          updatedAt
          respondentLists {
            id
            label
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

const GETSURVEYSURVEYSECTIONS = gql`
  query GetSurveySurveySections($surveyId: ID!) {
    survey(id: $surveyId) {
      id
      label
      sections {
        id
        label
        order
      }
    }
  }
`;

const GETSURVEYSURVEYSECTIONSQUESTIONS = gql`
  query GetSurveySurveySectionsQuestions($surveyId: ID!) {
    survey(id: $surveyId) {
      id
      label
      languages {
        id
        label
      }
      sections {
        id
        label
        order
        questions {
          id
          order
          direction
          questionType {
            id
            label
          }
          questionCategories {
            id
            label
            color
          }
          questionTags {
            id
            label
            category {
              id
            }
          }
          translations {
            id
            label
            description
            language {
              id
              label
            }
          }
        }
      }
    }
  }
`;

const GETSURVEYPUBLICATIONS = gql`
  query GetSurveyPublications($id: ID!) {
    survey(id: $id) {
      id
      publishedSurveys {
        id
        label
        createdAt
        updatedAt
        typeformSurveys {
          id
          url
          reference
          language {
            id
            label
          }
        }
        responseTasks {
          id
          emails {
            id
            subject
            message
            from
            language {
              id
              label
            }
          }
        }
      }
    }
  }
`;

const GETSURVEYREPORTS = gql`
  query GetSurveyReports($id: ID!) {
    survey(id: $id) {
      id
      reports {
        id
        label
        location
        createdAt
        updatedAt
        reportStructure {
          id
          label
        }
        respondent {
          id
          name
          email
        }
      }
    }
  }
`;

const GETSURVEYREPORTSTRUCTURES = gql`
  query GetSurveyReportStuctures($id: ID!) {
    survey(id: $id) {
      id
      reportStructures {
        id
        label
        isTemplate
        isIndividual
        createdAt
        updatedAt
      }
    }
  }
`;

const GETSURVEYSREPORTSTRUCTURES = gql`
  query GetSurveySReportStuctures {
    surveys {
      id
      label
      isTemplate
      reportStructures {
        id
        label
      }
    }
    surveyTemplates {
      id
      label
      isTemplate
      reportStructures {
        id
        label
      }
    }
  }
`;

const GETSURVEYRESPONSES = gql`
  query GetSurveyResponses($id: ID!) {
    survey(id: $id) {
      id
      publishedSurveys {
        id
        label
        createdAt
        updatedAt
        surveyResponses {
          id
          createdAt
          updatedAt
          submittedAt
          respondent {
            id
            name
            email
          }
        }
      }
    }
  }
`;

const SURVEYTRANSLATIONSCOMPLETE = gql`
  query SurveyTranslationsComplete($id: ID!, $languageIds: [ID!]!) {
    surveyTranslationsComplete(id: $id, languageIds: $languageIds)
  }
`;

const calls = {
  CREATESURVEY,
  SAVESURVEYASTEMPLATE,
  UPDATESURVEY,
  ARCHIVESURVEY,
  UNARCHIVESURVEY,
  DELETESURVEY,
  GETSURVEYS,
  GETSURVEYTEMPLATES,
  GETSURVEY,
  EXPORTSURVEY,
  GETSURVEYSURVEYSECTIONS,
  GETSURVEYSURVEYSECTIONSQUESTIONS,
  GETSURVEYPUBLICATIONS,
  GETSURVEYREPORTS,
  GETSURVEYCLIENT,
  SURVEYTRANSLATIONSCOMPLETE,
  GETSURVEYREPORTSTRUCTURES,
  GETSURVEYSREPORTSTRUCTURES,
  GETSURVEYRESPONSES,
};

export default calls;
