import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, QuestionsPage } from "../../../components";

export default function Questions() {
  // Hooks
  const { projectId, surveyId } = useParams();
  const { t } = useTranslation();

  return (
    <QuestionsPage
      breadCrumbs={
        <Breadcrumbs
          links={[
            {
              label: t(
                "project.survey.questions.breadcrumbs.projects",
                "Projects"
              ),
              link: `/projects`,
            },
            {
              label: t(
                "project.survey.questions.breadcrumbs.surveys",
                "Surveys"
              ),
              link: `/projects/${projectId}/surveys`,
            },
            {
              label: t(
                "project.survey.questions.breadcrumbs.dashboard",
                "Dashboard"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/dashboard`,
            },
            {
              label: t(
                "project.survey.questions.breadcrumbs.questions",
                "Questions"
              ),
              link: `/projects/${projectId}/surveys/${surveyId}/questions`,
            },
          ]}
          back={`/projects/${projectId}/surveys/${surveyId}/dashboard`}
        />
      }
      questionURL={`/projects/${projectId}/surveys/${surveyId}/questions`}
      surveyId={surveyId}
    />
  );
}
