import { gql } from "@apollo/client";

const CREATEPUBLISHEDSURVEY = gql`
  mutation CreatePublishedSurvey(
    $label: String!
    $surveyId: ID!
    $languageIds: [ID!]!
  ) {
    createPublishedSurvey(
      publishedSurvey: {
        label: $label
        surveyId: $surveyId
        languageIds: $languageIds
      }
    ) {
      id
    }
  }
`;

const DELETEPUBLISHEDSURVEY = gql`
  mutation DeletePublishedSurvey($id: ID!) {
    deletePublishedSurvey(id: $id)
  }
`;

const GETPUBLISHEDSURVEYS = gql`
  query GetPublishedSurveys {
    publishedSurveys {
      id
      label
      typeformSurveys {
        id
        url
        reference
        language {
          id
          label
        }
      }
    }
  }
`;

const GETPUBLISHEDSURVEY = gql`
  query GetPublishedSurvey($id: ID!) {
    publishedSurvey(id: $id) {
      id
      label
      typeformSurveys {
        id
        url
        reference
        language {
          id
          label
        }
      }
    }
  }
`;

const calls = {
  CREATEPUBLISHEDSURVEY,
  DELETEPUBLISHEDSURVEY,
  GETPUBLISHEDSURVEYS,
  GETPUBLISHEDSURVEY,
};

export default calls;
