import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { HexColorInput, HexColorPicker } from "react-colorful";
import {
  Select,
  Input,
  Icons,
  SelectCategoriesOrTags,
  SelectQuestionOrCategoriesOrTags,
  Checkbox,
} from "../..";
import { getTranslation } from "../../../utils";

/**
 * Report component options form.
 * This form sets the component options for all given component types.
 * @param {array} surveySections - Sections of the survey
 * @param {object} componentType - The type of the selected component
 * @param {string} componentContent - Content of the component
 * @param {function} setComponentContent - Setter function
 * @param {object} componentOptions - Component options
 * @param {function} setComponentOptions - Setter function
 * @param {object} componentQuestionId - Id of the question related to the selected component
 * @param {function} setComponentQuestionId - Setter function
 * @param {array} questionCategories - All supported question categories
 * @param {array} questionTags - All supported question tags
 * @param {array} componentQuestionCategoryIds - Selected question category Ids for the component
 * @param {function} setComponentQuestionCategoryIds - Setter function
 * @param {array} componentQuestionTagIds - Selected question tag Ids for the component
 * @param {function} setComponentQuestionTagIds - Setter function
 * @returns {node} React component
 */
export default function ReportComponentOptionsForm({
  surveySections,
  componentType,
  componentContent,
  setComponentContent,
  componentOptions,
  setComponentOptions,
  componentQuestionId,
  setComponentQuestionId,
  questionCategories,
  questionTags,
  componentQuestionCategoryIds,
  setComponentQuestionCategoryIds,
  componentQuestionTagIds,
  setComponentQuestionTagIds,
}) {
  // Hooks
  const { t, i18n } = useTranslation();
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: "image/jpeg,image/png",
    });
  // State
  const [image, setImage] = useState(undefined);
  // Effects
  useEffect(() => {
    if (
      componentType &&
      componentType.label === "Image" &&
      typeof componentContent === "string"
    ) {
      setImage(componentContent);
    } else {
      setImage(false);
    }
  }, [componentType]);
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      if (file.size > 5e6) {
        toast.custom(
          t(
            "components.report-component-options-form.image-form.max-size",
            "Maximum allowed file size is 5MB."
          )
        );
      } else {
        setComponentContent(file);
        var reader = new FileReader();
        reader.onload = function () {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else if (acceptedFiles && acceptedFiles.length > 1) {
      toast.custom(
        t(
          "components.report-component-options-form.image-form.only-one-image",
          "Please select only a single image"
        )
      );
      setImage(undefined);
    }
  }, [acceptedFiles]);
  useEffect(() => {
    if (fileRejections.length) {
      toast.custom(
        t(
          "components.report-component-options-form.image-form.only-image",
          "Only PNG and JPEG files are supported!"
        )
      );
      setImage(undefined);
    }
  }, [fileRejections]);

  switch (componentType.label) {
    case "Heading": {
      const onChangeHeadingSize = (size) => {
        setComponentOptions({
          ...componentOptions,
          style: {
            ...(componentOptions?.style || {}),
            headingSize: size,
          },
        });
      };

      const toggleProperty = (prop) => {
        setComponentOptions({
          ...componentOptions,
          style: {
            ...(componentOptions?.style || {}),
            [prop]: !componentOptions?.style?.[prop],
          },
        });
      };

      const onChangeColor = (c) => {
        setComponentOptions({
          ...componentOptions,
          style: {
            ...(componentOptions?.style || {}),
            color: c,
          },
        });
      };

      return (
        <>
          <Input
            id="heading-content"
            label={t(
              "components.report-component-options-form.heading-form.heading-label",
              "Heading"
            )}
            value={componentContent}
            onChange={setComponentContent}
            placeholder={t(
              "components.report-component-options-form.heading-form.heading-placeholder",
              "heading"
            )}
          />
          <Select
            id="heading-size"
            label={t(
              "components.report-component-options-form.heading-form.heading-size-label",
              "Heading size"
            )}
            value={
              componentOptions?.style?.headingSize
                ? componentOptions.style.headingSize
                : "-1"
            }
            onChange={onChangeHeadingSize}
            placeholderValue={"-1"}
            placeholderLabel={t(
              "components.report-component-options-form.heading-form.heading-size-placeholder",
              "select heading size"
            )}
            options={[...new Array(5)].map((_, i) => ({
              value: i + 1,
              label: `${i + 1}`,
            }))}
          />
          <div className="flex flex-row items-center space-x-6">
            <Checkbox
              id="heading-bold"
              label={t(
                "components.report-component-options-form.heading-form.heading-bold-label",
                "Bold"
              )}
              checked={componentOptions.style && componentOptions.style.bold}
              toggle={() => toggleProperty("bold")}
            />
            <Checkbox
              id="heading-italic"
              label={t(
                "components.report-component-options-form.heading-form.heading-italic-label",
                "Italic"
              )}
              checked={componentOptions.style && componentOptions.style.italic}
              toggle={() => toggleProperty("italic")}
            />
            <Checkbox
              id="heading-underlined"
              label={t(
                "components.report-component-options-form.heading-form.heading-underlined-label",
                "Underlined"
              )}
              checked={
                componentOptions.style && componentOptions.style.underlined
              }
              toggle={() => toggleProperty("underlined")}
            />
          </div>
          <label htmlFor="heading-color">
            {t(
              "components.report-component-options-form.heading-form.heading-color-label",
              "Color"
            )}
          </label>
          <HexColorInput
            id="heading-color"
            color={componentOptions?.style?.color || "#555050"}
            onChange={onChangeColor}
            prefixed={true}
            className="input input-bordered"
          />
          <HexColorPicker
            className="test"
            color={componentOptions?.style?.color || "#555050"}
            onChange={onChangeColor}
          />
        </>
      );
    }
    case "Paragraph": {
      const onChangeParagraphCenter = (center) => {
        setComponentOptions({
          ...componentOptions,
          style: {
            ...componentOptions.style,
            center: center,
          },
        });
      };

      // TODO: Add info for templating and markdown
      // TODO: Add templating support
      return (
        <>
          <Checkbox
            id="image-center"
            label={t(
              "components.report-component-options-form.paragraph-form.centered-label",
              "Paragraph centered"
            )}
            checked={
              componentOptions &&
              componentOptions.style &&
              componentOptions.style.center
            }
            toggle={() =>
              onChangeParagraphCenter(
                !componentOptions.style || !componentOptions.style.center
              )
            }
          />
          <div className="h-96 flex flex-col">
            <MarkdownEditor
              value={componentContent}
              onChange={(_editor, _data, value) => setComponentContent(value)}
              className="flex-grow"
              height={350}
              visible={false}
              toolbars={["bold", "italic", "underline", "link"]}
            />
          </div>
        </>
      );
    }
    case "Image": {
      const onChangeImageWidth = (width) => {
        if (isNaN(parseFloat(width)) && width !== "-") {
          toast.error(
            t(
              "components.report-component-options-form.image-form.width-input-numeric",
              "A width can only have numeric values."
            )
          );
          return;
        }
        setComponentOptions({
          ...componentOptions,
          image: {
            ...componentOptions.image,
            width: parseFloat(width),
          },
        });
      };
      const onChangeImageCenter = (center) => {
        setComponentOptions({
          ...componentOptions,
          image: {
            ...componentOptions.image,
            center: center,
          },
        });
      };
      return (
        <>
          <div className="flex flex-row items-end">
            <Input
              id="image-width"
              label={t(
                "components.report-component-options-form.image-form.image-width-label",
                "Image width"
              )}
              value={
                componentOptions && componentOptions.image
                  ? componentOptions.image.width
                  : 0
              }
              onChange={onChangeImageWidth}
              placeholder={t(
                "components.report-component-options-form.image-form.image-width-placeholder",
                "width"
              )}
              className="flex-grow"
            />
            <p className="pb-3 ml-2">cm</p>
          </div>
          <Checkbox
            id="image-center"
            label={t(
              "components.report-component-options-form.image-form.image-center-label",
              "Image centered"
            )}
            checked={
              componentOptions &&
              componentOptions.image &&
              componentOptions.image.center
            }
            toggle={() =>
              onChangeImageCenter(
                !componentOptions.image || !componentOptions.image.center
              )
            }
          />
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p className="w-full h-32 border-2 rounded-box border-base-300 border-dashed flex flex-row items-center justify-center mb-2">
              <Icons.Upload className="text-xl mr-2" />
              {t(
                "components.report-component-options-form.image-form.upload",
                "Upload image"
              )}
            </p>
          </div>
          <div className="overflow-auto max-h-72">
            {image ? <img src={image} /> : null}
          </div>
        </>
      );
    }
    case "Table": {
      const onChangeTableLabel = (value) => {
        setComponentOptions({
          ...componentOptions,
          table: {
            ...componentOptions.table,
            label: value,
          },
        });
      };

      const onChangeTableLevel = (level) => {
        setComponentOptions({
          ...componentOptions,
          table: {
            ...componentOptions.table,
            level: level,
          },
        });
        setComponentQuestionId(null);
        setComponentQuestionCategoryIds([]);
        setComponentQuestionTagIds([]);
      };

      return (
        <>
          <Input
            id="table-label"
            label={t("components.table-form.labels.table-label", "Title")}
            value={componentOptions.table ? componentOptions.table.label : ""}
            placeholder={t(
              "components.table-form.labels.table-label-placeholder",
              "title"
            )}
            onChange={onChangeTableLabel}
          />
          <SelectQuestionOrCategoriesOrTags
            level={componentOptions.table ? componentOptions.table.level : ""}
            onChangeLevel={onChangeTableLevel}
            questionCategories={questionCategories}
            selectedCategoryIds={componentQuestionCategoryIds}
            setSelectedCategoryIds={setComponentQuestionCategoryIds}
            questionTags={questionTags}
            selectedTagIds={componentQuestionTagIds}
            setSelectedTagIds={setComponentQuestionTagIds}
            sections={surveySections}
            selectedQuestionId={componentQuestionId}
            setSelectedQuestionId={setComponentQuestionId}
            allowedQuestionTypes={[
              "Integer",
              "Likert",
              "MultipleChoice",
              "NetPromotorScore",
              "OpenText",
              "Score10",
              "ScoredMultipleChoice",
              "YesNo",
            ]}
          />
        </>
      );
    }
    case "Chart": {
      const onChangeChartLabel = (value) => {
        setComponentOptions({
          ...componentOptions,
          chart: {
            ...componentOptions.chart,
            label: value,
          },
        });
      };

      const onChangeChartLevel = (level) => {
        setComponentOptions({
          ...componentOptions,
          chart: {
            ...componentOptions.chart,
            level: level,
            type: level === "question" ? undefined : "Bar",
          },
        });
        setComponentQuestionId(null);
        setComponentQuestionCategoryIds([]);
        setComponentQuestionTagIds([]);
      };

      const onChangeChartType = (value) => {
        setComponentOptions({
          ...componentOptions,
          chart: {
            ...componentOptions.chart,
            type: value,
          },
        });
      };
      return (
        <>
          <Input
            id="chart-label"
            label={t("components.chart-form.labels.chart-label", "Title")}
            value={componentOptions.chart ? componentOptions.chart.label : ""}
            placeholder={t(
              "components.chart-form.labels.chart-label-placeholder",
              "title"
            )}
            onChange={onChangeChartLabel}
          />
          <SelectQuestionOrCategoriesOrTags
            level={componentOptions?.chart?.level || ""}
            onChangeLevel={onChangeChartLevel}
            questionCategories={questionCategories}
            selectedCategoryIds={componentQuestionCategoryIds}
            setSelectedCategoryIds={setComponentQuestionCategoryIds}
            questionTags={questionTags}
            selectedTagIds={componentQuestionTagIds}
            setSelectedTagIds={setComponentQuestionTagIds}
            sections={surveySections}
            selectedQuestionId={componentQuestionId}
            setSelectedQuestionId={setComponentQuestionId}
            allowedQuestionTypes={[
              "Likert",
              "MultipleChoice",
              "ScoredMultipleChoice",
              "YesNo",
            ]}
          />
          {componentOptions?.chart?.level === "question" && (
            <Select
              id="chart-type"
              label={t(
                "components.report-component-options-form.chart-form.chart-type-label",
                "Chart type"
              )}
              value={
                componentOptions && componentOptions.chart
                  ? componentOptions.chart.type
                  : ""
              }
              onChange={onChangeChartType}
              placeholderValue={""}
              placeholderLabel={t(
                "components.report-component-options-form.chart-form.chart-type-placeholder",
                "Select chart type"
              )}
              options={[
                {
                  value: "Bar",
                  label: t(
                    "components.report-component-options-form.chart-form.chart-types.bar",
                    "Bar chart"
                  ),
                },
                {
                  value: "Pie",
                  label: t(
                    "components.report-component-options-form.chart-form.chart-types.pie",
                    "Pie chart"
                  ),
                },
              ]}
            />
          )}
        </>
      );
    }
    case "ScoreCard": {
      const onChangeLevel = (isCategoryLevel) => {
        setComponentOptions({
          ...componentOptions,
          scorecard: {
            ...componentOptions.scorecard,
            level: isCategoryLevel ? "category" : "tag",
          },
        });
        setComponentQuestionCategoryIds([]);
        setComponentQuestionTagIds([]);
      };

      const onChangeScore = (score) => {
        if (isNaN(parseInt(score)) && score !== "-") {
          toast.error(
            t(
              "components.report-component-options-form.image-form.width-input-numeric",
              "A width can only have numeric values."
            )
          );
          return;
        }

        setComponentOptions({
          ...componentOptions,
          scorecard: {
            ...componentOptions.scorecard,
            maxScore: parseInt(score),
          },
        });
      };

      return (
        <>
          <Input
            id="scorecard-label"
            label={t(
              "components.report-component-options-form.scorecard-form.scorecard-label-label",
              "Label"
            )}
            placeholder={t(
              "components.report-component-options-form.scorecard-form.scorecard-label-placeholder",
              "label"
            )}
            value={componentContent}
            onChange={setComponentContent}
          />
          <Input
            id="scorecard-max-score"
            label={t(
              "components.report-component-options-form.scorecard-form.scorecard-max-score-label",
              "Max score"
            )}
            placeholder={t(
              "components.report-component-options-form.scorecard-form.scorecard-max-score-placeholder",
              "score"
            )}
            value={
              componentOptions.scorecard
                ? componentOptions.scorecard.maxScore
                : 0
            }
            onChange={onChangeScore}
          />
          <SelectCategoriesOrTags
            level={
              componentOptions.scorecard ? componentOptions.scorecard.level : ""
            }
            onChangeLevel={onChangeLevel}
            questionCategories={questionCategories}
            questionTags={questionTags}
            selectedCategoryIds={componentQuestionCategoryIds}
            setSelectedCategoryIds={setComponentQuestionCategoryIds}
            selectedTagIds={componentQuestionTagIds}
            setSelectedTagIds={setComponentQuestionTagIds}
          />
        </>
      );
    }
    case "NetPromotorScore": {
      return (
        <>
          <Input
            id="nps-label"
            label={t("components.nps-form.labels.nps-label", "Label")}
            value={componentContent}
            placeholder={t(
              "components.nps-form.labels.nps-label-placeholder",
              "label"
            )}
            onChange={setComponentContent}
          />
          <Select
            id="component-question"
            label={t(
              "components.nps-form.labels.component-question-label",
              "Question"
            )}
            value={componentQuestionId}
            placeholderLabel={t(
              "components.nps-form.labels.component-question-placeholder",
              "Select question"
            )}
            placeholderValue={"-1"}
            onChange={setComponentQuestionId}
            options={surveySections.map((section) => ({
              label: section.label,
              options: section.questions
                .filter(
                  (question) =>
                    question.questionType.label === "NetPromotorScore"
                )
                .map((question) => ({
                  label: getTranslation(question.translations, i18n.language)
                    .label,
                  value: question.id,
                })),
            }))}
          />
        </>
      );
    }
    case "Row": {
      const onChangeRowCenter = (center) => {
        setComponentOptions({
          ...componentOptions,
          row: {
            ...componentOptions.row,
            center: center,
          },
        });
      };

      return (
        <Checkbox
          id="image-center"
          label={t(
            "components.report-component-options-form.row-form.row-center-label",
            "Center items"
          )}
          checked={
            componentOptions &&
            componentOptions.row &&
            componentOptions.row.center
          }
          toggle={() =>
            onChangeRowCenter(
              !componentOptions.row || !componentOptions.row.center
            )
          }
        />
      );
    }
    case "Conditional": {
      const onChangeLevel = (isCategoryLevel) => {
        setComponentOptions({
          ...componentOptions,
          conditional: {
            ...componentOptions.conditional,
            level: isCategoryLevel ? "category" : "tag",
          },
        });
        setComponentQuestionCategoryIds([]);
        setComponentQuestionTagIds([]);
      };

      const onChangeOperator = (operator) => {
        setComponentOptions({
          ...componentOptions,
          conditional: {
            ...componentOptions.conditional,
            operator: operator,
            lowerValue: 0,
            upperValue: 100,
          },
        });
      };

      const onChangeUpperValue = (value) => {
        setComponentOptions({
          ...componentOptions,
          conditional: {
            ...componentOptions.conditional,
            upperValue: value,
          },
        });
      };

      const onChangeLowerValue = (value) => {
        setComponentOptions({
          ...componentOptions,
          conditional: {
            ...componentOptions.conditional,
            lowerValue: value,
          },
        });
      };

      return (
        <>
          <div className="flex flex-row items-end space-x-2">
            <Select
              id="operator"
              label={t(
                "components.report-component-options-form.conditional-form.operator-label",
                "Operator"
              )}
              value={
                componentOptions.conditional
                  ? componentOptions.conditional.operator
                  : "-1"
              }
              onChange={onChangeOperator}
              placeholderValue={"-1"}
              placeholderLabel={t(
                "components.report-component-options-form.conditional-form.operator-placeholder",
                "select operator"
              )}
              options={[
                {
                  label: t(
                    "components.report-component-options-form.conditional-form.operator-gte",
                    "greater than or equal"
                  ),
                  value: ">=",
                },
                {
                  label: t(
                    "components.report-component-options-form.conditional-form.operator-gt",
                    "greater than"
                  ),
                  value: ">",
                },
                {
                  label: t(
                    "components.report-component-options-form.conditional-form.operator-lte",
                    "less than or equal"
                  ),
                  value: "<=",
                },
                {
                  label: t(
                    "components.report-component-options-form.conditional-form.operator-lt",
                    "less than"
                  ),
                  value: "<",
                },
                {
                  label: t(
                    "components.report-component-options-form.conditional-form.operator-between",
                    "between"
                  ),
                  value: "==",
                },
              ]}
              className="flex-grow"
            />
            {componentOptions.conditional &&
            (componentOptions.conditional.operator === ">=" ||
              componentOptions.conditional.operator === ">" ||
              componentOptions.conditional.operator === "==") ? (
              <>
                <Input
                  id="conditional-value"
                  label={t(
                    "components.report-component-options-form.conditional-form.value-label",
                    "Value"
                  )}
                  placeholder={t(
                    "components.report-component-options-form.conditional-form.value-placeholder",
                    "value"
                  )}
                  value={
                    componentOptions.conditional
                      ? componentOptions.conditional.lowerValue
                      : 0
                  }
                  onChange={onChangeLowerValue}
                />
                <p className="pb-3 ml-2">%</p>
              </>
            ) : null}
            {componentOptions.conditional &&
            (componentOptions.conditional.operator === "<=" ||
              componentOptions.conditional.operator === "<" ||
              componentOptions.conditional.operator === "==") ? (
              <>
                <Input
                  id="conditional-value"
                  label={t(
                    "components.report-component-options-form.conditional-form.value-label",
                    "Value"
                  )}
                  placeholder={t(
                    "components.report-component-options-form.conditional-form.value-placeholder",
                    "value"
                  )}
                  value={
                    componentOptions.conditional
                      ? componentOptions.conditional.upperValue
                      : 0
                  }
                  onChange={onChangeUpperValue}
                />
                <p className="pb-3">%</p>
              </>
            ) : null}
          </div>
          <p className="">
            {t(
              "components.report-component-options-form.conditional-form.score-from",
              "Calculate score from"
            )}
          </p>
          <SelectCategoriesOrTags
            level={
              componentOptions.conditional
                ? componentOptions.conditional.level
                : ""
            }
            onChangeLevel={onChangeLevel}
            questionCategories={questionCategories}
            questionTags={questionTags}
            selectedCategoryIds={componentQuestionCategoryIds}
            setSelectedCategoryIds={setComponentQuestionCategoryIds}
            selectedTagIds={componentQuestionTagIds}
            setSelectedTagIds={setComponentQuestionTagIds}
          />
        </>
      );
    }
    default: {
      return null;
    }
  }
}

ReportComponentOptionsForm.propTypes = {
  surveySections: PropTypes.array,
  componentType: PropTypes.object,
  componentContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setComponentContent: PropTypes.func,
  componentOptions: PropTypes.object,
  setComponentOptions: PropTypes.func,
  componentQuestionId: PropTypes.string,
  setComponentQuestionId: PropTypes.func,
  questionCategories: PropTypes.array,
  questionTags: PropTypes.array,
  componentQuestionCategoryIds: PropTypes.array,
  setComponentQuestionCategoryIds: PropTypes.func,
  componentQuestionTagIds: PropTypes.array,
  setComponentQuestionTagIds: PropTypes.func,
};
