import { gql } from "@apollo/client";

const CREATEQUESTIONROUTE = gql`
  mutation CreateQuestionRoute(
    $value: String!
    $questionFromId: ID!
    $questionToId: ID!
    $routeOperatorId: ID!
  ) {
    createQuestionRoute(
      questionRoute: {
        value: $value
        questionFromId: $questionFromId
        questionToId: $questionToId
        routeOperatorId: $routeOperatorId
      }
    ) {
      id
    }
  }
`;

const UPDATEQUESTIONROUTE = gql`
  mutation UpdateQuestionRoute(
    $id: ID!
    $value: String
    $questionFromId: ID
    $questionToId: ID
    $routeOperatorId: ID
  ) {
    updateQuestionRoute(
      id: $id
      questionRoute: {
        value: $value
        questionFromId: $questionFromId
        questionToId: $questionToId
        routeOperatorId: $routeOperatorId
      }
    ) {
      id
    }
  }
`;

const DELETEQUESTIONROUTE = gql`
  mutation DeleteQuestionRoute($id: ID!) {
    deleteQuestionRoute(id: $id)
  }
`;

const GETQUESTIONROUTES = gql`
  query GetQuestionCategories {
    questionRoutes {
      id
      value
      questionFrom {
        id
      }
      questionTo {
        id
      }
      routeOperator {
        id
        label
      }
      createdAt
      updatedAt
    }
  }
`;

const GETQUESTIONROUTE = gql`
  query GetQuestionRoute($id: ID!) {
    questionRoute(id: $id) {
      id
      value
      questionFrom {
        id
      }
      questionTo {
        id
      }
      routeOperator {
        id
        label
      }
      createdAt
      updatedAt
    }
  }
`;

const calls = {
  CREATEQUESTIONROUTE,
  UPDATEQUESTIONROUTE,
  DELETEQUESTIONROUTE,
  GETQUESTIONROUTES,
  GETQUESTIONROUTE,
};

export default calls;
