import React, { useContext } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { PageComponent } from "../..";
import { AppStateContext } from "../../functional/Provider";
import { userRoleLevels } from "../../../utils";

// Bug: React-beautiful-dnd nested in fixed/absolute div
// https://github.com/atlassian/react-beautiful-dnd/issues/499
function DraggablePortalHandler({ children, snapshot }) {
  if (snapshot.isDragging)
    return ReactDOM.createPortal(children, document.body);
  return children;
}
function ComponentsPane({
  id,
  header,
  components,
  onChangeComponents,
  onEditComponent,
  onDeleteComponent,
}) {
  const { appState } = useContext(AppStateContext);
  const { userLevel } = appState;

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (userLevel < userRoleLevels.Manager) return;
    if (!destination) return;
    if (source.index === destination.index) return;
    const movedComponents = [...components];
    const [movedComponent] = movedComponents.splice(source.index, 1);
    movedComponents.splice(destination.index, 0, movedComponent);
    onChangeComponents(movedComponents);
  };

  return (
    <div className="flex flex-grow flex-col bg-base-300 rounded-box px-5 pt-3 space-y-2">
      {header}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`droppable${id}`}>
          {(provided) => (
            <div
              className="w-full flex flex-col space-y-2 py-5"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {components && components.length
                ? components.map((component, i) => (
                    <Draggable
                      key={component.id}
                      draggableId={`draggable${id}-${component.id}`}
                      index={i}
                    >
                      {(provided, snapshot) => {
                        return (
                          <DraggablePortalHandler snapshot={snapshot}>
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                              ref={provided.innerRef}
                            >
                              <PageComponent
                                component={component}
                                onEditComponent={onEditComponent}
                                onDeleteComponent={onDeleteComponent}
                              />
                            </div>
                          </DraggablePortalHandler>
                        );
                      }}
                    </Draggable>
                  ))
                : null}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

ComponentsPane.propTypes = {
  id: PropTypes.string,
  header: PropTypes.node,
  components: PropTypes.array,
  onChangeComponents: PropTypes.func,
  onEditComponent: PropTypes.func,
  onDeleteComponent: PropTypes.func,
};

export default ComponentsPane;
