import React from "react";
import PropTypes from "prop-types";

/**
 * Textarea input component
 * @param {string} id - Input id
 * @param {string} label - Input label
 * @param {string} value - Input value
 * @param {string} placeholder - Input placeholder
 * @param {function} onChange - Input change handler
 * @param {boolean} hasError - Error state
 * @param {boolean} hasWarning - Warning state
 * @param {boolean} isDisabled - True if input should be disabled
 * @param {string} className - ClassName overwrite
 * @returns {node} React textarea component
 */
function TextArea({
  id,
  label,
  value,
  placeholder,
  onChange,
  hasError,
  hasWarning,
  isDisabled,
  className,
}) {
  return (
    <>
      {label ? <label htmlFor={id}>{label}</label> : undefined}
      <textarea
        name={label}
        id={id}
        placeholder={placeholder}
        className={`textarea textarea-bordered ${
          hasError ? "textarea-error" : ""
        } ${hasWarning ? "textarea-warning" : ""} ${className}`}
        value={value}
        onChange={isDisabled ? () => {} : (e) => onChange(e.target.value)}
        disabled={isDisabled}
      />
    </>
  );
}

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

TextArea.defaultProps = {
  id: "",
  label: "",
  value: "",
  placeholder: "",
  onChange: () => {},
  hasError: false,
  hasWarning: false,
  isDisabled: false,
  className: "",
};

export default TextArea;
